import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';

import { reducer as attributeRangeReducer } from './attributeRange/reducer';
import { epic as attributeRangeEpic } from './attributeRange/rx';

import { reducer as attributeRangeDetectorMatchesReducer } from './attributeRangeDetectorMatches';
import { epic as attributeRangeDetectorMatchesEpic } from './attributeRangeDetectorMatches';

import { reducer as commuteDetectorReducer } from './commuteDetectors/reducer';
import { epic as commuteDetectorEpic } from './commuteDetectors/rx';

import { reducer as devicePresenceReducer } from './devicePresence/reducer';
import { epic as devicePresenceEpic } from './devicePresence/rx';

import { reducer as devicePresenceMatchesReducer } from './devicePresenceMatches';
import { epic as devicePresenceMatchesEpic } from './devicePresenceMatches';

import { reducer as deviceStatusDetectorReducer } from './deviceStatusDetectors/reducer';
import { epic as deviceStatusDetectorEpic } from './deviceStatusDetectors/rx';

import { reducer as flowProcessorReducer } from './flowProcessors/reducer';
import { epic as flowProcessorEpic } from './flowProcessors/rx';

import { reducer as fuelLevelReducer } from './fuelLevel/reducer';
import { epic as fuelLevelEpic } from './fuelLevel/rx';

import { reducer as messagePublishersReducer } from './messagePublishers/reducer';
import { epic as messagePublishersEpic } from './messagePublishers/rx';

import { reducer as downsampledMessageHistoryReducer } from './downsampled-message-history/reducer';
import { epic as downsampledMessageHistoryEpic } from './downsampled-message-history/rx';

export const reducer = combineReducers({
	attributeRange: attributeRangeReducer,
	attributeRangeMatches: attributeRangeDetectorMatchesReducer,
	commuteDetectors: commuteDetectorReducer,
	devicePresence: devicePresenceReducer,
	devicePresenceMatches: devicePresenceMatchesReducer,
	deviceStatusDetectors: deviceStatusDetectorReducer,
	fuelLevel: fuelLevelReducer,
	// fuelLevelMatches: fuelLevelDetectorMatches.reducer, // TODO
	flowProcessors: flowProcessorReducer,
	messagePublishers: messagePublishersReducer,
	downsampledMessageHistory: downsampledMessageHistoryReducer
});

export const epic = combineEpics(
	attributeRangeEpic,
	attributeRangeDetectorMatchesEpic,
	commuteDetectorEpic,
	devicePresenceEpic,
	devicePresenceMatchesEpic,
	deviceStatusDetectorEpic,
	fuelLevelEpic,
	// fuelLevelDetectorMatches.epic, TODO
	flowProcessorEpic,
	messagePublishersEpic,
	downsampledMessageHistoryEpic
);

export { actions } from './actions';
