import React, { useRef } from 'react';
import { useDrop } from 'react-dnd';
import { DragItemType } from '../../share/dnd/DragItemType';
import ListItem from '../../general/list/ListItem';
import { cx } from '../../../api';
import { generateId } from '../../../misc/misc';
import Intent from '../../../misc/ObjectActionIntent';
import { className, fromProps } from '../../../lib/className';

/**
 * @param {Object} props
 * @param {Object} props.zoneGuard
 * @param {function} props.onSelect
 * @param {function} props.onIntent
 */

function ZoneGuardListItem(props) {
	const uid = useRef(generateId());

	const onClick = () => {
		props.onSelect(props.zoneGuard);
	}

	const onDrop = (item) => {
		if (item.type == DragItemType.ACTION_EDIT) {
			props.onIntent(new Intent(null, Intent.Action.Edit, props.zoneGuard));
		} else {
			const offset = cx.dom.at.offset(document.getElementById(uid.current));
			props.onIntent(new Intent(null, Intent.Action.Remove, props.zoneGuard, { offset }));
		}
	}

	const [dropState, drop] = useDrop({
		accept: [DragItemType.ACTION_EDIT, DragItemType.ACTION_REMOVE],
		drop: (item) => onDrop(item),
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		})
	});

	return (
		<ListItem
			customRef={drop}
			className={className(fromProps(props), { 'droppable': dropState.isOver && dropState.canDrop })}
			onClick={onClick}
			id={uid.current}
		>
			{props.zoneGuard.name}
		</ListItem>
	);
}

export default ZoneGuardListItem;