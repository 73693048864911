import React from 'react';

import ObjectDeviceManager from '../../share/devices/ObjectDeviceManager';
import { MessagePublisherDetails } from "./MessagePublisherDetails";

import './messagePublisherView.scss';

/**
 * @param {Object} props
 * @param {cx.ods.processors.MessagePublisher} [props.messagePublisher]
 * @param {boolean} [props.isEdit]
 * @param {boolean} [props.isDisabled]
 */

export const MessagePublisherView = ({ messagePublisher, isEdit, isDisabled}) => (
	!!messagePublisher || isEdit ?
		<div className="message-publisher-view body">
			<ObjectDeviceManager
				uris={messagePublisher ? messagePublisher.uris : null}
				disabled={isDisabled}
				editMode={isEdit}
			/>
			<MessagePublisherDetails messagePublisher={messagePublisher} isEdit={isEdit} />
		</div> : null
)
