import { api, ods, rx } from '../../api';
import { reduxSwitch } from '../tools';
import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, map, takeUntil } from 'rxjs/operators';
import { of } from 'rxjs';
import { ActionGeneratorBuilder, errorMap } from '../actions';

const defaultState = {
	details: null,
	pending: false,
	error: null
};

const actions = new ActionGeneratorBuilder('account')
	.subtype('retrieve', retrieve => retrieve.request().success('details').fail().cancel())
	.subtype('update', update => update.request('details').success().fail().cancel())
	.subtype('password', password => password.request({ password: true, token: true }).success().fail().cancel())
	.subtype('signInOptions', password => password.request({ otpEmail: false, toughenAuth: false, signInPeriod: false,  token: true }).success().fail().cancel())
	.type('clear')
	.build()
;

// ---------------------------------------------------------

function retrieveReducer(state, action) {
	switch (action.type) {
		case actions.retrieve.request.type:
			state = {
				...state,
				pending: true,
				error: null
			};
			break;
		case actions.retrieve.success.type:
			state = {
				...state,
				details: action.details,
				pending: false
			};
			break;
		case actions.retrieve.fail.type:
			state = {
				...state,
				pending: false,
				error: action.errorMessage
			};
			break;
		default:
	}
	return state;
};

function updateReducer(state, action) {
	switch (action.type) {
		case actions.update.request.type:
			state = {
				...state,
				pending: true,
				error: null
			};
			break;
		case actions.update.fail.type:
			state = {
				...state,
				pending: false,
				error: action.errorMessage
			};
			break;
		default:
	}
	return state;
};

function passwordReducer(state, action) {
	switch (action.type) {
		case actions.password.request.type:
			state = {
				...state,
				pending: true,
				error: null
			};
			break;
		case actions.password.success.type:
			state = {
				...state,
				pending: false
			};
			break;
		case actions.password.fail.type:
			state = {
				...state,
				pending: false,
				error: action.errorMessage
			};
			break;
		default:
	}
	return state;
};


function signInOptionsReducer(state, action) {
	switch (action.type) {
		case actions.signInOptions.request.type:
			state = {
				...state,
				pending: true,
				error: null
			};
			break;
		case actions.signInOptions.success.type:
			state = {
				...state,
				pending: false
			};
			break;
		case actions.signInOptions.fail.type:
			state = {
				...state,
				pending: false,
				error: action.errorMessage
			};
			break;
		default:
	}
	return state;
};

function clearReducer(state, action) {
	switch (action.type) {
		case actions.clear.type:
			return {
				...state,
				error: null
			};
		default:
			return state;
	}
}

const reducer = reduxSwitch([retrieveReducer, updateReducer, passwordReducer, signInOptionsReducer, clearReducer], defaultState);

// ---------------------------------------------------------

const retrieveEpic = (action$) => {
	return action$.pipe(
		ofType(actions.retrieve.request.type),
		mergeMap(action =>
			rx(api.account.retrieve).pipe(
				map(operation => actions.retrieve.success({ details: operation.response() })),
				errorMap(actions.retrieve.fail),
				takeUntil(action$.pipe(ofType(actions.retrieve.cancel.type)))
			)
		)
	)
}

const updateEpic = (action$) => {
	return action$.pipe(
		ofType(actions.update.request.type),
		mergeMap(action =>
			rx(api.account.update, action.details).pipe(
				map(operation => actions.retrieve.request()),
				errorMap(actions.update.fail),
				takeUntil(action$.pipe(ofType(actions.update.cancel.type)))
			)
		)
	)
}

const passwordEpic = (action$) => {
	return action$.pipe(
		ofType(actions.password.request.type),
		mergeMap(action => {
			const passwordUpdate = new ods.accounts.PasswordUpdate();
			passwordUpdate.password = action.password;
			passwordUpdate.token = action.token;
			return rx(api.account.passwd, passwordUpdate).pipe(
				map(operation => actions.password.success()),
				errorMap(actions.password.fail),
				takeUntil(action$.pipe(ofType(actions.password.cancel.type)))
			);
		})
	)
}

const signInOptionsEpic = (action$) => {
	return action$.pipe(
		ofType(actions.signInOptions.request.type),
		mergeMap(action => {
			const signInOptions = new ods.accounts.SignInOptions();
			if (action.otpEmail) signInOptions.otpEmail = action.otpEmail;
			if (action.toughenAuth) signInOptions.toughenAuth = action.toughenAuth;
			if (action.signInPeriod !== null) signInOptions.signInPeriod = action.signInPeriod;
			signInOptions.token = action.token;

			return rx(api.account.signInOptions, signInOptions).pipe(
				mergeMap(operation => of(actions.signInOptions.success(), actions.retrieve.request())),
				errorMap(actions.signInOptions.fail),
				takeUntil(action$.pipe(ofType(actions.signInOptions.cancel.type)))
			);
		})
	)
}

const epic = combineEpics(retrieveEpic, updateEpic, passwordEpic, signInOptionsEpic);

// ---------------------------------------------------------

export { actions, reducer, epic };
