import React from 'react';

import { ISVGPersonalProps } from 'types/svg';

export const hideGroup: ISVGPersonalProps = {
  viewBox: '0 0 16 16',
  content: (
    <>
      <path
        id="path9"
        d="M8 0a8 8 0 0 0-8 8 8 8 0 0 0 8 8 8 8 0 0 0 8-8 8 8 0 0 0-8-8zm0 2a6 6 0 0 1 6 6 6 6 0 0 1-6 6 6 6 0 0 1-6-6 6 6 0 0 1 6-6z"
        strokeWidth="0"
      />
      <path
        id="path10"
        d="M8 4a4 4 0 0 0-4 4 4 4 0 0 0 .14 1.031l.872-.87A3 3 0 0 1 5 8a3 3 0 0 1 3-3 3 3 0 0 1 .168.004l.867-.867A4 4 0 0 0 8 4zm3.863 2.965-.867.867A3 3 0 0 1 11 8a3 3 0 0 1-3 3 3 3 0 0 1-.16-.012l-.871.871A4 4 0 0 0 8 12a4 4 0 0 0 4-4 4 4 0 0 0-.137-1.035z"
        strokeWidth="0"
      />
      <path
        id="rect10"
        d="M7.936 10.314H-7.92a8 8 0 0 0 0 2H7.936a8 8 0 0 0 0-2z"
        strokeWidth="0"
        transform="rotate(-45)"
      />
    </>
  ),
};
