import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Menu, MenuItem } from '@blueprintjs/core';
import { unitsString } from '../../../misc/attributeTypes';
import { useI18n } from '../../../../i18n';
import Popup from '../../general/Popup';
import { FiMenu } from 'react-icons/fi';

/**
 * @param {Object} props
 * @param {Array.<string>} [props.uris]
 * @param {boolean} [props.disabled]
 * @param {function} props.onSelect
 */

function DeviceInputVoltageSelector(props) {
	const { f, fc } = useI18n();
	const [isOpen, setIsOpen] = useState(false);
	let content = null;

	if (props.deviceMap && props.inputVoltageMap) {
		const menuItems = Object.keys(props.inputVoltageMap).map(uri => {
			const deviceName = props.deviceMap[uri] && props.deviceMap[uri].name;
			const value = props.inputVoltageMap[uri].value;
			const units = props.inputVoltageMap[uri].units;
			const onClick = () => {
				props.onSelect(value);
				setIsOpen(false);
			}
			return (
				<MenuItem
					key={uri}
					onClick={onClick}
					text={<><span className="caption">{deviceName || uri}: </span>{value + ', ' + f({ prefix: 'units', id: units})}</>}
				/>
			);
		});
		const menu = (
			<Menu>
				{menuItems}
			</Menu>
		);
		content = (
			<Popup
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				content={menu}
				disabled={props.disabled}
			>
				<div title={fc('select current input voltage')}><FiMenu /></div>
			</Popup>
		);
	}
	return content;
}

export default connect((state, props) => {
	let inputVoltageMap = null;
	if (props.uris && state.deviceStates.map) {
		props.uris.forEach(uri => {
			const message = state.deviceStates.map[uri] && state.deviceStates.map[uri].message;
			const types = state.registry.messageFields.typeMap;
			const aTypes = state.registry.attributeTypes && state.registry.attributeTypes.codeMap;
			if (message && message.fields && types && aTypes) {
				const map = message.fields.map();
				Object.keys(map).some(fieldId => {
					if (types[fieldId] && types[fieldId].name == "input-voltage") {
						if (!inputVoltageMap) inputVoltageMap = {};
						inputVoltageMap[uri] = { value: map[fieldId], units: unitsString(aTypes[types[fieldId].fieldType].mnemonics) };
						return true;
					}
					return false;
				});
			}
		});
	}
	return {
		deviceMap: state.devices.map,
		inputVoltageMap
	};
})(DeviceInputVoltageSelector);