import {combineEpics, ofType} from "redux-observable";
import {filter, map, switchMap, withLatestFrom} from 'rxjs/operators';

import {fc, f} from '../../../../i18n';

import {formatDateTime} from '../../../misc/misc';

import {cx, api, rx} from "../../../api";
import {denominate as denominateDevices} from '../../../api/device'

import {deltaReducer, errorMap} from "../../actions";
import { datetimeEqual } from '../../../lib/datetime';
import { nullityEqual } from '../../../lib/objects';
import { valuesEqual as arraysValuesEqual } from '../../../lib/arrays';


const {addActions, getActions} = (() => {
	let builder, actions;
	const addActions = report => {
		builder = report
			.subtype('generate', generate => generate.request({parameters: true, options: false}).success({utilizations: true}).fail())
			.subtype('export', subtype => subtype.request().progress().success({csv: true}).clear())
		;
	};
	const getActions = () => {
		if (!actions) {
			if (!builder) throw new Error('Driving behaviour report actions weren\'t registered');
			actions = builder.build();
			builder = null;
		}
		return actions;
	};
	return {addActions, getActions};
})();

const withActions = target => (...args) => target(getActions(), ...args);


const defaultState = {
	parameters: null
	, utilizations: null
	, options: null
	, report: null
	, pending: false
	, error: null
	, exporting: false
	, csv: null
};

const parametersEqual = (left, right) => nullityEqual(left, right, (left, right) => {
	if (!nullityEqual(left.timeRange?.since, right.timeRange?.since, datetimeEqual)) return false;
	if (!nullityEqual(left.timeRange?.until, right.timeRange?.until, datetimeEqual)) return false;
	if (!nullityEqual(left.uris, right.uris, arraysValuesEqual)) return false;
	return true;
});

const reducer = deltaReducer(withActions((actions, state, action) => {
	switch (action.type) {
		case actions.generate.request.type:
			if (parametersEqual(state.parameters, action.parameters)) return {
				options: action.options
				, report: undefined
			};
			return {
				pending: true, error: undefined
				, parameters: action.parameters
				, utilizations: undefined
				, options: action.options
				, report: undefined
			};
		case actions.generate.success.type: return {
			pending: false
			, utilizations: action.utilizations
			, report: buildReport(action.utilizations, state.options) || []
		};
		case actions.generate.fail.type: return {
			pending: false, error: action.errorMessage
		};
		case actions.export.request.type: return {
			exporting: true
		};
		case actions.export.success.type: return {
			exporting: undefined
			, csv: action.csv
		};
		case actions.export.clear.type: return {
			csv: undefined
		}
	}
}), defaultState);

const buildReport = (utilizations, options) => {
	if (utilizations == null || utilizations.length == 0) return [];

	const totalSplit = [cx.ods.reports.UtilizationPhase.parked, cx.ods.reports.UtilizationPhase.idling, cx.ods.reports.UtilizationPhase.working];
	let grandTotals = statisticsZero(), phaseTotals = {};
	const indexed = utilizations.map(utilization => {
		const totals = statisticsZero();
		let phases = {};
		if (utilization.phases) phases = Object.fromEntries(utilization.phases.map(indexViolations).map(({phase, ...statistics}) => {
			if (totalSplit.includes(phase)) accumulateStatistics(totals, statistics);
			return [phase, statistics];
		}));
		if (options.filter) {
			let matches = false;
			if (options.filter.minimumIdling && phases.idling && 0 < totals.duration) {
				const percent = phases.idling.duration * 100 / totals.duration;
				if (options.filter.minimumIdling <= percent) matches = true;
			}
			if (options.filter.minimumViolations && 0 < totals.distance) {
				const rate = totals.violations * 100 * 1000 / totals.distance;
				if (options.filter.minimumViolations <= rate) matches = true;
			}
			if (options.includeMatching != matches) return null; 
		}

		phases = Object.fromEntries(Object.entries(phases).map(([phase, statistics]) => {
			if (!phaseTotals[phase]) phaseTotals[phase] = statisticsZero();
			accumulateStatistics(phaseTotals[phase], statistics);
			return [phase, roundStatistics(statistics)];
		}));
		accumulateStatistics(grandTotals, totals);
		return {
			uri: utilization.uri
			, phases, totals: roundStatistics(totals)
		};
	}).filter(Boolean);

	grandTotals = roundStatistics(grandTotals);
	phaseTotals = Object.fromEntries(Object.entries(phaseTotals).map(([phase, totals]) => [phase, roundStatistics(totals)]));
	
	indexed.forEach(utilization => {
		const shares = {}, totals = utilization.totals;
		if (0 < grandTotals.violations) shares.violations = percentShare(totals.violations / grandTotals.violations);
		if (0 < grandTotals.distance) shares.distance = percentShare(totals.distance / grandTotals.distance);
		if (0 < phaseTotals.working?.duration) {
			if (!utilization.phases.working?.duration) shares.working = 0;
			else shares.working = percentShare(utilization.phases.working.duration / phaseTotals.working.duration);
		}
		if (0 < phaseTotals.idling?.duration) {
			if (!utilization.phases.idling?.duration) shares.idling = 0;
			else shares.idling = percentShare(utilization.phases.idling.duration / phaseTotals.idling.duration);
		}
		utilization.shares = shares;
	});

	return indexed;
};

const indexViolations = (statistics) => {
	if (!statistics.violationTypes) return statistics;
	const violationTypes = Object.fromEntries(
		statistics.violationTypes.map(({eventType, quantity}) => [eventType, quantity])
	);
	return {...statistics, violationTypes};
};

const statisticsZero = () => ({
	distance: 0, duration: 0, quantity: 0, violations: 0, violationTypes: {}
});

const accumulateStatistics = (statistics, addition) => {
	if (!addition) return statistics;
	for (const counter of ['distance', 'duration', 'quantity', 'violations']) {
		const value = addition[counter];
		if (value) statistics[counter] += value;
	}
	if (addition.violationTypes) {
		if (!statistics.violationTypes) statistics.violationTypes = Object.assign({}, addition.violationTypes);
		else Object.entries(addition.violationTypes).forEach(([eventType, quantity]) => {
			statistics.violationTypes[eventType] = (statistics.violationTypes[eventType] || 0) + quantity; 
		});
	}
	return statistics;
};

const roundStatistics = statistics => ({
	...statistics
	, duration: Math.round(statistics.duration * 100 / (1000 * 60 * 60)) / 100
	, distance: Math.round(statistics.distance * 10 / 1000) / 10
});

const percentShare = share => Math.round(share * 100 * 100) / 100;


const epic = combineEpics(
	withActions((actions, action$, state$) => action$.pipe(
		ofType(actions.generate.request.type)
		, withLatestFrom(state$.pipe(map(state => state.reports.drivingBehaviour.utilizations)))
		, filter(([action, utilizations]) => utilizations == null)
		, map(([action, utilizations]) => action)
		, switchMap(action => rx(api.reports.drivingBehaviour, action.parameters).pipe(
			map(operation => actions.generate.success({utilizations: operation.response()}))
			, errorMap(actions.generate.fail)
		))
	))
	, withActions((actions, action$, state$) => action$.pipe(
		ofType(actions.generate.request.type)
		, withLatestFrom(state$.pipe(map(state => state.reports.drivingBehaviour.utilizations)))
		, filter(([action, utilizations]) => utilizations != null)
		, map(([action, utilizations]) => actions.generate.success({utilizations}))
	))
	, withActions((actions, action$, state$) => action$.pipe(
		ofType(actions.export.request.type)
		, withLatestFrom(state$.pipe(map(state => ({
			reporting: state.reports.drivingBehaviour
			, deviceMap: state.devices.map
			, categoryMap: state.categories.general.map
			, assetTypeRoot: state.categories.assetTypes.root
		}))))
		, map(([action, state]) => actions.export.success({csv: formatCSV(state)}))
	))
);


const formatCSV = state => {
	const {parameters, options, report} = state.reporting;
	const lines = [];

	lines.push(['report type', 'generated', 'from', 'to', 'devices'].map(fc));
	lines.push([
		fc('driving behaviour report')
		, new Date()
		, parameters.timeRange.since, parameters.timeRange.until
		, parameters.uris ? denominateDevices(parameters.uris.map(uri => state.deviceMap[uri])) : fc('all devices')
	]);

	const headers = [fc('device')];
	if (options.groupingId) headers.push(state.categoryMap[options.groupingId].name);
	if (options.includeAssetType) headers.push(fc('asset type'));

	headers.push(`${fc('distance')}, ${f('units.km')}`);
	headers.push(`${fc("odometer")}, ${f('units.km')}, ${f('first')}`, f('last'));

	headers.push(`${fc('utilization-phase.working')}, ${f('units.h')}`);
	headers.push(`${fc('utilization-phase.parked')}, ${f('units.h')}`);
	headers.push(`${fc('utilization-phase.idling')}, ${f('units.h')}`, f('units.%'), f('quantity'));

	headers.push(fc('device-event.speeding'), f('per 100 km'))
	headers.push(fc('device-event.harsh acceleration'), fc('device-event.harsh braking'), fc('device-event.harsh cornering'));
	headers.push(fc('violations'), f('per 100 km'));

	headers.push(`${fc('violations')}, ${f('units.%')}`);
	headers.push(`${fc('distance')}, ${f('units.%')}`);
	headers.push(`${fc('utilization-phase.working')}, ${f('units.%')}`);
	headers.push(`${fc('utilization-phase.idling')}, ${f('units.%')}`);
	lines.push(headers);

	const utilizations = report;

	utilizations.forEach(utilization => {
		const device = state.deviceMap[utilization.uri];
		const cells = [device.denomination()];

		let deviceGroup = null, assetType = null;
		if (device.categoryIds && (options.groupingId || options.includeAssetType)) {
			const assetTypeRootId = state.assetTypeRoot?.categoryId;
			device.categoryIds.forEach(categoryId => {
				const category = state.categoryMap[categoryId], comprisingIds = category?.comprisingIds;
				if (comprisingIds) {
					if (options.groupingId && comprisingIds.includes(options.groupingId)) deviceGroup = category;
					if (options.includeAssetType && comprisingIds.includes(assetTypeRootId)) assetType = category;
				}
			});
		}
		if (options.groupingId) cells.push(deviceGroup?.name);
		if (options.includeAssetType) cells.push(assetType && fc({ prefix: 'category', id: assetType.name }));

		const {phases, totals, shares} = utilization;

		cells.push(totals.distance || null);

		const firstOdometer = phases.total?.firstOdometer != null ? Math.round(phases.total?.firstOdometer / 1000) : null;
		const lastOdometer = phases.total?.lastOdometer != null ? Math.round(phases.total?.lastOdometer / 1000) : null;
		cells.push(firstOdometer, lastOdometer);

		cells.push(phases.working?.duration);
		cells.push(phases.parked?.duration);
		cells.push(phases.idling?.duration);
		const idling = phases.idling?.duration || 0;
		const idlingShare = 0 < idling ? percentShare(idling / totals.duration) : '';
		cells.push(idlingShare);
		cells.push(phases.idling?.quantity);


		const specificSpeedings = 0 < totals.distance && totals.violationTypes?.speeding != null 
			? Math.round(totals.violationTypes.speeding * 100 * 100 / totals.distance) / 100 : null
		;
		const specificViolations = 0 < totals.distance ? Math.round(totals.violations * 100 * 100 / totals.distance) / 100 : null;
		cells.push(totals.violationTypes?.speeding, specificSpeedings || null);
		cells.push(totals.violationTypes?.acceleration);
		cells.push(totals.violationTypes?.braking);
		cells.push(totals.violationTypes?.cornering);
		cells.push(totals.violations || null, specificViolations || null);

		cells.push(shares.violations);
		cells.push(shares.distance);
		cells.push(shares.working);
		cells.push(shares.idling);

		lines.push(cells);
	});


	return lines.map(cells => cells.map(value => {
		if (value == null) return '';
		if (value instanceof Date) return formatDateTime(value);
		let string = String(value);
		const qutes = 0 <= string.indexOf('"');
		if (qutes) string = string.replaceAll('"', '""');
		if (qutes || 0 <= string.indexOf(',')) string = '"' + string + '"';
		return string; 
	}).join(',')).join('\n');
};

export {addActions, reducer, epic, percentShare};
