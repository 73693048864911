import styled from 'styled-components';

import { Colors } from 'ui/constants/colors';

export const DetectorFormStyled = styled.form`
  height: 100%;

  label {
    display: block;
    font-weight: 700;
  }

  .rs-checkbox-checker {
    height: 26px;
    min-height: 26px;
    padding-left: 26px;
  }

  .rs-checkbox-wrapper {
    top: 5px;
    left: 0;
  }
`;

export const GeneralFieldsStyled = styled.div`
  padding: 15px;
  overflow-y: auto;

  & > div {
    &:not(:last-of-type) {
      margin-bottom: 15px;
    }

    &:last-of-type {
      margin-bottom: 20px;
    }
  }
`;

export const FieldErrorStyled = styled.span`
  display: block;
  margin-top: 10px;
  text-align: center;
  color: ${Colors.RossoCorsa};
`;

export const FormErrorStyled = styled(FieldErrorStyled)`
  margin-top: -10px;
  margin-bottom: 15px;
`;
