import React from 'react';

import { ISVGPersonalProps } from 'types/svg';

export const expiration: ISVGPersonalProps = {
  viewBox: '0 0 123.75 128.83',
  content: (
    <g>
      <polyline
        stroke="currentColor"
        strokeWidth="10"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="54.31 28.07 54.31 62.14 79.71 62.14"
      />
      <path
        stroke="currentColor"
        strokeWidth="10"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M105.38,77.24c2.51-6.13,3.91-12.84,3.91-19.88,0-28.99-23.5-52.5-52.5-52.5S4.29,28.37,4.29,57.36s23.5,52.5,52.5,52.5c4.55,0,8.96-.58,13.17-1.67"
      />
      <path
        stroke="currentColor"
        fill="currentColor"
        strokeMiterlimit="10"
        d="M98.1,111.79c0,.51-.04,.93-.12,1.28-.08,.35-.22,.62-.43,.83s-.47,.36-.81,.45c-.33,.09-.75,.14-1.26,.14s-.93-.05-1.27-.14c-.34-.09-.61-.24-.8-.45-.2-.21-.34-.49-.42-.83-.08-.34-.12-.77-.12-1.28s.04-.95,.12-1.3c.08-.35,.22-.63,.42-.85,.2-.21,.46-.37,.8-.46,.34-.09,.76-.14,1.27-.14s.93,.05,1.26,.14c.33,.09,.6,.25,.81,.46,.2,.22,.34,.5,.43,.85,.08,.35,.12,.79,.12,1.3Zm-.57-5.42c-.01,.12-.05,.23-.11,.33-.06,.1-.17,.18-.32,.25-.15,.07-.36,.12-.63,.16s-.59,.06-.97,.06-.72-.02-.98-.06-.47-.09-.62-.16c-.15-.07-.26-.15-.32-.25-.06-.1-.1-.21-.11-.33l-.48-16.79c0-.16,.04-.3,.11-.43,.07-.12,.21-.22,.4-.3,.19-.08,.45-.14,.78-.18,.33-.04,.74-.06,1.23-.06s.9,.02,1.22,.06c.32,.04,.58,.11,.77,.19,.19,.08,.33,.18,.41,.3s.12,.26,.12,.42l-.48,16.79Z"
      />
      <circle
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="9"
        cx="95.49"
        cy="101.55"
        r="24.31"
      />
    </g>
  ),
};
