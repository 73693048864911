import { cx } from '../api';

class Observable {
	#observable;

	constructor(events) {
		this.#observable = new cx.Observable();
		this.events = this.#observable.events = events;
	}

	getObservable() {
		return this.#observable;
	}

	addObserver(type, callback, processor, closing) {
		this.#observable.addObserver(type, callback, processor, closing);
	}

	removeObserver(type, callback, processor) {
		this.#observable.removeObserver(type, callback, processor);
	}

	removeObservers(processor) {
		this.#observable.removeObservers(processor);
	}

	notifyObservers(type, ...args) {
		this.#observable.notifyObservers(type, ...args);
	}

	dropObservers(type) {
		this.#observable.dropObservers(type);
	}

	hasObservers(type) {
		return this.#observable.hasObservers(type);
	}
}

export default Observable;
