import React from 'react';
import { DeviceSidebar } from '../custom/sidebar/device-sidebar';
import SidebarLayout from './SidebarLayout';

function DashboardLayout(props) {

	return (
		<SidebarLayout sidebar={<DeviceSidebar />}>
			{props.children}
		</SidebarLayout>
	);
}

export default DashboardLayout;
