import { Address, ComponentType } from "./address";
import { BatchResolver, ResolverFialedError, ResolverThrottledError } from "./resolver";

export class WaickResolver extends BatchResolver {

	constructor(batchSize = 10) {
		super(batchSize);
		this.serviceURL = new URL('https://waick.dev.overdrive.asia/resolve');
		// this.serviceURL = new URL('http://waick.local:7080/resolve');
	}

	submitBatch(batch) {
		fetch(
			new URL('?' + batch.map(({entry: {longitude, latitude}}) => `p=${longitude},${latitude}`).join('&'), this.serviceURL)
			, {headers: {'Accept': 'application/json'}, mode: 'cors'}
		).then(
			response => response.json()
		).then(
			reply => {
				const results = Array.isArray(reply) ? reply : [];
				batch.forEach(({resolve, reject}, at) => {
					const result = results[at];
					if (result?.status == null) reject(new Error('No valid result replied'));
					else if (result.status == 'OK') resolve(this.constructAddress(result.address));
					else if (result.status == 'TEMPORARY_UNAVAILABLE') reject(ResolverThrottledError.getInstance());
					else reject(new Error('Resolve failed'));
				});
			}
			, error => batch.forEach(({reject}) => reject(new ResolverFialedError(error.message)))
		);
	}

	constructAddress(result) {
		if (!result) return null;
		const components = result.components ? Object.fromEntries(Object.entries(result.components).map(([type, name]) => {
			const componentType = ComponentType[type];
			return componentType ? [componentType, name] : null;
		}).filter(Boolean)) : null;
		return result.formatted || components ? new Address(result.formatted, components) : null;
	}
}
