function reduxActionType(prefix, actionName) {
	let cprefix = (prefix + '_' + actionName).toUpperCase();
	return {
		REQUEST: cprefix + '_REQUEST',
		SUCCESS: cprefix + '_SUCCESS',
		FAIL: cprefix + '_FAIL',
		CANCEL: cprefix + '_CANCEL'
	};
}

function reduxActionTypes(prefix, keys) {
	let result = {};
	keys.forEach(key => {
		result[key] = reduxActionType(prefix, key.toUpperCase());
	});
	return result;
}

// ---------------------------------------------------------

function reduxAction(type, paramNames) {
	return function () {
		let object = { type: type };
		if (paramNames != null) {
			paramNames = Array.isArray(paramNames) ? paramNames : [paramNames];
			if (arguments.length > paramNames.length) {
				throw new Error("Arguments count exceeds specified count for action type " + type + ". Must be < " + paramNames.length + ".");
			}
			paramNames.forEach((param, at) => {
				object[param] = arguments[at];
			});
		}
		return object;
	};
}

function reduxActions(types, params) {
	if (params == null) params = [];
	if (!Array.isArray(params)) params = params.arguments;
	let result = {};
	Object.keys(types).forEach((typeName, at) => {
		result[typeName.toLowerCase()] = reduxAction(types[typeName], params[at]);
	});
	return result;
}

// ---------------------------------------------------------

// config = { key: [array of params | of array of params if multiple] }
// function generate(prefix, actionConfig, reducerConfig) {
function reduxGenerate(config) {
	let types = {};
	let actions = {};
	Object.keys(config.meta).forEach(key => {
		types[key] = reduxActionType(config.name, key);
		actions[key] = reduxActions(types[key], config.meta[key]);
	});
	types.CLEAR = config.name.toUpperCase() + '_CLEAR';
	actions['clear'] = reduxAction(types.CLEAR);
	return [types, actions];
}

// ---------------------------------------------------------

// calling through reducers and returns first changed state
function reduxSwitch(reducers, defaultState) {
	return function (state, action) {
		if (state == null) return typeof defaultState == 'function' ? defaultState(action) : defaultState;
		let newState = state;
		for (let at = 0; at < reducers.length; ++at) {
			const reducer = reducers[at];
			newState = reducer(state, action);
			if (newState != state) break;
		}
		return newState;
	}
}

// ---------------------------------------------------------

export {
	reduxActionType,
	reduxActionTypes,
	reduxAction,
	reduxActions,
	reduxGenerate,
	reduxSwitch
};
