import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../i18n';
import ZoneSelectMap from '../../custom/zones/viewer/ZoneSelectMap';
import ZoneCategorySelector from '../../custom/zones/ZoneCategorySelector';
import ZoneList from '../../custom/zones/ZoneList';
import { actions as zonesActions } from '../../../redux/api/zones';
import ZoneListItem from './ZoneListItem';
import ButtonGroup from '../../general/form/ButtonGroup';
import Button from '../../general/form/Button';
import ZoneFilter from '../../custom/zones/ZoneFilter';
import './zoneSelector.scss';

/**
 * @param {Object} props
 * @param {Array.<number>} props.zoneIds
 * @param {function} props.onSubmit
 * @param {function} props.onCancel
 * @param {function} [props.setModalFooter] possibility to set footer content when used inside modal window
 */

function ZoneSelector(props) {
	const { f } = useI18n();
	const [selectedIds, setSelectedIds] = useState(props.zoneIds);
	const [lastSelectedId, setLastSelectedId] = useState(null);

	useEffect(() => {
		props.setModalFooter && props.setModalFooter(footer);
	}, [selectedIds]);

	useEffect(() => {
		setSelectedIds(props.zoneIds);
	}, [props.zoneIds]);

	useEffect(() => {
		return () => {
			props.dispatch(zonesActions.setCategoryFilter({}));
			props.dispatch(zonesActions.setFilter({}));
		};
	}, []);

	const onSubmit = () => {
		props.onSubmit(selectedIds);
	}

	const onChange = (ids, id) => {
		setSelectedIds(ids);
		setLastSelectedId(id);
	}

	const footer = (
		<ButtonGroup className="zone-selector-buttons">
			<Button onClick={props.onCancel}>
				{f('cancel')}
			</Button>
			<Button onClick={onSubmit}>
				{f('save')}
			</Button>
		</ButtonGroup>
	);

	return (<>
		<div className="zone-selector">
			<div className="section">
				<ZoneFilter />
				<label>{f('zones')}</label>
				<div className="content">
					<ZoneCategorySelector
						selectedIds={props.zones.categoryFilter}
						onChange={(categoryIds) => props.dispatch(zonesActions.setCategoryFilter({ categoryIds }))}
					/>
					<ZoneList
						itemType={ZoneListItem}
						selectedIds={selectedIds}
						lastSelectedId={lastSelectedId}
						onChange={onChange}
					/>
					{!props.setModalFooter && footer}
				</div>
			</div>
			<ZoneSelectMap
				selectedIds={selectedIds}
				focusIds={lastSelectedId ? [lastSelectedId] : null}
				onChange={onChange}
				selectedOnly={true}
				focusButtonTitle={f('show selected zones')}
				noDrop
			/>
		</div>
	</>);
}

export default connect(state => ({
	zones: state.zones
}))(ZoneSelector);
