import React, { FC } from 'react';

import {
  ProcessorInfoCellStyled,
  ProcessorInfoListStyledStyled,
} from 'ui/pages/downsampled-message-history/processor-info.styled';
import { IDownsampledMessageHistoryProcessor } from 'types/detectors';
import { useI18n } from 'i18n';

interface IProcessorInfoListProps {
  processor: IDownsampledMessageHistoryProcessor;
}

export const ProcessorInfoList: FC<IProcessorInfoListProps> = ({ processor }) => {
  const { fc } = useI18n();

  const { ignitionChange, odometerPrecision, speedPrecision } = processor;

  const details = [
    { label: 'ignition change', value: fc(ignitionChange ? 'on' : 'off') },
    { label: 'odometer precision', value: odometerPrecision },
    { label: 'speed precision', value: speedPrecision },
  ];

  return (
    <ProcessorInfoListStyledStyled>
      {details.map(({ label, value }) => (
        <ProcessorInfoCellStyled key={label}>
          <label>{fc(label)}</label>
          {value}
        </ProcessorInfoCellStyled>
      ))}
    </ProcessorInfoListStyledStyled>
  );
};
