import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { actions } from '../../../redux/api/assets';
import { designateEmployee } from '../../../redux/api/assets/employees/reducer';
import EmployeeDetailsCard from './EmployeeDetailsCard';
import EmployeeList from './EmployeeList';
import './employeeView.scss';

/**
 * @param {Object} props
 * @param {cx.ods.assets.Employee} props.selectedEmployee if it initialed should be null
 * @param {function} [props.onSelectEmployee]
 * @param {function} props.onEdit
 * @param {Array.<string || number>} [props.byCategories]
 * @param {boolean} [props.disabled]
 * @param {function} [props.onImport]
 * @param {boolean} props.showAll
 * @param {function} props.setShowAll
 */

function EmployeeView(props) {
	const missUpdate = useRef();
	const [filter, setFilter] = useState('');
	const [selectedIds, setSelectedIds] = useState([]);
	const [filteredIds, setFilteredIds] = useState([]);
	const employees = props.employees.list;

	useEffect(() => {
		if (props.employees.map == null && !props.employees.pending) {
			props.dispatch(actions.employees.load.request());
		}
	}, []);

	useEffect(() => {
		if (props.selectedEmployee) {
			if (!selectedIds.includes(props.selectedEmployee.assetId)) {
				missUpdate.current = true;
				setSelectedIds([props.selectedEmployee.assetId]);
			}
		}
	}, [props.selectedEmployee]);

	useEffect(() => {
		if (props.selectedEmployee !== undefined) {
			if (selectedIds.length == 1 && !missUpdate.current) {
				const selectedId = selectedIds[0];
				if (props.selectedEmployee?.assetId != selectedId) props.onSelectEmployee(props.employees.map[selectedId]);
			} else props.onSelectEmployee(null);
		}
		missUpdate.current = false;
	}, [selectedIds]);

	let cardContent = null;
	if (props.selectedEmployee && filteredIds.includes(props.selectedEmployee.assetId) || selectedIds.length > 1) {
		cardContent = <EmployeeDetailsCard employee={props.selectedEmployee} assetIds={selectedIds} />;
	}

	const getEmployees = () => {
		return filter
			? employees.filter(employee => designateEmployee(employee).toLowerCase().includes(filter.toLowerCase()))
			: employees
		;
	}
	const getFilterByCategories = () => {
		if (props.byCategories?.length > 0) {
			return getEmployees().filter(employee => {
				if (employee.categoryIds) {
					return employee.categoryIds.some(categoryId => props.byCategories.includes(categoryId));
				}
				return false;
			});
		}
		return getEmployees();
	}

	useEffect(() => {
		setFilteredIds(getFilterByCategories().map(employee => employee.assetId));
	}, [filter, props.byCategories]);

	return (
		<div className="employee-view body">
			<EmployeeList
				selectedId={props.selectedEmployee?.assetId}
				onEdit={props.onEdit}
				filter={filter}
				setFilter={setFilter}
				selectedIds={selectedIds}
				onImport={props.onImport}
				showAll={props.showAll}
				setShowAll={props.setShowAll}
				setSelectedIds={setSelectedIds}
				onSelect={props.onSelectEmployee}
				filteredIds={filteredIds}
			/>
			{cardContent}
		</div>
	);
}

export default connect(state => ({
	employees: state.assets.employees
}))(EmployeeView);
