import { ActionGeneratorBuilder } from '../../actions';

export const actions = new ActionGeneratorBuilder('zones')
	.subtype('add', add => add.request('data').success('data').fail().cancel())
	.subtype('load', load => load.request().success('zones').fail().cancel())
	.subtype('update', update => update.request({ id: true, data: true }).success('data').fail().cancel())
	.subtype('remove', remove => remove.request('id').success().fail().cancel())
	.type('setCategoryFilter', { categoryIds: false })
	.type('setFilter', { filter: false })
	.type('clear')
	.build()
;
