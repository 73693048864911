import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useDrop } from 'react-dnd';
import { useI18n } from '../../../../i18n';
import { actions as categoriesActions } from '../../../redux/api/categories';
import { DragItemType } from '../../share/dnd/DragItemType';
import List from '../../general/list/List';
import ListItem from '../../general/list/ListItem';
import CategoriesControl from '../../share/categories/CategoriesControl';
import { className } from '../../../lib/className';

/**
 * @param {Object} props
 * @param {cx.ods.categories.CategoryData} props.category
 */
function DetectorCategoryItem(props) {

	const handleDrop = (item) => {
		props.onRemove(props.category.categoryId);
	}

	const [dropState, dropRef] = useDrop({
		accept: DragItemType.ACTION_REMOVE,
		drop: () => handleDrop(),
		collect: monitor => ({
			isOver: monitor.isOver(),
		})
	});

	return (
		<ListItem customRef={dropRef} className={className({ 'droppable': dropState.isOver })}>
			{props.category.name}
		</ListItem>
	);
}

/**
 * @param {Object} props
 * @param {boolean} props.editMode
 * @param {boolean} props.disabled
 * @param {cx.ods.processors.DevicePresenceDetector} [props.presenceDetector]
 */

function PresenceDetectorCategories(props) {
	const { f } = useI18n();
	let content = null;
	const categoryIds = props.presenceDetector && props.presenceDetector.categoryIds
		? props.presenceDetector.categoryIds
		: []
	;

	useEffect(() => {
		if (props.categories.map != null) {
			props.dispatch(categoriesActions.load.request());
		}
	}, []);

	if (props.categories.map != null && !props.editMode) {
		const items = categoryIds.map(categoryId => {
			const category = props.categories.map[categoryId];
			return (<ListItem key={category.categoryId}>{category.name}</ListItem>);
		});
		content = (
			<div className="presence-detector-categories">
				<div className="header">
					<span className="capitalize">{f('tags')}</span>
				</div>
				<div className="content">
					{items.length > 0 &&
						<List>
							{items}
						</List>
					}
					{items.length == 0 &&
						<span className="no-items">{props.emptyText || f('no tags assigned')}</span>
					}
				</div>
			</div>
		)
	} else if (props.deviceCategories.root != null && props.editMode) {
		content = (
			<CategoriesControl
				categoryIds={categoryIds}
				comprisingIds={[props.deviceCategories.root.categoryId]}
				emptyText={f('no tags assigned')}
				itemType={DetectorCategoryItem}
				disabled={props.disabled}
			/>
		);
	}

	return (content);
}

export default connect(
	state => ({
		categories: state.categories.general,
		deviceCategories: state.categories.devices
	})
)(PresenceDetectorCategories);