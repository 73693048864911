import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../i18n';
import { meta } from '../../../redux/api/meta';
import { cx } from '../../../api';
import DevicePropertyTile from './DevicePropertyTile';
import PropertyPopup from './PropertyPopup';
import DevicePropertyCard from './DevicePropertyCard';
import { filter as metaFilter } from '../../share/properties/meta/filter';
import { sortByName } from '../../../lib/sorting';
import './devicePropertiesView.scss';

const isBundle = (property) => {
	return cx.o.typeOf(property, cx.ods.meta.PropertyBundle);
}

/**
 * @enum ViewMode
 */

export const ViewMode = {
	Bundles: 'templates',
	Properties: 'properties'
};

/**
 * @param {Object} props
 * @param {ViewMode} props.viewMode
 * @param {string} props.filter
 * @param {function} props.onIntent
 * @param {function} props.onSelect
 */

function DevicePropertiesView(props) {
	const { f } = useI18n();
	const [intent, setIntent] = useState(null);
	let content = null;

	const isFiltered = (property) => {
		const bundle = isBundle(property);
		return props.viewMode == ViewMode.Bundles ? !bundle : bundle;
	}

	const onItemSelect = (property, data) => {
		setIntent({ subject: 'property', property, data });
		props.onSelect(property);
	}

	const getProperties = () => {
		return props.properties.list.filter(property =>
			isFiltered(property) ? false : metaFilter(property, props.filter, props.datatypes.map)
		);
	}

	const closePopup = () => {
		setIntent(null);
	}

	useEffect(() => {
		if (props.properties.list == null && !props.properties.pending) {
			props.dispatch(meta.properties.actions.load.request());
		}
		// if (props.datatypes.map == null && !props.datatypes.pending) {
		// 	props.dispatch(meta.datatypes.actions.datatypes.request());
		// }
		// if (props.units.map == null && !props.units.pending) {
		// 	props.dispatch(meta.units.actions.units.request());
		// }
	}, []);

	useEffect(() => {
		closePopup();
	}, [props.viewMode]);

	if (props.properties.list != null) {
		content = sortByName(getProperties()).map(property =>
			<DevicePropertyTile
				key={property.propertyId}
				property={property}
				onClick={onItemSelect}
				onIntent={props.onIntent}
			/>
		);
	}

	return (
		<div className="device-properties-view">
			{content}
			{intent && intent.subject == 'property' && (
				<PropertyPopup
					offset={intent.data.offset}
					width={intent.data.width}
					height={intent.data.height}
					onClose={closePopup}
					title={(intent.property && intent.property.name) + " " + f('property')}
				>
					<DevicePropertyCard property={intent.property} />
				</PropertyPopup>
			)}
		</div>
	)
}

export default connect(state => {
	return {
		properties: state.meta.properties,
		datatypes: state.meta.datatypes,
		units: state.meta.units
	}
})(DevicePropertiesView);