import React from 'react';

import { useI18n } from '../../../../i18n';
import Form from '../../general/form/Form';

/**
 * @param {Object} props
 * @param {cx.ods.processors.MessagePublisher} props.messagePublisher
 */

export const MessagePublisherForm = ({ messagePublisher }) => {
	const { f } = useI18n();

	const validateName = (value) => !value && f('please enter a value')

	return (
		<div className="attribute-detector-fields">
			<Form.ControlGroup>
				<Form.Control
					controlType={Form.Control.Type.Input}
					controlValidator={validateName}
					controlName="name"
					autoFocus
					label={f('name')}
					initialValue={messagePublisher?.name || ''}
				/>
			</Form.ControlGroup>
		</div>
	);
}
