import React from 'react';
import ObjectDeviceManager from '../../share/devices/ObjectDeviceManager';
import './deviceStatusDetectorView.scss';

/**
 * @param {Object} props
 * @param {cx.ods.processors.DeviceStatusDetector} [props.detector]
 * @param {boolean} [props.editMode]
 * @param {boolean} [props.disabled]
 */

function DeviceStatusDetectorView(props) {
	let content = null;

	if (props.detector || props.editMode) {
		content = (
			<div className="device-status-detector body">
				<ObjectDeviceManager
					uris={props.detector ? props.detector.uris : null}
					disabled={props.disabled}
					editMode={props.editMode}
				/>
			</div>
		);
	}

	return (content);
}

export default DeviceStatusDetectorView;