import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../i18n';
import { useDrop } from 'react-dnd';
import { actions } from '../../../redux/api/assets';
import { className } from '../../../lib/className';
import { DragItemType } from '../../share/dnd/DragItemType';
import { formatter } from '../../../misc/datetime';
import ObjectActionDialogPopup from '../../general/ObjectActionDialogPopup';
import RemoveDialog from '../../general/RemoveDialog';
import RadioGroup from '../../general/form/RadioGroup';
import Radio from '../../general/form/Radio';
import { cx } from '../../../api';
import InfoDialog from '../../general/InfoDialog';
import { designateEmployee } from '../../../redux/api/assets/employees/reducer';
import './employeeDetailsCard.scss';

/**
 * @param {Object} props
 * @param {cx.ods.assets.Employee} [props.employee]
 * @param {Array.<number>} [props.assetIds]
 */

const RemoveOption = {
	inactivate: 'inactivate'
	, delete: 'delete'
};

const RemovePopupType = {
	inapplicability: 'operation is not applicable'
	, deleteConfirmation: 'delete employees'
	, removeOptions: 'remove options'
};

const RemoveOptionLabels = {
	[RemoveOption.inactivate]: 'inactivate. Mark selected employees as inactive and preserve associated data'
	, [RemoveOption.delete]: 'delete. Permanently delete selected employees and associated data'
};

function EmployeeDetailsCard(props) {
	const { f, fc, pc } = useI18n();
	const boxRef = useRef();
	const [popupContext, setPopupContext] = useState(null);
	const [removeOption, setRemoveOption] = useState(RemoveOption.inactivate);

	useEffect(() => {
		if (props.employees.map == null && !props.employees.pending) {
			props.dispatch(actions.employees.load.request());
		}
	}, []);

	useEffect(() => {
		if (popupContext && props.assetIds && (!props.employees.pending && !props.employees.error)) setPopupContext(null);
	}, [props.assetIds?.length, props.employees]);

	const onPopupClose = () => {
		if (popupContext) setPopupContext(null);
	}

	const onRemove = () => {
		if (removeOption == RemoveOption.delete) props.dispatch(actions.employees.removeAll.request({ assetIds: props.assetIds }));
		else props.dispatch(actions.employees.inactivateAll.request({ assetIds: props.assetIds }));
	}

	const onDrop = useCallback((item) => {
		let hasActive = false;
		const immutableEmplyees = [];
		props.assetIds.forEach(id => {
			const employee = props.employees.map[id];
			if (employee) {
				if (!hasActive && !employee.inactivatedAt) hasActive = true;
				if (!employee.modifiable) immutableEmplyees.push(employee);
			}
		});
		const popupContext = {};
		if (0 < immutableEmplyees.length) {
			popupContext.popupType = RemovePopupType.inapplicability;
			const firstName = designateEmployee(immutableEmplyees[0]);
			popupContext.message = immutableEmplyees.length == 1 
				? f('employee NAME cannot be modified', {name: firstName})
				: f('employee NAME and X others cannot be modified', {name: firstName, quantity: immutableEmplyees.length - 1})
			;
		} else if (hasActive) {
			popupContext.popupType = RemovePopupType.removeOptions;
			setRemoveOption(RemoveOption.inactivate);
		} else {
			popupContext.popupType = RemovePopupType.deleteConfirmation;
			popupContext.message = fc('this will permanently delete selected employees and associated data. Continue?');
			setRemoveOption(RemoveOption.delete);
		}
		popupContext.offset = cx.dom.at.offset(boxRef.current);
		popupContext.offset.left += boxRef.current.offsetWidth;
		setPopupContext(popupContext);
	}, [props.assetIds, props.employees.map]);

	const [dropState, dropRef] = useDrop({
		accept: [DragItemType.ACTION_REMOVE],
		drop: onDrop,
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		})
	});

	const handleRef = (element) => {
		boxRef.current = element;
		dropRef(element);
	}
	const categories = [];
	if (props.employee && props.categoryMap && props.employee.categoryIds) {
		props.employee.categoryIds.forEach(id => props.categoryMap[id] && categories.push(<div key={id} className="category">{props.categoryMap[id]?.name}</div>));
	}
	return (
		<div className="employee-details-card">
			<div className="section-header"><span className="capitalize label">{f('description')}</span></div>
			{props.employee
				? <div className="employee-card">
					{props.employee.designation && <div className="block">
						<label>{fc('designation')}</label>
						<span>{props.employee.designation}</span>
					</div>}
					{props.employee.externalId && <div className="block">
						<label>{fc('external id')}</label>
						<span>{props.employee.externalId}</span>
					</div>}
					{props.employee.rfid && <div className="block">
						<label>{fc('RFID')}</label>
						<span>{props.employee.rfid}</span>
					</div>}
					{categories.length > 0
						&& <div className="block">
							<label>{fc(categories.length == 1 ? 'tag' : 'tags')}</label>
							<div className="categories">{categories}</div>
						</div>
					}
					<div className="block">
						<label>{fc('status')}</label>
						<span className="capitalize">
							{props.employee.inactivatedAt
								? f('inactivated') + ' ' + f('at') + ' ' + formatter.relative(props.employee.inactivatedAt).value
								: f('active')}
						</span>
					</div>
					{props.employee.modifiedAt && <div className="block">
						<span className="capitalize">{f('modified') + ' ' + f('at') + ' ' + formatter.relative(props.employee.modifiedAt).value}</span>
					</div>}
				</div>
				: <div className="employee-selection-statistic">
					<div ref={handleRef} className={className("selection-card", { 'droppable':  dropState.isOver && dropState.canDrop, 'canDrop': dropState.canDrop, })}>
						{pc('X employees selected', props.assetIds.length)}
					</div>
					{popupContext && (
						<ObjectActionDialogPopup
							offset={popupContext.offset}
							onClose={onPopupClose}
							title={f(popupContext.popupType)}
							disabled={props.employees.pending}
							error={props.employees.error}
						>
							{popupContext.popupType == RemovePopupType.inapplicability
								? <InfoDialog text={popupContext.message} onOkClick={onPopupClose} />
								: <RemoveDialog
									disabled={props.employees.pending}
									text={popupContext.message}
									onSubmit={onRemove}
									onCancel={onPopupClose}
									onEnterKeydownHandler={onPopupClose}
								>
									{popupContext.popupType == RemovePopupType.removeOptions &&
										<RadioGroup value={removeOption} onChange={setRemoveOption}>
											{Object.entries(RemoveOptionLabels).map(
												([option, label]) => <Radio key={option} value={option}>{fc(label)}</Radio>
											)}
										</RadioGroup>
									}
								</RemoveDialog>
							}
						</ObjectActionDialogPopup>
					)}
				</div>
			}
		</div>
	);
}

export default connect(state => ({
	categoryMap: state.categories.employees.map,
	employees: state.assets.employees
}))(EmployeeDetailsCard);
