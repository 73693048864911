/**
 * Split object into two object where first contains keys listed in keys parameter and second - the rest.
 * Object itself remains intact.
 * @param {Object} object
 * @param {Array.<string>} keys
 */

export const extract = (object, keys) => {
	const result = {}, rest = {};
	Object.keys(object).forEach(key => {
		const value = object[key];
		if (keys.includes(key)) {
			result[key] = value;
		} else {
			rest[key] = value;
		}
	});
	return [result, rest];
}

export const clone = (object, deep) => {
	const current = { ...object };
	if (deep) {
		Object.keys(current).forEach(key => {
			if (typeof current[key] == "object") {
				current[key] = clone(current[key], deep);
			}
		});
	}
	return current;
}

export const singleton = (type, instantiate = () => new type()) => {
	let instance = null;
	type.getInstance = () => {
		if (instance == null) instance = instantiate();
		return instance;
	}
	return type;
};
