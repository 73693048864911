import Observable from "../../../../misc/Observable";
import { isLineString, plainCoordinates } from "../../../../misc/ol";

class FeatureObservable extends Observable {

	constructor(feature) {
		super({
			changed: 'changed',
			geometryReplaced: 'geometryReplaced',
			geometryChanged: 'geometryChanged',
			pointAdded: 'pointAdded',
			pointRemoved: 'pointRemoved',
			pointChanged: 'pointChanged'
		});
		this._onGeometryRefChanged = this._onGeometryRefChanged.bind(this);
		this._onGeometryChanged = this._onGeometryChanged.bind(this);
		this.feature = feature;
		// to get notifications about geometry object reference changed
		this.feature.on('change:geometry', this._onGeometryRefChanged);
		this._hookGeometry();
	}

	_hookGeometry() {
		this._clearGeometry();
		const geometry = this.feature.getGeometry();
		if (geometry != null) {
			this.geometry = geometry;
			this.geometry.on('change', this._onGeometryChanged);
			this.points = this._copyCoordinates(this._getCoordinates(this.geometry));
		}
	}

	/**
	 * @returns {Array.<Array.<number>>} if geometry is polygon - returns coordinates of the first ring
	 */
	_getCoordinates(geometry) {
		if (isLineString(geometry)) {
			return geometry.getCoordinates();
		} else { // polygon
			return geometry.getCoordinates()[0];
		}
	}

	_copyCoordinates(coords) {
		return coords.map(xy => [xy[0], xy[1]]);
	}

	_clearGeometry() {
		if (this.geometry != null) {
			this.geometry.un('change', this._onGeometryChanged);
			this.geometry = null;
			this.points = null;
		}
	}

	_onGeometryChanged(event) {
		this._checkPoints();
		this.notifyObservers(this.events.geometryChanged, event);
		this.notifyObservers(this.events.changed);
	}

	_onGeometryRefChanged(event) {
		this._checkPoints();
		this._hookGeometry();
		this.notifyObservers(this.events.geometryReplaced, event);
		this.notifyObservers(this.events.changed);
	}

	// assumes that points could be deleted/added only by one
	// or all at once by setting new geometry ???
	_checkPoints() {
		const geometry = this.feature.getGeometry();
		const points = this._getCoordinates(geometry);
		if (this.points != null) {
			if (this.points.length > points.length) { // point removed
				let at = 0;
				if (points.length > 0) {
					at = points.findIndex((point, at) =>
						point[0] != this.points[at][0] || point[1] != this.points[at][1]
					);
					if (at == -1) at = this.points.length-1;
				}
				this.notifyObservers(this.events.pointRemoved, this.points[at], at);
			} else if (this.points.length < points.length) { // point added
				let at = this.points.findIndex((point, at) =>
					point[0] != points[at][0] || point[1] != points[at][1]
				);
				if (at == -1) at = points.length-1;
				this.notifyObservers(this.events.pointAdded, points[at], at);
			} else { // point modified
				const at = this.points.findIndex((point, at) =>
					point[0] != points[at][0] || point[1] != points[at][1]
				);
				const isPolygon = !isLineString(geometry);
				const coords = plainCoordinates(geometry);
				if (at >= 0) {
					if (isPolygon && at < coords.length-1 || !isPolygon) {
						this.notifyObservers(this.events.pointChanged, this.points[at], points[at], at);
					}
				}
			}
		}
		this.points = this._copyCoordinates(points);
	}

	destroy() {
		this._clearGeometry();
		this.feature.un('change:geometry', this._onGeometryReplaced);
		this.feature = null;
	}
}

export default FeatureObservable;
