import React from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../i18n';
import icon from '../../../img/icons/gnss.png';
import { className } from '../../../lib/className';
import './deviceStateIcon.scss';

/**
 * @param {Object} props
 * @param {cx.ods.devices.MessageDetails} props.message
 */

function GpsFixIcon(props) {
	const { messageFields, message } = props;
	const { fc } = useI18n();
	let status = 'unknown', title = fc('unknown');
	if (message != null && messageFields.nameMap != null) {
		const fieldMeta = messageFields.nameMap["no-gnss-fix"];
		let hasValue = false;
		let value = null;
		if (message.fields != null) {
			const string = message.fields.map()[fieldMeta.fieldId];
			hasValue = string !== undefined;
			if (hasValue) value = string === "true";
		}
		if (hasValue && value === true) {
			status = 'inactive'; title = fc('no');
		} else if (message.latitude != null && message.longitude != null && (!hasValue || hasValue && value === false)) {
			status = 'active'; title = fc('yes')
		}
	}
	return (
		<div className={className('device-state-icon', status)} title={fc('GNSS fix') + ": " + title}>
			<img src={icon} alt="" />
		</div>
	);
}

export default connect(state => {
	return {
		messageFields: state.registry.messageFields
	};
})(GpsFixIcon);