import React, {useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useI18n } from '../../../../i18n';
import { actions as zoneGuardsActions } from '../../../redux/api/zoneGuards';
import ZoneGuardListItem from './ZoneGuardListItem';
import List from '../../general/list/List';
import Input from '../../general/form/Input';
import { className } from '../../../lib/className';
import { sortByName } from '../../../lib/sorting';
import './zoneGuardsList.scss';

/**
 * @param {Object} props
 * @param {number} props.selectedId
 * @param {function} props.onSelect
 * @param {function} props.onIntent
 */

function ZoneGuardsList(props) {
	const { f } = useI18n();
	const [filter, setFilter] = useState('');
	const zoneGuards = props.zoneGuards.list;
	let content = null;

	useEffect(() => {
		if (zoneGuards == null) props.dispatch(zoneGuardsActions.load.request());
	}, []);

	const getZoneGuards = () => {
		return filter
			? zoneGuards.filter(zoneGuard => zoneGuard.name.toLowerCase().includes(filter.toLowerCase()))
			: zoneGuards
		;
	}

	if (zoneGuards != null) {
		const items = sortByName(getZoneGuards()).map(zoneGuard =>
			<ZoneGuardListItem
				className={className({ 'selected': (props.selectedId != null && props.selectedId == zoneGuard.processorId) })}
				key={zoneGuard.processorId}
				zoneGuard={zoneGuard}
				onSelect={props.onSelect}
				onIntent={props.onIntent}
			/>
		);
		content = (<List>{items}</List>);
	}

	return (<>
		<div className="filter">
			<Input label={f('filter')} value={filter} onChange={setFilter} cleanable />
		</div>
		<div className="zone-guard-list">
			<label>
				{f('zone guards')}
			</label>
			{content}
		</div>
	</>);
}

export default connect(state => {
	return {
		zoneGuards: state.zoneGuards
	}
})(withRouter(ZoneGuardsList));