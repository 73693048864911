import { Feature } from "ol";
import { toLonLat } from "ol/proj";
import { getCenter } from "ol/extent";
import { LineString, Polygon } from "ol/geom";
import { fromEPSG4326 } from "ol/proj/epsg3857";
import { getArea } from "ol/sphere";
import { assert } from "../../../../lib/assert";
import Observable from "../../../../misc/Observable";
import { generateId, debounce } from "../../../../misc/misc";
import { isLineString, plainCoordinates } from "../../../../misc/ol";
import { resolve } from "../../../../misc/location/resolve";
import FeatureObservable from "./FeatureObservable";

const DEFAULT_NAME = "Zone area";

class ZoneArea extends Observable {

	/**
	 * @param {import("./Zone").default} zone
	 * @param {Feature} [feature]
	 */
	constructor(zone, feature, index) {
		super({
			changed: 'changed',
			resolved: 'resolved',
		})
		this._onGeometryReplaced = this._onGeometryReplaced.bind(this);
		this._onGeometryChanged = this._onGeometryChanged.bind(this);
		this._zone = zone;
		this._id = generateId();
		this._index = index;
		if (feature == null) feature = new Feature();
		this._feature = feature;
		this._feature.setId(this._id);
		this.observable = new FeatureObservable(this._feature);
		const _resolve = debounce(function () {
			// to prevent call of resolve on destroyed object
			if (this._feature != null) this.resolve();
		}.bind(this), 500);
		this.observable.addObserver(this.observable.events.pointChanged, function () {
			this.resetName();
			_resolve();
		}, this);
		this.resetName();
		this.update();
		this.resolve();
	}

	feature() {
		return this._feature;
	}

	geometry() {
		return this.feature().getGeometry();
	}

	zone() {
		return this._zone;
	}

	id() {
		return this._id;
	}

	reindex(index) {
		this._index = index;
	}

	index() {
		return this._index;
	}

	name() {
		return this._name;
	}

	setName(name) {
		this._name = name;
	}

	resetName() {
		if (!this.building()) {
			const center = toLonLat(getCenter(this.geometry().getExtent()));
			this._name = '(' + center[0].toFixed(6) + ', ' + center[1].toFixed(6) + ')';
		} else {
			this._name = DEFAULT_NAME;
		}
	}

	update() {
		this._feature.setProperties({
			zoneId: this._zone.id(),
			style: this._zone.style()
		});
	}

	featureObservable() {
		return this.observable;
	}

	/**
	 * Calculates area in m2
	 */
	square() {
		let value = this.building() ? 0 : getArea(this.geometry());
		value = Math.round(value * 100) / 100;
		return value;
	}

	// managing

	remove() {
		this._zone.removeArea(this._id);
	}

	/**
	 * Whether area creation is not finished (polygon not yet closed).
	 * In fact means that geometry is line string.
	 */
	building() {
		if (this._feature == null) debugger;
		return this.geometry() == null || isLineString(this.geometry());
	}

	// point management

	/**
	 * Should be called only during manual creation process.
	 * @param {Array.<number>} lonLat EPSG:4326 coordinates
	 */
	addPoint(lonLat) {
		const geometry = this.geometry();
		if (geometry == null) {
			this.insertPoint(0, lonLat);
		} else {
			const coords = geometry.getCoordinates();
			if (this.building()) {
				this.insertPoint(coords.length, lonLat);
			} else { // polygon
				this.insertPoint(coords[0].length-1, lonLat);
			}
		}
	}

	/**
	 * @param {number} at index
	 * @param {Array.<number>} lonLat EPSG:4326 coordinates
	 */
	insertPoint(at, lonLat) {
		const xy = fromEPSG4326(lonLat);
		let geometry = this.geometry();
		if (geometry == null) {
			geometry = new LineString([xy]);
			this._feature.setGeometry(geometry);
		} else {
			const coordinates = geometry.getCoordinates();
			if (this.building()) {
				coordinates.splice(at, 0, xy);
			} else { // polygon
				const ring = coordinates[0];
				ring.splice(at, 0, xy);
				// correct closing point
				if (at == 0) ring[ring.length-1] = xy;
			}
			geometry.setCoordinates(coordinates);
		}
	}

	canRemovePoints() {
		return this.creatingPoints() || this.geometry().getCoordinates()[0].length > 4;
	}

	/**
	 * @param {number} at index
	 */
	removePoint(at) {
		if (this.canRemovePoints()) {
			const geometry = this.geometry();
			if (geometry != null) {
				const coords = geometry.getCoordinates();
				if (this.building()) {
					assert(at < coords.length, "ZoneArea.removePoint: at is outside of line string length");
					coords.splice(at, 1);
				} else { // polygon
					const ring = coords[0];
					assert(at < ring.length-1, "ZoneArea.removePoint: at is outside of polygon ring length");
					ring.splice(at, 1);
					if (at == 0) {
						ring[coords[0].length-1] = [ring[0][0], ring[0][1]];
					}
				}
				geometry.setCoordinates(coords);
			}
		}
	}

	/**
	 * @param {number} at index
	 * @param {Array.<number>} lonLat EPSG:4326 coordinates
	 */
	modifyPoint(at, lonLat) {
		const geometry = this._feature.getGeometry();
		if (geometry != null) {
			const coords = geometry.getCoordinates();
			if (this.building()) {
				coords[at] = fromEPSG4326(lonLat);
			} else { // polygon
				coords[0][at] = fromEPSG4326(lonLat);
				if (at == 0) coords[0][coords[0].length-1] = coords[0][at]; // closing point should be equal to first one
			}
			geometry.setCoordinates(coords);
			this._feature.changed();
		}
	}

	/**
	 * Point count for showing to user (not considering closing point in polygon).
	 */
	pointCount() {
		const coords = plainCoordinates(this.geometry());
		return coords.length;
	}

	point(at) {
		const coordinates = plainCoordinates(this.geometry());
		if (at < coordinates.length) {
			return coordinates[at];
		}
		return null;
	}

	/**
	 * @param {Array.<number>} xy EPSG:3857
	 */
	pointAt(xy) {
		const coordinates = plainCoordinates(this.geometry());
		const at = coordinates.findIndex(coord => coord[0] == xy[0] && coord[1] == xy[1]);
		return at >= 0 ? at : null;
	}

	creatingPoints() {
		const geometry = this.geometry();
		return geometry == null || isLineString(geometry);
	}

	canClosePoints() {
		const geometry = this.geometry();
		if (geometry && isLineString(geometry)) {
			return geometry.getCoordinates().length >= 3;
		}
		return false;
	}

	/**
	 * Finishes creating process by replacing LineString geometry with Polygon
	 */
	closePoints() {
		if (this.canClosePoints()) {
			const coords = this.geometry().getCoordinates();
			coords.push([coords[0][0], coords[0][1]]); // closing ring for polygon
			const polygon = new Polygon([coords]);
			this.feature().setGeometry(polygon);
		}
	}

	// location

	resolve() {
		if (this._feature == null) return; // HACK
		if (this.building()) return;
		const center = toLonLat(getCenter(this.geometry().getExtent()));
		if (center != null) {
			if (this.entry != null) this.entry.removeObservers(this);
			this.entry = resolve(center[0], center[1], this._onResolveReady, this._onResolveBusy, this);
		}
	}

	resolved() {
		return this.entry ? this.entry.ready() : false;
	}

	cancelResolve() {
		if (this.entry != null) {
			this.entry.removeObservers(this);
			this.entry = null;
		}
	}

	address() {
		return this.entry?.getAddress();
	}

	center() {
		const geometry = this.geometry();
		return geometry != null ? toLonLat(getCenter(geometry.getExtent())) : null;
	}

	// event handlers

	_onResolveReady(entry) {
		if (this._feature == null) return; // HACK
		const name = entry.getAddress()?.format();
		if (name) this._name = name;
		this.notifyObservers(this.events.resolved, this);
	}

	_onResolveBusy() {
		// TODO
	}

	_onGeometryReplaced(event) {
		this.notifyObservers(this.events.changed, this);
	}

	_onGeometryChanged(event) {
		this.notifyObservers(this.events.changed, this);
	}

	//

	destroy() {
		this.cancelResolve();
		this.dropObservers();
		this.observable.destroy();
		if (this._feature != null) {
			this._feature = null;
			this._center = null;
			this._address = null;
			this._name = null;
		}
	}

}

export default ZoneArea;
