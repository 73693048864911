import React, { FC, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FiExternalLink as PageIcon } from 'react-icons/fi';

import { useI18n } from 'i18n';
import { IMqttClient } from 'types/mqttClient';
import { AllRoutes } from 'constants/routes';
import { setPageTitle } from 'core/misc/page';
import Intent from 'core/misc/ObjectActionIntent';
import { mqttClientsStateSelector } from 'core/redux/selectors/mqttClients';
import { actions } from 'core/redux/api/mqttClients';
import { MqttClientForm } from 'core/react/custom/mqttClients/MqttClientForm';
import SidebarLayout from 'core/react/general/layout/SidebarLayout';
import ObjectActionDialogPopup from 'core/react/general/ObjectActionDialogPopup';
import RemoveDialog from 'core/react/general/RemoveDialog';
import Loader from 'core/react/general/Loader';
import PagePanel from 'core/react/general/panel/PagePanel';
import DefaultActionBar from 'core/react/share/actionbar/DefaultActionBar';
import ActionBack from 'core/react/share/actionbar/ActionBack';
import ActionAdd from 'core/react/share/actionbar/ActionAdd';
import { MqttClientsTable } from 'core/react/custom/mqttClients/MqttClientsTable';

import './mqttClientsPage.scss';

const PAGE_TITLE = 'mqtt clients';

const MqttClientsPage: FC = () => {
  const { f, fc } = useI18n();

  const history = useHistory();
  const params = useParams<Record<string, string>>();

  const dispatch = useDispatch();
  const { list: mqttClients, error, isLoading } = useSelector(mqttClientsStateSelector);

  // TODO: should be refactored. used to go to a previous route when a detector updated/created.
  const isPending = useRef(false);

  const [intent, setIntent] = useState<Intent | null>(null);

  const { 0: route } = params;
  const isFormPage = route === 'edit' || route === 'create';

  const onPopupClose = () => {
    intent && setIntent(null);
  };

  const onAdd = () => {
    history.push(`${AllRoutes.MqttClients}/create`);
  };

  const onBack = () => {
    history.goBack();
  };

  const onIntent = (intent: Intent) => {
    if (intent.action() === Intent.Action.Remove) {
      return setIntent(intent);
    }

    if (intent.action() === Intent.Action.Edit) {
      history.push(`${AllRoutes.MqttClients}/${intent.object().clientId}/edit`);
    }
  };

  const onRemove = ({ clientId }: IMqttClient) => {
    dispatch(actions.remove.request({ clientId }));
  };

  const clearError = () => {
    error && dispatch(actions.clear());
  };

  useEffect(() => {
    setPageTitle(fc(PAGE_TITLE));

    if (!mqttClients) {
      dispatch(actions.load.request());
    }

    return () => {
      clearError();
    };
  }, []);

  useEffect(() => {
    clearError();
  }, [intent]);

  useEffect(() => {
    clearError();
    onPopupClose();
  }, [params]);

  useEffect(() => {
    if (!isLoading && isPending.current) {
      isPending.current = false;

      if (!error) {
        onBack();
      }
    }
  }, [isLoading, error, mqttClients]);

  useEffect(() => {
    if (intent) {
      const isMqttClientExist = mqttClients?.find((client) => client.clientId === intent.object().clientId);

      !isMqttClientExist && onPopupClose();
    }
  }, [mqttClients, params]);

  let content = null;

  const sidebarContent = (
    <>
      <DefaultActionBar
        prependActions={
          isFormPage ? <ActionBack disabled={isLoading} onClick={onBack} /> : <ActionAdd onClick={onAdd} />
        }
        hideEdit={isFormPage}
        disabled={isLoading}
      />
      {isFormPage && <MqttClientForm isPending={isPending} />}
    </>
  );

  if (mqttClients) {
    content = (
      <div className="side-bar-content">
        <SidebarLayout
          className="body"
          icon={<PageIcon size="20" />}
          title={isFormPage ? f('mqtt client') : f('mqtt clients')}
          sidebarContent={sidebarContent}
        >
          <MqttClientsTable mqttClients={mqttClients} onIntent={onIntent} />
          {intent && intent.action() === Intent.Action.Remove && (
            <ObjectActionDialogPopup
              offset={intent.data().offset}
              onClose={onPopupClose}
              title={f('delete mqtt client')}
              disabled={isLoading}
              error={error}
            >
              <RemoveDialog
                object={intent.object()}
                title={f('mqtt client')}
                text={f('are you sure you want to delete mqtt client', { name: intent.object().name })}
                onSubmit={onRemove}
                onCancel={onPopupClose}
              />
            </ObjectActionDialogPopup>
          )}
        </SidebarLayout>
      </div>
    );
  } else if (isLoading) {
    content = (
      <div className="vh-center">
        <Loader size={Loader.Size.LG} />
      </div>
    );
  }

  return (
    <div className="app-page mqtt-clients">
      <PagePanel>{content}</PagePanel>
    </div>
  );
};

// TODO: get rid of it when routes are refactored
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
MqttClientsPage.Url = AllRoutes.MqttClients;

export default MqttClientsPage;
