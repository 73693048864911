import React, { useMemo } from "react";
import { connect } from "react-redux";

import { useI18n } from "../../../../i18n";

import './messagePublisherDetailsInfo.scss'

/**
 * @param {Object} props
 * @param {cx.ods.processors.MessagePublisher} props.messagePublisher
 */

const MessagePublisherDetailsInfo = ({ messagePublisher, messageFields, eventTypes }) => {
	const { f, fc } = useI18n();

	const getMessageFieldsDescriptions = (fieldIds) => fieldIds
		.map((field) => fc({ prefix: 'message-field', id: messageFields[field]?.description }));

	const getEventTypesNames = (eventTypesIds) => eventTypesIds
		.map((eventTypeId) => fc({ prefix: 'device-event', id: eventTypes[eventTypeId]?.name }));

	const messageFieldsDescriptions = useMemo(() => {
		if (!messageFields) {
			return null;
		}

		return messagePublisher?.fieldIds?.length ?
			getMessageFieldsDescriptions(messagePublisher.fieldIds) :
			[fc('default')];
	}, [messageFields, messagePublisher]);

	const eventTypesNames = useMemo(() => {
		if (!eventTypes) {
			return null;
		}

		return messagePublisher?.eventTypes?.length ?
			getEventTypesNames(messagePublisher.eventTypes) :
			[fc('default')];
	}, [eventTypes, messagePublisher]);

	return (
		messagePublisher ?
			<>
				{messagePublisher.service && <div className="details-item">
					<label>{f('service')}</label>
					<div>{messagePublisher.service}</div>
				</div>}
				{messagePublisher.statesDestination && <div className="details-item">
					<label>{f('states updates destination')}</label>
					<div>{messagePublisher.statesDestination}</div>
				</div>}
				{messagePublisher.eventsDestination && <div className="details-item">
					<label>{f('events destination')}</label>
					<div>{messagePublisher.eventsDestination}</div>
				</div>}
				{!!messageFieldsDescriptions && <div className="details-item">
					<label>{f('attributes')}</label>
					{messageFieldsDescriptions.map((description, index) =>
						<div className="entity-title" key={index}>{description}</div>)}
				</div>}
				{messagePublisher.events && !!eventTypesNames && <div className="details-item">
					<label>{f('event types')}</label>
					{eventTypesNames.map((name, index) =>
						<div className="entity-title" key={index}>{name}</div>)}
				</div>}
				{!!messagePublisher.forceTimeout && <div className="details-item">
					<label>{f('force update timeout')}</label>
					<div>{`${messagePublisher.forceTimeout} ${f({prefix: 'units', id: 's'})}`}</div>
				</div>}
			</> : null
	)
}

export default connect(state => {
	return {
		messageFields: state.registry.messageFields.typeMap,
		eventTypes: state.registry.eventTypes.typeMap
	}
})(MessagePublisherDetailsInfo);
