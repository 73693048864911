import React, { useEffect, useRef, useState } from 'react';
import { useI18n } from '../../../../i18n';
import { formatter } from '../../../misc/datetime';
import Form from '../../general/form/Form';
import EmployeeCategories from './EmployeeCategories';
import './employeeFields.scss';

/**
 * @param {Object} props
 * @param {cx.ods.assets.Employee} [props.employee]
 * @param {AnyIfEmpty} [props.optionalDependency] for reset all value
 */

function EmployeeFields(props) {
	const { f } = useI18n();
	const firtsFocusedInput = useRef();
	const [designation, setDesignation] = useState(props.employee?.designation || '');
	const [externalId, setExternalId] = useState(props.employee?.externalId || '');
	const [rfid, setRfid] = useState(props.employee?.rfid || '');

	useEffect(() => {
		setDesignation(props.employee?.designation || '');
		setExternalId(props.employee?.externalId || '');
		setRfid(props.employee?.rfid || '');
		if (firtsFocusedInput.current) firtsFocusedInput.current.focus();
	}, [props.employee, props.optionalDependency]);

	const onFocus = (event) => {
		if (!firtsFocusedInput.current) firtsFocusedInput.current = event.target;
	}
	return (
		<div className="employee-fields">
			<Form.ControlGroup>
				<Form.Control
					controlType={Form.Control.Type.Input}
					controlName="designation"
					label={f('designation')}
					value={designation}
					onChange={setDesignation}
					onFocus={onFocus}
				/>
				<Form.Control
					controlType={Form.Control.Type.Input}
					controlName="externalId"
					label={f('external id')}
					value={externalId}
					onChange={setExternalId}
					onFocus={onFocus}
				/>
				<Form.Control
					controlType={Form.Control.Type.Input}
					controlName="rfid"
					label={f('RFID')}
					value={rfid}
					onChange={value => setRfid(value ? value.toUpperCase() : value)}
					onFocus={onFocus}
					controlValidator={() => {
						if (/\s/.test(rfid) || rfid && !/^[\dA-F]+$/.test(rfid)) return f('please, enter hexadecimal value');
						if (!designation.trim() && !externalId.trim() && !rfid) return f('please, fill in at least one field');
					}}
				/>
			</Form.ControlGroup>
			<EmployeeCategories employee={props.employee} editMode />
			{props.employee && <div className="last-information">
				{props.employee.inactivatedAt && <div className="last-inactivated-date">
					<span className="capitalize">{f('inactivated') + ' ' + f('at') + ' ' + formatter.relative(props.employee.inactivatedAt).value}</span>
				</div>}
				{props.employee.modifiedAt && <div className="last-modified-date">
					<span className="capitalize">{f('modified') + ' ' + f('at') + ' ' + formatter.relative(props.employee.modifiedAt).value}</span>
				</div>}
			</div>}
		</div>
	);
}

export default EmployeeFields;
