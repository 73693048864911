import { actions as processorActions } from '../actions';
import { reduxSwitch } from '../../../tools';
import { cx } from '../../../../api';

const actions = processorActions.deviceStatusDetectors;

const defaultState = {
	list: null,
	map: null,
	pending: false,
	error: null,
}

// -------------------------------------------------------------

const addReducer = (state, action) => {
	switch (action.type) {
		case actions.add.request.type:
			return {
				...state,
				pending: true,
				error: null
			}
		case actions.add.fail.type:
			return {
				...state,
				pending: false,
				error: action.errorMessage
			}
		case actions.add.cancel.type:
			return {
				...state,
				pending: false
			}
		default:
			return state;
	}
}

const loadReducer = (state, action) => {
	switch (action.type) {
		case actions.load.request.type:
			return {
				...state,
				pending: true,
				error: null
			}
		case actions.load.success.type:
			return {
				...state,
				pending: false,
				list: action.processors,
				map: cx.i.hash(action.processors, (processor) => processor.processorId)
			}
		case actions.load.fail.type:
			return {
				...state,
				pending: false,
				error: action.errorMessage
			}
		case actions.load.cancel.type:
			return {
				...state,
				pending: false
			}
		default:
			return state;
	}
}

const updateReducer = (state, action) => {
	switch (action.type) {
		case actions.update.request.type:
			return {
				...state,
				pending: true,
				error: null
			}
		case actions.update.fail.type:
			return {
				...state,
				pending: false,
				error: action.errorMessage
			}
		case actions.update.cancel.type:
			return {
				...state,
				pending: false
			}
		default:
			return state;
	}
}

const removeReducer = (state, action) => {
	switch (action.type) {
		case actions.remove.request.type:
			return {
				...state,
				pending: true,
				error: null
			}
		case actions.remove.fail.type:
			return {
				...state,
				pending: false,
				error: action.errorMessage
			}
		case actions.remove.cancel.type:
			return {
				...state,
				pending: false
			}
		default:
			return state;
	}
}

function clearReducer(state, action) {
	switch (action.type) {
		case actions.clear.type:
			return {
				...state,
				error: null
			};
		default:
			return state;
	}
}

// -------------------------------------------------------------

const reducer = reduxSwitch([addReducer, loadReducer, updateReducer, removeReducer, clearReducer], defaultState);

export { reducer };
