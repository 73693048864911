import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useDrop } from 'react-dnd';
import { useI18n } from '../../../../i18n';
import { className, fromProps } from '../../../lib/className';
import { sortByName } from '../../../lib/sorting';
import { DragItemType } from '../dnd/DragItemType';
import Input from '../../general/form/Input';
import ButtonGroup from '../../general/form/ButtonGroup';
import Button from '../../general/form/Button';
import CategoryPicker from './CategoryPicker';
import './categorySelector.scss';

/**
 *
 * @param {Object} props
 * @param {cx.ods.categories.CategoryData} props.category
 * @param {function} props.onClick
 * @param {function} props.onEdit
 * @param {function} props.onRemove
 */

function Item(props) {

	const onDrop = (item) => {
		if (item.type == DragItemType.ACTION_EDIT) {
			props.onEdit(props.category);
		} else {
			props.onRemove(props.category);
		}
	}

	const [dropState, dropRef] = useDrop({
		accept: [DragItemType.ACTION_EDIT, DragItemType.ACTION_REMOVE],
		drop: item => onDrop(item),
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		}),
	})

	return (
		<div
			ref={dropRef}
			className={className('item', 'clickable', fromProps(props), { 'droppable': dropState.isOver && dropState.canDrop })}
			onClick={() => props.onClick(props.category)}
		>
			{props.category.name}
		</div>
	);
}

/**
 * @param {Object} props
 * @param {Array.<number>} [props.categoryIds] selectedIds
 * @param {Array.<number>} [props.comprisingIds] for filtering all categories by direct parents
 * @param {Array.<number>} [props.excludeIds]
 * @param {function} props.onApply
 * @param {function} props.onCancel
 * @param {function} props.onIntent
 * @param {string} [props.emptyText]
 */

function CategorySelector(props) {
	const { f } = useI18n();
	const [selected, setSelected] = useState({});
	const [filter, setFilter] = useState(null);
	const inputRef = useRef(null);

	useEffect(() => {
		const hash = {};
		props.categoryIds.forEach(categoryId => {
			hash[categoryId] = !!props.categories.map[categoryId]
		});
		setSelected(hash);
	}, [props.categoryIds]);

	useEffect(() => {
		const keydownHandler = (event) => {
			if (event.ctrlKey && event.keyCode == 70) {
				event.preventDefault();
				inputRef.current.focus();
			}
		}
		window.addEventListener('keydown', keydownHandler);
		return () => {
			window.removeEventListener('keydown', keydownHandler);
		}
	}, []);

	const onClick = (category) => {
		if (selected[category.categoryId]) {
			delete selected[category.categoryId];
		} else {
			selected[category.categoryId] = true;
		}
		setSelected({...selected});
	}

	const onApply = () => {
		props.onApply(Object.keys(selected).map(id => +id));
	}

	const onRemoveIntent = (category) => {
		props.onIntent({
			type: 'remove',
			category
		});
	}

	const onEditIntent = (category) => {
		props.onIntent({
			type: 'edit',
			category
		});
	}

	let items = null;
	if (props.categories.list != null) {
		const comprisingIds = props.comprisingIds != null && props.comprisingIds.length > 0 ? props.comprisingIds : null;
		const filtered = comprisingIds != null
			? props.categories.list.filter(category => {
				return category.comprisingIds && category.comprisingIds.some(id => comprisingIds.includes(id));
			})
			: props.categories.list
		;

		const groups = {}; // { groupId: [categories] }
		let outOfGroup = [];
		// we separate the categories that are in the group
		filtered.forEach(category => {
			if (category.comprisingIds) {
				let inGroup = false;
				category.comprisingIds.forEach(id => {
					if (props.groups.map[id]) {
						if (groups[id]) groups[id].push(category);
						else groups[id] = [category];
						!inGroup && (inGroup = true);
					}
				});
				if (!inGroup) outOfGroup.push(category);
			} else outOfGroup.push(category);
		});
		// adding a group as a category so that we can filter it later
		Object.keys(groups).forEach(id => outOfGroup.push(props.groups.map[id]));

		if (filter) {
			outOfGroup = outOfGroup.filter(category => {
				const name = category.custom ? category.name : f({ prefix: 'category', id: category.name })
				return (name.toLowerCase().indexOf(filter.toLowerCase()) >= 0);
			});
		}
		items = [];
		sortByName(outOfGroup, 'category').forEach(category => {
			// we use the corresponding component depending on the category type
			if (props.groups.map && props.groups.map[category.categoryId]) {
				const groupName = f({ prefix: 'category', id: category.name });
				const categories = props.categories.list.filter(cat => cat.comprisingIds?.includes(category.categoryId));
				let selectedCategory = null;
				categories.forEach(category => {
					selected[category.categoryId] && (selectedCategory = category);
				});
				const onSelect = (category) => {
					categories.forEach(_category => {
						if (selected[_category.categoryId]) {
							delete selected[_category.categoryId];
						}
					});
					category && (selected[category.categoryId] = true);
					setSelected({ ...selected });
				}
				items.push(
					<CategoryPicker
						key={category.categoryId}
						className="item"
						onSelect={onSelect}
						selected={selectedCategory}
						categories={categories}
						label={groupName}
					/>
				);
			} else {
				items.push(
					<Item
						key={category.categoryId}
						className={className({ 'selected': selected[category.categoryId] })}
						category={category}
						onClick={onClick}
						onRemove={onRemoveIntent}
						onEdit={onEditIntent}
					/>
				);
			}
		});
	}

	const onRef = (element) => {
		inputRef.current = element;
	}

	return (
		<div className={className('category-selector', fromProps(props))}>
			<div className="block">
				{!props.hideHeader && <div className="header">{f('select category')}</div>}
				<div className="filter">
					<label>{f('filter')}</label>
					<Input
						value={filter}
						onChange={setFilter}
						inputRef={onRef}
						cleanable
						autoFocus
					/>
				</div>
				<div className="content">{items}</div>
				{items.length == 0 && <span className="empty capitalize">{(props.emptyText || f('no categories yet'))}</span>}
			</div>
			<div className="footer">
				<ButtonGroup>
					<Button onClick={props.onCancel}>{f('cancel')}</Button>
					<Button onClick={onApply} disabled={selected.length == 0}>{f('apply')}</Button>
				</ButtonGroup>
			</div>
		</div>
	);
}


export default connect(
	state => ({
		categories: state.categories.general,
		groups: state.categories.groups
	})
)(CategorySelector);
