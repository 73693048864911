import React, { useState, useEffect } from 'react';
import { useI18n } from '../../../../i18n';
import Modal from '../../general/Modal';
import Form from '../../general/form/Form';
import DeviceSet from './DeviceSet';
import DeviceSelector from './deviceSelector/DeviceSelector';
import ActionAdd from '../actionbar/ActionAdd';
import DefaultActionBar from '../actionbar/DefaultActionBar';
import './objectDeviceManager.scss';

/**
 * @param {Object} props
 * @param {Array.<string>} [props.uris]
 * @param {Array.<string>} [props.excludeUris]
 * @param {boolean} [props.editMode]
 * @param {boolean} [props.disabled]
 * @param {function} [props.setUris]
 * @param {string} [props.controlName]
 * @param {string} [props.title]
 */

function ObjectDeviceManager(props) {
	const { f } = useI18n();
	const [deviceUris, setUris] = useState([]);
	const [adding, setAdding] = useState(false);
	const controlled = Boolean(props.setUris);

	useEffect(() => {
		!controlled && setUris(props.uris ? props.uris : []);
	}, [props.uris]);

	const onChange = (uris) => {
		controlled ? props.setUris(uris) : setUris(uris);
		setAdding(false);
	}

	const closeModal = () => {
		setAdding(false);
	}

	return (
		<div className="object-device-manager">
			<div className="header">
				<span className="title">{props.title ? props.title : f('devices')}</span>
				{props.editMode &&
					<DefaultActionBar
						hideRemove
						hideEdit
						disabled={props.disabled}
						appendActions={<ActionAdd onClick={() => setAdding(true)} />}
					/>
				}

			</div>
			<Form.Control
				controlType={DeviceSet}
				controlName={props.controlName || 'uris'}
				value={controlled ? props.uris : deviceUris}
				onChange={onChange}
				emptyMessage={props.emptyMessage || f('no items yet')}
				editMode={props.editMode}
			/>
			{adding &&
				<Modal
					className="object-device-manager"
					title={f('select devices')}
					onClose={closeModal}
				>
					<DeviceSelector
						excludeUris={props.excludeUris}
						uris={controlled ? props.uris : deviceUris}
						onSubmit={onChange}
						onCancel={closeModal}
					/>
				</Modal>
			}
		</div>
	);
}

export default ObjectDeviceManager;
