import React, { FC } from 'react';

import { useProcessorPage } from 'hooks/downsampled-message-history/useProcessorPage';
import Loader from 'core/react/general/Loader';
import { ProcessorsView } from './processors-view';
import { ProcessorForm } from './processor-form';

export const DownsampledMessageHistory: FC = () => {
  const { isFormPage, isLoading, viewProps, formProps } = useProcessorPage();

  if (isLoading) {
    return (
      <div className="vh-center">
        <Loader size={Loader.Size.LG} />
      </div>
    );
  }

  return isFormPage ? <ProcessorForm {...formProps} /> : <ProcessorsView {...viewProps} />;
};
