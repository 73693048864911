import React, { Dispatch, FC } from 'react';

import { IMqttClient } from 'types/mqttClient';
import Intent from 'core/misc/ObjectActionIntent';
import List from 'core/react/general/list/List';
import { MqttClientsTableHeader } from './MqttClientsTableHeader';
import { MqttTableRow } from './MqttTableRow';

import './mqttClientsTable.scss';

interface IMqttClientsTableProps {
  mqttClients: IMqttClient[];
  onIntent: Dispatch<Intent>;
}

export const MqttClientsTable: FC<IMqttClientsTableProps> = ({ mqttClients, onIntent }) => (
  <div className="mqtt-clients-table">
    <MqttClientsTableHeader />
    <List className="mqtt-clients-list">
      {mqttClients.map((client) => (
        <MqttTableRow key={client.clientId} mqttClient={client} onIntent={onIntent} />
      ))}
    </List>
  </div>
);
