import styled from 'styled-components';

import { Colors } from 'ui/constants/colors';

export const ColumnedPageStyled = styled.div`
  display: flex;
  height: 100%;
  min-width: 620px;
`;

export const MainColumnStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 280px;
  border-right: 1px solid ${Colors.PurpleCrystal};
`;

export const SideColumnsStyled = styled.div`
  position: relative;
  display: flex;
  width: 100%;

  @media (max-width: 890px) {
    flex-direction: column;
  }
`;

export const SideColumnStyled = styled.div`
  width: 100%;
  max-width: 280px;

  &:nth-child(2) {
    max-width: 380px;
  }

  @media (max-width: 890px) {
    min-height: 200px;
    max-width: 100%;
    border-right: none;

    &:nth-child(2) {
      max-width: 100%;
      border-top: 1px solid ${Colors.PurpleCrystal};
    }
  }
`;
