import { f } from "../../i18n";

/**
 * @param {function} [getter] function which returns comparable value
 * @param {boolean} [ascending] sorting order
 */
export const compare = (getter = value => value, ascending = true) => (left, right) => {
	if (!left && !right) return 0;
	else if (!left && right) return 1;
	else if (left && !right) return -1;
	else {
		const leftValue = getter(left), rightValue = getter(right);
		if (leftValue < rightValue) return ascending ? -1 : 1;
		else if (leftValue > rightValue) return ascending ? 1 : -1;
		else return 0;
	}
}

/**
 * @param {Array.<Object>} objects
 * @param {function} [getter]
 * @param {boolean} [ascending]
 */
export const sort = (objects, getter, ascending) => {
	if (objects) objects.sort(compare(getter, ascending));
	return objects;
}

export const sortByName = (objects, prefix) => {
	return sort(objects, object => {
		let name = object.name;
		if (name == null) return '';
		name = prefix ? f({ prefix, id: name }) : f(name);
		return name.toLowerCase();
	});
}
