import { ActionGeneratorBuilder } from "../../actions";

export const actions = new ActionGeneratorBuilder('processors')
	.subtype('attributeRange', attributeRange => attributeRange
		.subtype('add', add => add.request('data').success('data').fail().cancel())
		.subtype('load', load => load.request().success('detectors').fail().cancel())
		.subtype('update', update => update.request({ processorId: true, data: true }).success('data').fail().cancel())
		.subtype('remove', remove => remove.request('processorId').success().fail().cancel())
		.type('clear')
	)
	.subtype('attributeRangeDetectorMatches', attributeRangeDetectorMatches => attributeRangeDetectorMatches
		.subtype('matches', matches => matches
			.request({ processorId: true, filter: false })
			.success({ processorId: true, matches: true })
			.fail({ processorId: true, errorMessage: true })
			.cancel()
		)
		.type('subscribe', 'processorId')
		.type('unsubscribe', 'processorId')
	)
	.subtype('commuteDetectors', commuteDetectors => commuteDetectors
		.subtype('add', add => add.request('data').success('data').fail().cancel())
		.subtype('load', load => load.request().success('processors').fail().cancel())
		.subtype('update', update => update.request({ processorId: true, data: true }).success('data').fail().cancel())
		.subtype('remove', remove => remove.request('processorId').success().fail().cancel())
		.type('clear')
	)
	.subtype('devicePresence', devicePresence => devicePresence
		.subtype('add', add => add.request('data').success('data').fail().cancel())
		.subtype('load', load => load.request().success('detectors').fail().cancel())
		.subtype('update', update => update.request({ processorId: true, data: true }).success('data').fail().cancel())
		.subtype('remove', remove => remove.request('processorId').success().fail().cancel())
		.type('clear')
	)
	.subtype('devicePresenceMatches', devicePresenceMatches => devicePresenceMatches
		.subtype('matches', matches => matches
			.request({ processorId: true, filter: false })
			.success({ processorId: true, matches: true })
			.fail({ processorId: true, errorMessage: true })
			.cancel()
		)
		.type('subscribe', 'processorId')
		.type('unsubscribe', 'processorId')
	)
	.subtype('deviceStatusDetectors', deviceStatusDetectors => deviceStatusDetectors
		.subtype('add', add => add.request('data').success('data').fail().cancel())
		.subtype('load', load => load.request().success('processors').fail().cancel())
		.subtype('update', update => update.request({ processorId: true, data: true }).success('data').fail().cancel())
		.subtype('remove', remove => remove.request('processorId').success().fail().cancel())
		.type('clear')
	)
	.subtype('flowProcessors', flowProcessors => flowProcessors
		.subtype('add', add => add.request('data').success('processor').fail().cancel())
		.subtype('load', load => load.request().success('processors').fail().cancel())
		.subtype('update', update => update.request({ processorId: true, data: true }).success().fail().cancel())
		.subtype('remove', remove => remove.request('processorId').success().fail().cancel())
		.type('clear')
	)
	.subtype('fuelLevel', fuelLevel => fuelLevel
		.subtype('add', add => add.request('data').success('data').fail().cancel())
		.subtype('load', load => load.request().success('processors').fail().cancel())
		.subtype('update', update => update.request({ processorId: true, data: true }).success('data').fail().cancel())
		.subtype('remove', remove => remove.request('processorId').success().fail().cancel())
		.type('clear')
	)
	.subtype('messagePublishers', messagePublisher => messagePublisher
		.subtype('add', add => add.request('data').success('data').fail().cancel())
		.subtype('load', load => load.request().success('publishers').fail().cancel())
		.subtype('update', update => update.request({ processorId: true, data: true }).success('data').fail().cancel())
		.subtype('remove', remove => remove.request('processorId').success().fail().cancel())
		.type('clear')
	)
	.subtype('downsampledMessageHistory', downsampledMessageHistory => downsampledMessageHistory
		.subtype('add', add => add.request('data').success('data').fail().cancel())
		.subtype('load', load => load.request().success('processors').fail().cancel())
		.subtype('update', update => update.request({ processorId: true, data: true }).success('data').fail().cancel())
		.subtype('remove', remove => remove.request('processorId').success().fail().cancel())
		.type('clear')
	)
	.build()
;
