import { api, rx } from '../../api';
import { ofType, combineEpics } from 'redux-observable';
import { of, from } from 'rxjs';
import { mergeMap, catchError, map as rxmap, takeUntil, filter } from 'rxjs/operators';
import { actions as sessionActions } from './session';
import { sessionStorage as storage } from '../../app/storage';
import { actions as accountActions } from './account';
import { ActionGeneratorBuilder } from '../actions';

const STORAGE_KEY = 'feedId';

const defaultState = {
	feedId: null,
	announcements: null,
	announcedAt: null,
	error: null
};

const actions = new ActionGeneratorBuilder('announcer')
	.type('started', 'feedId')
	.type('announced', 'announcements')
	.type('error', 'errorMessage')
	.type('stopped')
	.type('setFeedId', 'feedId')
	.type('inquiry')
	.type('retry')
	.build()
;

// ---------------------------------------------------------

function reducer(state, action) {
	switch (action.type) {
		case actions.setFeedId.type:
			state = {
				...state,
				feedId: action.feedId
			};
			break;
		case actions.started.type:
			storage.set(STORAGE_KEY, action.feedId);
			state = {
				...state,
				announcements: null,
				feedId: action.feedId,
				error: null
			};
			break;
		case actions.announced.type:
			state = {
				...state,
				announcements: action.announced,
				announcedAt: new Date(),
				error: null
			};
			break;
		case actions.error.type:
			storage.remove(STORAGE_KEY);
			state = {
				...state,
				feedId: null,
				error: action.errorMessage
			};
			break;
		case actions.stopped.type:
			storage.remove(STORAGE_KEY);
			state = {
				...state,
				feedId: null,
				announcedAt: null,
				error: null
			};
			break;
		default:
	}
	return state || defaultState;
}

// ---------------------------------------------------------

// TODO:
// + 1. Retry in case of failed start
// + 2. Repeat request after announced

const feedIdEpic = (action$) => {
	return action$.pipe(
		ofType(accountActions.retrieve.success.type),
		rxmap(() => {
			const feedId = storage.get(STORAGE_KEY);
			if (feedId != null) {
				return actions.setFeedId({ feedId });
			}
		}),
		filter(action => action != null)
	)
}

const startEpic = (action$, state$) => {
	return action$.pipe(
		ofType(sessionActions.events.started.type, sessionActions.events.resumed.type, actions.retry.type),
		filter(action => state$.value.announcer.feedId == null),
		mergeMap(action =>
			rx(api.announcer.start).pipe(
				rxmap(operation => actions.started({ feedId: operation.response().feedId })),
				catchError(error => {
					const mapped = [actions.error({ errorMessage: error.userMessage || error.message })];
					if (error.custom && error.isNack()) mapped.push(actions.retry());
					return from(mapped);
				})
			)
		)
	)
};

const startLocalEpic = (action$, state$) => {
	return action$.pipe(
		ofType(sessionActions.events.started.type, sessionActions.events.resumed.type, actions.retry.type),
		filter(action => state$.value.announcer.feedId != null),
		rxmap(action => actions.started({ feedId: state$.value.announcer.feedId }))
	)
};

const stopEpic = (action$) => {
	return action$.pipe(
		ofType(sessionActions.events.paused.type, sessionActions.events.closed.type),
		rxmap(action => actions.stopped())
	)
};

const inquiryEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.started.type, actions.inquiry.type),
		mergeMap(action =>
			rx(api.announcer.inquire, state$.value.announcer.feedId).pipe(
				mergeMap(operation => {
					let args = [];
					if (operation.response().length > 0) { // emit announced action only in case of not empty response
						args.push(actions.announced({ announcements: operation.response() }));
					}
					args.push(actions.inquiry());
					return of.apply(this, args);
				}),
				catchError(error => {
					const args = [];
					args.push(actions.error({ errorMessage: error.userMessage || error.message }));
					if (error.custom) {
						if (error.isNack()) {
							args.push(actions.inquiry());
						} else if (error.isNotFound()) {
							args.push(actions.retry());
						}
					}
					return of.apply(this, args);
				}),
				takeUntil(action$.pipe(ofType(actions.stopped.type)))
			)
		)
	)
};

const epic = combineEpics(feedIdEpic, startEpic, startLocalEpic, stopEpic, inquiryEpic);

// ---------------------------------------------------------

export { actions, reducer, epic };
