import React from 'react';
import { capitalize } from '../../../misc/misc';
import { className, fromProps } from '../../../lib/className';

/**
 * @param {Object} props
 * @param {function} props.onClick
 * @param {React.RefObject} [props.customRef]
 * @param {boolean} [props.disabled]
 * @param {boolean} [props.active]
 * @param {string} [props.title]
 */

function BarAction(props) {
	return (
		<div
			id={props.id}
			className={className('action', fromProps(props), { 'clickable': !!props.onClick, 'disabled': props.disabled, 'active': props.active })}
			onClick={props.disabled ? null : props.onClick}
			ref={props.customRef}
			title={capitalize(props.title)}
		>
			{props.children}
		</div>
	);
}

export default BarAction;