import { combineEpics, ofType } from "redux-observable";
import { mergeMap, exhaustMap, map, withLatestFrom, filter, tap, catchError, ignoreElements } from 'rxjs/operators';
import { of } from "rxjs";
import { ActionGeneratorBuilder, errorMap } from "../actions";
import { actions as accountActions } from './account';
import { actions as sessionActions } from './session';
import { reduxSwitch } from "../tools";
import { api, ods, rx } from "../../api";
import { commonSessionStorage, commonLocalStorage } from "../../app/storage";
import { LogLevel, rootLogger } from "core/lib/log";

const logger = rootLogger.logger('authorization');

const STORAGE_KEY = 'authorization-credentials';

export const getRememberedCredentials = () => {
	const credentials = commonLocalStorage.get(STORAGE_KEY);
	return { remembered: Boolean(credentials), credentials };
};

const resendDefaultState = {
	pending: false
}

const authTypes = {
	signIn: 'signIn',
	verify: 'verify'
};

const defaultState = {
	verificationToken: null,
	started: false,
	pending: false,
	authType: null,
	loginName: null,
	challenge: null,
	resend: null,
	tmpCredentials: null,
	error: null
};

const actions = new ActionGeneratorBuilder('authorization')
	.subtype(authTypes.signIn, signIn => signIn.request({ loginName: true, remember: false }).success({ loginName: true, resolution: true }).fail())
	.subtype(authTypes.verify, verify => verify.request().success({ loginName: true, resolution: true }).fail())
	.subtype('signOut', signOut => signOut.request().success().fail())
	.subtype('resend', resend => resend.request('challengeNo').success('challenge').fail())
	.subtype('step', step => step.request('value').success('challenge').fail())
	.type('restart', { challenge: false }).type('clear')
	.build()
;

const tokenManager = new (class TokenManager {

	constructor() {
		this.STORAGE_KEY = 'token-manager';
		this.fallbackSignInAdvance = 24 * 60 * 60 * 1000;
		this.refreshMargin = 1 * 60 * 1000;
		this.restore();
	}

	restore() {
		let storage, context;
		context = this.readContext(storage = commonSessionStorage);
		if (context == null) context = this.readContext(storage = commonLocalStorage);
		if (context == null) this.storage = commonSessionStorage;
		else {
			this.storage = storage;
			this.loginName = context.loginName;
			this.setAccessToken(context.accessToken);
			this.setSessionToken(context.sessionToken);
		}
	}

	getLoginName() {
		return this.loginName;
	}

	signedIn(loginName, resolution) {
		logger.debug(`Signed in %s`, loginName);
		if (loginName != this.loginName) {
			if (this.loginName) this.clear();
			this.loginName = loginName;
		}
		this.updateAccessToken(this.jwtToken(resolution.access));
		this.updateSessionToken(resolution.session);
	}

	inspecting(loginName, signinContext) {
		logger.debug(`Inspecting %s`, loginName);
		this.updateAccessToken(signinContext.access);
	}

	updateAccessToken(accessToken) {
		this.setAccessToken(accessToken);
		this.updateContext({accessToken});
	}

	setAccessToken(accessToken) {
		this.accessToken = accessToken;
		if (logger.loggable(LogLevel.Debug)) {
			const loginName = accessToken && this.decodeJWT(accessToken.jwt)?.upn;
			logger.debug('Access token set for %s', loginName, accessToken);
		}
		this.scheduleAccessRefresh();
	}

	scheduleAccessRefresh() {
		if (this.accessToken == null) return;
		const remains = this.accessToken.expiresAt.getTime() - Date.now();
		if (remains < this.refreshMargin) this.obtainAccessToken();
		else {
			if (this.accessRefreshTimer) clearTimeout(this.accessRefreshTimer);
			const interval = remains - this.refreshMargin;
			this.accessRefreshTimer = setTimeout(() => {
				this.accessRefreshTimer = null;
				this.obtainAccessToken();
			}, interval);
			logger.debug('Scheduled access token refresh in %d', interval);
		}
	}

	stopAccessTokenRefresh() {
		if (this.accessRefreshTimer != null) {
			clearTimeout(this.accessRefreshTimer);
			this.accessRefreshTimer = null;
			logger.debug('Stopped access token refresh');
		}
	}

	obtainAccessToken() {
		const loginName = this.loginName;
		logger.debug('Obtaining access token for %s', loginName);
		rx(api.auth.token.access)
			.pipe(filter(() => loginName == this.loginName), catchError(error => of(null)))
			.subscribe(operation => this.setAccessToken(operation?.response()))
		;
	}

	getAccessToken() {
		return this.accessToken;
	}

	updateSessionToken(sessionToken) {
		this.setSessionToken(sessionToken);
		this.updateContext({sessionToken});
	}

	setSessionToken(sessionToken) {
		this.sessionToken = sessionToken;
		logger.debug('Session token set', sessionToken);
	}

	getSessionToken() {
		return this.sessionToken;
	}

	accountActivated(account) {
		this.stopSignInRefresh();
		if (account.loginName != this.loginName) return;
		const context = this.readContext();
		const storage = account.toughenAuth ? commonSessionStorage : commonLocalStorage;
		if (storage != this.storage) {
			if (context != null) {
				this.storage.remove(this.STORAGE_KEY);
				this.writeContext(context, storage);
				logger.debug('Moved context to %s storage', storage == commonSessionStorage ? 'session' : 'local');
			}
			this.storage = storage;
		}
		if (account.signInPeriod == null) this.signInAdvance = this.fallbackSignInAdvance;
		else this.signInAdvance = Math.min(account.signInPeriod * 60 * 1000 * 2 / 3, this.fallbackSignInAdvance);
		this.startSignInRefresh(context?.signInToken);
	}

	startSignInRefresh(signInToken) {
		if (signInToken == null) this.obtainSignInToken();
		else this.scheduleSignInRefresh(signInToken);
	}

	obtainSignInToken() {
		const loginName = this.loginName;
		logger.debug('Obtaining signin token for %s', loginName);
		rx(api.auth.token.signIn)
			.pipe(filter(() => loginName == this.loginName), catchError(error => of(null)))
			.subscribe(operation => this.setSignInToken(operation?.response()))
		;
	}

	scheduleSignInRefresh(signInToken) {
		const remains = signInToken.expiresAt.getTime() - Date.now();
		if (remains < this.signInAdvance) this.refreshSignInToken(signInToken);
		else {
			if (this.signInUpdateTimer) clearTimeout(this.signInUpdateTimer);
			const interval = remains - this.signInAdvance;
			this.signInUpdateTimer = setTimeout(() => {
				this.signInUpdateTimer = null;
				this.refreshSignInToken(signInToken);
			}, interval);
			logger.debug('Scheduled signin token refresh in %d', interval);
		}
	}
	
	refreshSignInToken(signInToken) {
		const remains = signInToken.expiresAt.getTime() - Date.now();
		if (remains < this.refreshMargin) this.obtainSignInToken();
		else {
			const loginName = this.loginName;
			logger.debug('Refreshing signin token for %s', loginName);
			rx(api.auth.token.renew, signInToken.value)
				.pipe(filter(() => loginName == this.loginName), catchError(error => of(null)))
				.subscribe(operation => this.setSignInToken(operation?.response()))
			;
		}
	}

	stopSignInRefresh() {
		if (this.signInUpdateTimer != null) {
			clearTimeout(this.signInUpdateTimer);
			this.signInUpdateTimer = null;
			logger.debug('Stopped signin token refresh');
		}
	}

	setSignInToken(signInToken) {
		this.updateContext({signInToken});
		logger.debug('Signin token set', signInToken);
		if (signInToken) this.scheduleSignInRefresh(signInToken);
	}

	hasSignInToken() {
		return Boolean(this.readContext()?.signInToken);
	}

	getSignInToken(loginName) {
		if (loginName != this.loginName) return null;
		return this.readContext()?.signInToken;
	}

	signingOut$(account) {
		const signInToken = this.getSignInToken(account.loginName);
		if (signInToken == null) return of(null);
		this.stopSignInRefresh();
		logger.debug('Revoking signin token of %s', account.loginName);
		return rx(api.auth.token.remove, signInToken.value).pipe(catchError(error => of(null)));
	}

	signedOut(account, signinContext) {
		logger.debug(`Signed out %s`, account.loginName);
		if (signinContext?.access) this.setAccessToken(signinContext.access);
		if (account.loginName != this.loginName) return;
		this.clear();
	}

	updateContext(update) {
		const context = this.readContext();
		this.writeContext(Object.assign(context || {loginName: this.loginName}, update));
	}

	readContext(storage = this.storage) {
		const context = storage?.get(this.STORAGE_KEY);
		if (context == null || context.loginName == null) return null;
		if (context.sessionToken?.expiresAt) context.sessionToken.expiresAt = new Date(context.sessionToken.expiresAt);
		if (context.accessToken?.expiresAt) context.accessToken.expiresAt = new Date(context.accessToken.expiresAt);
		if (context.signInToken?.expiresAt) context.signInToken.expiresAt = new Date(context.signInToken.expiresAt);
		return context;
	}

	writeContext(context, storage = this.storage) {
		storage.set(this.STORAGE_KEY, context);
	}

	clear() {
		this.stopSignInRefresh();
		this.stopAccessTokenRefresh();
		this.storage.remove(this.STORAGE_KEY);
		this.storage = commonSessionStorage;
		this.sessionToken = null;
		this.accessToken = null;
		this.loginName = null;
	}

	jwtToken(jwt) {
		const data = this.decodeJWT(jwt);
		if (data == null) return null;
		return {value: data.jti, expiresAt: new Date(data.exp * 1000), jwt};
	}

	decodeJWT(jwt) {
		const payload = jwt?.split('.')[1]?.replace(/[-_]/g, match => match == '-' ? '+' : '/');
		if (payload != null) try {
			const data = JSON.parse(window.atob(payload));
			if (data.jti == null || data.exp == null) throw new Error(`Malformed JWT ${jwt}`);
			return data;
		} catch (error) {
			logger.error(`Failed to decode JWT ${error}`);
		}
		return null;
	}
})();

const signInReducer = (state, action) => {
	switch (action.type) {
		case actions.signIn.request.type:
			let tmpCredentials = null;
			if (action.remember) {
				tmpCredentials =  { ...getRememberedCredentials().credentials };
				if (tmpCredentials.loginName != action.loginName) delete tmpCredentials.password;
				tmpCredentials.loginName = action.loginName;
			}
			return {
				...defaultState,
				authType: authTypes.signIn,
				loginName: action.loginName,
				tmpCredentials,
				error: null
			};
		case actions.verify.request.type:
			return {
				...defaultState,
				authType: authTypes.verify,
				loginName: action.loginName,
				error: null
			};
		case actions[state.authType]?.success?.type:
			if (state.authType == authTypes.signIn) {
				if (state.tmpCredentials) commonLocalStorage.set(STORAGE_KEY, state.tmpCredentials);
				else commonLocalStorage.remove(STORAGE_KEY);
			}
			return {
				...state,
				verificationToken: action.resolution?.verification,
				authType: null,
				tmpCredentials: null,
				started: false,
				pending: false
			};
		case actions[state.authType]?.fail?.type:
			return {
				...state,
				error: action.errorMessage,
				pending: false
			};
		default:
			return state;
	}
}

const signOutReducer = (state, action) => {
	switch (action.type) {
		case actions.signOut.request.type:
			return {
				...state,
				pending: true,
				error: null
			};
		case actions.signOut.success.type:
			return {
				...state,
				pending: false
			};
		case actions.signOut.fail.type:
			return {
				...state,
				pending: false,
				error: action.errorMessage
			};
		default:
			return state;
	}
}

const resendReducer = (state, action) => {
	switch (action.type) {
		case actions.resend.request.type:
			return {
				...state,
				resend: { pending: true },
				error: null
			};
		case actions.resend.success.type:
			return {
				...state,
				resend: { pending: false },
				challenge: action.challenge
			};
		case actions.resend.fail.type:
			return {
				...state,
				resend: { pending: false },
				error: action.errorMessage
			};
		default:
			return state;
	}
}

const stepReducer = (state, action) => {
	switch (action.type) {
		case actions.step.request.type:
			const newState = { ...state };
			if (newState.challenge?.error) delete newState.challenge.error;
			if (newState.tmpCredentials && newState.authType == authTypes.signIn) {
				if (!newState.challenge) { // if has no challenge, it means that the value is the login name
					newState.tmpCredentials =  { ...getRememberedCredentials().credentials };
					if (newState.tmpCredentials.loginName != action.value) delete newState.tmpCredentials.password;
					newState.tmpCredentials.loginName = action.value;
				} else if (
					newState.challenge.credentialType == ods.auth.CredentialType.password
					|| newState.challenge.credentialType == ods.auth.CredentialType.newPassword
				) {
					newState.tmpCredentials = { ...newState.tmpCredentials, password: action.value };
				}
			}
			newState.pending = true;
			newState.error = null;
			return newState;
		case actions.step.success.type:
			const challenge = action.challenge.error ? { ...state.challenge, ...action.challenge } : action.challenge;
			return {
				...state,
				challenge,
				started: state.started || !challenge.error,
				resend: action.challenge.resendable ? resendDefaultState : null,
				pending: false,
				error: null
			};
		case actions.step.fail.type:
			return {
				...state,
				error: action.errorMessage,
				pending: false
			};
		default:
			return state;
	}
}

const clearReducer = (state, action) => {
	switch (action.type) {
		case actions.clear.type:
			return { ...defaultState, verificationToken: state.verificationToken };
		case actions.restart.type:
			return { ...defaultState, challenge: action.challenge };
		default:
			return state;
	}
}

const reducer = reduxSwitch([signInReducer, resendReducer, stepReducer, signOutReducer, clearReducer], defaultState);

const signEpic = combineEpics(
	(action$) => action$.pipe(ofType(actions.signIn.request.type), map(action => actions.step.request({ value: action.loginName }))),
	(action$) => action$.pipe(ofType(actions.signIn.success.type, actions.signOut.success.type), map(action => actions.clear())),
	(action$) => action$.pipe(ofType(actions.verify.request.type), map(action => actions.step.request({ value: null }))),
	(action$) => action$.pipe(ofType(actions.verify.success.type, actions.signOut.success.type), map(action => actions.clear())),
	(action$, state$) => action$.pipe(
		ofType(actions.signOut.request.type),
		withLatestFrom(state$.pipe(map(state => state.account.details))),
		exhaustMap(([action, account]) => tokenManager.signingOut$(account).pipe(
			mergeMap(operation => rx(api.auth.leave).pipe(
				tap(operation => tokenManager.signedOut(account, operation.response())),
				map(operation => actions.signOut.success()),
				errorMap(actions.signOut.fail)
			))
		))
	)
);

const resendEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.resend.request.type),
		withLatestFrom(state$.pipe(map(state => state.authorization.authType))),
		mergeMap(([action, authType]) => {
			return rx(api.auth[authType].resend, action.challengeNo).pipe(
				map(operation => actions.resend.success({ challenge: operation.response() })),
				errorMap(actions.resend.fail)
			)
		})
	);
}

class CredentialBuilder {
	#challengeNo;
	#credentialType;
	#value;

	challengeNo(challengeNo) {
		this.#challengeNo = challengeNo;
		return this;
	}

	credentialType(credentialType) {
		this.#credentialType = credentialType;
		return this;
	}

	value(value) {
		this.#value = value;
		return this;
	}

	build() {
		const credential = new ods.auth.Credential();
		credential.challengeNo = this.#challengeNo;
		credential.credentialType = this.#credentialType;
		credential.value = this.#value;
		return credential;
	}
}

const stepEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.step.request.type),
		withLatestFrom(state$.pipe(map(state => state.authorization))),
		mergeMap(([action, state]) => {
			const petition = new ods.auth.Petition();
			petition.sessionId = state.challenge?.sessionId;
			petition.credentials = [];
			let credential = null;
			if (action.value) {
				const challenge = state.challenge, builder = new CredentialBuilder();
				if (!challenge) builder.credentialType(ods.auth.CredentialType.loginName);
				else builder.challengeNo(challenge.challengeNo).credentialType(challenge.credentialType);
				credential = builder.value(action.value).build();
				petition.credentials.push(credential);
			}
			let tokenCredential = null;
			if (credential && credential.credentialType == ods.auth.CredentialType.loginName) {
				const signInToken = tokenManager.getSignInToken(credential.value);
				if (signInToken) {
					tokenCredential = new CredentialBuilder().credentialType(ods.auth.CredentialType.token).value(signInToken.value).build();
					petition.credentials.push(tokenCredential);
				}
			}
			return rx(api.auth[state.authType], petition).pipe(
				withLatestFrom(state$.pipe(map(state => state.authorization))),
				map(([operation, state]) => {
					const resolution = operation.response();
					if (!resolution.challenge) {
						if (state.authType == authTypes.signIn) tokenManager.signedIn(state.loginName, resolution);
						return actions[state.authType].success({ loginName: state.loginName, resolution });
					}
					if (tokenCredential) {
						tokenManager.clear();
						return actions.restart();
					}
					if (resolution.challenge.challengeNo < credential?.challengeNo) return actions.restart({ challenge: resolution.challenge });
					return actions.step.success({ challenge: resolution.challenge });
				}),
				errorMap(actions.step.fail)
			);
		})
	);
}

const watchSessionEpic = action$  => {
	return action$.pipe(
		ofType(sessionActions.inspect.success.type)
		, tap(action => tokenManager.inspecting(action.loginName, action.signinContext))
		, ignoreElements()
	);
};

const watchAccountRetrieveEpic = (action$) => {
	return action$.pipe(
		ofType(accountActions.retrieve.success.type),
		tap(action => tokenManager.accountActivated(action.details)),
		ignoreElements()
	);
}

const epic = combineEpics(signEpic, resendEpic, stepEpic, watchSessionEpic, watchAccountRetrieveEpic);


export { actions, reducer, epic, tokenManager };
