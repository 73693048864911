import { Dispatch, useRef } from 'react';
import { DragObjectWithType, useDrop } from 'react-dnd';

import { IDownsampledMessageHistoryProcessor } from 'types/detectors';
import { cx } from 'core/api';
import { generateId } from 'core/misc/misc';
import Intent from 'core/misc/ObjectActionIntent';
import { DragItemType } from 'core/react/share/dnd/DragItemType';

interface IUseProcessorListItemProps {
  className: string;
  processor: IDownsampledMessageHistoryProcessor;
  onSelect: Dispatch<IDownsampledMessageHistoryProcessor>;
  onIntent: Dispatch<Intent>;
}

export const useProcessorListItem = (props: IUseProcessorListItemProps) => {
  const { processor, onSelect, onIntent } = props;

  const uid = useRef(generateId());

  const selectProcessor = () => {
    onSelect(processor);
  };

  const onEdit = () => {
    onIntent(new Intent(null, Intent.Action.Edit, processor));
  };

  const onRemove = () => {
    const offset = cx.dom.at.offset(document.getElementById(uid.current));

    onIntent(new Intent(null, Intent.Action.Remove, processor, { offset }));
  };

  const onDrop = (item: DragObjectWithType) => {
    item.type === DragItemType.ACTION_EDIT ? onEdit() : onRemove();
  };

  const [dropState, dropRef] = useDrop({
    accept: [DragItemType.ACTION_EDIT, DragItemType.ACTION_REMOVE],
    drop: (item) => onDrop(item),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return { id: uid.current, dropState, dropRef, name: processor.name, selectProcessor };
};
