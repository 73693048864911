import React, { useState, useEffect, useRef } from 'react';
import Chart from "react-apexcharts";
import { textColorMain, colorPrimary } from '../../../defaults.scss';
import { useI18n } from '../../../../i18n';

/**
 * @param {Object} props
 * @param {Array.<cx.ods.processors.VoltageLevel>} props.levels
 */

function VoltageLevelsGraphView(props) {
	const [state, setState] = useState();
	const { fc } = useI18n();
	const ref = useRef();
	let graph = null;

	useEffect(() => {
		const newState = {
			series: [{
				name: fc('level'),
				data: props.levels.map(level => level.level)
			}],
			options: {
				colors: props.levels.map(name => colorPrimary),
				chart: {
					zoom: {
						enabled: false
					}
				},
				dataLabels: {
					enabled: true,
				},
				fill: {
					opacity: 1
				},
				legend: {
					show: false
				},
				plotOptions: {
					bar: {
						columnWidth: '40%',
						distributed: true,
						dataLabels: {
							position: 'top'
						}
					}
				},
				stroke: {
					show: true,
					curve: 'straight',
					lineCap: 'round',
				},
				tooltip: {
					x: {
						formatter: (index, data) => props.levels[data.dataPointIndex].voltage + ' ' + fc({ prefix: 'units', id: 'V' }),
					},
					y: {
						formatter: (level) => level + ' ' + fc({ prefix: 'units', id: '%' }),
						title: {
							formatter: (seriesName) => seriesName,
						},
					},
				},
				xaxis: {
					categories: props.levels.map(level => level.voltage),
					position: 'bottom',
					axisBorder: {
						show: false
					},
					axisTicks: {
						offsetX: 1,
						offsetY: -1
					},
					labels: {
						formatter: (val) => val,
						hideOverlappingLabels: false,
						style: {
							fontWeight: 'bold',
							fontFamily: 'inherit',
							colors: textColorMain
						}
					},
					title: {
						text: fc('voltage') + ', ' + fc({ prefix: 'units', id: 'V' }),
						style: {
							color: textColorMain
						}
					}
				},
				yaxis: {
					labels: {
						show: true,
						style: {
							fontWeight: 'bold',
							fontFamily: 'inherit',
							colors: textColorMain
						},
						offsetX: -5
					},
					title: {
						text: fc('level') + ', ' + fc({ prefix: 'units', id: '%' }),
						style: {
							color: textColorMain
						},
						offsetX: 5
					}
				}
			}
		}
		setState(newState);
	}, [props.levels]);

	if (state) {
		graph = (
			<Chart
				options={state.options}
				series={state.series}
				type="line"
				width="100%"
				height={220}
				ref={ref}
			/>
		);
	}

	return (
		<div className="voltage-levels-graph-view">
			{graph}
		</div>
	);
}

export default VoltageLevelsGraphView;