import React, { useRef, useContext, useImperativeHandle, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { List } from 'rsuite';
import { useDrag, useDrop } from 'react-dnd';
import { DragItemType } from '../../../share/dnd/DragItemType';
import { withRouter } from 'react-router-dom'
import { getOwMap } from '../../../general/location/Map';
import * as appValues from '../../../../redux/api/application/values';
import { actions as appActions } from '../../../../redux/app/appActions';
import { AppActionType } from '../../../../app/AppActionType';
import { GroupingIdContext } from '../device-side-menu';
import { ReduxKeyContext } from '../../../../misc/ReduxKeyContext';
import { scrollIntoView } from '../../../../misc/misc';
import { getEmptyImage } from 'react-dnd-html5-backend';
import DeviceListItemContent from './DeviceListItemContent';
import { className } from '../../../../lib/className';
import { DeviceDetailsProxy } from '../../../../api/device'; // eslint-disable-line

/**
 * @param {Object} props
 * @param {DeviceDetailsProxy} props.device
 * @param {React.RefObject} [props.customRef]
 */

function DeviceListItem(props) {
	const boxRef = useRef(null);
	const groupingId = useContext(GroupingIdContext);
	const reduxKey = useContext(ReduxKeyContext);
	const state = useSelector(state => state.pages[reduxKey]);
	const dispatch = useDispatch();
	const { device, categoryId, pinnedDevices } = props;
	const lock = useRef(true); // to avoid focusing when changes caused internally

	const lastUri = state && state.lastSelected && state.lastSelected.uri;

	const selected = state && state.selectionMap[props.device.uri];

	const focus = () => {
		selected && scrollIntoView(boxRef.current);
	}

	useImperativeHandle(props.customRef, () => ({ focus }));

	// -------------- pin --------------------------

	const pinId = categoryId || groupingId;
	const pinned = pinnedDevices && pinnedDevices[pinId] && pinnedDevices[pinId][device.uri];

	const togglePin = (event) => {
		const pinned = {...pinnedDevices};
		if (pinned[pinId] && pinned[pinId][device.uri]) {
			delete pinned[pinId][device.uri];
			if (Object.keys(pinned[pinId]).length == 0) delete pinned[pinId];
		} else {
			if (!pinned[pinId]) pinned[pinId] = {};
			pinned[pinId][device.uri] = true;
		}
		dispatch(appValues.actions.add.request({ name: appValues.valueTypes.devices.pin, value: pinned }));
		event && event.stopPropagation();
	}

	const handleEdit = () => {
		props.history.push('/device/' + props.device.uri + '/card');
	}

	const handleDrop = (item) => {
		if (item.type == DragItemType.ACTION_PIN) {
			togglePin();
		} else if (item.type == DragItemType.ACTION_EDIT) {
			handleEdit();
		}
	}

	// --------------- drag & drop ----------------

	const [{ isDragging }, dragDevice, dragPreview] = useDrag({
		item: {
			type: DragItemType.DEVICE, device, categoryId, pinned,
			width: boxRef.current && boxRef.current.offsetWidth,
			reduxKey
		},
		collect: monitor => ({
			isDragging: monitor.isDragging()
		})
	})

	const [stageDropActions, dropAction] = useDrop({
		accept: [DragItemType.ACTION_PIN, DragItemType.ACTION_EDIT],
		drop: item => handleDrop(item),
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		}),
	})

	const isActiveAction = stageDropActions.isOver && stageDropActions.canDrop;

	useEffect(() => {
		dragPreview(getEmptyImage());
	}, []);

	// ------------------------------------------

	function handleClick() {
		if (lastUri == props.device.uri) {
			if (!(state.multiple && selected)) {
				const map = getOwMap(reduxKey);
				if (map) map.focusMarkers(props.device.uri);
			}
		}
		lock.current = true;
		dispatch(appActions.action(
			AppActionType.DEVICE_SELECTION_CHANGED, {
				uri: props.device.uri
			}
		));
	}

	const handleRef = (element) => {
		boxRef.current = element;
		dragDevice(element);
		dropAction(element);
	}

	useEffect(() => {
		if (lock.current) {
			lock.current = false;
		} else if (lastUri == props.device.uri) {
			focus();
		}
	}, [state ? state.lastSelected : null]);

	return (
		<div ref={handleRef} className='container-device-list-item'>
			<List.Item
				className={className(
					'device',
					{
						selected,
						'draggable': isDragging,
						'droppable': isActiveAction
					}
				)}
				onClick={handleClick}
			>
				<DeviceListItemContent
					device={props.device}
					pinned={pinned}
					selected={selected}
					togglePin={togglePin}
					reduxKey={reduxKey}
				/>
			</List.Item>
		</div>
	);
}

export default withRouter(DeviceListItem);
