import React from 'react';
import { connect } from 'react-redux';
import DeviceListItem from './DeviceListItem';
import List from '../../../general/list/List';

/**
 * @param {Object} props
 * @param {number} [props.categoryId] - renders devices which belongs to the category
 * @param {Array.<cx.ods.devices.DeviceDetails>} [props.devices] - renders only specified devices
 */

function DeviceList(props) {

	const sort = (devices) => {
		devices.sort((left, right) => left.compare(right));
		return devices;
	}

	const getDevices = () => {
		return props.devices != null
			? props.devices
			: props.categoryId != null
				? props.devices.list.filter(
					device => device.categoryIds && device.categoryIds.indexOf(props.categoryId) >= 0
				)
				: props.storeDevices.list
		;
	}

	let content = null;
	if (props.storeDevices.list != null || props.devices != null) {
		const items = sort(getDevices()).map(device => {
			return (<DeviceListItem
				key={device.uri}
				device={device}
				categoryId={props.categoryId}
			>
			</DeviceListItem>);
		});
		content = (<List bordered={false} className="device-list">{items}</List>);
	}

	return (content);
}

export default connect(state => {
	return {
		storeDevices: state.devices,
		application: state.application.map
	};
})(DeviceList);