import React from 'react';
import { connect } from 'react-redux';
import Loader from '../../general/Loader';
import { useI18n } from '../../../../i18n';
import InputPicker from '../../general/form/InputPicker';
import { fromProps, className } from '../../../lib/className';

/**
 * @callback MessageFieldPickerFilter
 * @param {MessageFilter} field
 * @param {RegisteredValue} type corresponding field type
 */

/**
 * @param {Object} props
 * @param {MessageFieldPickerFilter} [props.filter]
 * @param {string} [props.placeholder]
 * @param {boolean} [props.disabled]
 * @param {string | number | null} [props.value]
 * @param {Function} [props.onChange]
 */

function MessageFieldPicker(props) {
	const { fc } = useI18n();

	const getMessageFields = () => {
		let messageFields = Object.values(props.messageFields.typeMap);
		if (props.filter != null) messageFields = messageFields.filter(field =>
			props.filter(field, props.attributeTypes.codeMap[field.fieldType])
		);
		return messageFields;
	}

	const messageFields = props.messageFields.typeMap
		&& props.attributeTypes.codeMap
		&& getMessageFields()
			.map(messageField => ({ value: messageField.fieldId, label: fc({ prefix: 'message-field' , id: messageField.description }) }))
	;

	const compareSort = (a, b) => {
		if (a.label > b.label) return 1;
		if (a.label < b.label) return -1;
		return 0;
	}
	messageFields && messageFields.sort(compareSort);

	const content = messageFields
		? (
			<InputPicker
				block
				data={messageFields}
				placeholder={props.placeholder}
				disabled={props.disabled}
				cleanable={false}
				value={props.value}
				onChange={props.onChange}
			/>
		)
		: <Loader size={Loader.Size.MD} />
	;

	return (
		<div className={className('message-field-picker', fromProps(props))}>
			{props.label && <label>{props.label}</label>}
			{content}
		</div>
	)
}

export default connect(state => {
	return {
		messageFields: state.registry.messageFields,
		attributeTypes: state.registry.attributeTypes
	}
})(MessageFieldPicker);
