import React from 'react';
import { className, fromProps } from '../../../lib/className';
import DatePicker from './DatePicker';
import './xPicker.scss';

/**
 * For other parameters - see rsuite DatePicker component
 * @param {Object} props
 * @param {string} [props.label]
 */

function DateTimePicker(props) {
	const { label, ..._props } = props;
	return (
		<div className={className('x-picker', 'datetime-picker', fromProps(props))}>
			{label && <label>{label}</label>}
			<DatePicker
				className={className('datetime-picker', fromProps(props))}
				format="YYYY-MM-DD HH:mm:ss"
				{..._props}
			/>
		</div>
	)
}

export default DateTimePicker;