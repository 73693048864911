import React, { useState, useRef, useEffect } from 'react';
import { cx } from '../../../api'
import { isEscapeButton } from '../../../misc/misc';
import ActionClose from '../../share/actionbar/ActionClose';
import './propertyPopup.scss';

/**
 * @param {Object} props
 * @param {Object} props.offset - { left: number, top: number }
 * @param {number} props.width - px
 * @param {number} props.height - px
 * @param {string} props.title
 * @param {function} props.onClose - callback
 */

function PropertyPopup(props) {
	const [position, setPosition] = useState(null);
	const box = useRef(null);

	useEffect(() => {
		box.current.focus();
	});

	useEffect(() => {
		const windowOffset = cx.dom.at.offset(box.current.offsetParent);
		let correctionLeft = 0;
		if (props.offset.left + box.current.offsetWidth > document.body.offsetWidth) {
			correctionLeft = document.body.offsetWidth - (props.offset.left + box.current.offsetWidth);
		}
		let correctionTop = 0;
		if (props.offset.top + box.current.offsetHeight > document.body.offsetHeight) {
			correctionTop = document.body.offsetHeight - (props.offset.top + box.current.offsetHeight);
		}
		setPosition({
			top: props.offset.top > windowOffset.top ? (props.offset.top - windowOffset.top + correctionTop) : 0,
			left: props.offset.left > windowOffset.left ? (props.offset.left - windowOffset.left + correctionLeft) : 0
		});
		const handleEscape = (event) => {
			if (isEscapeButton(event)) {
				props.onClose();
			}
		}
		window.addEventListener('keydown', handleEscape);
		return () => window.removeEventListener('keydown', handleEscape);
	}, [props]);

	const onClose = () => {
		props.onClose();
	}

	const style = {
		width: props.width,
		minHeight: props.height,
		visibility: position ? 'visible' : 'hidden' // do not display popup until positioned properly to avoid flickering
	}

	return (
		<div
			ref={box}
			className="property-popup"
			style={{...position, ...style}}
			tabIndex="0" // HACK to close popup on outside click, consider another way
			onBlur={() => props.onClose()}
		>
			<div className="header">
				<div className="title">{props.title}</div>
				<div className="actions">
					<ActionClose onClick={onClose} />
				</div>
			</div>
			<div>
				{props.children}
			</div>
		</div>
	)
}

export default PropertyPopup;