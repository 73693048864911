import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  ProcessorInfoHeaderStyled,
  ProcessorInfoStyled,
  ProcessorInfoListStyledStyled,
} from 'ui/pages/downsampled-message-history/processor-info.styled';
import { FieldErrorStyled } from 'ui/pages/attribute-range-detectors/detector-form.styled';
import { useI18n } from 'i18n';
import { IProcessorForm } from 'hooks/downsampled-message-history/useProcessorForm';
import Checkbox from 'core/react/general/form/Checkbox';
import InputNumber from 'core/react/general/form/InputNumber';

interface IDetailsFieldsProps {
  isDisabled: boolean;
}

export const DetailsFields: FC<IDetailsFieldsProps> = ({ isDisabled }) => {
  const { fc } = useI18n();

  const { control, formState } = useFormContext<IProcessorForm>();
  const { errors } = formState;

  const validatePrecision = (value: string) =>
    value && Number(value) <= 0 ? fc('precision value must be positive') : true;

  return (
    <ProcessorInfoStyled>
      <ProcessorInfoHeaderStyled>
        <label>{fc('details')}</label>
      </ProcessorInfoHeaderStyled>
      <ProcessorInfoListStyledStyled>
        <Controller
          control={control}
          name="ignitionChange"
          render={({ value, onChange }) => (
            <Checkbox label={fc('ignition change')} disabled={isDisabled} value={value} onChange={onChange} />
          )}
        />
        <Controller
          control={control}
          name="odometerPrecision"
          rules={{ required: fc('please enter a value'), validate: validatePrecision }}
          render={({ value, onChange }) => (
            <div>
              <InputNumber label={fc('odometer precision')} disabled={isDisabled} value={value} onChange={onChange} />
              {/*TODO: error messages should be moved to corresponding form components when they are being refactored*/}
              {!!errors.odometerPrecision?.message && (
                <FieldErrorStyled>{errors.odometerPrecision.message}</FieldErrorStyled>
              )}
            </div>
          )}
        />
        <Controller
          control={control}
          name="speedPrecision"
          rules={{ required: fc('please enter a value'), validate: validatePrecision }}
          render={({ value, onChange }) => (
            <div>
              <InputNumber label={fc('speed precision')} disabled={isDisabled} value={value} onChange={onChange} />
              {/*TODO: error messages should be moved to corresponding form components when they are being refactored*/}
              {!!errors.speedPrecision?.message && <FieldErrorStyled>{errors.speedPrecision.message}</FieldErrorStyled>}
            </div>
          )}
        />
      </ProcessorInfoListStyledStyled>
    </ProcessorInfoStyled>
  );
};
