import { generateId } from "../../../misc/misc"

const WidgetType = {
	Commuters: 'commuters',
	DeviceInfo: 'deviceInfo',
	DevicePresenceDetector: 'devicePresenceDetector',
	AttributeRangeDetector: 'attributeRangeDetector',
	Trips: 'trips',
	Events: 'events',
	DeviceTrackingWidget: 'deviceTrackingWidget',
	DeviceEventsWidget: 'deviceEventsWidget',
	// singleton widgets
	TimeMachineSettingsWidget: 'TimeMachineSettingsWidget',
	TimeMachinePlayerWidget: 'TimeMachinePlayerWidget'
};

const defaultState = {
	x: 0, y: 0,
	w: 6 , h: 16,
	minW: 2, minH: 16,
	isResizable: false
};

const getLayoutByType = (type) => {
	switch (type) {
		case WidgetType.Commuters:
		case WidgetType.DeviceInfo:
		case WidgetType.DevicePresenceDetector:
		case WidgetType.DeviceTrackingWidget:
		case WidgetType.AttributeRangeDetector:
		case WidgetType.DeviceEventsWidget:
			return {
				...defaultState,
				minW: 5,
				isResizable: true,
				i: generateId()
			};
		case WidgetType.TimeMachineSettingsWidget:
			return {
				...defaultState,
				w: 7,
				h: 16,
				i: WidgetType.TimeMachineSettingsWidget
			};
		case WidgetType.TimeMachinePlayerWidget:
			return {
				...defaultState,
				h: 11,
				minH: 10,
				maxH: 11,
				i: WidgetType.TimeMachinePlayerWidget
			};
		case WidgetType.Trips:
		case WidgetType.Events:
			return {
				...defaultState,
				isResizable: true,
				i: generateId(),
				w: 3
			};
		default:
			return {
				...defaultState,
				i: generateId()
			};
	}
}

const validateLayout = (old, valid) => {
	if (old.h < valid.minH) old.h = valid.minH;
	else if (valid.maxH < old.h) old.h = valid.maxH;
	old.minH = valid.minH;
	old.maxH = valid.maxH;
	if (old.w < valid.minW) old.w = valid.minW;
	else if (valid.maxW < old.w) old.w = valid.maxW;
	old.minW = valid.minW;
	old.maxW = valid.maxW;
}

export { WidgetType, getLayoutByType, validateLayout };
