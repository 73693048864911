import React from 'react';
import { TagPicker as RsTagPicker } from 'rsuite';
import { useI18n } from '../../../../i18n';
import { capitalize } from '../../../misc/misc';
import { className, fromProps } from '../../../lib/className';
import './xPicker.scss';

/**
 * For other parameters - see rsuite TagPicker
 * @param {Object} props
 * @param {string} [props.label]
 * @param {boolean} [props.integer]
 */

function NumberPicker(props) {
	const { f } = useI18n();
	const { label, onChange, placeholder, ..._props } = props;
	const _placeholder = placeholder || f('select');
	return (
		<div className={className('x-picker', 'number-picker', fromProps(props))}>
			{props.label && <label>{props.label}</label>}
			<RsTagPicker
				block
				virtualized={false}
				placeholder={capitalize(_placeholder)}
				{..._props}
			/>
		</div>
	);
}

// For internal use in FormControl component
NumberPicker.process = (values) => {
	return values == null ? values : values.map(value => +value.replace(/,/g, ''));
}

export default NumberPicker;