import React, { useState } from 'react';
import { Modal as RsModal } from 'rsuite';
import { className, fromProps } from '../../lib/className';
import './modal.scss';

/**
 * @param {Object} props
 * @param {ReactElement} props.children
 * @param {string} [props.className]
 * @param {string} [props.title]
 * @param {boolean|function} [props.show=true]
 * @param {boolean} [props.fullHeight=false]
 * @param {boolean} [props.fullWidth=false]
 * @param {function} props.onClose
 */

function Modal(props) {
	const [_className, setClassName] = useState('');
	const [title, setTitle] = useState(props.title || null);
	const [header, setHeader] = useState(props.header || null);
	const [footer, setFooter] = useState(props.footer || null);

	const children = React.Children
		.toArray(props.children)
		.filter(child => typeof child == 'object')
		.map(child =>
			React.cloneElement(child, {
				setModalClassName: setClassName,
				setModalTitle: setTitle,
				setModalHeader: setHeader,
				setModalFooter: setFooter
			})
		);

	const show = props.show === undefined ? true : props.show;
	const fullHeight = props.fullHeight === undefined ? false : props.fullHeight;
	const fullWidth = props.fullWidth === undefined ? false : props.fullWidth;

	return (
		<RsModal
			className={className('modal', _className, fromProps(props), { 'full-height' : fullHeight, 'full-width': fullWidth })}
			show={typeof show == 'function' ? show() : show}
			onHide={props.onClose}
			overflow
			size={props.size}
		>
			<RsModal.Header closeButton={false}>
				{title && <RsModal.Title>{title}</RsModal.Title>}
				{header}
			</RsModal.Header>
			<RsModal.Body>
				{children}
			</RsModal.Body>
			{footer && <RsModal.Footer>{footer}</RsModal.Footer>}
		</RsModal>
	);
}

export default Modal;
