import React, { useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { useI18n } from '../../../../../i18n';
import { fromProps, className } from '../../../../lib/className';
import { DragItemType } from '../../dnd/DragItemType';
import Modal from '../../../general/Modal';
import Form from '../../../general/form/Form';
import PropertyElement from './PropertyElement';
import DevicePropertySelector from '../../../custom/deviceProperties/meta/DevicePropertySelector';
import DevicePropertyEditor from '../../../custom/deviceProperties/meta/DevicePropertyEditor';
import DefaultActionBar from '../../actionbar/DefaultActionBar';
import './vectorElementControl.scss';


/**
 * @param {Object} props
 * @param {function} props.onEdit
 * @param {function} props.onClick
 * @param {cx.ods.meta.ComposableProperty} [props.property]
 */

function VectorPropertyElement(props) {

	const [dropState, dropRef] = useDrop({
		accept: DragItemType.ACTION_EDIT,
		drop: () => props.onEdit(),
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		}),
	})

	return (<>
		{props.property != null &&
			<PropertyElement
				customRef={dropRef}
				className={className({ 'droppable': dropState.isOver && dropState.canDrop })}
				onClick={props.onClick}
				element={props.property}
			/>
		}
		{props.property == null &&
			<div className="property-element clickable" onClick={props.onClick}>...</div>
		}
	</>);
}

/**
 * @param {Object} props
 * @param {cx.ods.meta.PropertyVector} props.vector
 * @param {cx.ods.meta.ComposableProperty} props.value
 * @param {function} props.onChange
 */

function VectorElement(props) {
	const { f } = useI18n();
	const [replacing, setReplacing] = useState(false);
	const [editing, setEditing] = useState(false);

	const onReplace = () => {
		setReplacing(true);
	}

	const onReplaceCancel = () => {
		setReplacing(false);
	}

	const onReplaceApply = (properties) => {
		setReplacing(false);
		props.onChange(properties[0] || null);
	}

	const onEdit = () => {
		setEditing(true);
	}

	const onEditCancel = () => {
		setEditing(false);
	}

	const onEditReady = () => {
		setEditing(false);
	}

	const excludeIds = [];
	if (props.vector) {
		excludeIds.push(props.vector.propertyId);
		const element = props.vector.element;
		if (element && props.value && element.propertyId == props.value.propertyId) {
			excludeIds.push(element.propertyId);
		}
	}

	return (
		<div className={className('vector-element', fromProps(props))}>
			<div className="header">
				<div className="label">{f('item property')}</div>
				<div className="actions">
					<DefaultActionBar
						hideEdit={props.value == null}
						hideRemove
					/>
				</div>
			</div>
			<VectorPropertyElement
				property={props.value}
				onClick={onReplace}
				onEdit={onEdit}
			/>
			{replacing && <Modal show onClose={onReplaceCancel}>
				<DevicePropertySelector
					onCancel={onReplaceCancel}
					onApply={onReplaceApply}
					extraProperties={props.vector && props.vector.element && [props.vector.element]}
					excludeIds={excludeIds}
					singleSelection
				/>
			</Modal>}
			{editing && <Modal show onClose={onEditCancel}>
				<DevicePropertyEditor
					onCancel={onEditCancel}
					onReady={onEditReady}
					property={props.value}
				/>
			</Modal>}
		</div>
	);
}

/**
 * See VectorElement parameters.
 * @param {Object} props
 */

function VectorElementControl(props) {
	const [element, setElement] = useState(null);

	useEffect(() => {
		props.vector.element && setElement(props.vector.element);
	}, [props.vector]);

	return (
		<Form.Control
			controlType={VectorElement}
			controlName="element"
			value={element}
			onChange={setElement}
			{...props}
		/>
	);
}

export default VectorElementControl;