import React, { useState, useEffect, useRef, useContext } from 'react';
import { useDrop } from 'react-dnd';
import { className, fromProps } from '../../../../lib/className';
import { useI18n } from '../../../../../i18n';
import { DragItemType } from '../../../share/dnd/DragItemType';
import ListItem from '../../../general/list/ListItem';
import List from '../../../general/list/List';
import { SelectedFeatureContext } from './ZoneEditor';
import Zone from '../model/Zone'; // eslint-disable-line no-unused-vars
import ZoneArea from '../model/ZoneArea'; // eslint-disable-line no-unused-vars
import { cx } from '../../../../api';
import NoDataView from '../../../general/view/NoDataView';

/**
 * @param {Object} props
 * @param {ZoneArea} props.area
 * @param {function} props.onClick
 * @param {function} props.onIntent
 */

function ZfListItem(props) {

	const onDrop = (item) => {
		cx.run.later(() => { // can't unmount during drop - it causes exception, so make it in next call stack
			props.onIntent(props.area.id(), (item.type == DragItemType.ACTION_EDIT ? 'edit' : 'remove'));
		});
	}

	const onClick = () => {
		props.onClick(props.area.id());
	}

	const [dropState, dropRef] = useDrop({
		accept: [DragItemType.ACTION_EDIT, DragItemType.ACTION_REMOVE],
		drop: onDrop,
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		}),
	});

	return (
		<ListItem
			className={className(fromProps(props), { 'droppable': dropState.isOver && dropState.canDrop })}
			onClick={onClick}
			customRef={dropRef}
		>
			{props.area.name()}
		</ListItem>
	);
}

/**
 * @param {Object} props
 * @param {Zone} props.zone
 */

function ZoneFeatureList(props) {
	const { f } = useI18n();
	const [dirty, setDirty] = useState(0);
	const dirtyRef = useRef(null);
	dirtyRef.current = dirty;
	const selectedFeatureContext = useContext(SelectedFeatureContext);
	const selectedId = selectedFeatureContext.value;

	const handleClick = (id) => {
		if (selectedFeatureContext.value == id) {
			selectedFeatureContext.clear();
		} else {
			selectedFeatureContext.set(id);
		}
	}

	const handleIntent = (id, intent) => {
		if (intent == 'remove') {
			props.zone.removeArea(id);
		} else if (intent == 'edit') {
			selectedFeatureContext.set(id, true);
		}
	}

	const items = props.zone.areas().map(area =>
		<ZfListItem
			className={className({ 'selected': selectedId && selectedId == area.id() })}
			key={area.id()}
			area={area}
			onClick={handleClick}
			onIntent={handleIntent}
		/>
	);

	useEffect(() => {
		const handleChanged = () => {
			setDirty(dirtyRef.current+1);
		}
		props.zone.addObserver(props.zone.events.changed, handleChanged);
		return () => { // CHECKME
			props.zone.removeObserver(props.zone.events.changed, handleChanged);
		}
	}, []);

	let content = null;
	if (items && items.length > 0) {
		content = <List>{items}</List>;
	} else {
		content = <NoDataView message={props.emptyTitle || f('no areas yet')} />;
	}

	return (
		<div className="zone-feature-list">
			{content}
		</div>
	);
}

export default ZoneFeatureList;