// localhost	20b5c1857832f5765d67154a83ae0cb23b7412c985c5eda7c89b75d9173bb729566bb236dd0c16fe
// ow.dev.overdrive.asia	d33f04ce4d4a308a9d9008163d3fe4659fab17e399f1ab2284e610fdbd5e8c3f49b442f019557a77
// wheels.overdriveiot.com	660f79050e22d96f9d9008163d3fe465529783db068621d56fd5ba10062b1f79284471a9e0f9947d
// wheels.overdrive.asia	a1c5f3cf4a46f4229d9008163d3fe465658277d1a3984a585446805ff409e14af4db4314902f5b24
// https://mosaic.covexis.com/api?key=20b5c1857832f5765d67154a83ae0cb23b7412c985c5eda7c89b75d9173bb729566bb236dd0c16fe
// https://mosaic.covexis.com/resolve?key=20b5c1857832f5765d67154a83ae0cb23b7412c985c5eda7c89b75d9173bb729566bb236dd0c16fe&POINT=103.761735,1.32154&POINT=103.759998,1.314657&POINT=103.751751,1.327566&POINT=103.747333,1.331362&POINT=103.829833,1.256852&jsonp=1

import { Address, ComponentType } from "./address";
import { BatchResolver, ResolverFialedError } from "./resolver";


const mosaicKeys = {
	'localhost': '20b5c1857832f5765d67154a83ae0cb23b7412c985c5eda7c89b75d9173bb729566bb236dd0c16fe'
	, 'ow.dev.overdrive.asia': 'd33f04ce4d4a308a9d9008163d3fe4659fab17e399f1ab2284e610fdbd5e8c3f49b442f019557a77'
	, 'wheels.overdrive.asia': 'a1c5f3cf4a46f4229d9008163d3fe465658277d1a3984a585446805ff409e14af4db4314902f5b24'
	, 'wheels.overdriveiot.com': '660f79050e22d96f9d9008163d3fe465529783db068621d56fd5ba10062b1f79284471a9e0f9947d'
};


const parseResponseText = text => {
	const json = /^cx\.jsr\("x",(?<json>.*)\)$/.exec(text)?.groups['json'];
	if (!json) throw new Error(`Can't extract json from ${text}`);
	return JSON.parse(json);
};

export class MosaicResolver extends BatchResolver {

	constructor(batchSize = 50) {
		super(batchSize);
		const hostname = new URL(window.location.href).hostname;
		this.key = mosaicKeys[hostname];
		if (this.key) this.serviceURL = new URL('https://mosaic.covexis.com/resolve');
		else this.submitBatch = batch => batch.forEach(job => job.reject(`domain ${hostname} is not supported`));
	}

	name() {
		return 'mosaic';
	}

	submitBatch(batch) {
		const pointParams = batch.map(({ entry: { longitude, latitude } }) => `POINT=${longitude},${latitude}`).join('&');
		fetch(new URL('?key=' + this.key + '&' + pointParams + '&jsonp=x', this.serviceURL))
			.then(response => response.text())
			.then(text => parseResponseText(text))
			.then(reply => {
				const locatedAddresses = batch.length <= 1 
					? [reply.addressResolve.addressLocation] 
					: reply.poolAddressResolve?.addressList
				;
				if (locatedAddresses?.length != batch.length) throw new Error(`Resolver reply is invalid ${JSON.stringify(reply)}`);
				batch.forEach(({resolve}, at) => {
					resolve(this.constructAddress(locatedAddresses[at]?.address));
				});
			})
			.catch(error => batch.forEach(({reject}) => reject(new ResolverFialedError(error.message))))
		;
	}

	static componentTypeMap = {
		'country': ComponentType.country
		, 'locality': ComponentType.locality
		, 'postalCode': ComponentType.postalCode
		, 'streetName': ComponentType.street, 'houseNumber': ComponentType.streetNumber
	};

	constructAddress(mosaicAddress) {
		if (!mosaicAddress) return null;
		const entries = Object.entries(mosaicAddress)
			.map(([type, value]) => {
				const componentType = MosaicResolver.componentTypeMap[type];
				return componentType ? [componentType, value] : null;
			})
			.filter(Boolean)
		;
		return 0 < entries.length ? new Address(null, Object.fromEntries(entries)) : null;
	}
};
