import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { className } from '../../../lib/className';
import { FiPlus, FiMinus } from 'react-icons/fi';
import { useI18n } from '../../../../i18n';
import { actions as zonesActions } from '../../../redux/api/zones';
import { sortByName } from '../../../lib/sorting';
import Loader from '../../general/Loader';
import ZoneListItem from './ZoneListItem';
import List from '../../general/list/List';
import { scrollIntoView } from '../../../misc/misc';
import './zoneList.scss';

/**
 * @param {Object} props
 * @param {Array.<number>} props.selectedIds
 * @param {function} props.onChange
 * @param {function} props.onIntent
 * @param {boolean} [props.singleSelection]
 * @param {React.Component} [props.itemType]
 * @param {function} [props.filter] custom filter
 * @param {string||number} [props.lastSelectedId]
 */

function ZoneList(props) {
	const { f, fc } = useI18n();
	const focusZoneRef = useRef();
	const [collapsed, setCollapsed] = useState(false);
	const ItemType = props.itemType ? props.itemType : ZoneListItem;

	let zones = props.zones.filteredList ? props.zones.filteredList : props.zones.list;
	if (zones) {
		if (props.filter) zones = props.filter(zones);
		zones = sortByName(zones);
	}

	useEffect(() => {
		if (focusZoneRef.current) scrollIntoView(focusZoneRef.current);
	}, [props.lastSelectedId]);

	const handleClick = (zoneId) => {
		if (props.singleSelection) {
			props.onChange([zoneId]);
		} else {
			const copySelectedIds = [...props.selectedIds];
			copySelectedIds.includes(zoneId)
				? copySelectedIds.splice(props.selectedIds.indexOf(zoneId), 1)
				: copySelectedIds.push(zoneId)
			;
			props.onChange(copySelectedIds, zoneId);
		}
	}

	const createItem = (zone) => {
		return <ItemType
			className={className({ 'selected': props.selectedIds.includes(zone.zoneId) })}
			key={zone.zoneId}
			zone={zone}
			customRef={props.lastSelectedId == zone.zoneId ? focusZoneRef : null}
			onClick={handleClick}
			onIntent={props.onIntent}
		/>
	}

	const trigger = () => {
		setCollapsed(!collapsed);
	}

	let content = null;
	if (!zones && !props.zones.pending) {
		props.dispatch(zonesActions.load.request());
		content = <div className="zone-list vh-center"><Loader /></div>;
	} else if (zones) {

		const selectAll = () => {
			if (props.singleSelection || collapsed) return;
			if (zones.length > props.selectedIds.length) {
				const zoneIds = zones.map(zone => zone.zoneId);
				props.onChange(zoneIds);
			} else {
				props.onChange([]);
			}
		}

		const items = zones.map(zone => createItem(zone));
		const toggleIcon = collapsed ? (<FiPlus />) : (<FiMinus />);
		content = (
			<div className="zone-list">
				<div className="header" >
					<span
						className={className({ 'clickable': !props.singleSelection && !collapsed })}
						onClick={selectAll}
						title={(!props.singleSelection && !collapsed) ? fc('select/deselect all') : null}
					>
						{f('zones')}
					</span>
					<span
						className="clickable"
						onClick={trigger}
						title={collapsed ? fc('expand') : fc('collapse')}
					>
						{toggleIcon}
					</span>
				</div>
				{!collapsed && (
					<List className="zone-list">{items}</List>
				)}
			</div>
		);
	}

	return (content);
}

export default connect(
	state => ({
		zones: state.zones
	})
)(ZoneList);