import React, { useEffect } from 'react';
import { useI18n } from '../../../i18n';
import { isEnterButton } from '../../misc/misc';
import Button from './form/Button';
import ButtonGroup from './form/ButtonGroup';
import './removeDialog.scss';

/**
 * @param {Object} props
 * @param {Object} props.object
 * @param {string} props.text
 * @param {string} [props.name]
 * @param {function} props.onCancel
 * @param {function} props.onSubmit
 * @param {function} [props.onEnterKeydownHandler]
 * @param {boolean} [props.disabled]
 * @param {string} [props.error]
 * @param {string} [props.title]
 */

function RemoveDialog(props) {
	const { f } = useI18n();

	useEffect(() => {
		const onKeydown = (event) => {
			if (!props.disabled && isEnterButton(event)) {
				if (props.onEnterKeydownHandler) props.onEnterKeydownHandler()
				else props.onSubmit(props.object);
			}
		}
		window.addEventListener('keydown', onKeydown);
		return () => window.removeEventListener('keydown', onKeydown);
	}, [props.object, props.disabled]);

	return (
		<div className="remove-dialog">
			{props.text != null && <div className="message">
				{props.text}
			</div>}
			{props.children}
			{props.error != null &&
				<div className="error">
					{props.error}
				</div>
			}
			<ButtonGroup>
				<Button disabled={props.disabled} onClick={() => props.onSubmit(props.object)}>
					{f('yes')}
				</Button>
				<Button disabled={props.disabled} onClick={props.onCancel}>
					{f('no')}
				</Button>
			</ButtonGroup>
		</div>
	);
}

export default RemoveDialog;
