import { from } from "rxjs";
import { catchError } from "rxjs/operators";

const toSnakeCase = name => {
	return name.replace(
		/([^A-Z_])([A-Z])/g
		, (match, preceding, symbol) => preceding + '_' + symbol
	).replace(/\./g, '_').toUpperCase();
}

export class ActionGeneratorBuilder {

	constructor(domain, preload = null) {
		this.domain = domain;
		this.actions = preload || {};
	}

	type(name, specs = null) {
		const type = toSnakeCase(this.pathname(name));
		const generator = (parameters = null) => {
			const action = { type: type };
			if (specs) {
				if (parameters != null && typeof parameters !== 'object') throw new Error(`Action ${type} expects it's parameters as an object`);
				Object.entries(this.normalizeSpecs(specs)).forEach(([parameterName, required]) => {
					if (required && (!parameters || !parameters.hasOwnProperty(parameterName))) throw new Error(`Action ${type} required parameter ${parameterName} is missing`);
					if (parameters?.hasOwnProperty(parameterName)) action[parameterName] = parameters[parameterName];
				});
			}
			return action;
		};
		Object.defineProperty(generator, "type", { get: () => type });
		this.actions[name] = generator;
		return this;
	}

	normalizeSpecs(specs) {
		if (typeof specs !== "object") return { [specs]: true };
		if (Array.isArray(specs)) return specs.reduce((accumulator, parameterName) => {
			accumulator[parameterName] = true;
			return accumulator;
		}, {});
		return specs;
	}

	request(specs = null) {
		return this.type('request', specs);
	}

	cancel(specs = null) {
		return this.type('cancel', specs);
	}

	progress(specs = {progress: true, count: false, total: false}) {
		return this.type('progress', specs);
	}

	success(specs = null) {
		return this.type('success', specs);
	}

	fail(specs = { errorMessage: true }) {
		return this.type('fail', specs);
	}

	clear(specs = null) {
		return this.type('clear', specs);
	}

	subtype(name, populate) {
		const builder = new ActionGeneratorBuilder(this.pathname(name));
		populate(builder);
		this.actions[name] = builder.build();
		return this;
	}

	pathname(name) {
		return this.domain ? this.domain + '.' + name : name;
	}

	build() {
		return this.actions;
	}
}

export const errorMap = (...generators) => {
	return catchError(error => {
		const param = { errorMessage: error.userMessage || error.message };
		return from(generators.map(generator => generator(param)));
	});
}

export const deltaReducer = (evaluateDelta, defaultState = null) => (state, action) => {
	if (state == null) state = defaultState;
	const delta = evaluateDelta(state, action);
	if (delta == null) return state;
	const newState = { ...state };
	Object.entries(delta).forEach(([name, value]) => {
		if (value === undefined) delete newState[name];
		else newState[name] = value;
	});
	return newState;
};
