import { cx } from '../../../../api';
import { deltaReducer } from '../../../actions';
import { actions } from '../actions';

const defaultState = {
	list: null,
	map: null,
	pending: false,
	error: null
};

export const designateEmployee = (employee) => {
	return employee.designation || employee.externalId || employee.rfid || '';
}

export const isValidEmployee = (employee) => {
	return Boolean(employee.externalId || employee.rfid || employee.designation);
}

export const reducer = deltaReducer((state, action) => {
	switch (action.type) {
		case actions.employees.add.request.type:
		case actions.employees.load.request.type:
		case actions.employees.update.request.type:
		case actions.employees.remove.request.type:
		case actions.employees.upload.request.type:
		case actions.employees.inactivateAll.request.type:
		case actions.employees.removeAll.request.type:
		case actions.employees.inactivate.request.type: return {
			pending: true,
			error: null
		};
		case actions.employees.load.success.type: return {
			pending: false,
			list: action.employees,
			map: cx.i.hash(action.employees, (employee) => employee.assetId)
		};
		case actions.employees.add.fail.type:
		case actions.employees.load.fail.type:
		case actions.employees.update.fail.type:
		case actions.employees.remove.fail.type:
		case actions.employees.upload.fail.type:
		case actions.employees.inactivateAll.fail.type:
		case actions.employees.removeAll.fail.type:
		case actions.employees.inactivate.fail.type: return {
			pending: false,
			error: action.errorMessage
		};
		case actions.employees.clear.type: return {
			error: null
		}
	};
	return null;
}, defaultState);
