import React, { useEffect, useState } from 'react';

import {fc, useI18n} from 'i18n';
import { useEventTypesOptions } from 'hooks/useEventTypesOptions';
import Form from 'core/react/general/form/Form';

import './zoneGuardFields.scss';

/**
 * @param {Object} props
 * @param {cx.ods.processors.ZoneGuard} [zoneGuard]
 */

const ZoneGuardFields = ({ zoneGuard }) => {
	const { f } = useI18n();

	const eventTypesOptions = useEventTypesOptions();

	const [isOnEntry, setOnEntry] = useState(false);
	const [isOnExit, setOnExit] = useState(false);
	const [maxSpeed, setMaxSpeed] = useState(null);
	const [isOnStop, setOnStop] = useState(false);
	const [isOnStopSkipped, setOnStopSkipped] = useState(false);
	const [acceleration, setAcceleration] = useState(null);
	const [deceleration, setDeceleration] = useState(null);
	const [onEntryEventType, setOnEntryEventType] = useState(0);
	const [entryAction, setEntryAction] = useState(0);
	const [onExitEventType, setOnExitEventType] = useState(0);
	const [exitAction, setExitAction] = useState(0);
	const [speedingEventType, setSpeedingEventType] = useState(0);
	const [stoppedEventType, setStoppedEventType] = useState(0);
	const [stopSkippedEventType, setStopSkippedEventType] = useState(0);
	const [acceleratingEventType, setAcceleratingEventType] = useState(0);
	const [deceleratingEventType, setDeceleratingEventType] = useState(0);
	const [esg, setEsg] = useState(null);

	const processorActions = [
		{ label: fc('no action'), value: 0 },
		{ label: fc('set normal tracking mode'), value: 'NORMAL_TRACKING' },
		{ label: fc('set intense tracking mode'), value: 'INTENSE_TRACKING' },
		{ label: fc('set delayed tracking mode'), value: 'DELAYED_TRACKING' },
	];

	const handleOnEntryChange = (value) => {
		setOnEntry(value);
		setOnEntryEventType(0);
	};

	const handleOnExitChange = (value) => {
		setOnExit(value);
		setOnExitEventType(0);
	};

	const handleMaxSpeedChange = (value) => {
		setMaxSpeed(value);
		!value && setSpeedingEventType(0);
	};

	const handleOnStopChange = (value) => {
		setOnStop(value);
		setStoppedEventType(0);
	};

	const handleOnStopSkippedChange = (value) => {
		setOnStopSkipped(value);
		setStopSkippedEventType(0);
	};

	const handleAccelerationChange = (value) => {
		setAcceleration(value);
		!value && setAcceleratingEventType(0);
	};

	const handleDecelerationChange = (value) => {
		setDeceleration(value);
		!value && setDeceleratingEventType(0);
	};

	useEffect(() => {
		setOnEntry(!!zoneGuard?.onEntry);
		setOnExit(!!zoneGuard?.onExit);
		setMaxSpeed(zoneGuard?.maximumSpeed || null);
		setOnStop(!!zoneGuard?.onStop);
		setOnStopSkipped(!!zoneGuard?.onStopSkipped);
		setAcceleration(zoneGuard?.acceleration || null);
		setDeceleration(zoneGuard?.deceleration || null);
		setOnEntryEventType(zoneGuard?.onEntryEventType || 0);
		setEntryAction(zoneGuard?.entryAction || 0);
		setOnExitEventType(zoneGuard?.onExitEventType || 0);
		setExitAction(zoneGuard?.exitAction || 0);
		setSpeedingEventType(zoneGuard?.setSpeedingEventType || 0);
		setAcceleratingEventType(zoneGuard?.acceleratingEventType || 0);
		setDeceleratingEventType(zoneGuard?.deceleratingEventType || 0);
		setEsg(zoneGuard?.esg || null);
	}, [zoneGuard]);

	return (
		<div className="zone-guard-fields">
			<Form.ControlGroup>
				<Form.Control
					controlType={Form.Control.Type.Input}
					controlValidator={(value) => value == null && f('please enter a value')}
					autoFocus
					label={f('name')}
					controlName="name"
					initialValue={zoneGuard && zoneGuard.name}
				/>
				<Form.Control
					controlType={Form.Control.Type.Checkbox}
					controlName="onEntry"
					label={f('on entry')}
					value={isOnEntry}
					onChange={handleOnEntryChange}
				/>

				<Form.ControlGroup disabled={!isOnEntry} label>
					<Form.Control
						controlType={Form.Control.Type.InputPicker}
						controlName="onEntryEventType"
						label={fc('event type')}
						block
						cleanable={false}
						data={eventTypesOptions}
						value={onEntryEventType}
						onChange={setOnEntryEventType}
					/>
					<Form.Control
						controlType={Form.Control.Type.InputPicker}
						controlName="entryAction"
						label={fc('action')}
						block
						cleanable={false}
						data={processorActions}
						value={entryAction}
						onChange={setEntryAction}
					/>
				</Form.ControlGroup>
				<Form.Control
					controlType={Form.Control.Type.Checkbox}
					controlName="onExit"
					label={f('on leave')}
					value={isOnExit}
					onChange={handleOnExitChange}
				/>
				<Form.ControlGroup disabled={!isOnExit} label>
					<Form.Control
						controlType={Form.Control.Type.InputPicker}
						controlName="onExitEventType"
						label={fc('event type')}
						block
						cleanable={false}
						data={eventTypesOptions}
						value={onExitEventType}
						onChange={setOnExitEventType}
					/>
					<Form.Control
						controlType={Form.Control.Type.InputPicker}
						controlName="exitAction"
						label={fc('action')}
						block
						cleanable={false}
						data={processorActions}
						value={exitAction}
						onChange={setExitAction}
					/>
				</Form.ControlGroup>
				<Form.Control
					controlType={Form.Control.Type.Input}
					controlName="esg"
					controlValidator={(value) =>
						value != null && value.length > 16 && f('please enter a value not longer than X symbols', { value: 16 })
					}
					label="ESG"
					value={esg}
					onChange={setEsg}
				/>
			</Form.ControlGroup>
			<Form.ControlGroup label={f('speeding')}>
				<Form.Control
					controlType={Form.Control.Type.InputNumber}
					controlName="maximumSpeed"
					controlValidator={(value, values) => {
						if (values.speedingDuration != null && value == null) {
							return f('please enter a value');
						}
						if (value != null && !/^(\d+)$/.test(value)) {
							return f('please enter an integer value');
						}
					}}
					label={f('maximum speed') + ', ' + f({ prefix: 'units', id: 'km/h' })}
					min={1}
					value={maxSpeed}
					onChange={handleMaxSpeedChange}
				/>
				<Form.Control
					controlType={Form.Control.Type.InputNumber}
					controlName="speedingDuration"
					controlValidator={(value) => {
						if (value != null && !/^(\d+)$/.test(value)) {
							return f('please enter an integer value');
						}
					}}
					label={f('duration') + ', ' + f({ prefix: 'units', id: 's' })}
					min={1}
					initialValue={zoneGuard && zoneGuard.speedingDuration}
				/>
				<Form.ControlGroup disabled={!maxSpeed}>
					<Form.Control
						label={fc('event type')}
						controlType={Form.Control.Type.InputPicker}
						controlName="speedingEventType"
						block
						cleanable={false}
						data={eventTypesOptions}
						value={speedingEventType}
						onChange={setSpeedingEventType}
					/>
				</Form.ControlGroup>
			</Form.ControlGroup>
			<Form.ControlGroup label={f('stop detection')}>
				<Form.Control
					controlType={Form.Control.Type.InputNumber}
					controlName="minimumSpeed"
					controlValidator={(value, values) => {
						if ((values.stopDuration != null || values.onStop || values.onStopSkipped) && value == null) {
							return f('please enter a value');
						}
						if (value != null && !/^(\d+)$/.test(value)) {
							return f('please enter an integer value');
						}
					}}
					label={f('minimum speed') + ', ' + f({ prefix: 'units', id: 'km/h' })}
					min={0}
					initialValue={zoneGuard && zoneGuard.minimumSpeed}
				/>
				<Form.Control
					controlType={Form.Control.Type.InputNumber}
					controlName="stopDuration"
					controlValidator={(value) => {
						if (value != null && !/^(\d+)$/.test(value)) {
							return f('please enter an integer value');
						}
					}}
					label={f('duration') + ', ' + f({ prefix: 'units', id: 's' })}
					min={1}
					initialValue={zoneGuard && zoneGuard?.stopDuration}
				/>
				<Form.Control
					controlType={Form.Control.Type.Checkbox}
					controlName="onStop"
					label={f('on stop')}
					value={isOnStop}
					onChange={handleOnStopChange}
				/>
				<Form.ControlGroup disabled={!isOnStop}>
					<Form.Control
						controlType={Form.Control.Type.InputPicker}
						controlName="stoppedEventType"
						block
						cleanable={false}
						data={eventTypesOptions}
						value={stoppedEventType}
						onChange={setStoppedEventType}
					/>
				</Form.ControlGroup>
				<Form.Control
					controlType={Form.Control.Type.Checkbox}
					controlName="onStopSkipped"
					label={f('on stop skipped')}
					value={isOnStopSkipped}
					onChange={handleOnStopSkippedChange}
				/>
				<Form.ControlGroup disabled={!isOnStopSkipped}>
					<Form.Control
						controlType={Form.Control.Type.InputPicker}
						controlName="stopSkippedEventType"
						block
						cleanable={false}
						data={eventTypesOptions}
						value={stopSkippedEventType}
						onChange={setStopSkippedEventType}
					/>
				</Form.ControlGroup>
			</Form.ControlGroup>
			<Form.ControlGroup>
				<Form.Control
					controlType={Form.Control.Type.InputNumber}
					controlName="acceleration"
					controlValidator={(value) => {
						if (value != null && value == 0) {
							return f('please enter a value not less than', { value });
						}
					}}
					min={0}
					label={f("acceleration") + ', ' + f({ prefix: 'units', id: 'km' }) + '/(' + f({ prefix: 'units', id: 'h' }) + '*' + f({ prefix: 'units', id: 's' }) + ')'}
					value={acceleration}
					onChange={handleAccelerationChange}
				/>
				<Form.ControlGroup disabled={!acceleration}>
					<Form.Control
						label={fc('acceleration event type')}
						controlType={Form.Control.Type.InputPicker}
						controlName="acceleratingEventType"
						block
						cleanable={false}
						data={eventTypesOptions}
						value={acceleratingEventType}
						onChange={setAcceleratingEventType}
					/>
				</Form.ControlGroup>
				<Form.Control
					controlType={Form.Control.Type.InputNumber}
					controlName="deceleration"
					controlValidator={(value) => {
						if (value != null && value == 0) {
							return f('please enter a value not less than', { value });
						}
					}}
					min={0}
					label={f("deceleration") + ', ' + f({ prefix: 'units', id: 'km' }) + '/(' + f({ prefix: 'units', id: 'h' }) + '*' + f({ prefix: 'units', id: 's' }) + ')'}
					value={deceleration}
					onChange={handleDecelerationChange}
				/>
				<Form.ControlGroup disabled={!deceleration}>
					<Form.Control
						label={fc('deceleration event type')}
						controlType={Form.Control.Type.InputPicker}
						controlName="deceleratingEventType"
						block
						cleanable={false}
						data={eventTypesOptions}
						value={deceleratingEventType}
						onChange={setDeceleratingEventType}
					/>
				</Form.ControlGroup>
			</Form.ControlGroup>
		</div>
	);
};

export default ZoneGuardFields;
