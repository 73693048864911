import React from 'react';
import ActionBar from '../../general/actionbar/ActionBar';
import ActionEdit from './ActionEdit';
import ActionRemove from './ActionRemove';
import { className, fromProps } from '../../../lib/className';

/**
 * @param {Object} props
 * @param {Array | ReactElement} [props.prependActions] - array of react elements
 * @param {Array | ReactElement} [props.appendActions] - array of react elements
 * @param {boolean} [props.hideEdit]
 * @param {boolean} [props.hideRemove]
 * @param {string} [props.editTitle]
 * @param {string} [props.removeTitle]
 * @param {boolean | undefined} [props.disabled]
 */

function DefaultActionBar(props) {

	return (
		<ActionBar className={className('action-bar-default', fromProps(props))} disabled={props.disabled}>
			{props.prependActions}
			{!props.hideEdit && <ActionEdit />}
			{!props.hideRemove && <ActionRemove />}
			{props.appendActions}
		</ActionBar>
	);
}

export default DefaultActionBar;
