import React, { useRef, useState, useEffect, FC, useMemo, KeyboardEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { FiExternalLink as PageIcon } from 'react-icons/fi';

import { actions as processorActions } from 'core/redux/api/processors';
import PagePanel from 'core/react/general/panel/PagePanel';
import { useI18n } from 'i18n';
import { AllRoutes } from 'constants/routes';
import Intent from 'core/misc/ObjectActionIntent';
import DefaultActionBar from 'core/react/share/actionbar/DefaultActionBar';
import ActionBack from 'core/react/share/actionbar/ActionBack';
import ActionAdd from 'core/react/share/actionbar/ActionAdd';
import Button from 'core/react/general/form/Button';
import SidebarLayout from 'core/react/general/layout/SidebarLayout';
import ObjectActionDialogPopup from 'core/react/general/ObjectActionDialogPopup';
import RemoveDialog from 'core/react/general/RemoveDialog';
import Loader from 'core/react/general/Loader';
import { cx } from 'core/api';
import { CommuteDetectorCard } from 'core/react/custom/commuteDetectors/CommuteDetectorCard';
import CommuteDetectorList from 'core/react/custom/commuteDetectors/CommuteDetectorList';
import { CommuteDetectorFields } from 'core/react/custom/commuteDetectors/CommuteDetectorFields';
import { CommuteDetectorView } from 'core/react/custom/commuteDetectors/CommuteDetectorView';
import { setPageTitle } from 'core/misc/page';
import { commuteDetectorsSelector } from 'core/redux/selectors/processors';
import { ICommuteDetector } from 'types/detectors';

import './commuteDetectorsPage.scss';

export interface ICommuteDetectorFormData {
  name: string;
  tapDebounce: boolean;
  debounceTimeout: string;
  detectorReset: boolean;
  resetTimeout: string;
  onTapIn: boolean;
  onTapOut: boolean;
  uris: string[];
}

//TODO: remove any when actions are typed
const actions = processorActions.commuteDetectors;

const PAGE_TITLE = 'commute detectors';

export const COMMUTE_DETECTOR_DEFAULT_VALUES = {
  name: '',
  tapDebounce: false,
  debounceTimeout: '',
  detectorReset: false,
  resetTimeout: '',
  onTapIn: false,
  onTapOut: false,
  uris: [],
};

const CommuteDetectorsPage: FC = () => {
  const { f, fc } = useI18n();

  const history = useHistory();
  const params = useParams<Record<string, string>>();

  const dispatch = useDispatch();
  const commuteDetectors = useSelector(commuteDetectorsSelector);

  // TODO: should be refactored. used to go to a previous route when a detector updated/created.
  const pending = useRef(false);

  const [intent, setIntent] = useState<Intent | null>(null);

  const formMethods = useForm<ICommuteDetectorFormData>({
    defaultValues: COMMUTE_DETECTOR_DEFAULT_VALUES,
  });

  const { 0: route, id: processorId } = params;
  const isFormPage = route === 'edit' || route === 'create';
  const isDisabled = commuteDetectors.pending;

  const selectedDetector = useMemo(
    () => (!!processorId && !!commuteDetectors.map ? commuteDetectors.map[Number(processorId)] : null),
    [processorId, commuteDetectors],
  );

  const onPopupClose = () => {
    intent && setIntent(null);
  };

  const onAdd = () => {
    history.push(`${AllRoutes.CommuteDetectors}/create`);
  };

  const onBack = () => {
    history.goBack();
  };

  const onSelect = (detector: ICommuteDetector) => {
    history.push(`${AllRoutes.CommuteDetectors}/${detector.processorId}`);
  };

  const onIntent = (intent: Intent) => {
    if (intent.action() === Intent.Action.Remove) {
      return setIntent(intent);
    }

    if (intent.action() === Intent.Action.Edit) {
      history.push(`${AllRoutes.CommuteDetectors}/${intent.object().processorId}/edit`);
    }
  };

  const onRemove = (detector: ICommuteDetector) => {
    dispatch(actions.remove.request({ processorId: detector.processorId }));
  };

  //TODO: we should make the form include not the whole page but related fields. remove the following when it`s done
  const handleFormKeyDown = (event: KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const clearError = () => {
    commuteDetectors.error && dispatch(actions.clear());
  };

  const onSubmit = formMethods.handleSubmit(({ debounceTimeout, resetTimeout, ...rest }: ICommuteDetectorFormData) => {
    const payload = {
      ...rest,
      debounceTimeout: debounceTimeout || undefined,
      resetTimeout: resetTimeout || undefined,
    };
    const data = new cx.ods.processors.CommuteDetector(payload);

    processorId ? dispatch(actions.update.request({ processorId, data })) : dispatch(actions.add.request({ data }));
    pending.current = true;
  });

  useEffect(() => {
    setPageTitle(fc(PAGE_TITLE));

    if (!commuteDetectors.list) {
      dispatch(actions.load.request());
    }

    return () => {
      clearError();
    };
  }, []);

  useEffect(() => {
    clearError();
  }, [intent]);

  useEffect(() => {
    clearError();
    onPopupClose();
  }, [params]);

  useEffect(() => {
    if (!commuteDetectors.pending && pending.current) {
      pending.current = false;

      if (!commuteDetectors.error) {
        onBack();
      }
    }
  }, [commuteDetectors]);

  useEffect(() => {
    if (intent && commuteDetectors.map && !commuteDetectors.map[intent.object().processorId]) {
      onPopupClose();
    }
  }, [commuteDetectors, params]);

  const sidebarContent = (
    <>
      <DefaultActionBar
        prependActions={
          isFormPage ? <ActionBack disabled={isDisabled} onClick={onBack} /> : <ActionAdd onClick={onAdd} />
        }
        hideEdit={isFormPage}
        disabled={isDisabled}
      />
      {isFormPage ? (
        <div className="content">
          <CommuteDetectorFields commuteDetector={selectedDetector} isDisabled={isDisabled} />
          <div className="error">{commuteDetectors.error}</div>
          <Button type="submit" disabled={isDisabled}>
            {f('save')}
          </Button>
        </div>
      ) : (
        <>
          <CommuteDetectorList
            selectedId={selectedDetector?.processorId || null}
            onSelect={onSelect}
            onIntent={onIntent}
          />
          {!!selectedDetector && <CommuteDetectorCard commuteDetector={selectedDetector} />}
        </>
      )}
    </>
  );

  let content = null;

  if (commuteDetectors.list) {
    content = (
      <FormProvider {...formMethods}>
        <form className="form" onSubmit={onSubmit} onKeyDown={handleFormKeyDown}>
          <SidebarLayout
            className="body"
            icon={<PageIcon size="20" />}
            title={isFormPage ? f('commute detector') : f('commute detectors')}
            sidebarContent={sidebarContent}
          >
            {(!!selectedDetector || isFormPage) && (
              <CommuteDetectorView commuteDetector={selectedDetector} isEditMode={isFormPage} isDisabled={isDisabled} />
            )}
            {intent && intent.action() === Intent.Action.Remove && (
              <ObjectActionDialogPopup
                offset={intent.data().offset}
                onClose={onPopupClose}
                title={f('delete commute detector')}
                disabled={isDisabled}
                error={commuteDetectors.error}
              >
                <RemoveDialog
                  object={intent.object()}
                  title={f('commute detector')}
                  text={f('are you sure you want to delete commute detector', { name: intent.object().name })}
                  onSubmit={onRemove}
                  onCancel={onPopupClose}
                />
              </ObjectActionDialogPopup>
            )}
          </SidebarLayout>
        </form>
      </FormProvider>
    );
  } else if (commuteDetectors.pending) {
    content = (
      <div className="vh-center">
        <Loader size={Loader.Size.LG} />
      </div>
    );
  }

  return (
    <div className="app-page commute-detectors">
      <PagePanel>{content}</PagePanel>
    </div>
  );
};

// TODO: get rid of it when routes are refactored
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
CommuteDetectorsPage.Url = AllRoutes.CommuteDetectors;

export default CommuteDetectorsPage;
