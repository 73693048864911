import React, { Dispatch, FC } from 'react';

import ActionAdd from './DeviceActionAdd';
import ActionEdit from 'core/react/share/actionbar/ActionEdit';
import ActionPin from 'core/react/share/actionbar/ActionPin';
import ActionRemove from 'core/react/share/actionbar/ActionRemove';
import ActionBar from 'core/react/general/actionbar/ActionBar';
import MultipleSelectionSwitch from './MultipleSelectionSwitch';
import { DevicesVisibilityButton } from './devices-visibility-button';

import './deviceActionBar.scss';

interface IDeviceActionBarProps {
  groupingId: number;
  setHiddenCategories: Dispatch<number[]>;
}

export const DeviceActionBar: FC<IDeviceActionBarProps> = ({ groupingId, setHiddenCategories }) => (
  <ActionBar className="device-action-bar">
    <DevicesVisibilityButton setHiddenCategories={setHiddenCategories} />
    <MultipleSelectionSwitch />
    <ActionAdd groupingId={groupingId} />
    <ActionEdit />
    <ActionPin />
    <ActionRemove />
  </ActionBar>
);
