import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { IDownsampledMessageHistoryProcessor } from 'types/detectors';
import { useI18n } from 'i18n';
import { AllRoutes } from 'constants/routes';
import { downsampledMessageHistorySelector } from 'core/redux/selectors/processors';
import { actions as processorActions } from 'core/redux/api/processors';
import Intent from 'core/misc/ObjectActionIntent';
import { setPageTitle } from 'core/misc/page';

const actions = processorActions.downsampledMessageHistory;

const PAGE_TITLE = 'downsampled message history processors';

export const useProcessorPage = () => {
  const { fc } = useI18n();

  const history = useHistory();
  const params = useParams<Record<string, string>>();

  const dispatch = useDispatch();
  const processors = useSelector(downsampledMessageHistorySelector);

  // TODO: should be refactored. used to go to a previous route when a processor updated/created.
  const pending = useRef(false);

  const [intent, setIntent] = useState<Intent | null>(null);

  const { 0: route, id: processorId } = params;
  const isFormPage = route === 'edit' || route === 'create';

  const selectedProcessor = useMemo(
    () => (!!processorId && !!processors.map ? processors.map[Number(processorId)] : null),
    [processorId, processors],
  );

  const onPopupClose = () => {
    intent && setIntent(null);
  };

  const onIntent = (intent: Intent) => {
    if (intent.action() === Intent.Action.Remove) {
      return setIntent(intent);
    }

    if (intent.action() === Intent.Action.Edit) {
      history.push(`${AllRoutes.DownsampledMessageHistory}/${intent.object().processorId}/edit`);
    }
  };

  const onRemove = (processor: IDownsampledMessageHistoryProcessor) => {
    dispatch(actions.remove.request({ processorId: processor.processorId }));
  };

  const clearError = () => {
    processors.error && dispatch(actions.clear());
  };

  useEffect(() => {
    setPageTitle(fc(PAGE_TITLE));

    if (!processors.list) {
      dispatch(actions.load.request());
    }

    return () => {
      clearError();
    };
  }, []);

  useEffect(() => {
    clearError();
  }, [intent]);

  useEffect(() => {
    clearError();
    onPopupClose();
  }, [params]);

  useEffect(() => {
    if (!processors.pending && pending.current) {
      pending.current = false;

      if (!processors.error) {
        history.goBack();
      }
    }
  }, [processors]);

  useEffect(() => {
    if (intent && processors.map && !processors.map[intent.object().processorId]) {
      onPopupClose();
    }
  }, [processors, params]);

  const viewProps = { selectedProcessor, intent, onIntent, onRemove, onPopupClose };

  const formProps = { processor: selectedProcessor, pending };

  return { isFormPage, isLoading: processors.pending && !processors.list, viewProps, formProps };
};
