import { ofType, combineEpics } from 'redux-observable';
import { switchMap, takeUntil, map as rxmap, filter } from 'rxjs/operators';
import { actions } from '../actions';
import { rx, api } from '../../../../api';
import { formatDateTime, getTimezoneString } from '../../../../misc/misc';
import { formatter } from '../../../../misc/datetime';
import { fc } from '../../../../../i18n';
import { errorMap } from '../../../actions';

const requestEpic = (action$) => {
	return action$.pipe(
		ofType(actions.zoneVisits.request.type),
		switchMap(action => rx(api.reports.zoneVisits, action.parameters).pipe(
			rxmap(operation => actions.zoneVisits.success({ zoneVisits: operation.response() })),
			errorMap(actions.zoneVisits.fail),
			takeUntil(action$.pipe(ofType(actions.zoneVisits.cancel.type)))
		))
	)
}

const startPrepareEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.zoneVisits.export.type),
		rxmap(action => {
			const state = state$.value.reports.zoneVisits;
			if (state.hasMore) {
				return actions.zoneVisits.request({ parameters: state.parameters });
			} else {
				return actions.zoneVisits.exportProgress({ progress: 100 });
			}
		})
	)
}

const processPrepareEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.zoneVisits.success.type),
		filter(action => state$.value.reports.zoneVisits.exporting),
		rxmap(action => {
			const state = state$.value.reports.zoneVisits;
			return state.hasMore
				? actions.zoneVisits.request({ parameters: state.parameters })
				: actions.zoneVisits.exportProgress({ progress: 100 });
		})
	)
}

const exportCsvEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.zoneVisits.exportProgress.type),
		filter(action => action.progress == 100),
		rxmap(action => {
			const state = state$.value.reports.zoneVisits;
			const devices = state$.value.devices.map;
			const zones = state$.value.zones.map;
			// report header
			let csv = '"'
				+ fc('report type')
				+ '","'
				+ fc('generated at')
				+ '",'
				+ fc('timezone')
				+ ' \n';
			csv += '"' + fc('zone visits') + '"';  // report type
			csv += ',"' + formatDateTime(state.at) + '"'; // generated at
			csv += ',"' + getTimezoneString() + '"'; // timezone at
			csv += "\n\n";
			// content header
			csv += fc('device');
			csv += ',' + fc('zone');
			csv += ',"' + fc('entry time') + '"';
			csv += ',"' + fc('exit time') + '"';
			csv += ',"' + fc('entry day of week') + '"';
			csv += ',' + fc('duration');
			if (state.parameters.esg) {
				csv += ',"' + fc('ESG entry') + '"';
				csv += ',"' + fc('ESG exit') + '"';
			}
			csv += "\n";
			// content
			state.list.forEach(zoneVisit => {
				// device
				csv += '"' + devices[zoneVisit.uri].name + '"';
				// zone
				csv += ',"' + (zones[zoneVisit.zoneId] ? zones[zoneVisit.zoneId].name : ('#' + zoneVisit.zoneId + ' (' + fc('deleted') + ')')) + '"';
				// entry time
				csv += ',"' + formatDateTime(zoneVisit.enteredAt) + '"';
				// exit time
				csv += ',"' + formatDateTime(zoneVisit.leftAt) + '"';
				// Entry day of week
				csv += ',' + formatter.dayOfWeek(zoneVisit.enteredAt);
				// duration
				csv += ',"' + (zoneVisit.enteredAt && zoneVisit.leftAt ? formatter.duration(zoneVisit.enteredAt, zoneVisit.leftAt) : ' ') + '"';
				if (state.parameters.esg) {
					// ESG entry
					csv += ',"' + (zoneVisit.esgEntryStatus ? zoneVisit.esgEntryStatus : '') + '"';
					// ESG Exit
					csv += ',"' + (zoneVisit.esgExitStatus ? zoneVisit.esgExitStatus : '') + '"';
				}
				csv += "\n";
			});
			return actions.zoneVisits.exportDone({ csv });
		}),
	)
}

const epic = combineEpics(requestEpic, processPrepareEpic, startPrepareEpic, exportCsvEpic);

export { epic };
