import {
	ResolverQueue,
	ResolverCache,
	ResolverEntry,
	RegainingQuotaResolveService,
	RateLimitingResolveService,
} from './resolver';
import OneMapResolver from './oneMapResolver';
import { GoogleResolver } from './googleResolver';
import { WaickResolver } from './waickResolver';
import { MosaicResolver } from './mosaicResolver';

const resolverQueue = ResolverQueue.getInstance();

export const resolve = (longitude, latitude, handleReady, handleBusy, processor) => {
	if (longitude == null || latitude == null) return null;
	const cache = ResolverCache.getInstance();
	let entry = cache.get(longitude, latitude);
	if (entry != null) return entry.observe(handleReady, handleBusy, processor);
	entry = new ResolverEntry(longitude, latitude).observe(handleReady, handleBusy, processor);
	cache.put(entry);
	resolverQueue.enqueue(entry);
	return entry;
};

export const control = {
	pause: () => resolverQueue.pause(),
	resume: () => resolverQueue.resume(),
	reset: () => resolverQueue.reset(),
};

const resolversList = process.env.REACT_APP_RESOLVER ? process.env.REACT_APP_RESOLVER.split(/, */) : [];

for (const resolver of resolversList) {
	if (resolver === 'mosaic') {
		new RateLimitingResolveService(resolverQueue, new MosaicResolver());

		continue;
	}

	if (resolver === 'onemap') {
		new RateLimitingResolveService(resolverQueue, new OneMapResolver(), 4);

		continue;
	}

	if (resolver === 'google') {
		new RegainingQuotaResolveService(resolverQueue, new GoogleResolver(), 10, 0.5, resolverQueue.filters().last());

		continue;
	}

	if (resolver === 'waick') {
		new RateLimitingResolveService(resolverQueue, new WaickResolver(), 10);
	}
}

window.resolverQueue = resolverQueue;
window.resolverCache = ResolverCache.getInstance();
