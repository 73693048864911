import React from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../i18n';
import { className } from '../../../lib/className';
import { rootLogger as logger } from '../../../lib/log';
import './deviceStatusIcon.scss';

/**
 * Either "uri" or "message" parameter should be present.
 * @param {Object} props
 * @param {string} [props.uri]
 * @param {boolean} [props.custom] indicates passing all through parameters
 */

function DeviceStatusIcon(props) {
	const { status, state, notCurrent } = props;
	const { fc } = useI18n();
	let isPointer = false;
	let _status = null;
	let title = 'unknown';
	if (status) {
		_status = status ? status.status.toLowerCase() : "";
		if (status != "") title = _status;
	}
	let style = {};
	const message = state ? state.message : null;
	if (message != null && message.heading != null) {
		isPointer = true;
		style["transform"] = "rotate(" + message.heading + "deg)";
	}
	return (
		<div
			className={className('device-status-icon', _status, { 'pointer': isPointer })}
			title={
				notCurrent
					? null
					: (fc('status') + ": " + fc({ prefix: 'status', id: title }))
			}
		>
			<div style={style}></div>
		</div>
	);
}

export default connect((state, props) => {
	if (props.reduxKey && props.reduxKey == 'timeMachine') {
		return {
			status: state.timeMachine.deviceStatus.map && state.timeMachine.deviceStatus.map[props.uri],
			state: state.timeMachine.state.map && state.timeMachine.state.map[props.uri]
		}
	} else {
		if (!props.reduxKey) logger.warning('DeviceStatusIcon: reduxKey is not defined');
		return {
			status: state.deviceStatuses.map && state.deviceStatuses.map[props.uri],
			state: state.deviceStates.map && state.deviceStates.map[props.uri]
		}
	}
})(DeviceStatusIcon);
