import { ActionGeneratorBuilder } from "../actions";

const defaultState = {
	device: {
		uri: null,
		changedAt: null
	},
	// store uri's for pinned device details panels
	panels: {
		uris: []
	},
	// store opened event list instances' uids
	eventLists: {}, // uri => {uid => boolean}
	eventSelections: {}, // uid => { eventId => EventDetails }
	sidebarCollapsed: false,
	action: null,
	prevAction: null
};

// ---------------------------------------------------------

const actions = new ActionGeneratorBuilder('context')
	.type('deviceChanged', 'uri')
	.type('panelsPinChanged', 'uri')
	.type('eventListChanged', { uri: true, uid: true })
	.type('eventSelectionChanged', { uri: true, event: true })
	.type('setSidebarCollapsed', 'collapsed')
	.type('actionSet', { actionType: true, name: true, data: true })
	.type('actionClear')
	.build()
;

// ---------------------------------------------------------

const reducer = (state, action) => {
	let byUri, byUid;
	switch (action.type) {
		case actions.deviceChanged.type:
			state = {
				...state,
				device: {
					uri: action.uri,
					changedAt: JSON.stringify(new Date())
				}
			};
			break;
		case actions.panelsPinChanged.type:
			let uris = [...state.panels.uris];
			let uriAt = uris.indexOf(action.uri);
			uriAt >= 0
				? uris.splice(uriAt, 1)
				: uris.push(action.uri)
			;
			state = {
				...state,
				panels: { uris }
			};
			break;
		case actions.eventListChanged.type:
			let eventLists = {...state.eventLists};
			byUri = eventLists[action.uri];
			byUid = byUri ? byUri[action.uid] : null;
			if (byUid) {
				delete eventLists[action.uri][action.uid];
			} else {
				if (!byUri) eventLists[action.uri] = {};
				eventLists[action.uri][action.uid] = true;
			}
			state = {
				...state,
				eventLists
			};
			break;
		case actions.eventSelectionChanged.type:
			let eventSelections = {...state.eventSelections};
			// simplified for now (only single selection support)
			if (eventSelections[action.uid] && eventSelections[action.uid][action.event.eventId]) {
				delete eventSelections[action.uid];
			} else {
				eventSelections[action.uid] = {
					[action.event.eventId]: action.event
				};
			}
			// byUid = eventSelections[action.uid];
			// if (!byUid) {
			// 	eventSelections[action.uid] = {
			// 		[action.event.eventId]: action.event
			// 	};
			// } else if (byUid[action.event.eventId] != null) {
			// 	delete eventSelections[action.uid][action.event.eventId];
			// } else {
			// 	byUid[action.event.eventId] = action.event;
			// }
			state = {
				...state,
				eventSelections
			};
			break;
		case actions.setSidebarCollapsed.type:
			state = {
				...state,
				sidebarCollapsed: action.collapsed
			};
			break;
		case actions.actionSet.type:
			state = {
				...state,
				action: {
					type: action.actionType,
					name: action.name,
					data: action.data
				},
				prevAction: {...state.action},
			};
			break;
		case actions.actionClear.type:
			state = {
				...state,
				action: null,
				prevAction: null
			};
			break;
	}
	return state || defaultState;
}

// ---------------------------------------------------------

export { actions, reducer };
