import React, { useContext } from 'react';
import ListItem from '../../../general/list/ListItem';
import { DeviceSelectorContext } from './DeviceSelector';
import { className } from '../../../../lib/className';
import { DeviceDetailsProxy } from '../../../../api/device'; // eslint-disable-line

/**
 * @param {Object} props
 * @param {DeviceDetailsProxy} props.device
 */
function DeviceListItem(props) {
	const context = useContext(DeviceSelectorContext);

	return (
		<ListItem
			className={className({ 'selected': context.selectedUris.includes(props.device.uri) })}
			onClick={() => context.onDeviceClick(props.device.uri)}
		>
			{props.device.denomination()}
		</ListItem>
	)
}

export default DeviceListItem;