import React from 'react';
import { useI18n } from '../../../../i18n';
import { className, fromProps } from '../../../lib/className';
import ActionBar from '../../general/actionbar/ActionBar';
import icon from '../../../img/icons/close.png';

/**
 * @param {Object} props
 * @param {function} [props.onClick]
 * @param {string} [props.title]
 * @param {boolean} [props.disabled]
 */

function ActionClose(props) {
	const { f } = useI18n();

	return (
		<ActionBar.Action
			className={className('close', fromProps(props))}
			onClick={props.onClick}
			title={props.title || f('close')}
			disabled={props.disabled}
		>
			<img src={icon} alt=""  />
		</ActionBar.Action>
	)
}

export default ActionClose;