import React, { useEffect, useRef } from 'react';
import Checkbox from './Checkbox';
import './groupCheckbox.scss';

function GroupCheckbox(props) {
	const checkboxRef = useRef();

	useEffect(() => {
		const checker = checkboxRef.current.querySelector('.rs-checkbox-checker');
		if (checker) {
			const line = document.createElement('div');
			line.className = 'line';
			checker.appendChild(line);
		}
	}, []);

	return <Checkbox customRef={checkboxRef} className="group-checkbox" {...props} />;
}

export default GroupCheckbox;