import { store } from '../redux/store';
import { sortByName } from '../lib/sorting';

export const sortZoneIds = (ids) => {
	const map = store.getState().zones.map;
	if (map) {
		const zones = ids.map(id => map[id]);
		return sortByName(zones).map(zone => zone.zoneId);
	}
	return ids;
}