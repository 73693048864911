import React, { useRef } from 'react';
import { useDrop } from 'react-dnd';
import { DragItemType } from '../../share/dnd/DragItemType';
import { cx } from '../../../api';
import ObjectActionIntent from '../../../misc/ObjectActionIntent';
import PropertyInfo from './PropertyInfo';
import { className, fromProps } from '../../../lib/className';
import './devicePropertyTile.scss';

/**
 * @param {Object} props
 * @param {function} [props.onClick]
 * @param {function} props.onIntent
 * @param {cx.ods.meta.Property} props.property
 * @param {boolean} [props.short]
 */

function DevicePropertyTile (props) {
	const box = useRef(null);
	const property = props.property;

	// -------------------------------------------

	const onClick = () => {
		const offset = cx.dom.at.offset(box.current);
		const width = box.current.offsetWidth;
		const height = box.current.offsetHeight;
		props.onClick && props.onClick(property, { offset, width, height });
	}

	// --------------- drag & drop ----------------

	const onDrop = (item) => {
		const offset = cx.dom.at.offset(box.current);
		if (item.type == DragItemType.ACTION_REMOVE) {
			props.onIntent(new ObjectActionIntent(
				'property',
				ObjectActionIntent.Action.Remove,
				props.property,
				{ offset }
			));
		} else if (item.type == DragItemType.ACTION_EDIT) {
			props.onIntent(new ObjectActionIntent(
				'property',
				ObjectActionIntent.Action.Edit,
				props.property,
				{ offset }
			));
		}
	}

	const [dropState, dropRef] = useDrop({
		accept: [DragItemType.ACTION_REMOVE, DragItemType.ACTION_EDIT],
		drop: item => onDrop(item),
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		})
	});

	// ------------------------------------------

	const onRef = (element) => {
		box.current = element;
		dropRef(element);
	}

	return (
		<div
			className={className(
				'device-property',
				fromProps(props),
				{
					'droppable': (dropState.isOver && dropState.canDrop),
					'clickable': !!props.onClick
				}
			)}
			ref={onRef}
			onClick={onClick}
		>
			<PropertyInfo property={property} />
			{!props.short &&
				<div className="description">
					{property.description}
				</div>
			}
		</div>
	)
}

export default DevicePropertyTile;