import React from 'react';
import { className, fromProps } from '../../../lib/className';
import Loader from '../Loader';
import './list.scss';

/**
 * @param {Object} props
 * @param {string} [props.id]
 * @param {string} [props.pending]
 * @param {string} [props.className]
 * @param {React.ReactNode} props.children
 */

function List(props) {
	let loader = null;
	if (props.pending) {
		loader = (
			<div className="list-loader">
				<Loader />
			</div>
		);
	}

	return (
		<div className={className('x-list', 'list', fromProps(props), { 'pending': props.pending })} id={props.id}>
			{loader}
			{props.children}
		</div>
	)
}

export default List;
