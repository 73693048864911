import React, { FC } from 'react';

import {
  ProcessorInfoHeaderStyled,
  ProcessorInfoStyled,
} from 'ui/pages/downsampled-message-history/processor-info.styled';
import { IDownsampledMessageHistoryProcessor } from 'types/detectors';
import { useI18n } from 'i18n';
import { ProcessorInfoList } from './processor-info-list';

interface IProcessorInfoProps {
  processor: IDownsampledMessageHistoryProcessor | null;
}

export const ProcessorInfo: FC<IProcessorInfoProps> = ({ processor }) => {
  const { fc } = useI18n();

  return (
    <ProcessorInfoStyled>
      <ProcessorInfoHeaderStyled>
        <label>{fc('details')}</label>
      </ProcessorInfoHeaderStyled>
      {processor && <ProcessorInfoList processor={processor} />}
    </ProcessorInfoStyled>
  );
};
