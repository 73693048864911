import React, { useEffect, useRef } from 'react';
import { Input as RsInput } from 'rsuite';
import { isEscapeButton, capitalize } from '../../../misc/misc';
import { className, fromProps } from '../../../lib/className';
import './input.scss';

/**
 * For other parameters - see rsuite Input component
 * @param {Object} props
 * @param {string} [props.label]
 * @param {boolean} [props.autoFocus]
 * @param {boolean} [props.cleanable]
 * @param {boolean | undefined} [props.disabled]
 * @param {string | null} [props.value]
 * @param {function} [props.onChange]
 */

function Input(props) {
	const box = useRef(null);

	const { cleanable, value, onChange, placeholder, ..._props } = props;
	const _value = value === null ? '' : value;

	const canClean = props.cleanable && props.value !== '' && props.value != null;

	const onClear = () => {
		props.onChange && props.onChange('');
	}

	const _onChange = (value) => {
		onChange && onChange(value === '' ? null : value);
	}

	useEffect(() => {
		let handleEscape = null;
		if (canClean) {
			handleEscape = (event) => {
				if (isEscapeButton(event)) onClear();
			}
			box.current.addEventListener('keydown', handleEscape);
		}
		return () => box.current.removeEventListener('keydown', handleEscape);
	}, [canClean]);

	return (
		<div ref={box} className={className('input', fromProps(props), { 'cleanable': canClean })}>
			{props.label && <label><span className="capitalize">{props.label}</span></label>}
			<div className="content">
				<RsInput value={_value} onChange={_onChange} placeholder={capitalize(placeholder)} {..._props} />
				{canClean && <span className="action-clear" onClick={onClear}>x</span>}
			</div>
		</div>
	)
}

export default Input;
