import React from 'react';
import { useI18n } from '../../../../i18n';
import icon from '../../../img/icons/collapse-sidebar.png';

/**
 * @param {Object} props
 * @param {boolean} props.value
 * @param {function} props.onChange
 */

function SidebarToggle(props) {
	const { fc } = useI18n();
	const style = { transform : props.value ? "rotate(0deg)" : "rotate(180deg)" };

	return (
		<div className="sidebar-toggle">
			<img
				src={icon}
				alt=""
				style={style}
				className="clickable"
				title={props.value ? fc('show') : fc('hide')}
				onClick={() => props.onChange(!props.value)}
			/>
		</div>
	);
}

export default SidebarToggle;