import React, { useEffect } from 'react';
import { useI18n } from '../../../i18n';
import { isEnterButton } from '../../misc/misc';
import Button from './form/Button';
import './infoDialog.scss';

/**
 * @param {Object} props
 * @param {string} props.text
 * @param {function} props.onOkClick
 */

function InfoDialog(props) {
	const { f } = useI18n();

	useEffect(() => {
		const onKeydown = (event) => {
			if (isEnterButton(event)) props.onOkClick();
		}
		window.addEventListener('keydown', onKeydown);
		return () => window.removeEventListener('keydown', onKeydown);
	}, []);

	return (
		<div className="info-dialog">
			<div className="message">
				{props.text}
			</div>
			{props.children}
			<Button onClick={props.onOkClick}>{f('ok')}</Button>
		</div>
	);
}

export default InfoDialog;
