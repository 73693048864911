import React, { useContext, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Map } from '../../general/location/Map'; // eslint-disable-line
import { actions as appActions } from '../../../redux/app/appActions';
import { AppActionType } from '../../../app/AppActionType';
import { URL_DELIMITER } from '../../../misc/url';
import { DeviceDetailsProxy } from '../../../api/device'; // eslint-disable-line
import DeviceMapMarker from './DeviceMapMarker';
import { MarkerOrderManagerContext } from '../../general/location/MarkerOrderManager';
import { MarkerHoverManagerContext } from '../../general/location/MarkerHoverManager';

/**
 * @param {Object} props
 * @param {DeviceDetailsProxy} props.device
 * @param {boolean} [props.alwaysCenter]
 * @param {Map} props.map implicitly passed by Map
 * @param {function} [props.onClick]
 */

function DeviceStateMapMarker(props) {
	const markerOrderContext = useContext(MarkerOrderManagerContext);
	const markerHoverContext = useContext(MarkerHoverManagerContext);
	const markerRef = useRef();

	const uris = props.match.params.uris
		? props.match.params.uris.split(URL_DELIMITER)
		: []
	;
	const selected = props.device ? uris.includes(props.device.uri) : false;

	const onClick = () => {
		if (props.onClick) {
			props.onClick();
		} else {
			if (selected) {
				props.map.focusMarkers(props.device.uri);
			}
			props.dispatch(appActions.action(
				AppActionType.DEVICE_SELECTION_CHANGED,
				{
					uri: props.device.uri
				}
			));
		}
	}

	useEffect(() => {
		if ((markerOrderContext || markerHoverContext) && props.deviceState != null && props.device) {
			const message = props.deviceState.message;
			if (message && message.longitude && message.latitude) {
				const coordinates = [message.longitude, message.latitude];
				if (markerOrderContext) {
					markerOrderContext.addMarker({
						id: props.device.uri,
						coordinates,
						time: message.generatedAt,
						setZIndex: markerRef.current.setZIndex,
						selected
					});
				}
				if (markerHoverContext) markerHoverContext.addMarker({ id: props.device.uri, coordinates, domNode: markerRef.current.getDomBox() });
			}

		}

	}, [props.deviceState, props.match.params.uris]);

	useEffect(() => {
		return () => {
			if (markerOrderContext) markerOrderContext.removeMarker(props.device.uri);
			if (markerHoverContext) markerHoverContext.removeMarker(props.device.uri);
		}
	}, [])

	const getStatusClass = () => {
		return props.deviceStatus && props.deviceStatus.status.toLowerCase();
	}

	let marker = null;
	if (props.deviceState != null && props.device) {
		const message = props.deviceState.message;
		if (message && message.longitude && message.latitude) {
			const coordinates = [message.longitude, message.latitude];
			marker = (<DeviceMapMarker
				markerRef={markerRef}
				status={getStatusClass()}
				map={props.map}
				coordinates={coordinates}
				onClick={onClick}
				alwaysCenter={props.alwaysCenter}
				device={props.device}
				heading={message.heading}
				selected={selected}
				params={props.params}
			/>);
		}
	}

	return (marker);
}

export default connect((state, props) => {
	return {
		deviceState: state.deviceStates.map && props.device && state.deviceStates.map[props.device.uri],
		deviceStatus: state.deviceStatuses.map && props.device && state.deviceStatuses.map[props.device.uri]
	};
})(withRouter(DeviceStateMapMarker));
