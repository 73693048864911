import { ActionGeneratorBuilder } from '../../actions';

export const actions = new ActionGeneratorBuilder('assets')
	.subtype(
		'employees',
		employees => employees
			.subtype('add', add => add.request('employee').success('employee').fail().cancel())
			.subtype('load', load => load.request().success('employees').fail().cancel())
			.subtype('update', update => update.request({ assetId: true, employee: true }).success().fail().cancel())
			.subtype('remove', remove => remove.request('assetId').success().fail().cancel())
			.subtype('upload', upload => upload.request('upload').success().fail().cancel())
			.subtype('inactivateAll', inactivateAll => inactivateAll.request({ assetIds: true, activate: false }).success().fail().cancel())
			.subtype('removeAll', removeAll => removeAll.request('assetIds').success().fail().cancel())
			.subtype('inactivate', inactivate => inactivate.request({ assetId: true, activate: false }).success().fail().cancel())
			.type('clear')
	)
	.subtype(
		'commute',
		commute => commute
			.request({ uid: true, parameters: true, mode: true, clear: false })
			.success({ uid: true, intervals: true })
			.fail({ uid: true, errorMessage: true })
			.cancel({ uid: true })
			.type('remove', 'uid')
	)
	.build()
;
