import React, { useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { actions as traceActions } from '../../../../redux/api/trace';
import { Map, getOwMap } from '../../../general/location/Map';
import { LineString, Point } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import LayerGroup from 'ol/layer/Group';
import { Feature } from 'ol';
import { Style, Stroke, Circle, Fill, Icon } from 'ol/style';
import { messageProxy } from '../../../../api/message';
import { actions as zonesActions } from '../../../../redux/api/zones';
import useLocalStorage from '../../../../misc/useLocalStorage';
import { setProps } from '../../../../misc/ol';
import { readMultiPolygon } from '../../../../misc/wkt';
import { makeStyle, StyleType } from '../../zones/zoneStyle';
import MapControls from '../../map/controls/MapControls';
import MapOptions from '../../map/controls/MapOptions';
import MapOptionLayers from '../../map/controls/MapOptionLayers';
import MapOptionZones from '../../map/controls/MapOptionZones';
import MapOptionGoogleSatMode from '../../map/controls/MapOptionGoogleSatMode';
import selectedMarkerImg from '../../../../img/markers/tripend.png';
import css from '../../../../defaults.scss';
import { cx } from '../../../../api';
import GeometryType from 'ol/geom/GeometryType';
import { fromEPSG4326 } from 'ol/proj/epsg3857';


const MAP_NAME = 'route-inspection';

/**
 * @param {Object} props
 * @param {Array.<cx.ods.reports.RidershipReportEntry>} props.entries
 * @param {cx.ods.reports.RidershipReportEntry} props.selectedEntry
 * @param {string} props.uri
 * @param {string} [props.title]
 */

function RouteInspectionMap(props) {
	const map = useRef(null);
	const layerGroup = useRef(null);
	const traceLayer = useRef(null);
	const zoneLayerGroup = useRef(null);
	const [traceUid, setTraceUid] = useState(null);
	const [displayZones, setDisplayZones] = useLocalStorage(MAP_NAME + '_display_zones');
	const trace = useSelector(state => state.trace[traceUid] && state.trace[traceUid].list);

	const styleFunction = (feature) => {
		const geometryType = feature.getGeometry().getType();
		const coordinates = feature.getGeometry().getCoordinates();
		const styles = [
			new Style({
				image: new Circle({
					radius: css.tracePointRadius,
					fill: new Fill({ color: css.tracePointFillColor }),
					stroke: new Stroke({
						color: css.traceStrokeColor1,
						width: css.tracePointStrokeWidth
					})
				}),
				geometry: () => {
					return new Point(coordinates);
				},
				zIndex: feature.getProperties().selected ? 3 : 2
			})
		];
		if (geometryType == GeometryType.LINE_STRING) {
			styles.push(
				new Style({
					stroke: new Stroke({
						color: css.traceStrokeColor1,
						width: css.traceStrokeWidth1
					}),
					zIndex: 1
				}),
				new Style({
					stroke: new Stroke({
						color: css.traceStrokeColor2,
						width: css.traceStrokeWidth2
					}),
					zIndex: 1
				})
			);
		}
		if (feature.getProperties().selected) {
			styles.push(
				new Style({
					image: new Icon({
						src: selectedMarkerImg,
						anchor: [0.5, 1],
					}),
					zIndex: 4,
					geometry: () => {
						return new Point(coordinates);
					}
				})
			);
		}
		return styles;
	}

	useEffect(() => {
		if (props.entries && props.entries.length > 0) {
			const features = [];
			props.entries.forEach(entry => {
				const message = messageProxy(entry.event && entry.event.message);
				if (message) {
					const geometry = new Point(message.flatCoords());
					const feature = new Feature({ geometry, name: 'entry' });
					feature.setProperties({ selected: props.selectedEntry && props.selectedEntry.event.eventId == entry.event.eventId });
					features.push(feature);
				}
			});
			layerGroup.current = new LayerGroup({
				layers: [
					new VectorLayer({ source: new VectorSource({ features }), style: styleFunction, zIndex: 2 })
				]
			});
			map.current.getOlMap().addLayer(layerGroup.current);
		}

		if (props.selectedEntry) {
			const message = messageProxy(props.selectedEntry.event && props.selectedEntry.event.message);
			if (message) getOwMap(MAP_NAME).focus(message.flatCoords(), 16);
		}

		if (props.entries && props.entries.length > 1) {
			const firstEvent = props.entries[0].event;
			const lastEvent = props.entries[props.entries.length - 1].event;
			setTraceUid(firstEvent.eventId + '-' + lastEvent.eventId);
		} else setTraceUid(null);

		return () => {
			if (map.current) {
				map.current.getOlMap().removeLayer(layerGroup.current);
				layerGroup.current = null;
			}
		};
	}, [props.entries, props.selectedEntry]);

	useEffect(() => {
		if (traceUid) {
			const firstTime = props.entries[0].event.generatedAt;
			const lastTime = props.entries[props.entries.length - 1].event.generatedAt;
			const filter = new cx.ods.devices.MessageFilter();
			filter.minGeneratedAt = firstTime;
			filter.maxGeneratedAt = lastTime;
			filter.window = new cx.ods.devices.MessageWindowFilter({ size: 50 });
			props.dispatch(traceActions.load.request({ uid: traceUid, uri: props.uri, filter, clear: true }));
		}
		return () => {
			props.dispatch(traceActions.clear({ uid: traceUid }));
		}
	}, [traceUid]);

	useEffect(() => {
		if (trace && trace.length > 0) {
			const coordinates = [];
			trace.forEach(item => {
				if (item.longitude && item.latitude) coordinates.push(fromEPSG4326([item.longitude, item.latitude]));
			});
			const geometry = coordinates.length == 1
				? new Point(coordinates[0])
				: new LineString(coordinates)
			;
			const feature = new Feature();
			feature.setGeometry(geometry);
			const layer = new VectorLayer({
				source: new VectorSource({ features: [feature] }),
				style: styleFunction
			});
			traceLayer.current = new LayerGroup({ layers: [layer] });
			map.current.getOlMap().addLayer(traceLayer.current);
		}
		return () => {
			map.current.getOlMap().removeLayer(traceLayer.current);
		}
	}, [trace]);

	// zones ------------------------------------------------------------
	useEffect(() => {
		if (props.zones.map != null) {
			const zones = Object.values(props.zones.map);
			const layers = {};
			zones.forEach(zone => {
				const layer = new VectorLayer({
					source: new VectorSource({
						features: setProps(readMultiPolygon(zone.geometry), {
							zoneId: zone.zoneId,
							name: zone.name,
							style: zone.style
						})
					}),
					style: makeStyle(StyleType.Default),
				});
				layer.setProperties({ zoneId: zone.zoneId });
				layers[zone.zoneId] = layer;
			});
			zoneLayerGroup.current = new LayerGroup({ layers: Object.values(layers) });
			map.current.getOlMap().addLayer(zoneLayerGroup.current);
			zoneLayerGroup.current.setVisible(displayZones);
		}
	}, [props.zones]);

	useEffect(() => {
		if (props.zones.map == null) {
			props.dispatch(zonesActions.load.request());
		}
		if (zoneLayerGroup.current != null) {
			zoneLayerGroup.current.setVisible(displayZones);
		}
	}, [displayZones]);
	// -----------------------------------------------------------------------------

	return (
		<Map
			className="fleet"
			name={MAP_NAME}
			ref={map}
			baseLayer={Map.Layers.GOOGLE}
			controls={
				<MapControls>
					<MapOptions>
						<MapOptionLayers mapName={MAP_NAME} />
						<MapOptionGoogleSatMode mapName={MAP_NAME} />
						<MapOptionZones value={displayZones} onChange={setDisplayZones} />
					</MapOptions>
					{props.controls}
				</MapControls>
			}
		>
		</Map>
	);
}

export default connect(state => ({
	zones: state.zones
}))(RouteInspectionMap);
