//TODO: component should be used instead of ObjectDeviceManager. it may make sense to divide it into a list & a form
import React, { useState, FC, Dispatch } from 'react';

import { useI18n } from 'i18n';
import Modal from 'core/react/general/Modal';
import DeviceSet from './DeviceSet';
import DeviceSelector from './deviceSelector/DeviceSelector';
import ActionAdd from '../actionbar/ActionAdd';
import DefaultActionBar from '../actionbar/DefaultActionBar';

import './objectDeviceManager.scss';

interface IDeviceManagerProps {
  uris: string[];
  excludeUris?: string[];
  isEditMode?: boolean;
  isDisabled?: boolean;
  title?: string;
  noContentText?: string;
  setUris?: Dispatch<string[]>;
}

export const DeviceManager: FC<IDeviceManagerProps> = (props) => {
  const { uris, setUris, excludeUris, isEditMode, isDisabled, title, noContentText } = props;

  const { f } = useI18n();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const updateSelectedUris = (uris: string[]) => {
    !!setUris && setUris(uris);
    closeModal();
  };

  return (
    <div className="object-device-manager">
      <div className="header">
        <span className="title">{title || f('devices')}</span>
        {isEditMode && (
          <DefaultActionBar
            hideRemove
            hideEdit
            disabled={isDisabled}
            appendActions={<ActionAdd onClick={openModal} />}
          />
        )}
      </div>
      <DeviceSet
        value={uris}
        emptyMessage={noContentText || f('no items yet')}
        editMode={isEditMode}
        onChange={updateSelectedUris}
      />
      {isModalOpen && (
        <Modal className="object-device-manager" title={f('select devices')} onClose={closeModal}>
          <DeviceSelector excludeUris={excludeUris} uris={uris} onSubmit={updateSelectedUris} onCancel={closeModal} />
        </Modal>
      )}
    </div>
  );
};
