import { cx } from '../../../api';
import { actions } from './actions';
import { reduxSwitch } from '../../tools';

const defaultState = {
	list: null,
	filteredList: null, // [ZoneData]
	map: null, // { zoneId: ZoneData }
	addedId: null,
	pending: false,
	error: null,
	filter: null, // string
	categoryFilter: null, // [categoryId]
}

const filterByName = (zones, filter) => {
	if (zones) {
		filter = filter.toLowerCase();
		return zones.filter(zone => zone.name.toLowerCase().includes(filter));
	}
	return zones;
}

const filterByCategories = (zones, categoryIds) => {
	if (zones) {
		return zones.filter(zone => {
			if (zone.categoryIds) {
				let pass = false;
				for (let at = 0; at < categoryIds.length; ++at) {
					if (zone.categoryIds.indexOf(categoryIds[at]) != -1) {
						pass = true;
						break;
					}
				}
				return pass;
			}
			return false;
		})
	}
	return zones;
}

const filter = (zones, categoryIds, filter) => {
	if (!categoryIds && !filter) return null;
	zones = filter ? filterByName(zones, filter) : zones;
	return categoryIds ? filterByCategories(zones, categoryIds) : zones;
}

// -------------------------------------------------------------

const loadReducer = (state, action) => {
	switch (action.type) {
		case actions.load.request.type:
			return {
				...state,
				pending: true,
				error: null
			}
		case actions.load.success.type:
			return {
				...state,
				pending: false,
				list: action.zones,
				map: cx.i.hash(action.zones, (zone) => zone.zoneId),
			}
		case actions.load.fail.type:
			return {
				...state,
				pending: false,
				error: action.errorMessage
			}
		case actions.load.cancel.type:
			return {
				...state,
				pending: false
			}
		default:
			return state;
	}
}

// -------------------------------------------------------------

const addReducer = (state, action) => {
	switch (action.type) {
		case actions.add.request.type:
			return {
				...state,
				pending: true,
				error: null
			}
		case actions.add.success.type:
			state.map[action.data.zoneId] = action.data;
			return {
				...state,
				pending: false,
				addedId: action.data.zoneId
			}
		case actions.add.fail.type:
			return {
				...state,
				pending: false,
				error: action.errorMessage
			}
		case actions.add.cancel.type:
			return {
				...state,
				pending: false
			}
		default:
			return state;
	}

}

// -------------------------------------------------------------

const updateReducer = (state, action) => {
	switch (action.type) {
		case actions.update.request.type:
			return {
				...state,
				pending: true,
				error: null
			}
		case actions.update.success.type:
			state.map[action.data.zoneId] = action.data;
			return {
				...state,
				pending: false
			}
		case actions.update.fail.type:
			return {
				...state,
				pending: false,
				error: action.errorMessage
			}
		case actions.update.cancel.type:
			return {
				...state,
				pending: false
			}
		default:
			return state;
	}
}

// -------------------------------------------------------------

const removeReducer = (state, action) => {
	switch (action.type) {
		case actions.remove.request.type:
			return {
				...state,
				pending: true,
				error: null
			}
		case actions.remove.success.type:
			return {
				...state,
				pending: false
			}
		case actions.remove.fail.type:
			return {
				...state,
				pending: false,
				error: action.errorMessage
			}
		case actions.remove.cancel.type:
			return {
				...state,
				pending: false
			}
		default:
			return state;
	}
}

function clearReducer(state, action) {
	switch (action.type) {
		case actions.clear.type:
			return {
				...state,
				error: null
			};
		default:
			return state;
	}
}

// -------------------------------------------------------------

const filterByCategoryReducer = (state, action) => {
	switch (action.type) {
		case actions.setCategoryFilter.type:
			return {
				...state,
				categoryFilter: action.categoryIds,
				filteredList: filter(state.list, action.categoryIds, state.filter)
			}
		default:
			return state;
	}
}

const filterReducer = (state, action) => {
	switch (action.type) {
		case actions.setFilter.type:
			return {
				...state,
				filter: action.filter,
				filteredList: filter(state.list, state.categoryFilter, action.filter)
			}
		default:
			return state;
	}
}

// -------------------------------------------------------------

const reducer = reduxSwitch([
	addReducer, loadReducer, updateReducer, removeReducer,
	filterByCategoryReducer, filterReducer,
	clearReducer
], defaultState);

export { reducer };
