import React, { useRef } from 'react';
import SelectPicker from '../../../general/form/SelectPicker';
import { Map, getOwMap } from '../../../general/location/Map';
import { generateId } from '../../../../misc/misc';

/**
 * @param {Object} props
 * @param {string} props.mapName
 */

function MapOptionLayers(props) {
	const uid = useRef(generateId());
	const map = getOwMap(props.mapName);

	const onChange = (value) => {
		map.setBaseLayerByName(value);
	}

	const layers = Object.keys(Map.Layers).map(key => {
		return {
			value: key,
			label: Map.Layers[key]
		};
	});

	return (
		<div className="map-option map-option-layers" id={uid.current}>
			<SelectPicker
				container={() => { return document.getElementById(uid.current) }}
				data={layers}
				searchable={false}
				cleanable={false}
				onChange={onChange}
				defaultValue={map.getBaseLayerName()}
			></SelectPicker>
			<div></div>
		</div>
	);
}

export default MapOptionLayers;