import React, { createContext } from 'react';
import { Sidenav } from 'rsuite';

import { CategoryList } from './categories/category-list';
import GroupingPicker from 'core/react/share/categories/GroupingPicker';
import DeviceSearchControl from 'core/react/share/devices/DeviceSearchControl';
import { useDevicesSideMenu } from 'hooks/sidebar/useDevicesSideMenu';
import SidebarToggle from './SidebarToggle';
import { DeviceActionBar } from './actionbar/device-action-bar';
import groupingIcon from 'core/img/icons/grouping.png';
import filterIcon from 'core/img/icons/explore.png';

import './deviceSideMenu.scss';

export const GroupingIdContext = createContext(null);

export const DevicesSideMenu = () => {
  const {
    isCollapsed,
    groupingId,
    categoryListProps,
    actionBarProps,
    setGroupingId,
    onToggleChanged,
  } = useDevicesSideMenu();

  return (
    <GroupingIdContext.Provider value={groupingId}>
      <div className="device-side-menu">
        <Sidenav.Header className="side-header">
          <div className="top-line">
            <SidebarToggle value={isCollapsed} onChange={onToggleChanged} />
            {!isCollapsed && <DeviceActionBar {...actionBarProps} />}
          </div>
          <div className="side-lines">
            <div className="side-line">
              <img src={groupingIcon} alt="" />
              {!isCollapsed && <GroupingPicker onChange={setGroupingId} categoryId={groupingId} dark />}
            </div>
            <div className="side-line">
              <img src={filterIcon} alt="" />
              {!isCollapsed && <DeviceSearchControl />}
            </div>
          </div>
        </Sidenav.Header>
        <Sidenav className="side-body scrollbar dark">
          <Sidenav.Body>
            <CategoryList {...categoryListProps} />
          </Sidenav.Body>
        </Sidenav>
      </div>
    </GroupingIdContext.Provider>
  );
};
