import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useI18n } from 'i18n';
import { eventTypesListSelector } from 'core/redux/selectors/registry';

export const useEventTypesOptions = () => {
  const { fc } = useI18n();

  const eventTypesList = useSelector(eventTypesListSelector);

  const defaultEventType = { label: fc('default event type'), value: 0 };

  return useMemo(() => {
    if (!eventTypesList) {
      return [defaultEventType];
    }

    const options = Object.values(eventTypesList)
      .map((eventType) => ({
        value: eventType.eventType,
        label: fc({ prefix: 'device-event', id: eventType.name }),
      }))
      .sort((currentOption, nextOption) => currentOption.label.localeCompare(nextOption.label));

    return [defaultEventType, ...options];
  }, [eventTypesList]);
};
