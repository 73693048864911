import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useDrop } from 'react-dnd';
import { useI18n } from '../../../../i18n';
import { className, fromProps } from '../../../lib/className';
import { DragItemType } from '../../share/dnd/DragItemType';
import { FiPlus, FiMinus } from 'react-icons/fi';
import { cx } from '../../../api';
import Intent from '../../../misc/ObjectActionIntent';
import * as zoneCategories from '../../../redux/app/categories/zone';
import List from '../../general/list/List';
import ListItem from '../../general/list/ListItem';
import Checkbox from '../../general/form/Checkbox';
import { sortByName } from '../../../lib/sorting';
import './zoneCategorySelector.scss';


function ZCSListItem(props) {
	const { f } = useI18n();
	const box = useRef(null);

	const onClick = (event) => {
		!props.disabled && props.onClick(props.category.categoryId);
	}

	const canEdit = () => {
		return !!props.category.custom && props.onIntent != null;
	}

	const onDrop = (item) => {
		if (!canEdit()) return;
		const offset = cx.dom.at.offset(box.current);
		if (item.type == DragItemType.ACTION_EDIT) {
			props.onIntent(new Intent('category', Intent.Action.Edit, props.category, { offset }));
		} else {
			props.onIntent(new Intent('category', Intent.Action.Remove, props.category, { offset }));
		}
	}

	const [dropState, dropRef] = useDrop({
		accept: [DragItemType.ACTION_EDIT, DragItemType.ACTION_REMOVE],
		drop: (item) => onDrop(item),
		canDrop: () => !props.disabled,
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		}),
	});

	const onRef = (element) => {
		dropRef(element);
		box.current = element;
	}

	const categoryName = props.category.custom ? props.category.name : f({ prefix: 'category', id: props.category.name });

	return (
		<ListItem
			className={className(
				fromProps(props),
				{
					'selected': props.selected,
					'disabled': props.disabled,
					'droppable': dropState.isOver && dropState.canDrop && canEdit()
				}
			)}
			onClick={onClick}
			customRef={onRef}
		>
			<div>{categoryName}</div>
			<Checkbox disabled={props.disabled} checked={props.selected} inline={true} />
		</ListItem>
	);
}

/**
 * @param {Object} props
 * @param {Array.<number>} props.selectedIds
 * @param {function} props.onChange
 * @param {function} [props.onIntent]
 * @param {boolean} [props.collapsed]
 * @param {boolean} [props.disabled]
 */

function ZoneCategorySelector(props) {
	const { f, fc } = useI18n();
	const [collapsed, setCollapsed] = useState(props.collapsed !== undefined ? props.collapsed : true);
	const selectedIds = props.selectedIds || [];

	useEffect(() => {
		if (props.categories.list == null && !props.categories.pending) {
			props.dispatch(zoneCategories.actions.load.request());
		}
	}, []);

	const onClick = (categoryId) => {
		let at = selectedIds.indexOf(categoryId);
		if (at >= 0) {
			selectedIds.splice(at, 1);
		} else {
			selectedIds.push(categoryId);
		}
		props.onChange(selectedIds.length > 0 ? [...selectedIds] : null);
	}

	let content = null;
	if (props.categories.list != null) {
		content = sortByName(props.categories.list).map(category => (
			<ZCSListItem
				key={category.categoryId}
				category={category}
				selected={selectedIds.indexOf(category.categoryId) >= 0}
				onClick={onClick}
				onIntent={props.onIntent}
				disabled={props.disabled}
			/>
		));
	}

	const trigger = () => {
		setCollapsed(!collapsed);
	}

	const toggleIcon = collapsed ? (<FiPlus />) : (<FiMinus />);
	return (
		<div className={className('zone-category-selector', fromProps(props), { collapsed })}>
			<div className="header">
				<span className="capitalize">{f('tags')}</span>
				<span
					className="clickable"
					onClick={trigger}
					title={collapsed ? fc('expand') : fc('collapse')}
				>
					{toggleIcon}
				</span>
			</div>
			{!collapsed && (
				<List>
					{content}
				</List>
			)}
		</div>
	);
}

export default connect(
	state => ({
		categories: state.categories.zones
	})
)(ZoneCategorySelector);