import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../../i18n';
import Form from '../../../general/form/Form';
import Button from '../../../general/form/Button';
import ZoneCategorySelector from '../ZoneCategorySelector';
import { actions as zonesActions } from '../../../../redux/api/zones';
import ColorPickerControl, { ColorFormat } from '../../../general/color/ColorPickerControl';
import Zone from '../model/Zone'; // eslint-disable-line no-unused-vars

/**
 * @param {Object} props
 * @param {Zone} props.zone
 * @param {function} props.onSubmit
 * @param {boolean} [props.disabled]
 * @param {function} props.onIntent
 */

function ZoneForm(props) {
	const { f } = useI18n();
	const [color, setColor] = useState(props.zone.color());

	const [dirty, setDirty] = useState(0);
	const dirtyRef = useRef(null);
	dirtyRef.current = dirty;

	const [name, setName] = useState(props.zone.name());
	const formSubmitHook = useRef(null);
	const pending = useRef(false);

	const [categoryIds, setCategoryIds] = useState(() => {
		const data = props.zone.zoneData();
		if (data.categoryIds) return [...data.categoryIds];
	});

	const onSubmit = () => {
		if (!props.zone.hasAreas()) return;
		pending.current = true;
		props.zone.flush();
		props.zone.setCategoryIds(categoryIds);
		props.zone.setColor(color);
		if (props.zone.virtual()) {
			props.dispatch(zonesActions.add.request({ data: props.zone.zoneData() }));
		} else {
			props.dispatch(zonesActions.update.request({ id: props.zone.id(), data: props.zone.zoneData() }));
		}
	}

	const submit = () => {
		formSubmitHook.current();
	}

	useEffect(() => {
		const handleChange = () => {
			setDirty(dirtyRef.current+1);
		}
		props.zone.addObserver(props.zone.events.changed, handleChange);
		return () => {
			props.zone.removeObserver(props.zone.events.changed, handleChange);
		}
	}, [])

	useEffect(() => {
		if (!props.zones.pending && pending.current) {
			pending.current = false;
			props.zones.error == null && props.onSuccess(props.zone.zoneData().zoneId);
		}
	}, [props.zones]);

	useEffect(() => {
		props.zones.error != null && props.dispatch(zonesActions.clear());
	}, [props.zone]);

	const onCategoriesChange = (selectedIds) => {
		setCategoryIds(selectedIds);
	}

	const onColorChange = (color) => {
		props.zone.setColor(color);
		setColor(color);
	}

	const onNameChange = (value) => {
		props.zone.setName(value);
		setName(value);
	}

	return (
		<div className="zone-form">
			<Form onSubmit={onSubmit} submitHook={formSubmitHook} disabled={props.disabled}>
				<Form.Control
					controlName="name"
					controlType={Form.Control.Type.Input}
					controlValidator={(value) => !value && f('please enter zone name')}
					label={f('name')}
					value={name}
					onChange={onNameChange}
				/>
				<Form.Control
					controlName="style"
					controlType={ColorPickerControl}
					label={f('zone color')}
					colorFormat={ColorFormat.Hex}
					value={color}
					onChange={onColorChange}
				/>
				<div className="form-control">
					<label>{f('zone tags')}</label>
					<ZoneCategorySelector
						selectedIds={categoryIds}
						onChange={onCategoriesChange}
						onIntent={props.onIntent}
						disabled={props.disabled}
					/>
				</div>
				{props.children}
				<div className="error">
					{props.zones.error}
				</div>
				<Form.ControlGroup>
					<Button onClick={submit} disabled={!props.zone.hasAreas() || props.disabled}>{f('save')}</Button>
				</Form.ControlGroup>
			</Form>
		</div>
	);
}

export default connect(state => {
	return {
		zones: state.zones
	}
})(ZoneForm)
