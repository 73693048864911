import React, { FC, MutableRefObject } from 'react';
import { FormProvider } from 'react-hook-form';
import { FiExternalLink as IconZoneGuards } from 'react-icons/fi';

import {
  ColumnedPageStyled,
  MainColumnStyled,
  SideColumnsStyled,
  SideColumnStyled,
} from 'ui/layout/columned-page.styled';
import { ProcessorFormStyled } from 'ui/pages/downsampled-message-history/processor-form.styled';
import { IDownsampledMessageHistoryProcessor } from 'types/detectors';
import { useProcessorForm } from 'hooks/downsampled-message-history/useProcessorForm';
import { EntitiesActions } from 'components/molecules/entities-actions';
import { GeneralFields } from './general-fields';
import { DevicesSelect } from './devices-select';
import { DetailsFields } from './details-fields';

interface IProcessorFormProps {
  processor: IDownsampledMessageHistoryProcessor | null;
  pending: MutableRefObject<boolean>;
}

export const ProcessorForm: FC<IProcessorFormProps> = (props) => {
  const { actionsProps, formMethods, error, isDisabled, onSubmit } = useProcessorForm(props);

  return (
    <FormProvider {...formMethods}>
      <ProcessorFormStyled onSubmit={onSubmit}>
        <ColumnedPageStyled>
          <MainColumnStyled>
            <EntitiesActions icon={<IconZoneGuards size={20} />} {...actionsProps} />
            <GeneralFields isDisabled={isDisabled} error={error} />
          </MainColumnStyled>
          <SideColumnsStyled>
            <SideColumnStyled>{<DevicesSelect isDisabled={isDisabled} />}</SideColumnStyled>
            <SideColumnStyled>{<DetailsFields isDisabled={isDisabled} />}</SideColumnStyled>
          </SideColumnsStyled>
        </ColumnedPageStyled>
      </ProcessorFormStyled>
    </FormProvider>
  );
};
