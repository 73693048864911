import React, { useRef } from 'react';
import SelectPicker from '../../../general/form/SelectPicker';
import { generateId } from '../../../../misc/misc';
import { MarkerOrderManager } from '../../../general/location/MarkerOrderManager';
import { useI18n } from '../../../../../i18n';

/**
 * @param {Object} props
 * @param {string} props.mapName
 * @param {string} props.value
 * @param {function} props.onChange
 */

function MarkerOrderOption(props) {
	const uid = useRef(generateId());
	const { fc } = useI18n();

	const data = Object.values(MarkerOrderManager.orderOptionMap).map(value => {
		return {
			value: value.key,
			label: fc(value.description)
		};
	});

	return (
		<div className="map-option marker-order" id={uid.current}>
			<SelectPicker
				container={() => { return document.getElementById(uid.current) }}
				label={fc('marker order')}
				data={data}
				searchable={false}
				cleanable={false}
				onChange={props.onChange}
				defaultValue={props.value || MarkerOrderManager.orderOptionMap.latitude.key}
			></SelectPicker>
			<div></div>
		</div>
	);
}

export default MarkerOrderOption;
