import React, { useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { className, fromProps } from '../../../../lib/className';
import { Menu, MenuItem } from "@blueprintjs/core";
import { useI18n } from '../../../../../i18n';
import { ReduxKeyContext } from '../../../../misc/ReduxKeyContext';
import selectionIcon from '../../../../img/icons/selectionmode.png';
import ActionBar from '../../../general/actionbar/ActionBar';
import Popup from '../../../general/Popup';
import { actions } from '../../../../redux/app/pages';
import './multipleSelectionSwitch.scss';

function MultipleSelectionSwitch(props) {
	const { f, fc } = useI18n();
	const [isOpen, setIsOpen] = useState(false);
	const reduxKey = useContext(ReduxKeyContext);
	const stateValue = useSelector(state => state.pages[reduxKey]);
	const dispatch = useDispatch();
	const active = stateValue && stateValue.multiple;
	const title = active ? f('selection') + '...' : f('select devices');

	const multipleSwitch = () => {
		dispatch(actions[reduxKey].setMultiple({ status: !active }));
	}

	const popupClickHandler = (value) => {
		if (!active) {
			value && multipleSwitch(); // HACK?
		} else {
			setIsOpen(value);
		}
	}

	const menu = (
		<Menu>
			<MenuItem onClick={() => dispatch(actions[reduxKey].setSelectionAll())} text={fc('select all')} />
			<MenuItem onClick={() => dispatch(actions[reduxKey].selectionClear())} text={fc('select none')} />
			<MenuItem onClick={() => multipleSwitch()} text={fc('leave selection mode')} />
		</Menu>
	);

	return (
		<ActionBar.Action className={className('multiple-selection', fromProps(props), { active })} title={title}>
			<Popup
				isOpen={isOpen}
				setIsOpen={popupClickHandler}
				className="multiple-selection"
				content = {menu}
			>
				<img src={selectionIcon} alt="" />
			</Popup>
		</ActionBar.Action>
	);
}

export default MultipleSelectionSwitch;
