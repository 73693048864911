import React from 'react';

import { useI18n } from '../../../../i18n';

import Form from '../../general/form/Form';


/**
 * @param {Object} props
 * @param {function} props.onCancel
 * @param {function} porps.onSubmit
 * @param {cx.ods.processors.CommuteDetector} [props.detector]
 */

function FlowProcessorForm(props) {
	const { f } = useI18n();

	return (
		<div>
			<Form.ControlGroup>
				<Form.Control
					controlName="name"
					label={f('name')}
					controlType={Form.Control.Type.Input}
					controlValidator={value => value == null && f('please enter a value')}
					autoFocus
					initialValue={props.processor && props.processor.name}
				/>
			</Form.ControlGroup>
		</div>
	);
}

export default FlowProcessorForm;
