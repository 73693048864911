import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { useI18n } from '../../../../i18n';

import MessageFieldPicker from '../../share/message/MessageFieldPicker';

import Form from '../../general/form/Form';
import GroupCheckbox from '../../general/form/GroupCheckbox';

import './flowProcessorDetails.scss';
import { unitsString } from '../../../misc/attributeTypes';


/**
 * @param {Object} props
 * @param {cx.ods.processors.FlowProcessor} [props.processor]
 * @param {boolean} [props.editMode]
 * @param {boolean} [props.disabled]
 */
function FlowProcessorDetails(props) {
	const METER_UNITS = ['l', 'counter'], COUNTER_UNIT = 'counter';
	const { f, fc } = useI18n();
	const processor = props.processor;
	const [meterId, setMeterId] = useState(null);
	const [meterGeneration, setMeterGeneration] = useState(null);
	const [burstDetection, setBurstDetection] = useState(null);
	const [leakDetection, setLeakDetection] = useState(null);
	let content = null;

	useEffect(() => {
		setMeterId(processor?.meterId || null);
		setMeterGeneration(processor?.counterId != null);
		setBurstDetection(processor?.burstRate != null);
		setLeakDetection(processor?.tolerance != null);
	}, [processor])

	const meterField = meterId && props.messageFields?.[meterId];
	const meterUnit = (() => {
		const mnemonics = meterField && props.attributeTypes?.[meterField.fieldType]?.mnemonics;
		return mnemonics && unitsString(mnemonics);
	})();

	if (props.editMode) {
		content = (<>
			<Form.Control
				controlName="meterId"
				label={f('meter')}
				placeholder={f('select meter attribute')}
				controlType={MessageFieldPicker}
				filter={(field, type) => METER_UNITS.includes(type.mnemonics)}
				controlValidator={(value) => {
					if (value == null) return f('please select meter attribute');
				}}
				value={meterId}
				onChange={setMeterId}
			/>
			<Form.Control
				controlType={GroupCheckbox}
				label={fc('meter generation')}
				title={fc('generate meter based on counter attribute')}
				value={meterGeneration}
				onChange={setMeterGeneration}
			/>
			<Form.ControlGroup disabled={!meterGeneration}>
				<Form.Control
					controlName="counterId"
					label={f('counter')}
					placeholder={f('select counter attribute')}
					controlType={MessageFieldPicker}
					filter={(field, type) => type.mnemonics == COUNTER_UNIT}
					controlValidator={(value) => {
						if (value == null) return f('please select counter attribute');
					}}
					initialValue={processor && processor.counterId}
				/>
				<Form.Control
					controlName="counterScale"
					label={f('counter scale') + (meterUnit ? ', ' + f({ prefix: 'units', id: meterUnit }) : '')}
					controlType={Form.Control.Type.InputNumber}
					controlValidator={(value) => {
						if (value == null || !(0 < value)) return f('please enter a positive value');
					}}
					initialValue={processor && processor.counterScale}
					min={Number.MIN_VALUE}
				/>
				<Form.Control
					controlType={Form.Control.Type.Checkbox}
					controlName="accumulative"
					label={f('accumulate on reset')}
					initialValue={!!processor?.accumulative}
				/>
			</Form.ControlGroup>
			<Form.Control
				controlType={GroupCheckbox}
				label={fc('burst detection')}
				title={fc('fire an event when flow rate is exceptionally high')}
				value={burstDetection}
				onChange={setBurstDetection}
			/>
			<Form.ControlGroup disabled={!burstDetection}>
				<Form.Control
					controlName="burstRate"
					label={f('burst rate') + ', ' + f({ prefix: 'units', id: '1/min' })}
					title={fc('minimum flow rate of a burst')}
					controlType={Form.Control.Type.InputNumber}
					controlValidator={(value) => {
						if (value == null || !(0 < value)) return f('please enter a positive value');
					}}
					initialValue={processor && processor.burstRate}
					min={1}
				/>
			</Form.ControlGroup>
			<Form.Control
				controlType={GroupCheckbox}
				label={fc('leak detection')}
				title={fc('fire an event when flow rate looks like a leak')}
				value={leakDetection}
				onChange={setLeakDetection}
				controlValidator={(enabled, values) => {
					if (enabled && !(values.minimumDuration || values.minimumAmount || values.threshold)) {
						return f('leak detection requires either minimum duration/amount or threshold to be specified');
					}
				}}
			/>
			<Form.ControlGroup disabled={!leakDetection}>
				<Form.Control
					controlName="tolerance"
					label={f('tolerance') + ', ' + f({ prefix: 'units', id: '%' })}
					title={fc('maximum flow rate change at which it is still considered steady')}
					controlType={Form.Control.Type.InputNumber}
					controlValidator={(value) => {
						if (value == null || !(0 <= value && value <= 100)) return f('please enter a value within the range', {min: 0, max: 100});
					}}
					initialValue={processor && processor.tolerance}
					min={0}
				/>
				<Form.Control
					controlName="minimumDuration"
					label={f('minimum duration') + ', ' + f({ prefix: 'units', id: 's' })}
					title={fc('minimum duration of steady flow to be considered as a leak')}
					controlType={Form.Control.Type.InputNumber}
					controlValidator={(value) => {
						if (value != null && !(0 <= value)) return f('please enter a non-negative value');
					}}
					initialValue={processor && processor.minimumDuration}
					min={0}
				/>
				<Form.Control
					controlName="minimumAmount"
					label={f('minimum amount') + (meterUnit ? ', ' + f({ prefix: 'units', id: meterUnit }) : '')}
					title={fc('minimum amount of steady flow to be considered as a leak')}
					controlType={Form.Control.Type.InputNumber}
					controlValidator={(value) => {
						if (value != null && !(0 <= value)) return f('please enter a non-negative value');
					}}
					initialValue={processor && processor.minimumAmount}
					min={0}
				/>
				<Form.Control
					controlName="threshold"
					label={f('threshold') + ', ' + f({ prefix: 'units', id: '1*min' })}
					title={fc('minimum product of the flow duration and amount to be considered as a leak')}
					controlType={Form.Control.Type.InputNumber}
					controlValidator={(value) => {
						if (value != null && !(0 <= value)) return f('please enter a non-negative value');
					}}
					initialValue={processor && processor.threshold}
					min={0}
				/>
			</Form.ControlGroup>
		</>);
	} else {
		if (!processor) return null;

		content = (<>
			<div className="message-field">
				<label>{f('meter')}</label>
				<div>{meterField ? f({ prefix: 'message-field', id: meterField.description }) : `#${meterId}`}</div>
			</div>
			{processor.counterId && <div className="message-field">
				<label>{f('counter')}</label>
				<div>{(() => {
					const field = props.messageFields?.[processor.counterId];
					return field ? f({ prefix: 'message-field', id: field.description }) : `#${processor.counterId}`;
				})()}</div>
			</div>}
			{processor.counterScale && <div>
				<label>{f('counter scale')}</label>
				<div>{processor.counterScale + (meterUnit ? ' ' + f({ prefix: 'units', id: meterUnit }) : '')}</div>
			</div>}
			{processor.counterId && <div>
				<label>{f('accumulate on reset')}</label>
				<div>{fc(processor.accumulative ? 'on' : 'off')}</div>
			</div>}
			{processor.burstRate && <div>
				<label>{f('burst rate')}</label>
				<div>{processor.burstRate + ' ' + f({ prefix: 'units', id: '1/min' })}</div>
			</div>}
			{processor.tolerance && <div>
				<label>{f('tolerance')}</label>
				<div>{processor.tolerance + ' ' + f({ prefix: 'units', id: '%' })}</div>
			</div>}
			{processor.minimumDuration && <div>
				<label>{f('minimum duration')}</label>
				<div>{processor.minimumDuration + ' ' + f({ prefix: 'units', id: 's' })}</div>
			</div>}
			{processor.minimumAmount && <div>
				<label>{f('minimum amount')}</label>
				<div>{processor.minimumAmount + (meterUnit ? ' ' + f({ prefix: 'units', id: meterUnit }) : '')}</div>
			</div>}
			{processor.threshold && <div>
				<label>{f('threshold')}</label>
				<div>{processor.threshold + ' ' + f({ prefix: 'units', id: '1*min' })}</div>
			</div>}
		</>);
	}

	return (
		<div className="flow-processor-details">
			<div className="header">
				<label>{f('details')}</label>
			</div>
			<div className="content">
				{content}
			</div>
		</div>
	);
}

export default connect(state => {
	return {
		messageFields: state.registry.messageFields.typeMap,
		attributeTypes: state.registry.attributeTypes.codeMap
	}
})(FlowProcessorDetails);
