import React, { Dispatch, FC } from 'react';

import { IDownsampledMessageHistoryProcessor } from 'types/detectors';
import { useProcessorListItem } from 'hooks/downsampled-message-history/useProcessorListItem';
import Intent from 'core/misc/ObjectActionIntent';
import { className, fromProps } from 'core/lib/className';
import ListItem from 'core/react/general/list/ListItem';

interface IProcessorListItemProps {
  className: string;
  processor: IDownsampledMessageHistoryProcessor;
  onSelect: Dispatch<IDownsampledMessageHistoryProcessor>;
  onIntent: Dispatch<Intent>;
}

export const ProcessorListItem: FC<IProcessorListItemProps> = (props) => {
  const { id, dropState, dropRef, name, selectProcessor } = useProcessorListItem(props);

  return (
    <ListItem
      customRef={dropRef}
      className={className(fromProps(props), { droppable: dropState.isOver && dropState.canDrop })}
      onClick={selectProcessor}
      id={id}
    >
      {name}
    </ListItem>
  );
};
