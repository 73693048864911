import { actions } from "../actions";

export const modeMap = { CURRENT: 'current', RECENT_TRIPS: 'recentTrip', DEFAULT: 'current' };


const defaultState = {};  // { uid => { mode, parameters, list, pending, error } }

export function reducer(state = defaultState, action) {
	let stateItem;
	switch (action.type) {
		case actions.commute.request.type:
			stateItem = { ...state[action.uid] };
			// if (action.clear) stateItem.list = null;   TODO
			stateItem.mode = action.mode;
			stateItem.parameters = action.parameters;
			stateItem.pending = true;
			stateItem.error = null;
			return {
				...state,
				[action.uid]: stateItem
			};
		case actions.commute.success.type:
			stateItem = { ...state[action.uid] };
			stateItem.list = action.intervals; // TODO
			stateItem.pending = false;
			return {
				...state,
				[action.uid]: stateItem
			};
		case actions.commute.fail.type:
			stateItem = { ...state[action.uid] };
			stateItem.error = action.errorMessage;
			stateItem.pending = false;
			return {
				...state,
				[action.uid]: stateItem
			};
		case actions.commute.cancel.type:
			stateItem = { ...state[action.uid] };
			stateItem.pending = false;
			return {
				...state,
				[action.uid]: stateItem
			};
		case actions.commute.remove.type:
			const copyState = { ...state };
			delete copyState[action.uid];
			return copyState;
		default:
			return state;
	}
}
