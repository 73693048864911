export const IconSize = {
	LG: 'LG', // 32
	MD: 'MD', // 22
	SM: 'SM'  // 16
};

const locale = {};
const assetTypeLG = {};
const assetTypeMD = {};



function importAll (r, type) {
	r.keys().forEach(key => {
		const id = key.replace('./', '').replace('.png', '');
		type[id] = r(key)
	});
}

importAll(require.context('./icons/languages/', false, /\.png$/), locale);
importAll(require.context('./assetTypes/LG/', false, /\.png$/), assetTypeLG);
importAll(require.context('./assetTypes/MD/', false, /\.png$/), assetTypeMD);

export function getLocaleIcon(type) {
	if (type && locale[type]) return locale[type];
	else return locale['blank-language'];
}

export function getAssetTypeIcon(type, size) {
	switch (size) {
		case IconSize.LG:
			return assetTypeLG[type] || assetTypeLG['car'];
		case IconSize.MD:
			return assetTypeMD[type] || assetTypeMD['car'];
		default:
			return assetTypeLG[type] || assetTypeLG['car'];
	}
}
