import React, { useState, useRef, useEffect } from 'react';
import { useI18n } from '../../../../../i18n';
import MapButton from '../MapButton';
import Popup from '../../../general/Popup';
import searchIcon from '../../../../img/icons/16/search.png';
import MapSearchControl from './MapSearchControl';
import { cx } from '../../../../api';
import './mapSearch.scss';

/**
 * @param {Object} props
 * @param {function} props.onReady
 */

function MapSearch(props) {
	const { f } = useI18n();
	const [opened, setOpened] = useState(false);
	const box = useRef(null);
	const buttonBox = useRef(null);

	useEffect(() => {
		const onClick = (event) => {
			const insideBox = cx.dom.i.traverse(box.current, (node) => node == event.target);
			const insideButton = cx.dom.i.traverse(buttonBox.current, (node) => node == event.target);
			if (!insideBox && !insideButton) {
				setOpened(false);
			}
		}
		document.body.addEventListener('click', onClick);
		return () => {
			document.body.removeEventListener('click', onClick);
		}
	}, []);

	const onCancel = () => {
		setOpened(false);
	}

	const content = (
		<MapSearchControl
			customRef={box}
			onReady={props.onReady}
			onCancel={onCancel}
		/>
	);

	return (
		<Popup
			isOpen={opened}
			setIsOpen={(value) => !opened && setOpened(value)}
			className="map-search"
			customRef={buttonBox}
			content={content}
		>
			<MapButton title={f('search for address')}>
				<img src={searchIcon} alt="" />
			</MapButton>
		</Popup>
	)
}

export default MapSearch;
