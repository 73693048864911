import React from 'react';
import ListItem from '../../general/list/ListItem';
import { fromProps } from '../../../lib/className';

/**
 * @param {Object} props
 * @param {cx.ods.zones.ZoneData} props.zone
 * @param {function} props.onClick
 * @param {Object} [props.customRef] - ref object
 */

function ZoneListItem(props) {
	return (
		<ListItem customRef={props.customRef} className={fromProps(props)} onClick={() => props.onClick(props.zone.zoneId)}>
			{props.zone.name}
		</ListItem>
	);
}

export default ZoneListItem;