import { rx, api } from '../../../../api';
import { ofType, combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { map, mergeMap, takeUntil, exhaustMap } from 'rxjs/operators';
import { actions as processorActions } from '../actions';
import { errorMap } from '../../../actions';

const actions = processorActions.flowProcessors;

const addEpic = (action$) => {
	return action$.pipe(
		ofType(actions.add.request.type)
		, mergeMap(action =>
			rx(api.processors.flowProcessor.add, action.data).pipe(
				mergeMap(operation => of(
					actions.add.success({processor: operation.response()})
					, actions.load.request()
				))
				, errorMap(actions.add.fail),
				takeUntil(action$.pipe(ofType(actions.add.cancel.type)))
			)
		)
	)
}

const loadEpic = (action$) => {
	return action$.pipe(
		ofType(actions.load.request.type),
		exhaustMap(action =>
			rx(api.processors.flowProcessor.load).pipe(
				map(operation => actions.load.success({ processors: operation.response() })),
				errorMap(actions.load.fail),
				takeUntil(action$.pipe(ofType(actions.load.cancel.type)))
			)
		)
	)
}

const updateEpic = (action$) => {
	return action$.pipe(
		ofType(actions.update.request.type),
		mergeMap(action =>
			rx(api.processors.flowProcessor.update, action.processorId, action.data).pipe(
				map(operation => actions.load.request()),
				errorMap(actions.update.fail),
				takeUntil(action$.pipe(ofType(actions.update.cancel.type)))
			)
		)
	)
}

const removeEpic = (action$) => {
	return action$.pipe(
		ofType(actions.remove.request.type),
		mergeMap(action =>
			rx(api.processors.flowProcessor.remove, action.processorId).pipe(
				map(operation => actions.load.request()),
				errorMap(actions.remove.fail),
				takeUntil(action$.pipe(ofType(actions.remove.cancel.type)))
			)
		)
	)
}

const epic = combineEpics(addEpic, loadEpic, updateEpic, removeEpic);

export { epic };
