export enum Colors {
  ScubaBlue = '#00adc6',
  AquaBelt = '#79cdd0',
  RossoCorsa = '#d60000',
  MaximumRed = '#d42b22',
  White = '#ffffff',
  LynxWhite = '#f7f7f7',
  PurpleCrystal = '#e5e5ea',
  SuperSilver = '#f0f0f0',
  SilverMedal = '#d6d6d6',
  SilverColor = '#bfbfbf',
}
