import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  FieldErrorStyled,
  FormErrorStyled,
  GeneralFieldsStyled,
} from 'ui/pages/downsampled-message-history/processor-form.styled';
import { useI18n } from 'i18n';
import { IProcessorForm } from 'hooks/downsampled-message-history/useProcessorForm';
import Input from 'core/react/general/form/Input';
import Button from 'core/react/general/form/Button';

interface IGeneralFieldsProps {
  isDisabled: boolean;
  error: string | null;
}

export const GeneralFields: FC<IGeneralFieldsProps> = ({ isDisabled, error }) => {
  const { f, fc } = useI18n();

  const { control, formState } = useFormContext<IProcessorForm>();

  const { errors } = formState;

  //TODO: we should get error form fieldState when react-hook-form is upgraded
  return (
    <GeneralFieldsStyled>
      <Controller
        control={control}
        name="name"
        rules={{ required: fc('please enter a value') }}
        render={({ value, onChange }) => (
          <div>
            <Input autoFocus label={f('name')} disabled={isDisabled} value={value} onChange={onChange} />
            {/*TODO: error messages should be moved to corresponding form components when they are being refactored*/}
            {!!errors.name?.message && <FieldErrorStyled>{errors.name.message}</FieldErrorStyled>}
          </div>
        )}
      />
      {!!error && <FormErrorStyled>{error}</FormErrorStyled>}
      <Button type="submit" disabled={isDisabled}>
        {fc('save')}
      </Button>
    </GeneralFieldsStyled>
  );
};
