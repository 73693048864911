import React, { useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { useI18n } from '../../../i18n';
import Loader from '../general/Loader';
import { connect } from 'react-redux';
import { actions as zonesActions } from '../../redux/api/zones';
import { AllRoutes } from 'constants/routes';
import ZoneEditor from '../custom/zones/editor/ZoneEditor';
import ZoneViewer from '../custom/zones/viewer/ZoneViewer';
import PagePanel from '../general/panel/PagePanel';
import { setPageTitle } from '../../misc/page';
import './zonesPage.scss';

const PAGE_TITLE = 'zones';

function ZonesPage(props) {
	const { fc } = useI18n();
	const history = useHistory();

	const zoneId = props.match.params.id;
	const edit = props.match.params[0] == 'edit';
	const create = props.match.params[0] == 'create';
	const areaEdit = props.match.params[1] == 'edit';
	let areaAt = null;
	if (props.match.params['areaAt'] != null) {
		const value = +props.match.params['areaAt'];
		if (!isNaN(value)) {
			areaAt = value;
		} else {
			history.replace(AllRoutes.Zones);
		}
	}

	useEffect(() => {
		setPageTitle(fc(PAGE_TITLE));
		if (props.zones.map == null && !props.zones.pending) {
			props.dispatch(zonesActions.load.request());
		}
	}, []);

	useEffect(() => {
		if (props.zones.map && !props.zones.map[zoneId]) {
			history.replace(AllRoutes.Zones);
		}
	}, [zoneId, props.zones.map]);

	let content = null;
	if (props.zones.map != null) {
		content = zoneId && edit || !zoneId && create
			? <ZoneEditor action={props.match.params[0]} areaAt={areaAt} areaEdit={areaEdit} />
			: <ZoneViewer />
		;
	} else if (props.zones.pending) {
		content = <div className="vh-center"><Loader size={Loader.Size.LG} /></div>;
	}

	return (
		<div className="app-page zones">
			<PagePanel>
				{content}
			</PagePanel>
		</div>
	)
}

ZonesPage.Url = AllRoutes.Zones;

export default connect(state => {
	return {
		zones: state.zones
	}
})(withRouter(ZonesPage));
