import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useI18n } from '../../../../i18n';
import { actions as categoriesActions } from '../../../redux/api/categories';
import List from '../../general/list/List';
import ListItem from '../../general/list/ListItem';
import Checkbox from '../../general/form/Checkbox';
import Input from '../../general/form/Input';
import { className } from '../../../lib/className';
import { useDrop } from 'react-dnd';
import { cx } from '../../../api';
import { DragItemType } from '../../share/dnd/DragItemType';
import { actions as contextActions } from '../../../redux/app/context';
import ActionAdd from '../../share/actionbar/ActionAdd';
import './employeeCategoryList.scss';

/**
 * @param {Object} props
 * @param {cx.ods.categories.CategoryData} props.category
 * @param {boolean} [props.selected]
 * @param {function} props.onClick
 */
function EmployeeCategoryItem(props) {
	const boxRef = useRef();
	const dispatch = useDispatch();

	const onClick = (event) => {
		props.onClick(props.category.categoryId);
	}

	const onDrop = useCallback((item) => {
		if (props.category.custom) {
			const offset = cx.dom.at.offset(boxRef.current);
			offset.left = boxRef.current.offsetWidth + offset.left;
			dispatch(contextActions.actionSet({
				actionType: item.type == DragItemType.ACTION_EDIT ? 'edit' : 'remove',
				name: 'category',
				data: {
					title: "tag",
					offsetTop: offset.top,
					offsetLeft: offset.left,
					id: props.category.categoryId,
					elementHeight: boxRef.current.clientHeight
				}
			}));
		}
	}, []);

	const canDrop = useCallback(() => {
		return props.category.custom;
	}, [props.category]);

	const [dropState, dropRef] = useDrop({
		accept: [DragItemType.ACTION_REMOVE, DragItemType.ACTION_EDIT],
		canDrop,
		drop: onDrop,
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		})
	});

	const handleRef = (element) => {
		boxRef.current = element;
		dropRef(element);
	}

	return (
		<ListItem
			customRef={handleRef}
			onClick={onClick}
			className={className({ 'selected': props.selected, 'droppable':  dropState.isOver && dropState.canDrop, 'canDrop': dropState.canDrop })}
		>
			{props.category.name}
			<Checkbox checked={props.selected} inline={true} />
		</ListItem>
	);
}

/**
 * @param {Object} props
 * @param {boolean} props.disabled
 * @param {Array.<string || number>} props.selectedIds
 * @param {function} props.onChange
 */

function EmployeeCategoryList(props) {
	const [filter, setFilter] = useState('');
	const labelBoxRef = useRef();
	const { f } = useI18n();
	let content = null;

	useEffect(() => {
		if (props.categories.map == null) props.dispatch(categoriesActions.load.request());
	}, []);

	useEffect(() => { // ratify after remove category
		if (props.categories.map && props.selectedIds.length > 0) {
			let shouldReChange = false;
			const ratifiedIds = props.selectedIds.filter(id => {
				const has = Boolean(props.categories.map[id]);
				if (!shouldReChange && !has) shouldReChange = !has;
				return has;
			});
			if (shouldReChange) props.onChange(ratifiedIds);
		}
	}, [props.categories.map, props.selectedIds]);

	if (props.categories.map != null) {
		const onClick = (categoryId) => {
			const newSelected = [...props.selectedIds];
			const at = newSelected.indexOf(categoryId);
			if (at >= 0) newSelected.splice(at, 1);
			else newSelected.push(categoryId);
			props.onChange(newSelected);
		}

		const getCategories = () => {
			if (filter) {
				return props.categories.list.filter(category => category.name.includes(filter));
			}
			return props.categories.list;
		}
		const items = getCategories().map(category => <EmployeeCategoryItem
			key={category.categoryId}
			onClick={onClick}
			category={category}
			selected={props.selectedIds.includes(category.categoryId)}
		/>);
		content = (items.length > 0 ? <List>{items}</List> : <span className="no-items capitalize">{props.emptyText || f('no tags assigned')}</span>);
	}

	const onAddTag = () => {
		const offset = cx.dom.at.offset(labelBoxRef.current);
		offset.left = labelBoxRef.current.offsetWidth + offset.left;
		props.dispatch(contextActions.actionSet({
			actionType: 'edit',
			name: 'category',
			data: {
				comprisingId: props.categories.root.categoryId,
				offsetTop: offset.top,
				offsetLeft: offset.left,
				title: "tag",
				elementHeight: labelBoxRef.current.clientHeight
			}
		}));
	}

	return (
		<div className="employee-category-list">
			<div className="label" ref={labelBoxRef}>
				<label><span className="capitalize">{f('tags')}</span></label>
				<ActionAdd onClick={onAddTag} title={f('add tag')} />
			</div>
			<div className="filter">
				<Input placeholder={f('filter')} value={filter} onChange={setFilter} cleanable />
			</div>
			{content}
		</div>
	);
}

export default connect(state => ({ categories: state.categories.employees }))(EmployeeCategoryList);
