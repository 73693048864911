import React from 'react';
import { localStorage as storage } from '../app/storage';

const useLocalStorage = (key) => {
	const [state, setState] = React.useState(storage.get(key));
	React.useEffect(() => {
		storage.set(key, state);
	}, [state]);
	React.useEffect(() => {
		setState(storage.get(key, state));
	}, [key]);
	return [state, setState];
};

export default useLocalStorage;