export const shallowEqual = (left, right) => {
	if (left === right) return true;
	const length = left.length;
	if (length != right.length) return false;
	for (let at = 0; at < length; ++at) {
		if (left[at] !== right[at]) return false;
	}
	return true;
};

export const valuesEqual = (left, right) => {
	if (left === right) return true;
	if (left.length != right.length) return false;
	return left.every(item => right.includes(item));
};
