import React from 'react';
import { Spinner } from '@blueprintjs/core';
import { className, fromProps } from '../../lib/className';

/**
 * @enum {number}
 */
const Size = {
	XS: 16,
	SM: 18,
	MD: 36,
	LG: 64
}

/**
 * @param {Object} props
 * @param {Size} [props.size]
 * For other props - see blueprintjs/core Spinner.
 */

function Loader(props) {
	const { size, ...rest } = props;
	return (
		<Spinner
			className={className(fromProps(props), 'loader')}
			size={size || Size.MD}
			{...rest}
		/>
	)
}

Loader.Size = Size;

export default Loader;