import React, { useRef, useState, useEffect } from 'react';
import { useI18n } from '../../../../../../i18n';
import Form from '../../../../general/form/Form';
import ButtonGroup from '../../../../general/form/ButtonGroup';
import Button from '../../../../general/form/Button';

/**
 * @param {Object} props
 * @param {function} props.onApply
 * @param {Array.<number>} [props.lonLat]
 * @param {boolean} [props.disabled]
 * @param {number} [props.precision]
 * @param {React.RefObject} [props.lonRef]
 * @param {React.RefObject} [props.latRef]
 */

function LonLatForm(props) {
	const { f } = useI18n();
	const submitHook = useRef(null);
	const [lon, setLon] = useState(null); // string
	const [lat, setLat] = useState(null); // string
	const creating = props.lonLat == null;

	const canApply = () => {
		return creating && lon != null && lat != null
			|| !creating && (lon != this.lonLat[0] || lat != this.lonLat[1])
		;
	}

	const parseLonLat = (string) => {
		let lon = null, lat = null;
		const matches = string.match(/(-?\d+(\.\d+)?)/g);
		if (matches.length == 2) {
			let left = +matches[0];
			let right = +matches[1];
			if (left >= -180.0 && left <= 180.0 && right >= -90.0 && right <= 90.0) {
				lon = left;
				lat = right;
			} else if (right >= -180.0 && right <= 180.0 && left >= -90.0 && left <= 90.0) {
				lon = right;
				lat = left;
			}
		}
		return lon != null && lat != null ? [lon, lat] : null;
	}

	useEffect(() => {
		let lat = props.lonLat && props.lonLat[0] || null;
		let lon = props.lonLat && props.lonLat[1] || null;
		if (lat != null && lon != null && props.precision != null) {
			lat = lat.toFixed(props.precision);
			lon = lon.toFixed(props.precision);
		}
		setLon(lat);
		setLat(lon);
	}, [(props.lonLat != null ? props.lonLat[0] : null), (props.point != null ? props.lonLat[1] : null)]);

	useEffect(() => {
		let lonLat = null
		if (lon != null) lonLat = parseLonLat(lon);
		if (lonLat == null && lat != null) lonLat = parseLonLat(lat);
		if (lonLat != null) {
			setLon(lonLat[0].toString());
			setLat(lonLat[1].toString());
		}
	}, [lon, lat]);

	let submitSource = null;

	const onSubmit = () => {
		props.onApply([+lon, +lat], submitSource);
		submitSource = null;
	}

	const submit = () => {
		submitSource = 'button';
		submitHook.current();
	}

	return (
		<div className="point-form">
			<Form
				onSubmit={onSubmit}
				submitHook={submitHook}
				disabled={props.disabled}
				footer={
					<ButtonGroup>
						<Button
							onClick={submit}
							disabled={!canApply || props.disabled}
						>
							{f('apply')}
						</Button>
					</ButtonGroup>
				}
			>
				<Form.Control
					controlName="longitude"
					controlType={Form.Control.Type.Input}
					controlValidator={(value) => !value && f('please enter a value')}
					label={f('longitude')}
					value={lon}
					onChange={(value) => setLon(value)}
					inputRef={(element) => props.lonRef && (props.lonRef.current = element)}
				/>
				<Form.Control
					controlName="latitude"
					controlType={Form.Control.Type.Input}
					controlValidator={(value) => !value && f('please enter a value')}
					label={f('latitude')}
					value={lat}
					onChange={(value) => setLat(value)}
					inputRef={(element) => props.latRef && (props.latRef.current = element)}
				/>
			</Form>
		</div>
	)
}

export default LonLatForm;