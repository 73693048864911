import React, { useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useI18n } from '../../../../i18n';
import { DragItemType } from '../dnd/DragItemType';
import editIcon from '../../../img/icons/edit.png';
import ActionBar from '../../general/actionbar/ActionBar';
import { fromProps, className } from '../../../lib/className';

/**
 * @param {Object} props
 * @param {string} [props.title]
 * @param {boolean} [props.disabled]
 */

function ActionEdit(props) {
	const { f } = useI18n();

	const [{ isDragging }, dragRef, dragPreview] = useDrag({
		item: { type: DragItemType.ACTION_EDIT },
		canDrag: () => !props.disabled,
		collect: monitor => ({
			isDragging: monitor.isDragging()
		})
	});

	useEffect(() => {
		dragPreview(getEmptyImage(), { captureDraggingState: true });
	}, []);

	return (
		<ActionBar.Action
			className={className('drag', 'edit', fromProps(props), { 'draggable': isDragging })}
			customRef={dragRef}
			disabled={props.disabled}
			title={props.title || f('edit')}
		>
			<img src={editIcon} alt="" />
		</ActionBar.Action>
	)
}

export default ActionEdit;