import React, { useState, useEffect } from 'react';
import { InputGroup } from 'rsuite';
import { useI18n } from '../../../../i18n';
import InputNumber from '../../general/form/InputNumber';
import Button from '../../general/form/Button';
import DeviceInputVoltageSelector from './DeviceInputVoltageSelector';
import './editableLevelFields.scss';

/**
 * @param {Object} params
 * @param {cx.ods.processors.VoltageLevel} [props.voltageLevel] { voltage, level }
 * @param {number} props.index
 * @param {function} props.onEdit
 * @param {function} props.onSave
 * @param {function} props.onCancel
 * @param {boolean} [props.disabled]
 * @param {boolean} [props.removable]
 * @param {Array.<string>} [props.uris]
 */

function EditableLevelFields(props) {
	const { f } = useI18n();
	const [voltage, setVoltage] = useState('');
	const [level, setLevel] = useState('');

	useEffect(() => {
		setVoltage(props.voltageLevel && props.voltageLevel.voltage != undefined ? props.voltageLevel.voltage : '');
		setLevel(props.voltageLevel && props.voltageLevel.level != undefined ? props.voltageLevel.level : '');
	}, [props.voltageLevel]);

	const onSave = () => {
		const _voltageLevel = { ...props.voltageLevel };
		_voltageLevel.voltage = voltage;
		_voltageLevel.level = level;
		props.onSave(_voltageLevel, props.index);
	}

	const onRemove = () => {
		props.onRemove(props.voltageLevel, props.index);
	}

	const onEdit = () => {
		props.onEdit(props.voltageLevel, props.index);
	}

	return (
		<div className="editable-level-fields">
			{props.voltageLevel && <>
				<div>
					<InputGroup>
						<InputNumber
							value={voltage}
							onChange={setVoltage}
							min={0}
							autoFocus
						/>
						{props.uris
							&& props.uris.length > 0
							&& <InputGroup.Addon className="addon">
								<DeviceInputVoltageSelector uris={props.uris} onSelect={setVoltage} />
							</InputGroup.Addon>
						}
					</InputGroup>
					<InputNumber
						value={level}
						onChange={setLevel}
						max={100} min={0}
					/>
				</div>
			</>}
			{props.index != null
				&& !props.voltageLevel
				&& <div>
					<Button onClick={onEdit}>{f('edit')}</Button>
					{props.removable && <Button onClick={onRemove}>{f('delete')}</Button>}
				</div>
			}
			<div>
				{props.voltageLevel
					?
					<>
						<Button onClick={onSave} disabled={props.disabled || (voltage == undefined || voltage.trim && !voltage.trim() || level == undefined || level.trim && !level.trim())}>{f('save')}</Button>
						<Button onClick={props.onCancel} disabled={props.disabled}>{f('cancel')}</Button>
					</>
					: <Button onClick={props.onAdd}>{f('add')}</Button>
				}
			</div>
		</div>
	);
}

export default EditableLevelFields;