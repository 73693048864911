import React from 'react';
import { Radio as RsRadio } from 'rsuite';
import { className, fromProps } from '../../../lib/className';

/**
 * For other parameters - see rsuite Radio component
 * @param {Object} props
 */

function Radio(props) {
	return (
		<div className={className('radio', fromProps(props))}>
			<RsRadio {...props} />
		</div>
	);
}

export default Radio;