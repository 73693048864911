import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../../i18n';
import { meta } from '../../../../redux/api/meta';
import SelectPicker from '../../../general/form/SelectPicker';
import Loader from '../../../general/Loader';
import { className, fromProps } from '../../../../lib/className';

/**
 * Control for selecting cx.ods.meta.Datatype.
 * @param {Object} props
 * @param {string} props.value
 * @param {function} props.onChange
 * @param {boolean} props.disabled
 */

function PropertyTypePicker(props) {
	const { f, fc } = useI18n();
	let content = null;

	useEffect(() => {
		if (props.datatypes.map == null && !props.datatypes.pending) {
			props.dispatch(meta.datatypes.actions.datatypes.request());
		}
	}, []);

	if (props.datatypes.map != null) {
		const items = Object.values(props.datatypes.map)
			.filter(datatype => datatype.mnemonics != 'bundle' && datatype.mnemonics != 'vector') // TODO vector places here temporarily
			.map(datatype => ({
				value: datatype.datatypeId,
				label: fc(datatype.name)
			}))
		;
		content = <SelectPicker
			data={items}
			searchable={false}
			cleanable={false}
			value={props.value}
			onChange={props.onChange}
			disabled={props.disabled}
			placeholder={f('select type')}
			label={props.label}
		/>;
	} else {
		content = <Loader />
	}

	return (
		<div className={className('datatypes-picker', fromProps(props))}>
			{content}
		</div>
	);
}

export default connect(state => {
	return {
		datatypes: state.meta.datatypes
	}
})(PropertyTypePicker);