import React, { Dispatch, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useI18n } from 'i18n';
import { hideGroup, hideAll } from 'assets/icons';
import { Colors } from 'ui/constants/colors';
import { SvgWrapper } from 'components/atoms/svg-wrapper';
import { categoriesVisibilitySelector } from 'core/redux/selectors/categories';
import { actions } from 'core/redux/app/categories/visibility';

import './devices-visibility-button.scss';

interface IDevicesVisibilityButtonProps {
  setHiddenCategories: Dispatch<number[]>;
}

export const DevicesVisibilityButton: FC<IDevicesVisibilityButtonProps> = ({ setHiddenCategories }) => {
  const { fc } = useI18n();

  const dispatch = useDispatch();

  const { currentCategoriesIds, hiddenCategoriesIds } = useSelector(categoriesVisibilitySelector);

  const isCategoriesLoaded = !!currentCategoriesIds && !!hiddenCategoriesIds;

  const isAllHiddenHidden =
    !!currentCategoriesIds &&
    !!hiddenCategoriesIds &&
    [...currentCategoriesIds, 0].length === hiddenCategoriesIds.length;

  const isSomeHidden =
    !!currentCategoriesIds &&
    !!hiddenCategoriesIds?.length &&
    [...currentCategoriesIds, 0].length !== hiddenCategoriesIds.length;

  const showAllMarkers = () => {
    setHiddenCategories([]);
    dispatch(actions.setAllCategoriesVisible());
  };

  const hideAllMarkers = () => {
    if (currentCategoriesIds) {
      setHiddenCategories([...currentCategoriesIds, 0]);
      dispatch(actions.setAllCategoriesHidden());
    }
  };

  const handleMarkersVisibility = () => {
    // if (isSomeHidden) {
    //   return hideAllMarkers();
    // }
    isAllHiddenHidden ? showAllMarkers() : hideAllMarkers();
  };

  return (
    <div
      className="devices-visibility-button-wrapper"
      style={{
        ...(!isCategoriesLoaded ? { pointerEvents: 'none' } : undefined),
      }}
      onClick={handleMarkersVisibility}
    >
      <span
        className="visibility-icon"
        title={fc(isAllHiddenHidden ? 'show all markers' : 'hide all markers')}
        style={{
          ...(isAllHiddenHidden ? { color: Colors.ScubaBlue } : undefined),
        }}
      >
        {isSomeHidden ? (
          <SvgWrapper width={18.88} height={18.88} details={hideAll} />
        ) : (
          <SvgWrapper width={16} height={16} details={hideGroup} />
        )}
      </span>
    </div>
  );
};
