import React, { FC, useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { useI18n } from 'i18n';
import { COMMUTE_DETECTOR_DEFAULT_VALUES, ICommuteDetectorFormData } from 'core/react/pages/CommuteDetectorsPage';
import Input from 'core/react/general/form/Input';
import GroupCheckbox from 'core/react/general/form/GroupCheckbox';
import InputNumber from 'core/react/general/form/InputNumber';
import Checkbox from 'core/react/general/form/Checkbox';
import { ICommuteDetector } from 'types/detectors';
import { DEFAULT_SET_VALUE_PROPS } from 'constants/form';

import './commuteDetectorFields.scss';

interface ICommuteDetectorFieldsProps {
  commuteDetector: ICommuteDetector | null;
  isDisabled?: boolean;
}

export const CommuteDetectorFields: FC<ICommuteDetectorFieldsProps> = ({ commuteDetector, isDisabled }) => {
  const { f, fc } = useI18n();
  const { control, setValue, reset, register, unregister, formState } = useFormContext<ICommuteDetectorFormData>();
  const { errors } = formState;

  const isTapDebounce = useWatch({ control, name: 'tapDebounce' });
  const isDetectorReset = useWatch({ control, name: 'detectorReset' });

  const timeoutRules = { required: f('please enter a positive value') };

  const handleTimeoutChange = (field: 'tapDebounce' | 'detectorReset') => (value: boolean) => {
    const isTapDebounce = field === 'tapDebounce';
    const defaultTimeout = isTapDebounce ? '30' : '180';
    const timeoutField = isTapDebounce ? 'debounceTimeout' : 'resetTimeout';

    setValue(field, value, DEFAULT_SET_VALUE_PROPS);
    value ? register(timeoutField, timeoutRules) : unregister(timeoutField);
    setValue(timeoutField, value ? defaultTimeout : '', DEFAULT_SET_VALUE_PROPS);
  };

  const resetCommuteDetector = (commuteDetector: ICommuteDetector) => {
    const { name, debounceTimeout, resetTimeout, onTapIn, onTapOut } = commuteDetector;

    reset({
      name,
      tapDebounce: !!debounceTimeout,
      debounceTimeout: debounceTimeout ? String(debounceTimeout) : '',
      detectorReset: !!resetTimeout,
      resetTimeout: resetTimeout ? String(resetTimeout) : '',
      onTapIn,
      onTapOut,
    });
  };

  useEffect(() => {
    !!commuteDetector && resetCommuteDetector(commuteDetector);
  }, [commuteDetector]);

  useEffect(() => {
    return () => {
      reset(COMMUTE_DETECTOR_DEFAULT_VALUES);
    };
  }, []);

  //TODO: we should get error form fieldState when react-hook-form is upgraded
  return (
    <div className="presence-detector-fields">
      <Controller
        control={control}
        name="name"
        rules={{ required: f('please enter a value') }}
        render={({ value, onChange }) => (
          <div>
            <Input autoFocus label={f('name')} disabled={isDisabled} value={value} onChange={onChange} />
            {/*TODO: error messages should be moved to corresponding form components when they are being refactored*/}
            {!!errors.name?.message && <div className="error">{errors.name.message}</div>}
          </div>
        )}
      />
      <Controller
        control={control}
        name="tapDebounce"
        render={({ value }) => (
          <GroupCheckbox
            label={fc('ID tap debounce')}
            title={fc('ignore repeated ID taps within specified interval')}
            disabled={isDisabled}
            value={value}
            onChange={handleTimeoutChange('tapDebounce')}
          />
        )}
      />
      <Controller
        control={control}
        name="debounceTimeout"
        rules={{ ...(isTapDebounce && timeoutRules) }}
        render={({ value, onChange }) => (
          <div>
            <InputNumber
              label={`${f('interval')}, ${f({ prefix: 'units', id: 's' })}`}
              min={1}
              title={fc('debounce interval')}
              value={value}
              disabled={!isTapDebounce || isDisabled}
              onChange={onChange}
            />
            {!!errors.debounceTimeout?.message && <div className="error">{errors.debounceTimeout.message}</div>}
          </div>
        )}
      />
      <Controller
        control={control}
        name="detectorReset"
        render={({ value }) => (
          <GroupCheckbox
            label={fc('detector reset')}
            title={fc('reset detector after specified period of device being idle')}
            disabled={isDisabled}
            value={value}
            onChange={handleTimeoutChange('detectorReset')}
          />
        )}
      />
      <Controller
        control={control}
        name="resetTimeout"
        rules={{ ...(isDetectorReset && timeoutRules) }}
        render={({ value, onChange }) => (
          <div>
            <InputNumber
              label={`${f('timeout')}, ${f({ prefix: 'units', id: 's' })}`}
              min={1}
              title={fc('reset timeout')}
              value={value}
              disabled={!isDetectorReset || isDisabled}
              onChange={onChange}
            />
            {!!errors.resetTimeout?.message && <div className="error">{errors.resetTimeout.message}</div>}
          </div>
        )}
      />
      <Controller
        control={control}
        name="onTapIn"
        render={({ value, onChange }) => (
          <Checkbox
            className="tap-in-checkbox"
            label={f('on tap in')}
            title={fc('fire an event when commuter taps in')}
            disabled={isDisabled}
            value={value}
            onChange={onChange}
          />
        )}
      />
      <Controller
        control={control}
        name="onTapOut"
        render={({ value, onChange }) => (
          <Checkbox
            label={f('on tap out')}
            title={fc('fire an event when commuter taps out')}
            disabled={isDisabled}
            value={value}
            onChange={onChange}
          />
        )}
      />
    </div>
  );
};
