import React, { Dispatch, FC } from 'react';

import {
  ProcessorsFilterStyled,
  ProcessorsListStyled,
} from 'ui/pages/downsampled-message-history/processors-list.styled';
import { useI18n } from 'i18n';
import { IDownsampledMessageHistoryProcessor } from 'types/detectors';
import { className } from 'core/lib/className';
import Intent from 'core/misc/ObjectActionIntent';
import Input from 'core/react/general/form/Input';
import List from 'core/react/general/list/List';
import { ProcessorListItem } from './processors-list-item';

interface IProcessorsListList {
  selectedId: number | null;
  filter: string;
  processors: IDownsampledMessageHistoryProcessor[];
  setFilter: Dispatch<string>;
  onSelect: Dispatch<IDownsampledMessageHistoryProcessor>;
  onIntent: Dispatch<Intent>;
}

export const ProcessorsList: FC<IProcessorsListList> = (props) => {
  const { selectedId, filter, processors, setFilter, onSelect, onIntent } = props;

  const { f, fc } = useI18n();

  return (
    <>
      <ProcessorsFilterStyled>
        <Input label={f('filter')} value={filter} onChange={setFilter} cleanable />
      </ProcessorsFilterStyled>
      <ProcessorsListStyled>
        <label>{fc('downsampled message history processors')}</label>
        {!!processors?.length && (
          <List>
            {processors.map((processor) => (
              <ProcessorListItem
                className={className({ selected: selectedId && selectedId === processor.processorId })}
                key={processor.processorId}
                processor={processor}
                onSelect={onSelect}
                onIntent={onIntent}
              />
            ))}
          </List>
        )}
      </ProcessorsListStyled>
    </>
  );
};
