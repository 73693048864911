
const IntentAction = {
	Add: 'add',
	Edit: 'edit',
	Remove: 'remove'
}

class ObjectActionIntent {

	constructor(subject, action, object, data) {
		this._subject = subject;
		this._action = action;
		this._object = object;
		this._data = data;
	}

	subject() {
		return this._subject;
	}

	action() {
		return this._action;
	}

	object() {
		return this._object;
	}

	data() {
		return this._data;
	}
}

ObjectActionIntent.Action = IntentAction;

export default ObjectActionIntent;