import React from 'react';
import { useI18n } from '../../../../i18n';
import { className, fromProps } from '../../../lib/className';
import Checkbox from '../../general/form/Checkbox';
import ListItem from '../../general/list/ListItem';

/**
 * @param {Object} props
 * @param {boolean} [props.allchecked]
 * @param {boolean} [props.checked]
 * @param {function} [props.onCheck]
 * @param {boolean} [props.withStatus]
 */

function EmployeeHeaderListItem(props) {
	const { fc } = useI18n();
	const checkable = Boolean(props.checked != undefined && props.onCheck != undefined);

	const onCheck = () => {
		props.onCheck(!props.checked);
	}

	return (
		<ListItem className={className(fromProps(props), { checkable, 'withStatus': props.withStatus })}>
			{checkable && <div onClick={onCheck}>
				<Checkbox checked={props.checked} indeterminate={props.checked ? !props.allchecked : null} />
			</div>}
			<div>{fc('designation')}</div>
			<div>{fc('external id')}</div>
			<div>{fc('RFID')}</div>
			{props.withStatus && <div>{fc('status')}</div>}
		</ListItem>
	);
}

export default EmployeeHeaderListItem;
