import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Input from '../../general/form/Input';
import { actions as processorActions } from '../../../redux/api/processors';
import { useI18n } from '../../../../i18n';
import FuelProcessorListItem from './FuelProcessorListItem';
import { className } from '../../../lib/className';
import List from '../../general/list/List';
import { sortByName } from '../../../lib/sorting';
import './fuelProcessorList.scss';

const actions = processorActions.fuelLevel;

/**
 * @param {Object} props
 * @param {number} props.selectedId
 * @param {function} props.onSelect
 * @param {function} props.onIntent
 */

function FuelProcessorList(props) {
	const { f } = useI18n();
	const [filter, setFilter] = useState('');
	const processors = props.processors ? props.processors.list : [];

	useEffect(() => {
		if (processors == null) {
			props.dispatch(actions.load.request());
		}
	}, []);

	const getProcessors = () => {
		return filter
			? processors.filter(processor => processor.name.toLowerCase().includes(filter.toLowerCase()))
			: processors
		;
	}

	let content = null;
	if (processors != null) {
		const items = sortByName(getProcessors()).map(processor =>
			<FuelProcessorListItem
				className={className({ 'selected': (props.selectedId && props.selectedId == processor.processorId) })}
				key={processor.processorId}
				processor={processor}
				onSelect={props.onSelect}
				onIntent={props.onIntent}
			/>
		);
		content = (<List>{items}</List>);
	}

	return (<>
		<div className="filter">
			<Input label={f('filter')} value={filter} onChange={setFilter} cleanable />
		</div>
		<div className="fuel-processor-list">
			<label>
				{f('fuel level processors')}
			</label>
			{content}
		</div>
	</>);
}

export default connect(
	state => ({
		processors: state.processors.fuelLevel
	})
)(FuelProcessorList);
