import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { FiExternalLink as PageIcon } from 'react-icons/fi';

import { cx } from '../../api';

import { useI18n } from '../../../i18n';
import { AllRoutes } from 'constants/routes';
import Intent from '../../misc/ObjectActionIntent';
import { setPageTitle } from '../../misc/page';

import ActionAdd from '../share/actionbar/ActionAdd';
import ActionBack from '../share/actionbar/ActionBack';
import DefaultActionBar from '../share/actionbar/DefaultActionBar';

import Button from '../general/form/Button';
import Form from '../general/form/Form';
import Loader from '../general/Loader';
import PagePanel from '../general/panel/PagePanel';
import RemoveDialog from '../general/RemoveDialog';
import SidebarLayout from '../general/layout/SidebarLayout';
import ObjectActionDialogPopup from '../general/ObjectActionDialogPopup';

import FlowProcessorCard from '../custom/flowProcessors/FlowProcessorCard';
import FlowProcessorForm from '../custom/flowProcessors/FlowProcessorForm';
import FlowProcessorList from '../custom/flowProcessors/FlowProcessorList';
import FlowProcessorView from '../custom/flowProcessors/FlowProcessorView';

import { actions as processorActions } from '../../redux/api/processors';

import './flowProcessorsPage.scss';

const actions = processorActions.flowProcessors;

const PAGE_TITLE = 'flow processors';

/**
 * @param {Object} props
 */


function FlowProcessorsPage(props) {
	const { f, fc } = useI18n();
	const history = useHistory();
	const form = useRef(null);
	const submitHook = useRef(null);
	const pending = useRef(false);
	const [intent, setIntent] = useState(null);
	const processorId = props.match.params.id;
	const processor = props.processors.map?.[processorId];
	const modifying = props.match.params[0] == 'edit' || props.match.params[0] == 'create';
	const [error, setError] = useState(null);
	const disabled = props.processors.pending;

	useEffect(() => {
		setPageTitle(fc(PAGE_TITLE));
		if (props.processors.list == null) props.dispatch(actions.load.request());
		return () => {
			if (props.processors.error) props.dispatch(actions.clear());
		}
	}, []);

	useEffect(() => {
		if (props.processors.error) props.dispatch(actions.clear());
	}, [intent]);

	useEffect(() => {
		if (!modifying && form.current) form.current.purge();
	}, [processorId]);

	useEffect(() => {
		if (props.processors.error) props.dispatch(actions.clear());
		onPopupClose();
	}, [props.match]);

	useEffect(() => {
		if (!props.processors.pending && pending.current) {
			pending.current = false;
			if (props.processors.error == null) onBack();
		}
	}, [props.processors.pending]);

	useEffect(() => {
		if (processorId && props.processors.map) {
			if (!props.processors.map[processorId]) history.replace(AllRoutes.FlowProcessors);
		}
		if (intent && props.processors.map) {
			if (!props.processors.map[intent.object().processorId]) onPopupClose();
		}
	}, [processorId, props.processors.map, intent]);

	const submit = () => {
		submitHook.current();
	};

	const clearError = () => {
		if (error) setError();
	};

	const onSubmit = data => {
		const processing = data.counterId != null || data.burstRate != null || data.tolerance != null;
		if (!processing) {
			setError(f('please specify at least one kind of the flow processing'));
			return;
		}
		clearError();
		if (!processor) props.dispatch(actions.add.request({ data }));
		else props.dispatch(actions.update.request({ processorId: processor.processorId, data }));
		pending.current = true;
	};

	const onPopupClose = () => {
		intent && setIntent(null);
	}

	const onAdd = () => {
		history.push(`${AllRoutes.FlowProcessors}/create`);
	}

	const onBack = () => {
		history.goBack();
	}

	const onSelect = (processor) => {
		history.push(`${AllRoutes.FlowProcessors}/${processor.processorId}`);
	}

	const onIntent = (intent) => {
		if (intent.action() == Intent.Action.Remove) {
			setIntent(intent);
		} else if (intent.action() == Intent.Action.Edit) {
			history.push(`${AllRoutes.FlowProcessors}/${intent.object().processorId}/edit`);
		}
	}

	const onRemove = (flowProcessor) => {
		props.dispatch(actions.remove.request({ processorId: flowProcessor.processorId }));
	}

	const sidebarContent = (<>
		<DefaultActionBar
			prependActions={modifying
				? <ActionBack onClick={onBack} />
				: <ActionAdd onClick={onAdd} />
			}
			disabled={disabled}
		/>
		{modifying
			? <div className="content">
				<FlowProcessorForm processor={processor} />
				<div className="error">
					{error || props.processors.error}
				</div>
				<Button onClick={submit} disabled={disabled}>
					{f('save')}
				</Button>
			</div>
			: <>
				<FlowProcessorList
					selectedId={processor ? processor.processorId : null}
					onSelect={onSelect}
					onIntent={onIntent}
				/>
				{processor && <FlowProcessorCard processor={processor} />}
			</>
		}
	</>);

	let content = null;
	if (props.processors.list != null) {
		let excludeUris = null;
		props.processors.list.forEach(_processor => {
			if (!(processor && _processor.processorId == processor.processorId)) {
				excludeUris = excludeUris ? excludeUris.concat(_processor.uris) : _processor.uris;
			}
		});

		content = (
			<Form
				onSubmit={onSubmit} onChange={clearError}
				submitHook={submitHook}
				objectType={cx.ods.processors.FlowProcessor}
				disabled={disabled}
				customRef={form}
			>
				<SidebarLayout
					className="body"
					icon={<PageIcon size="20" />}
					title={modifying ? f('flow processor') : f('flow processors')}
					sidebarContent={sidebarContent}
				>
					<FlowProcessorView
						excludeUris={excludeUris}
						processor={processor}
						editMode={modifying}
						disabled={disabled}
					/>
					{intent && intent.action() == Intent.Action.Remove && (
						<ObjectActionDialogPopup
							offset={intent.data().offset}
							onClose={onPopupClose}
							title={f('delete flow processor')}
							disabled={disabled}
							error={props.processors.error}
						>
							<RemoveDialog
								object={intent.object()}
								title={f('flow processor')}
								text={f('are you sure you want to delete flow processor', { name: intent.object().name })}
								onSubmit={onRemove}
								onCancel={onPopupClose}
							/>
						</ObjectActionDialogPopup>
					)}
				</SidebarLayout>
			</Form>
		);
	} else if (props.processors.pending) {
		content = <div className="vh-center"><Loader size={Loader.Size.LG} /></div>;
	}

	return (
		<div className="app-page flow-processors">
			<PagePanel>{content}</PagePanel>
		</div>
	);
}

FlowProcessorsPage.Url = AllRoutes.FlowProcessors;

export default connect(
	state => ({
		processors: state.processors.flowProcessors
	})
)(FlowProcessorsPage);
