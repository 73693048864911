import React from 'react';
import { SelectPicker as RsSelectPicker } from 'rsuite';

import { useI18n } from '../../../../i18n';

import { className, fromProps } from '../../../lib/className';

import { capitalize } from '../../../misc/misc';

import Loader from '../Loader';

import './xPicker.scss';

/**
 * For other parameters - see rsuite SelectPicker component
 * @param {Object} props
 * @param {string} [props.label]
 * @param {Array} [props.data]
 * @param {boolean} [props.searchable]
 * @param {boolean} [props.disabled]
 * @param {boolean} [props.cleanable]
 * @param {string | number} [props.value]
 * @param {Function} [props.onChange]
 */

function SelectPicker(props) {
	const { f } = useI18n();
	let { label, placeholder, loading, dispatch, ..._props } = props;
	const _placeholder = placeholder || f('select');
	return (
		<div className={className('x-picker', 'select-picker', {'loading': loading}, fromProps(props))}>
			{label && <label>{label}</label>}
			{loading 
				? <Loader size={Loader.Size.XS}/>
				: <RsSelectPicker
					block
					virtualized={false}
					placeholder={capitalize(_placeholder)}
					renderMenu={(menu) => <div className={className('x-picker', 'select-picker', 'menu', fromProps(props))}>{menu}</div>}
					{..._props}
				/>
			}
		</div>
	)
}

export default SelectPicker;
