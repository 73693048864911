import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from '../../../general/Loader';
import { className } from '../../../../lib/className';
import { useI18n } from '../../../../../i18n';
import ReportHeader from './ReportHeader';
import ReportSummary from './ReportSummary';
import { actions } from '../../../../redux/api/reports';
import ScrollList from '../../../general/list/ScrollList';
import DatetimeField from '../../../general/datetime/DatetimeField';
import { actions as zonesActions } from '../../../../redux/api/zones';
import { formatter } from '../../../../misc/datetime';
import { scrollReportItemIntoView } from '../../../../misc/misc';
import SwitchMapMenuItem from './SwitchMapMenuItem';
import ZoneSelectMap from '../../zones/viewer/ZoneSelectMap';
import NoDataView from '../../../general/view/NoDataView';
import './zoneVisits.scss';

function HeaderListItem(props) {
	const { esg } = props;
	const { f } = useI18n();
	return (
		<>
			<div className="header-item"><span className="capitalize">{f('item No.')}</span></div>
			<div className="header-item"><span className="capitalize">{f('device')}</span></div>
			<div className="header-item"><span className="capitalize">{f('zone')}</span></div>
			<div className="header-item"><span className="capitalize">{f('entry time')}</span></div>
			<div className="header-item"><span className="capitalize">{f('exit time')}</span></div>
			<div className="header-item"><span className="capitalize">{f('entry day of week')}</span></div>
			<div className="header-item"><span className="capitalize">{f('duration')}</span></div>
			{esg && (<>
				<div className="header-item"><span className="capitalize">{f('ESG entry')}</span></div>
				<div className="header-item"><span className="capitalize">{f('ESG exit')}</span></div>
			</>)}

		</>
	);
}

function ReportListItem(props) {
	const { zoneVisit, index, device, zone, esg, selected, onClick, customRef } = props;
	const _onClick = () => {
		onClick(zoneVisit, index);
	}

	const _className = className('clickable', { selected });
	return (
		<>
			<div ref={customRef} className={_className} onClick={_onClick}>{index}</div>
			<div className={_className} onClick={_onClick}>{device && device.denomination()}</div>
			<div className={_className} onClick={_onClick}>
				{zone ? zone.name : ('#' + zoneVisit.zoneId + ' (deleted)')}
			</div>
			<div className={_className} onClick={_onClick}>{zoneVisit.enteredAt && <DatetimeField datetime={zoneVisit.enteredAt} />}</div>
			<div className={_className} onClick={_onClick}>{zoneVisit.leftAt && <DatetimeField datetime={zoneVisit.leftAt} now={zoneVisit.enteredAt} />}</div>
			<div className={_className} onClick={_onClick}>{zoneVisit.enteredAt && formatter.dayOfWeek(zoneVisit.enteredAt)}</div>
			<div className={_className} onClick={_onClick}>{zoneVisit.enteredAt && zoneVisit.leftAt && formatter.duration(zoneVisit.enteredAt, zoneVisit.leftAt)}</div>
			{esg && (<>
				<div className={_className} onClick={_onClick}>{zoneVisit.esgEntryStatus}</div>
				<div className={_className} onClick={_onClick}>{zoneVisit.esgExitStatus}</div>
			</>)}


		</>
	);
}

function EodListItem() {
	const { f } = useI18n();
	return <div className="indicator"><span className="capitalize">{f('you have reached the end')}</span></div>;
}

function ZoneVisits(props) {
	const { f } = useI18n();
	const selectedItemBox = useRef();
	const [selected, setSelected] = useState(); // { zoneVisit, index }
	const [displayMap, setDisplayMap] = useState(false);
	const esg = props.state.parameters.esg;

	useLayoutEffect(() => {
		if (selectedItemBox.current) {
			scrollReportItemIntoView(selectedItemBox.current);
		}
	}, [selected, displayMap]);

	const loadNext = () => {
		const parameters = props.state.parameters;
		props.dispatch(actions.zoneVisits.request({ parameters }));
	}

	const onItemClick = (zoneVisit, index) => {
		setSelected({ zoneVisit, index });
		if (!displayMap) setDisplayMap(true);
	}

	const onMapSwitch = () => {
		setDisplayMap(!displayMap);
	}

	useEffect(() => {
		if (props.zoneMap == null) {
			props.dispatch(zonesActions.load.request());
		}
	}, []);

	let content = null;
	let mapBox = null;
	let canExport = false;
	if (props.state.list != null) {
		if (!props.state.list || props.state.list.length == 0) {
			content = <NoDataView message={f('no data for such criteria')} />;
		} else {
			canExport = true;
			const items = props.state.list.map((zoneVisit, at) => {
				const index = at + 1;
				const isSelected = selected && (selected.index == index);
				return (
					<ReportListItem
						key={at}
						zone={props.zoneMap && props.zoneMap[zoneVisit.zoneId]}
						device={props.deviceMap && props.deviceMap[zoneVisit.uri]}
						onClick={onItemClick}
						selected={isSelected}
						customRef={isSelected ? selectedItemBox : undefined}
						zoneVisit={zoneVisit}
						esg={esg}
						index={index}
					/>
				)
			});
			items.unshift(<HeaderListItem key={1000000} esg={esg} />);
			content = <ScrollList
				className={className({ esg })}
				next={loadNext}
				pending={props.state.pending}
				isEod={!props.state.hasMore}
				eodItem={EodListItem}
			>
				{items}
			</ScrollList>;
			if (displayMap) {
				mapBox = (
					<ZoneSelectMap
						noDrop
						selectedOnly={true}
						selectedIds={selected ? [selected.zoneVisit.zoneId] : []}
					/>
				);
			}
		}
	} else {
		content = (
			<div className="center">
				{
					props.state.error
						? <span className="error">{props.state.error}</span>
						: <Loader size={Loader.Size.MD} />
				}
			</div>
		);
	}

	const exportReport = () => {
		props.dispatch(actions.zoneVisits.export());
		props.history.goBack();
	}

	const timeRange = props.state.parameters.timeRange;
	return (
		<div className="zove-visits report">
			<ReportHeader
				title={f('zone visits report')}
				canExport={canExport}
				onExport={exportReport}
				items={<SwitchMapMenuItem disabled={!canExport} active={displayMap} onClick={onMapSwitch} />}
			/>
			<ReportSummary
				since={timeRange.since}
				until={timeRange.until}
				generatedAt={props.state.at}
			/>
			<div className={className("content", { 'with-map': displayMap })}>
				{content}
				{mapBox}
			</div>
		</div>
	);
}

export default withRouter(connect(state => {
	return {
		state: state.reports.zoneVisits,
		deviceMap: state.devices.map,
		zoneMap: state.zones.map
	}
})(ZoneVisits));
