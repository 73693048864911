import React from 'react';
import { capitalize } from '../../../misc/misc';
import { className, fromProps } from '../../../lib/className';
import './navItem.scss';

/**
 * @param {Object} props
 * @param {function} [props.onClick]
 * @param {boolean} [props.disabled]
 * @param {string} [props.title]
 */

function NavItem(props) {

	const onClick = () => {
		if (!props.disabled && props.onClick) {
			props.onClick();
		};
	}

	return (
		<li className={className('nav-item', fromProps(props))}>
			<div
				title={props.disabled ? null : capitalize(props.title)}
				onClick={onClick}
				className={className({
					'disabled': props.disabled,
					'clickable': !props.disabled && !!props.onClick
				})}
			>
				{props.children}
			</div>
		</li>
	);
}

export default NavItem;