import React from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../i18n';
import { cx } from '../../../api';
import PropertyInfo from './PropertyInfo';
import { className, fromProps } from '../../../lib/className';
import './devicePropertyCard.scss';

/**
 * @param {Object} props
 * @param {cx.ods.meta.Property} props.property
 */

function Element(props) {
	const { f } = useI18n();

	const isComposite = (property) => {
		return cx.o.typeOf(property, cx.ods.meta.PropertyRecord)
			|| cx.o.typeOf(property, cx.ods.meta.PropertyVector)
			|| cx.o.typeOf(property, cx.ods.meta.PropertyBundle)
		;
	}

	const composite = isComposite(props.property);
	const datatypeName = props.datatypes.map
		&& props.datatypes.map[props.property.datatypeId]
		&& f(props.datatypes.map[props.property.datatypeId].name)
	;

	return (
		<div key={props.property.propertyId} className={className('element', { 'composite': composite })}>
			<div className="name">{(props.property.label || props.property.name) + (composite ? ' (' + datatypeName + ')' : '')}</div>
			{props.children}
		</div>
	);
}

const PropertyElement = connect(state => ({
	datatypes: state.meta.datatypes
}))(Element);

/**
 * @param {Object} props
 * @param {cx.ods.meta.Property} props.property
 */

function PropertyElements(props) {
	const { f } = useI18n();

	const getProperty = (element) => {
		let property = element;
		if (cx.o.typeOf(element, cx.ods.meta.BundleElement)) {
			property = props.properties.map[element.elementId];
		}
		return property;
	}

	const getMembers = (property) => {
		const members = property.element
			? [property.element]
			: (
				property.elements
					? property.elements
					: null
			)
		if (members) {
			const items = members.map(element => {
				const property = getProperty(element);
				return (
					<PropertyElement key={property.propertyId} property={property}>
						{getMembers(property)}
					</PropertyElement>
				);
			});
			return (<div className="elements">{items}</div>);
		} else {
			return null;
		}
	}

	let content = null;
	if (props.property.element || props.property.elements) {
		content = (
			<div className="property-elements">
				<div className="title">{f('members')}</div>
				{getMembers(props.property)}
			</div>
		);
	}

	return (content);
}

const DevicePropertyElements = connect(state => ({
	properties: state.meta.properties
}))(PropertyElements);

/**
 * @param {Object} props
 * @param {cx.ods.meta.Property} props.property
 */

function DevicePropertyCard(props) {
	const property = props.property;

	return (
		<div className={className('device-property-card', fromProps(props))}>
			<PropertyInfo property={props.property} detailed />
			<DevicePropertyElements property={property} />
		</div>
	)
}

export default DevicePropertyCard;