import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../../i18n';
import { cx } from '../../../../api';
import { meta } from '../../../../redux/api/meta';
import Button from '../../../general/form/Button';
import PropertyForm from '../../../share/properties/meta/PropertyForm';
import DeviceCategoriesControl from '../../../share/devices/DeviceCategoriesControl';
import { className, fromProps } from '../../../../lib/className';
import './deviceXEditor.scss';

/**
 * @param {Object} props
 * @param {cx.ods.meta.PropertyBundle} [props.bundle]
 * @param {boolean} [props.nested=false] indicates whether bundle is nested into another
 */

function DeviceBundleEditor(props) {
	const { f } = useI18n();
	const bundle = useRef(null);
	const submitHook = useRef(null);
	const [serial, setSerial] = useState(0);
	const pendingProperty = useRef(null);
	const disabled = props.properties.pending;

	const creating = () => {
		return props.bundle == null;
	}

	useEffect(() => {
		if (props.datatypes.mnemonicsMap != null) {
			if (creating()) {
				bundle.current = new cx.ods.meta.PropertyBundle();
				bundle.current.datatypeId = props.datatypes.mnemonicsMap['bundle'].datatypeId;
			} else {
				bundle.current = props.bundle;
			}
			setSerial(serial+1);
		}
	}, [props.datatypes, props.bundle]);

	// ------------------------------------------------

	const onCancel = () => {
		props.onCancel();
	}

	const onSubmit = (property) => {
		pendingProperty.current = property;
		if (props.bundle != null) {
			props.dispatch(meta.properties.actions.update.request({ propertyId: props.bundle.propertyId, property }));
		} else {
			props.dispatch(meta.properties.actions.add.request({ property }));
		}
	}

	useEffect(() => {
		if (pendingProperty.current && !props.properties.pending) {
			if (props.properties.error == null) {
				props.onReady();
				pendingProperty.current = null;
			}
		}
	}, [props.properties.pending]);

	const submit = () => {
		if (submitHook.current != null) submitHook.current();
	}

	let form = null;
	if (bundle.current != null) {
		form = <PropertyForm
			property={bundle.current}
			onSubmit={onSubmit}
			submitHook={submitHook}
			disabled={disabled}
			nested={props.nested}
		>
			<DeviceCategoriesControl
				categoryIds={bundle.current ? bundle.current.categoryIds : null}
			/>
		</PropertyForm>;
	}

	return (
		<div className={className('device-x-editor', 'device-bundle-editor', fromProps(props))}>
			{!props.hideHeader && <div className="header">{f((creating() ? 'create' : 'edit') + ' template')}</div>}
			<div className="content">
				{form}
			</div>
			<div className="error">
				{props.properties.error}
			</div>
			<div className="footer">
				<Button
					onClick={onCancel}
					disabled={disabled}
				>
					{f('cancel')}
				</Button>
				<Button
					onClick={submit}
					disabled={disabled}
					loading={props.properties.pending}
				>
					{f('save')}
				</Button>
			</div>
		</div>
	);
}

export default connect(state => ({
	datatypes: state.meta.datatypes,
	properties: state.meta.properties
}))(DeviceBundleEditor);
