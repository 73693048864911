import React, { FC, MutableRefObject } from 'react';
import { Controller } from 'react-hook-form';

import { useI18n } from 'i18n';
import { useMqttClientForm } from 'hooks/mqttClients/useMqttClientForm';
import Button from 'core/react/general/form/Button';
import Input from 'core/react//general/form/Input';

import './mqttClientForm.scss';

interface IMqttClientFormProps {
  isPending: MutableRefObject<boolean>;
}

export const MqttClientForm: FC<IMqttClientFormProps> = ({ isPending }) => {
  const { f, fc } = useI18n();

  const { isLoading, errors, formError, control, onSubmit } = useMqttClientForm(isPending);

  //TODO: we should get error form fieldState when react-hook-form is upgraded
  return (
    <form className="mqtt-client-form" onSubmit={onSubmit}>
      <div className="mqtt-client-form--field">
        <Controller
          control={control}
          name="description"
          defaultValue=""
          rules={{ required: fc('please enter a value') }}
          render={({ value, onChange }) => (
            <div>
              <Input autoFocus label={fc('description')} disabled={isLoading} value={value} onChange={onChange} />
              {/*TODO: error messages should be moved to corresponding form components when they are being refactored*/}
              {!!errors.description?.message && (
                <div className="mqtt-client-form--error">{errors.description.message}</div>
              )}
            </div>
          )}
        />
      </div>
      {!!formError && <div className="mqtt-client-form--error">{formError}</div>}
      <Button type="submit" disabled={isLoading}>
        {f('save')}
      </Button>
    </form>
  );
};
