import React from 'react';
import './mapControls.scss';

function MapControls(props) {
	return (
		<div className="map-controls">
			{props.children}
		</div>
	);
}

export default MapControls;