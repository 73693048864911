import React, { useState, useEffect, useRef } from 'react';
import { useI18n } from '../../../../../i18n';
import MapButton from '../MapButton';
import Popup from '../../../general/Popup';
import { FiMenu } from 'react-icons/fi';
import { cx } from '../../../../api';
import ActionClose from '../../../share/actionbar/ActionClose';
import './mapOptions.scss';

function MapOptions(props) {
	const { f } = useI18n();
	const [opened, setOpened] = useState(false);
	const box = useRef(null);
	const buttonBox = useRef(null);

	useEffect(() => {
		const onClick = (event) => {
			const insideBox = cx.dom.i.traverse(box.current, (node) => node == event.target);
			const insideButton = cx.dom.i.traverse(buttonBox.current, (node) => node == event.target);
			if (!insideBox && !insideButton) {
				setOpened(false);
			}
		}
		document.body.addEventListener('click', onClick);
		return () => {
			document.body.removeEventListener('click', onClick);
		}
	}, []);

	return (
		<Popup
			isOpen={opened}
			setIsOpen={(value) => !opened && setOpened(value)}
			className="map-options"
			customRef={buttonBox}
			usePortal
			content={(
				<div className="map-options-content" ref={box}>
					<div className="header">
						<div className="title">
							{f('map options')}
						</div>
						<ActionClose onClick={() => setOpened(false)} />
					</div>
					{props.children}
				</div>
			)}
		>
			<MapButton title={f('map options')}><FiMenu /></MapButton>
		</Popup>
	)
}

export default MapOptions;
