import React, { useRef } from 'react';
import { Popover, Position } from '@blueprintjs/core';
import { Menu, MenuItem } from "@blueprintjs/core";
import { useI18n } from '../../../../../i18n';
import { cx } from '../../../../api';
import { className, fromProps } from '../../../../lib/className';
import Intent from '../../../../misc/ObjectActionIntent';
import ActionBar from '../../../general/actionbar/ActionBar';
import icon from '../../../../img/icons/plus-bound.png';
import '../actionAdd.scss';

/**
 * @param {Object} props
 * @param {function} props.onIntent
 * @param {boolean} [props.disabled]
 */

function ActionAdd(props) {
	const { f, fc } = useI18n();
	const box = useRef(null);

	const onTagAdd = () => {
		const offset = cx.dom.at.offset(box.current);
		props.onIntent(new Intent('category', Intent.Action.Add, null, { offset }));
	}

	const onAreaAdd = () => {
		props.onIntent(new Intent('feature', Intent.Action.Add));
	}

	const menu = (
		<Menu>
			<MenuItem onClick={onTagAdd} text={fc('create tag')} />
			<MenuItem onClick={onAreaAdd} text={fc('create area')} />
		</Menu>
	);

	return (
		<ActionBar.Action
			customRef={box}
			className={className('add', fromProps(props))}
			disabled={props.disabled}
			title={f('create')}
		>
			<Popover
				usePortal={false}
				position={Position.BOTTOM_LEFT}
				content = {menu}
				captureDismiss={true}
				minimal={true}
				popoverClassName={'action-add-popover'}
				modifiers={{ preventOverflow: { enabled: false } }}
				disabled={props.disabled}
			>
				<div className="wrap">
					<img src={icon} alt="" />
				</div>
			</Popover>
		</ActionBar.Action>
	);
}

export default ActionAdd;