import React, { useState, useEffect } from 'react';
import { useI18n } from '../../../../i18n';
import Form from '../../general/form/Form';
import Checkbox from '../../general/form/Checkbox';
import InputNumber from '../../general/form/InputNumber';
import './fuelProcessorFields.scss';

/**
 * @param {Object} props
 * @param {cx.ods.processors.FuelLevelChangeParameters} [props.value]
 * @param {function} [props.onChange]
 * @param {string} [props.label]
 */

function ParameterControl(props) {
	const { f } = useI18n();
	const [open, setOpen] = useState(!!props.value);
	let content = null;

	useEffect(() => {
		if (!!props.value != open) {
			setOpen(!!props.value);
		}
	}, [props.value]);

	const onChangeOpen = (value) => {
		if (value) {
			props.onChange({
				sensitivity: props.value && props.value.sensitivity ? props.value.sensitivity : '',
				period: props.value && props.value.period ? props.value.period : ''
			});
			setOpen(true);
		} else {
			props.onChange(null);
			setOpen(false);
		}
	}

	const setSensitivity = (value) => {
		const copyValue = { ...props.value };
		copyValue.sensitivity = value;
		props.onChange(copyValue);
	}

	const setPeriod = (value) => {
		const copyValue = { ...props.value };
		copyValue.period = value;
		props.onChange(copyValue);
	}

	if (props.value) {
		content = (
			<div className="input-fields">
				<div className="parameter-input">
					<InputNumber
						label={f('sensitivity')}
						min={0} max={100}
						value={props.value.sensitivity}
						onChange={setSensitivity}
					/>
					<div className="unit">{f({ prefix: 'units', id: '%'})}</div>
				</div>
				<div className="parameter-input">
					<InputNumber
						label={f('period')}
						min={0}
						value={props.value.period}
						onChange={setPeriod}
					/>
					<div className="unit">{f({ prefix: 'units', id: 's'})}</div>
				</div>
			</div>
		);
	}

	return (
		<div className="fuel-level-change-parameter">
			<div className="toggle-group-header">
				<Checkbox label={props.label} value={open} onChange={onChangeOpen} />
			</div>
			{open && content}
		</div>
	);
}

/**
 * @param {Object} props
 * @param {cx.ods.processors.FuelLevelProcessor} props.processor
 */

function FuelProcessorFields(props) {
	const { f } = useI18n();
	return (
		<div className="fuel-processor-fields">
			<Form.Control
				controlType={Form.Control.Type.Input}
				controlValidator={(value) => value == null && f('please enter a value')}
				controlName="name"
				autoFocus
				label={f('name')}
				initialValue={props.processor && props.processor.name}
			/>
			<Form.Control
				controlType={Form.Control.Type.Checkbox}
				controlName="ignitionOn"
				label={f('only when ignition on')}
				initialValue={!!(props.processor && props.processor.ignitionOn)}
			/>
			<Form.ControlGroup>
				<Form.Control
					controlType={ParameterControl}
					controlValidator={(value) => {
						return value != null && Object.values(value).some(v => !v || v.trim && !v.trim()) && f('please enter a value');
					}}
					controlName="riseParameters"
					label={f('on rise')}
					initialValue={props.processor && props.processor.riseParameters}
				/>
				<Form.Control
					controlType={ParameterControl}
					controlValidator={(value) => {
						return value != null && Object.values(value).some(v => !v || v.trim && !v.trim()) && f('please enter a value');
					}}
					controlName="dropParameters"
					label={f('on drop')}
					initialValue={props.processor && props.processor.dropParameters}
				/>
				<div className="description">
					{f('alert-fuel-level-change')}
				</div>
			</Form.ControlGroup>
		</div>
	);
}

export default FuelProcessorFields;