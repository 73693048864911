import React, { useRef, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';

import HistoryMarker from './HistoryMapMarker';
import { connect } from 'react-redux';
import tinycolor from 'tinycolor2';
import { LineString, Point } from 'ol/geom';
import { Style, Stroke, Circle, Fill, Text } from 'ol/style';
import GeometryType from 'ol/geom/GeometryType';
import { Feature } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import LayerGroup from 'ol/layer/Group';
import { getDistance } from 'ol/sphere';
import { toEPSG4326 } from 'ol/proj/epsg3857';
import { className } from '../../../../lib/className';
import { Map } from '../../../general/location/Map';
import * as deviceTrace from '../../../../redux/app/deviceTrace';
import css from '../../../../defaults.scss';
import useLocalStorage from '../../../../misc/useLocalStorage';
import { setProps } from '../../../../misc/ol';
import { readMultiPolygon } from '../../../../misc/wkt';
import { makeStyle, StyleType } from '../../zones/zoneStyle';
import { actions as zonesActions } from '../../../../redux/api/zones';
import { ReduxKeyContext } from '../../../../misc/ReduxKeyContext';
import EventMapMarker from '../../map/EventMapMarker';

const DEFAULT_MAP_NAME = 'history';
const LAYER_TYPES = { trace: 'trace', zone: 'zone' };

const inRange = (message, since, until) => {
	return since <= message.generatedAt && message.generatedAt <= until;
}

const equals = (left, right) => {
	return left.length == right.length &&
		left.every(leftItem => right.find(rightItem => rightItem.message.isEqual(leftItem.message)))
	;
}

/**
 * @param {Object} props
 * @param {function} props.onMessageClick
 * @param {string} [props.name]
 * @param {React.Component} [props.control]
 * @param {React.Ref} [props.customRef]
 */

function HistoryMap(props) {
	const { uris } = useParams();
	const map = useRef(null);
	const layers = useRef({});
	const reduxKey = useContext(ReduxKeyContext);
	const traceLayerGroup = useRef(null);
	const zoneLayerGroup = useRef(null);
	const mapName = props.name || DEFAULT_MAP_NAME;
	const [displayZones, setDisplayZones] = useLocalStorage(mapName + '_display_zones');
	const [defaultView, setDefaultView] = useLocalStorage(`${mapName}_default_view`);
	const prevState = useRef(null);
	const fromClick = useRef(false);

	const getTraceStyles = (color) => {
		return (feature) => {
			const geometryType = feature.getGeometry().getType();
			const coordinates = feature.getGeometry().getCoordinates();
			const styles = [
				new Style({
					stroke: new Stroke({
						color: css.traceStrokeColor1,
						width: css.traceStrokeWidth1
					}),
					zIndex: 2
				}),
				new Style({
					stroke: new Stroke({
						color: color || css.traceStrokeColor2,
						width: css.traceStrokeWidth2
					}),
					zIndex: 3
				})
			];
			switch (geometryType) {
				case GeometryType.POINT:
					styles.push(
						new Style({
							image: new Circle({
								radius: css.tracePointRadius,
								fill: new Fill({ color: css.tracePointFillColor }),
								stroke: new Stroke({
									color: css.traceStrokeColor1,
									width: css.tracePointStrokeWidth
								})
							}),
							geometry: () => {
								return new Point(coordinates);
							}
						})
					);
					break;
				case GeometryType.LINE_STRING:
					if (!props.markers) {
						let lastXY = null, lastAt = 0;
						const resolution = map.current.getOlMap().getView().getResolution();
						if (resolution < 5 && props.displayTraceDirection) {
							const ratio = 4.77 / map.current.getOlMap().getView().getResolution();
							const baseDistance = 300 / ratio;
							coordinates.forEach((xy, at) => {
								if (!lastXY) {
									lastXY = xy;
								} else {
									const distance = getDistance(toEPSG4326(lastXY), toEPSG4326(xy));
									if (distance > baseDistance) {
										const anchorXY = coordinates[lastAt+1];
										if (anchorXY) {
											const dx = anchorXY[0] - lastXY[0];
											const dy = anchorXY[1] - lastXY[1];
											const rotation = Math.atan2(dy, dx);
											const tcolor = tinycolor(color).greyscale();
											styles.push(
												new Style({
													geometry: new Point(lastXY),
													text: new Text({
														scale: 1.5,
														text: "➤",
														offsetY: 1,
														rotation: -rotation,
														fill: new Fill({ color:
															tcolor.isLight()
																? tcolor.darken(70).toHexString()
																: tcolor.lighten(70).toHexString()
														}),
														placement: ''
													}),
													zIndex: 4
												})
											);
										}
										lastXY = xy; lastAt = at;
									}
								}
							});
						}
					}
					break;
			}
			return styles;
		}
	}

	const getTraceColor = (uri) => {
		const color = props.traceColorMap[uri];
		if (!color) props.dispatch(deviceTrace.actions.setTraceColor({ domain: reduxKey, uri }));
		return color;
	}

	const updateTraceStyle = () => {
		Object.values(layers.current).forEach(layer => {
			const uri = layer.getProperties().uri;
			layer.setStyle(getTraceStyles(getTraceColor(uri)))
		});
	}

	const onClick = (event) => {
		if (event.pointerEvent.target.classList.contains("event-map-marker")) return;
		if (props.scopeMap && props.selectedTraceUris) {
			const clickPoint = map.current.getOlMap().getCoordinateFromPixel(event.pixel);
			const clickFeatures = map.current.getOlMap().getFeaturesAtPixel(event.pixel, {
				layerFilter: (layer) => layer.getProperties().type === LAYER_TYPES.trace
			});
			const closestPoint = clickFeatures[0] && clickFeatures[0].getGeometry().getClosestPoint(clickPoint);
			if (closestPoint) {
				const uri = clickFeatures[0].getProperties().uri;
				let wantedMessage = null, minDistance = Infinity;
				const scopes = props.scopeMap[uri];
				if (scopes) {
					scopes.forEach(scope => {
						if (scope.messages) {
							scope.messages.forEach(message => {
								const distance = message.distanceToPoint(closestPoint, true);
								if (distance < minDistance) {
									minDistance = distance;
									wantedMessage = message;
								}
							});
						}
					});
				}
				fromClick.current = true;
				props.onMessageClick(wantedMessage);
			}
		}
	}


	const focusDefaultView = () => {
		if (defaultView && map.current) {
			map.current.getOlMap().getView().animate({ center: defaultView.center, zoom: defaultView.zoom, duration: 0 });
		}
	}

	const saveMapView = () => {
		if (map.current) {
			const zoom = map.current.getOlMap().getView().getZoom();
			const center = map.current.getOlMap().getView().getCenter();

			setDefaultView({ zoom, center });
		}
	}

	useEffect(() => {
		if (props.customRef) props.customRef.current = map.current;
		if (props.zones.map == null) {
			props.dispatch(zonesActions.load.request());
		}
		map.current.updateSize();
		const onPointerMove = (event) => {
			const olMap = map.current.getOlMap();
			const pixel = olMap.getEventPixel(event);
			if (olMap.hasFeatureAtPixel(pixel)) {
				map.current.getDomBox().style.cursor = 'pointer';
			} else {
				map.current.getDomBox().style.cursor = '';
			}
		}
		map.current.getDomBox().addEventListener('pointermove', onPointerMove);
		return () => {
			map.current.getDomBox().removeEventListener('pointermove', onPointerMove);
		}
	}, []);

	useEffect(() => {
		updateTraceStyle();
	}, [props.traceColorMap, props.displayTraceDirection]);

	useEffect(() => {
		if (props.scopeMap && props.selectedTraceUris) {
			props.selectedTraceUris.forEach(uri => {
				const scopes = props.scopeMap[uri];
				const features = [];
				const addFeature = (coordinates) => {
					const geometry = new LineString(coordinates);
					const feature = new Feature({ geometry });
					feature.setProperties({ uri, type: LAYER_TYPES.trace });
					features.push(feature);
				}
				if (scopes) {
					scopes.forEach(scope => {
						if (scope.messages) {
							let coordinates = [];
							scope.messages.forEach((message, at) => {
								if (!message.hasLonLat() || !inRange(message, props.since, props.until)) return;
								coordinates.push(message.flatCoords());
								const nextMessage = scope.messages[at + 1];
								if (!nextMessage || nextMessage.isNearby(message)) return;
								addFeature(coordinates);
								coordinates = [];
							});
							if (coordinates.length > 0) addFeature(coordinates);
						}
					});
					const layer = new VectorLayer({
						source: new VectorSource({ features })
					});
					layer.setProperties({ uri, type: LAYER_TYPES.trace });
					layers.current[uri] = layer;
				}
			});
			traceLayerGroup.current = new LayerGroup({
				layers: Object.values(layers.current)
			});
			map.current.getOlMap().addLayer(traceLayerGroup.current);
			updateTraceStyle();
			traceLayerGroup.current.setVisible(props.displayTrace);
		}
		map.current.getOlMap().on('click', onClick);
		return () => {
			map.current.getOlMap().un('click', onClick);
			map.current.getOlMap().removeLayer(traceLayerGroup.current);
			traceLayerGroup.current = null;
			layers.current = {};
		};
	}, [props.scopeMap, props.since, props.until, props.selectedTraceUris]);

	useEffect(() => {
		if (traceLayerGroup.current != null) {
			traceLayerGroup.current.setVisible(props.displayTrace);
		}
	}, [props.displayTrace]);

	useEffect(() => {
		if (props.zones.map != null) {
			const zones = Object.values(props.zones.map);
			const layers = {};
			zones.forEach(zone => {
				const layer = new VectorLayer({
					source: new VectorSource({
						features: setProps(readMultiPolygon(zone.geometry), {
							zoneId: zone.zoneId,
							name: zone.name,
							style: zone.style
						})
					}),
					style: makeStyle(StyleType.Default),
				});
				layer.setProperties({ zoneId: zone.zoneId, type: LAYER_TYPES.zone });
				layers[zone.zoneId] = layer;
			});
			zoneLayerGroup.current = new LayerGroup({ layers: Object.values(layers) });
			map.current.getOlMap().addLayer(zoneLayerGroup.current);
			zoneLayerGroup.current.setVisible(displayZones);
		}
	}, [props.zones]);

	useEffect(() => {
		if (zoneLayerGroup.current != null) {
			zoneLayerGroup.current.setVisible(displayZones);
		}
	}, [displayZones]);

	useEffect(() => {
		if (props.state && !fromClick.current) {
			if (prevState.current != null) {
				const states = Object.values(props.state), prevStates = Object.values(prevState.current);
				const loaded = !states.some(state => !state || state.message == null);
				const changed = loaded && !equals(prevStates, states);
				if (changed) map.current.getOwMap().focusDomains(['device']);
			}
			prevState.current = props.state;
		}
		fromClick.current = false;
	}, [props.state]);

	const focusExtent = (extent) => {
		map.current.getOwMap().fitExtent(extent);
	}

	const markers = [];
	if (props.state != null) {
		Object.keys(props.state).forEach(uri => {
			markers.push(<HistoryMarker key={uri} uri={uri} params={{ autoPan: true, autoPanMargin: 100 }} message={props.state[uri].message}/>);
		});
	}

	useEffect(() => {
		!uris && focusDefaultView();
	}, []);

	if (props.displayEvents && props.eventsScopeMap != null) {
		props.selectedEventsUris.forEach(uri => {
			const scopes = props.eventsScopeMap[uri];
			if (scopes) {
				scopes.forEach(scope => {
					if (scope.events) {
						scope.events.forEach(event => {
							if (inRange(event, props.since, props.until)) {
								markers.push(<EventMapMarker key={event.eventId} event={event} uri={uri} onClick={props.onMessageClick} />);
							}
						});
					}
				});
			}
		});
	}

	const MapControl = props.control;

	return (
		<>
			<Map className={className('overview', mapName)} name={mapName} ref={map} baseLayer={Map.Layers.GOOGLE}>
				{markers}
			</Map>
			{MapControl &&
				<MapControl
					mapName={mapName}
					focusExtent={focusExtent}
					displayZones={displayZones}
					setDisplayZones={setDisplayZones}
					saveMapView={saveMapView}
				/>
			}
		</>
	);
}

export default connect(
	state => ({
		zones: state.zones,
		state: state.timeMachine.state.map,
		scopeMap: state.history.messages.map,
		eventsScopeMap: state.history.events.map,
		displayEvents: state.appDeviceEvents.timeMachine && state.appDeviceEvents.timeMachine.display,
		selectedEventsUris: state.appDeviceEvents.timeMachine && state.appDeviceEvents.timeMachine.selectedEventsUris,
		displayTrace: state.deviceTrace.timeMachine && state.deviceTrace.timeMachine.display,
		displayTraceDirection: state.deviceTrace.timeMachine && state.deviceTrace.timeMachine.displayDirection,
		selectedTraceUris: state.deviceTrace.timeMachine && state.deviceTrace.timeMachine.selectedTraceUris,
		traceColorMap: state.deviceTrace.timeMachine && state.deviceTrace.timeMachine.traceColorMap
	})
)(HistoryMap);
