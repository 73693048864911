import React, { FC, useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { List } from 'rsuite';

import { ICategory } from 'types/categories';
import Loader from 'core/react/general/Loader';
import { GroupingIdContext } from 'core/react/custom/sidebar/device-side-menu';
import { fetchChildren as fetchGroupingCategories } from 'core/redux/app/categories/grouping';
import { categoriesGroupingsSelector } from 'core/redux/selectors/categories';
import { sortByName } from 'core/lib/sorting';
import CategoryListItem from './CategoryListItem';

import './categoryList.scss';

interface ICategoryListProps {
  groupingId?: number;
}

export const CategoryList: FC<ICategoryListProps> = ({ groupingId }) => {
  const storedGroupingId = useContext(GroupingIdContext);

  const { root: rootCategory, map: groupingsMap } = useSelector(categoriesGroupingsSelector);

  const groupingIdRoot = storedGroupingId || groupingId;

  const categories = useMemo(() => {
    const categories = (!!rootCategory && !!groupingIdRoot && groupingsMap?.[groupingIdRoot]) || null;

    return categories?.length ? (sortByName(categories) as ICategory[]) : categories;
  }, [rootCategory, groupingIdRoot, groupingsMap]);

  useEffect(() => {
    if (rootCategory && groupingIdRoot && !categories) {
      fetchGroupingCategories(groupingIdRoot);
    }
  }, [groupingsMap, groupingIdRoot]);

  return rootCategory && categories ? (
    <List bordered={false} className="category-list">
      {categories?.map((category) => (
        <CategoryListItem key={category.categoryId} category={category} />
      ))}
      <CategoryListItem key={'uncategorized'} exceptCategories={categories} category={null} />
    </List>
  ) : (
    <div className="loading-wrapper">
      <Loader size={Loader.Size.LG} />
    </div>
  );
};
