import { cx } from '../api';

export const LogLevel = {
	Silent: 0,
	Error: 1000,
	Warning: 2000,
	Info: 3000,
	Debug: 4000,
	Verbose: 5000
};

class Logger {

	constructor(parent, level = LogLevel.Info) {
		this._parent = parent;
		this._level = level;
		this._loggers = {};
	}

	at(level) {
		this._level = level;
		return this;
	}

	level() {
		return this._level;
	}

	loggable(level) {
		return level <= this._level;
	}

	logger(name, level = LogLevel.Info) {
		const logger = this._loggers[name];
		if (logger != null) return logger;
		return this._loggers[name] = new Logger(this, level);
	}

	message(level, ...args) {
		if (!this.loggable(level)) return;
		if (this._parent != null) this._parent.message(level, ...args);
		else throw new Error('Can\'t output message: no parent logger defined');
	}

	error(...args) {
		this.message(LogLevel.Error, ...args);
	}

	warning(...args) {
		this.message(LogLevel.Warning, ...args);
	}

	info(...args) {
		this.message(LogLevel.Info, ...args);
	}

	debug(...args) {
		this.message(LogLevel.Debug, ...args);
	}

	verbose(...args) {
		this.message(LogLevel.Verbose, ...args);
	}

}

class RootLogger extends Logger {

	constructor(level = LogLevel.Verbose) {
		super(null, level);
		this._enabled = true;
		this._writers = {};
	}

	addWriter(name, writer) {
		this._writers[name] = writer;
	}

	removeWriter(name) {
		delete this._writers[name];
	}

	writer(name) {
		return this._writers[name];
	}

	enable() {
		this._enabled = true;
	}

	disable() {
		this._enabled = false;
	}

	enabled() {
		return this._enabled;
	}

	message(level, ...args) {
		if (!this.loggable(level)) return;
		Object.values(this._writers).forEach(
			writer => writer.message(level, ...args)
		);
	}
}

class ConsoleWriter extends Logger {

	constructor(level = LogLevel.Verbose) {
		super(null, level);
	}

	message(level, ...args) {
		if (this.loggable(level)) {
			if (level <= LogLevel.Error) {
				this.error(...args);
			} else if (level <= LogLevel.Warning) {
				this.warning(...args);
			} else if (level <= LogLevel.Info) {
				this.info(...args);
			} else if (level <= LogLevel.Debug) {
				this.debug(...args);
			} else {
				this.verbose(...args);
			}
		}
	}

	error(...args) {
		console.error(...args);
	}

	warning(...args) {
		console.warn(...args);
	}

	info(...args) {
		console.info(...args);
	}

	debug(...args) {
		console.debug(...args);
	}

	verbose(...args) {
		console.debug(...args);
	}
}

class ServerWriter extends Logger {

	constructor(level = LogLevel.Verbose) {
		super(null, level);
	}

	message(level, ...args) {
		if (this.loggable(level)) {
			if (level <= LogLevel.Error) {
				this.send('SEVERE', this.format(args));
			} else if (level <= LogLevel.Warning) {
				this.send('WARNING', this.format(args));
			} else if (level <= LogLevel.Info) {
				this.send('INFO', this.format(args));
			} else if (level < LogLevel.Debug) {
				this.send('FINE', this.format(args));
			} else {
				this.send('FINEST', this.format(args));
			}
		}
	}

	send(level, message) {
		const msg = new cx.ods.application.LogMessage();
		msg.level = level;
		msg.message = message;
		cx.ods.ws.application.log('overdrive web', msg).onready(operation => {
			if (operation.failed()) console.error(`Server failed: ${operation.failed()}`);
		});
	}

	format(...args) {
		return args.map(arg => typeof arg == 'string' ? arg : JSON.stringify(arg)).join(', ');
	}
}

export const rootLogger = new RootLogger();
rootLogger.addWriter('default', new ConsoleWriter());
rootLogger.addWriter('server', new ServerWriter(LogLevel.Silent));

window.LogLevel = LogLevel;
window.logger = rootLogger;
