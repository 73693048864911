import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Input from '../../general/form/Input';
import { actions as processorActions } from '../../../redux/api/processors';
import { useI18n } from '../../../../i18n';
import DeviceStatusDetectorListItem from './DeviceStatusDetectorListItem';
import { className } from '../../../lib/className';
import List from '../../general/list/List';
import { sortByName } from '../../../lib/sorting';
import './deviceStatusDetectorList.scss';

const actions = processorActions.deviceStatusDetectors;

/**
 * @param {Object} props
 * @param {number} props.selectedId
 * @param {function} props.onSelect
 * @param {function} props.onIntent
 */

function DeviceStatusDetectorList(props) {
	const { f } = useI18n();
	const [filter, setFilter] = useState('');
	const detectors = props.detectors ? props.detectors.list : [];

	useEffect(() => {
		if (detectors == null) {
			props.dispatch(actions.load.request());
		}
	}, []);

	const getDetectors = () => {
		return filter
			? detectors.filter(detector => detector.name.toLowerCase().includes(filter.toLowerCase()))
			: detectors
		;
	}

	let content = null;
	if (detectors != null) {
		const items = sortByName(getDetectors()).map(detector =>
			<DeviceStatusDetectorListItem
				className={className({ 'selected': (props.selectedId && props.selectedId == detector.processorId) })}
				key={detector.processorId}
				detector={detector}
				onSelect={props.onSelect}
				onIntent={props.onIntent}
			/>
		);
		content = (<List>{items}</List>);
	}

	return (<>
		<div className="filter">
			<Input label={f('filter')} value={filter} onChange={setFilter} cleanable />
		</div>
		<div className="device-status-detector-list">
			<label>
				{f('device status detectors')}
			</label>
			{content}
		</div>
	</>);
}

export default connect(
	state => ({
		detectors: state.processors.deviceStatusDetectors
	})
)(DeviceStatusDetectorList);
