import { cx } from '../api';
import { p } from '../../i18n';

export const getTimezoneString = () => {
	var shift = -cx.now().getTimezoneOffset();
	var hours = Math.abs(Math.floor(shift / 60)), minutes = Math.abs(shift % 60);
	var sign = shift / Math.abs(shift);
	return (sign > 0 ? "" : "-") + cx.format.hundred(hours) + ":" + cx.format.hundred(minutes);
}

export const formatDateTime = (datetime) => {
	if (datetime) {
		datetime.setMilliseconds(0);
		return cx.datetime.format.iso.datetime(datetime);
	}
	return "";
}

export const formatUTCTicksDuration = (ticks) => {
	let seconds = ticks / 1000;
	const days = Math.floor(seconds / 86400); seconds -= days * 86400 // 60*60*24
	const hours = Math.floor(seconds / 3600); seconds -= hours * 3600 // 60*60
	const minutes = Math.floor(seconds / 60); seconds -= minutes * 60
	seconds = Math.floor(seconds);
	let string = days > 0 ? days + " day" + (days > 1 ? "s " : " ") : "";
	string += hours < 10 ? "0" + hours : hours; string += ":";
	string += minutes < 10 ? "0" + minutes : minutes; string += ":";
	string += seconds < 10 ? "0" + seconds : seconds;
	return string;
}

export const download = (data, type, title) => {
	const blob = new Blob(data, { type });
	const url = URL.createObjectURL(blob);
	let a = cx.dom.anchor();
	a.style = "display: none";
	document.body.appendChild(a);
	a.href = url;
	a.download = title + '.csv';
	a.click();
	cx.run.after(1000, function () {
		cx.dom.detach(a);
		URL.revokeObjectURL(url);
	}, this);
}

export const generateId = () => {
	return Math.random().toString(36).substr(2, 12);
}

// events

export const isLeftMouseButton = (event) => {
	return event.button == 0;
}

export const isRightMouseButton = (event) => {
	return event.button == 2;
}

export const isEnterButton = (event) => {
	return event.key && event.key == 'Enter' || event.keyCode && event.keyCode == 13;
}

export const isEscapeButton = (event) => {
	return event.key && event.key == 'Escape' || event.keyCode && event.keyCode == 27;
}

export const isDeleteButton = (event) => {
	return event.key && event.key == 'Delete' || event.keyCode && event.keyCode == 46;
}

export const isLeftButton = (event) => {
	return event.key && event.key == 'ArrowLeft' || event.keyCode && event.keyCode == 37;
}

export const isRightButton = (event) => {
	return event.key && event.key == 'ArrowRight' || event.keyCode && event.keyCode == 39;
}

export const isCtrlPressed = (event) => {
	return event.ctrlKey;
}

// class names

/**
 * @param {Object} props - react component props
 * @param {Array.<string>} props.addition - array of additional classes
 */

export const classname = (props, addition) => {
	let className = "";
	if (props.classes != null) {
		className = Array.isArray(props.classes)
			? " " + props.classes.join(" ")
			: " " + props.classes
		;
	} else if (props.className != null) {
		className = " " + props.className;
	}
	if (addition) className = [className, addition.join(' ')].join(' ');
	return className;
}


/**
 * @param {Object} props
 * @param {string} [props.className]
 * @param {Array.<string>} [props.classes]
 * @deprecated
 */

export const rclasses = (props) => {
	const classes = [];
	if (props.classes != null) {
		if (Array.isArray(props.classes)) {
			classes.push(...props.classes)
		} else {
			classes.push(props.classes);
		}
	}
	if (props.className != null) {
		classes.push(props.className);
	}
	return classes.length > 0 ? classes : null;
}

/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 */

export const debounce = (func, wait, immediate) => {
	let timeout;
	return function () {
		const context = this, args = arguments;
		const later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		const callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	}
}

/**
 * Finds the nearest scrollable parent element and returns it.
 * Or if nothing is found then returns "null".
 * @param {HTMLElement} element
 */

export const getScrollabeParent = (element) => {
	const scrollable = ["auto", "scroll", "hidden"];
	let node = element.parentNode;
	while (node != null) {
		if (node.scrollHeight > node.offsetHeight && (
			cx.i.item.has(scrollable, cx.dom.style.computed(node, "overflow-y"))
			|| cx.i.item.has(scrollable, cx.dom.style.computed(node, "overflow-x"))
		)) {
			return node;
		}
		node = node.parentNode;
	}
	return null;
}

/**
 * If the element is not visible in the scrollable parent,
 * it will scroll the parent along the shortest path so that the element becomes fully visible.
 * Or if the element is visible, it will do nothing.
 * @param {HTMLElement} element
 */

export const scrollIntoView = (element) => {
	const scrollableParent = getScrollabeParent(element);
	if (scrollableParent) cx.dom.at.focus.scroll(scrollableParent, element);
}

/**
 * If the element is not visible in the scrollable parent,
 * it will scroll the parent along the shortest path so that the element becomes fully visible.
 * Or if the element is visible, it will do nothing.
 * With header compensation
 * @param {HTMLElement} element
 */

export const scrollReportItemIntoView = (element, addMarginTop) => {
	function scroll(viewport, target) {
		var scrollAt = cx.dom.at.focus.getScroll(viewport, target);
		const marginTop = viewport.children[0].clientHeight + (addMarginTop ? addMarginTop : 0);
		viewport.scrollTop = viewport.scrollTop > scrollAt.top ? scrollAt.top - marginTop : scrollAt.top;
		viewport.scrollLeft = scrollAt.left;
	}
	if (element) {
		const scrollableParent = getScrollabeParent(element);
		if (scrollableParent) scroll(scrollableParent, element);
	}
}

/**
 * Convert number range to string.
 * @param {Object} range { leftOpen, leftBound, rightOpen, rightBound }
 * @param {function} f react-intl format function
 */

export const rangeToString = (range) => {
	let string = '';
	if (range.leftBound != null && range.rightBound != null) { // both
		string = (range.leftOpen ? "(" : "[")
			+ range.leftBound + " - " + range.rightBound
			+ (range.rightOpen ? ")" : "]")
		;
	} else if (range.leftBound != null) { // only left
		string = range.leftOpen
			? p('from X exclusive', range.leftBound)
			: p('from X', range.leftBound)
		;
	} else { // only right
		string = range.rightOpen
			? p('up to X exclusive', range.rightBound)
			: p('up to X', range.rightBound)
		;
	}
	return string;
}

/**
 * Make first letter capital.
 */

export const capitalize = (string) => {
	return cx.format.capitalize(string);
}

export const isEqualMessageDetails = (a, b) => {
	if (!a && !b) return true;
	if (!a || !b) return false;
	return a.generatedAt.getTime() === b.generatedAt.getTime()
		&& a.longitude === b.longitude
		&& a.latitude === b.latitude
	;
}

export const decimalRound = (value, pointsN = 0) => {
	return value != null && !isNaN(value) ? +(+value).toFixed(pointsN) : value;
}

export const isInModal = (elementNode) => {
	return !cx.dom.i.traverse(document.getElementById('root'), (node) => node == elementNode);
}

export const isInside = (intendedParent, elementNode) => {
	return cx.dom.i.traverse(intendedParent, (node) => node == elementNode);
}
