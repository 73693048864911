import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';

import { reducer as dashboardReducer} from './dashboard';
import { epic as dashboardEpic} from './dashboard';

import { reducer as timeMachineReducer} from './timeMachine';
import { epic as timeMachineEpic} from './timeMachine';

export const reducer = combineReducers({
	dashboard: dashboardReducer,
	timeMachine: timeMachineReducer
});

export const epic = combineEpics(
	dashboardEpic,
	timeMachineEpic
);

export { actions } from './actions';
