import Observable from '../../../../misc/Observable';
import { clone } from '../../../../redux/api/zones/clone';
import { cx } from '../../../../api';
import { generateId } from '../../../../misc/misc';
import { compactAddresses } from "../../../../misc/location/address";
import { writeMultiPolygon, readMultiPolygon } from '../../../../misc/wkt';
import ZoneArea from './ZoneArea';

const DEFAULT_COLOR = "#5a42d0";
const DEFAULT_NAME = "No name";

class Zone extends Observable {

	/**
	 * @param {cx.ods.zones.ZoneData} [zoneData]
	 */
	constructor(zoneData) {
		super({
			areaDidAdd: 'areaDidAdd',
			areaWillRemove: 'areaWillRemove',
			areaSelectionChanged: 'areaSelectionChanged',
			changed: 'changed'
		});
		this._onAreaChanged = this._onAreaChanged.bind(this);
		this._onAreaResolved = this._onAreaResolved.bind(this);
		if (zoneData == null) {
			zoneData = new cx.ods.zones.ZoneData();
			zoneData.name = DEFAULT_NAME;
			zoneData.style = { color: DEFAULT_COLOR };
			this.virtualId = generateId();
		}
		this.backupData = zoneData;
		this.data = clone(zoneData);
		this._initData();
		this._initAreas();
	}

	_initData() {
		if (this.data.style == null) {
			this.data.style = {};
		}
		if (this.data.style.color == null) {
			this.data.style.color = DEFAULT_COLOR;
		}
	}

	zoneData() {
		return this.data;
	}

	virtual() {
		return this.virtualId != null;
	}

	id() {
		return this.data.zoneId || this.virtualId;
	}

	name() {
		return this.data.name;
	}

	setName(name) {
		this.data.name = name;
	}

	style() {
		return this.data.style;
	}

	setStyle(style) {
		this.data.style = style;
	}

	color() {
		return this.data.style.color;
	}

	setColor(color) {
		this.data.style.color = color;
		this._eachArea(area => area.update());
		this.notifyObservers(this.events.changed, this);
	}

	categoryIds() {
		return this.data.categoryIds;
	}

	setCategoryIds(categoryIds) {
		this.data.categoryIds = categoryIds;
	}

	//

	flush() {
		this.data.geometry = writeMultiPolygon(this.features());
	}

	revert() {
		this._destroyAreas();
		this.data = clone(this.backupData);
		this._initData();
		this._initAreas();
		this.notifyObservers(this.events.changed, this);
	}

	// areas

	hasAreas() {
		return this.areas().length > 0;
	}

	areas() {
		return Object.values(this.areaMap);
	}

	area(id) {
		return this.areaMap[id];
	}

	areaAtId(at) {
		const area = this.areaAt(at);
		return area != null ? area.id() : null;
	}

	areaIdAt(id) {
		const area = this.area(id);
		return area != null ? area.index() : null
	}

	areaAt(at) {
		return this.indexMap[at];
	}

	features() {
		return this.areas().map(area => area.feature());
	}

	createEmptyArea() { // ????
		const at = Object.keys(this.indexMap).length;
		const area = new ZoneArea(this);
		this.areaMap[area.id()] = area;
		this.indexMap[at] = area;
		this.notifyObservers(this.events.areaDidAdd, area, this);
		this.notifyObservers(this.events.changed, this);
		return area;
	}

	createArea(feature) {
		const at = Object.keys(this.indexMap).length;
		const area = new ZoneArea(this, feature, at);
		area.addObserver(area.events.resolved, this._onAreaResolved, this);
		this.areaMap[area.id()] = area;
		this.indexMap[at] = area;
		this.notifyObservers(this.events.areaDidAdd, area, this);
		this.notifyObservers(this.events.changed, this);
		return area;
	}

	removeArea(id) {
		const area = this.areaMap[id];
		if (area != null) {
			this.notifyObservers(this.events.areaWillRemove, area, this);
			area.destroy();
			delete this.areaMap[id];
			this._reindexAreas();
			this.notifyObservers(this.events.changed, this);
		}
	}

	_initAreas() {
		this.areaMap = {};
		this.indexMap = {};
		if (this.data.geometry != null) {
			const features = readMultiPolygon(this.data.geometry);
			const areas = features.map(feature => new ZoneArea(this, feature));
			this.areaMap = cx.i.hash(areas, area => area.id());
			this._reindexAreas();
			// this._eachArea(area => area.resolve());
		}
		this._eachArea(area => {
			area.addObserver(area.events.changed, this._onAreaChanged);
			area.addObserver(area.events.resolved, this._onAreaResolved);
		});
	}

	_reindexAreas() {
		this.indexMap = {};
		this.areas().forEach((area, at) => {
			area.reindex(at);
			this.indexMap[at] = area;
		}, this);
	}

	_eachArea(callback) {
		this.areas().forEach(callback);
	}

	_onAreaResolved(area) {
		this._reconsiderNames();
		this.notifyObservers(this.events.changed);
	}

	_onAreaChanged(area) {
		// TODO
	}

	_destroyAreas() {
		this._eachArea(area => area.destroy());
		this.areaMap = null;
	}

	// names
	_reconsiderNames() {
		const areas = this.areas().filter(area => area.address());
		if (areas.length == 0) return;
		const addresses = compactAddresses(areas.map(area => area.address()));
		areas.forEach((area, index)  => {
			const address = addresses[index];
			if (address) area.setName(address);
			else area.resetName();
		});
	}

	destroy() {
		this._destroyAreas();
	}

}

export default Zone;
