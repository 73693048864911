import React from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../i18n';
import { actions as zonesActions } from '../../../redux/api/zones';
import Input from '../../general/form/Input';

function ZoneFilter(props) {
	const { f } = useI18n();

	const onChange = (filter) => {
		props.dispatch(zonesActions.setFilter({ filter }));
	}

	return (
		<div className="zone-filter">
			<Input autoFocus label={f('filter')} value={props.filter || ''} onChange={onChange} cleanable />
		</div>
	);
}

export default connect(state => ({
	filter: state.zones.filter
}))(ZoneFilter);
