import React from 'react';
import { toEPSG4326 } from 'ol/proj/epsg3857';
import { MarkerOrderManagerContext } from './MarkerOrderManager';
import { EllipsoidalLocality } from '../../../misc/location/locality';

export const MarkerHoverManagerContext = React.createContext();

class MarkerHoverManager extends React.Component {
	constructor(props) {
		super(props);
		this.map = this.props.map;
		this.started = Boolean(this.map);
		this.markerMap = {}; // { id: marker }; marker = { id, coordinates, domNode };
		this.markers = [];
		this.hoveredElement = null;
	}

	buildMarkerArray() {
		this.markers = Object.values(this.markerMap);
	}

	mouseMove(event) {
		if (this.map) {
			const pixel = this.map.getOlMap().getEventPixel(event);
			const mouseLocality = new EllipsoidalLocality(toEPSG4326(this.map.getOlMap().getCoordinateFromPixel(pixel)));
			const closestMarker = {
				distance: Infinity,
				marker: null
			};
			this.markers.forEach(marker => {
				const markerPosition = marker.coordinates;
				const distance = mouseLocality.distance2To(markerPosition);
				if (distance < closestMarker.distance) {
					closestMarker.marker = marker;
					closestMarker.distance = distance;
				}
			});
			if (closestMarker.marker) {
				if (this.hoveredElement && closestMarker.marker.id !== this.hoveredElement.id) {
					this.context.unHighlightMarker();
					this.hoveredElement = closestMarker.marker;
				}
				if (!this.hoveredElement) this.hoveredElement = closestMarker.marker;
				this.context.highlightMarker(this.hoveredElement.id);
			}
		}
	}

	mouseOut() {
		if (this.hoveredElement) this.context.unHighlightMarker();
	}

	addMarker(marker) {
		if (!this.markerMap[marker.id]) {
			marker.domNode.addEventListener('mousemove', this.mouseMove.bind(this));
			marker.domNode.addEventListener('mouseout', this.mouseOut.bind(this));
			this.markerMap[marker.id] = marker;
			this.buildMarkerArray();
		} else this.updateMarkerData(marker);
	}

	removeMarker(id) {
		if (this.markerMap[id]) {
			this.markerMap[id].domNode.removeEventListener('mousemove', this.mouseMove.bind(this));
			this.markerMap[id].domNode.removeEventListener('mouseout', this.mouseOut.bind(this));
		}
		delete this.markerMap[id];
		this.markers = this.markers.filter(marker => marker.id != id);
	}

	updateMarkerData(marker) {
		const updatingMarker = this.markerMap[marker.id];
		const needRebuild = updatingMarker.coordinates[0] != marker.coordinates[0] || updatingMarker.coordinates[1] != marker.coordinates[1];
		updatingMarker.coordinates = marker.coordinates;
		if (needRebuild) this.buildMarkerArray();
	}

	componentDidUpdate() {
		if (!this.started) {
			this.map = this.props.map;
			this.started = true;
		}
	}

	render() {
		return (
			<MarkerHoverManagerContext.Provider value={this}>
				{this.props.children}
			</MarkerHoverManagerContext.Provider>
		);
	}
}

MarkerHoverManager.contextType = MarkerOrderManagerContext;

export { MarkerHoverManager };
