import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../i18n';
import { fromProps, className } from '../../../lib/className';
import { capitalize } from '../../../misc/misc';
import DefaultActionBar from '../actionbar/DefaultActionBar';
import ActionAdd from '../actionbar/ActionAdd';
import ActionClose from '../actionbar/ActionClose';
import Modal from '../../general/Modal';
import Form from '../../general/form/Form';
import List from '../../general/list/List';
import ListItem from '../../general/list/ListItem';
import CategorySwitch from './CategorySwitch';
import './categoriesControl.scss';

/**
 * @param {Object} props
 * @param {cx.ods.categories.CategoryData} props.category
 * @param {function} props.onRemove
 */

function Item(props) {
	const { fc } = useI18n();
	const categoryName = !props.category.custom
		? fc({ prefix: 'category', id: props.category.name })
		: capitalize(props.category.name)
	;

	const onClick = () => {
		props.onRemove(props.category.categoryId);
	}
	return (
		<ListItem>
			<ActionClose onClick={onClick} />
			{categoryName}
		</ListItem>
	);
}

/**
 * @param {Object} props
 * @param {any} props.value
 * @param {function} props.onChange
 * @param {Array.<number>} [props.comprisingIds]
 * @param {React.Component} [props.itemType]
 * @param {boolean} [props.disabled]
 * @param {string} [props.modalClassName]
 */

function _Categories(props) {
	const { f } = useI18n();
	const [adding, setAdding] = useState();
	const value = props.value != null ? props.value : [];
	const rest = [];
	const categoryIds = value.filter(categoryId => {
		if (props.comprisingIds === undefined) return true;
		const category = props.categories.map && props.categories.map[categoryId];
		const passed = category && category.comprisingIds && category.comprisingIds.some(categoryId => props.comprisingIds.includes(categoryId));
		if (!passed) rest.push(categoryId);
		return passed;
	});

	const onAdd = () => {
		setAdding(true);
	}

	const onAddCancel = () => {
		setAdding(false);
	}

	const onAddReady = (categoryIds) => {
		setAdding(false);
		props.onChange([...rest].concat(categoryIds));
	}

	const onItemRemove = (categoryId) => {
		props.onChange(categoryIds.filter(id => id != categoryId));
	}

	const ItemComponent = props.itemType || Item;

	const items = categoryIds.map(categoryId => {
		const category = props.categories.map[categoryId];
		return <ItemComponent key={category.categoryId} category={category} onRemove={onItemRemove} />;
	});

	return (
		<div className={className('categories-control', fromProps(props), { 'empty': items.length == 0 })}>
			<div className="header">
				<span className="capitalize">{f('tags')}</span>
				<DefaultActionBar
					hideRemove
					hideEdit
					disabled={props.disabled}
					appendActions={<ActionAdd title={f('add tag')} onClick={onAdd} />}
				/>
			</div>
			<div className="content">
				{items.length > 0 &&
					<List>
						{items}
					</List>
				}
				{items.length == 0 &&
					<span className="empty capitalize">{(props.emptyText || f('no categories assigned'))}</span>
				}
			</div>
			{adding &&
				<Modal
					onClose={onAddCancel}
					className={props.modalClassName}
				>
					<CategorySwitch
						isTag
						onReady={onAddReady}
						onCancel={onAddCancel}
						comprisingIds={props.comprisingIds}
						selectedIds={categoryIds}
					/>
				</Modal>
			}
		</div>
	);
}

const Categories = connect(
	state => ({
		categories: state.categories.general
	})
)(_Categories);

/**
 * @param {Object} props
 * @param {Array.<number>} props.categoryIds
 * @param {Array.<number>} [props.comprisingIds]
 * @param {string} [props.name="categoryIds"]
 */

function Control(props) {
	const { name, categoryIds, onChange, ..._props } = props;

	return (
		<Form.Control
			controlType={Categories}
			controlName={name || 'categoryIds'}
			initialValue={onChange ? undefined : categoryIds}
			value={onChange ? categoryIds : undefined}
			onChange={onChange}
			{..._props}
		/>
	);
}


export default Control;
