import React from 'react';
import { className, fromProps } from '../../../lib/className';
import './colorPreviewBox.scss';

/**
 * Renders area for color preview
 * @param {Object} props
 * @param {string} props.hex
 */

function ColorPreviewBox(props) {
	const style = {
		backgroundColor: props.hex ? props.hex : 'none',
	};
	return (<div className={className('color-preview-box', fromProps(props))} style={style}></div>);
}

export default ColorPreviewBox;