import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../i18n';
import { sort } from '../../../lib/sorting';
import { actions } from '../../../redux/api/assets';
import List from '../../general/list/List';
import Input from '../../general/form/Input';
import { designateEmployee, isValidEmployee } from '../../../redux/api/assets/employees/reducer';
import EmployeeListItem from './EmployeeListItem';
import EmployeeHeaderListItem from './EmployeeHeaderListItem';
import ObjectActionDialogPopup from '../../general/ObjectActionDialogPopup';
import RemoveDialog from '../../general/RemoveDialog';
import Intent from '../../../misc/ObjectActionIntent';
import { className } from '../../../lib/className';
import Checkbox from '../../general/form/Checkbox';
import RadioGroup from '../../general/form/RadioGroup';
import Radio from '../../general/form/Radio';
import Papa from 'papaparse';
import iconExport from '../../../img/icons/export.png';
import iconImport from '../../../img/icons/import.png';
import { ods } from '../../../api';
import { download } from '../../../misc/misc';
import './employeeList.scss';

const csvAttributes = {
	externalId: "External id",
	rfid: "RFID",
	designation: "Designation",
	inactivatedAt: "Inactivation time"
};
const requiredCSVAttributes = ["externalId"];

/**
 * @param {Object} props
 * Or single
 * @param {number} props.selectedId
 * @param {function} props.onSelect
 * Or multiple
 * @param {Array.<string>} [props.selectedIds]
 * @param {function} [props.setSelectedIds]
 * ------------------
 * @param {function} [props.onEdit]
 * @param {Array.<cx.ods.assets.Employee>} [props.employees]
 * @param {Array.<string || number>} [props.byCategories]
 * If ids are filtering from the outside, then the component is controlled
 * @param {Array.<string || number>} [props.filteredIds]
 * @param {string} [props.filter]
 * @param {function} [props.setFilter]
 * @param {function} [props.onImport]
 * @param {boolean} props.showAll
 * @param {function} props.setShowAll
 */

const RemoveOption = {
	inactivate: 'inactivate'
	, delete: 'delete'
};

const RemovePopupType = {
	deleteConfirmation: 'delete employee'
	, removeOptions: 'remove options'
};

const RemoveOptionLabels = {
	[RemoveOption.inactivate]: 'inactivate. Mark employee as inactive and preserve associated data'
	, [RemoveOption.delete]: 'delete. Permanently delete employee and associated data'
};


function EmployeeList(props) {
	const { f, fc } = useI18n();
	const [employees, setEmployees] = useState(null);
	const [filter, setFilter] = useState('');
	const [popupContext, setPopupContext] = useState(null);
	const [removeOption, setRemoveOption] = useState(RemoveOption.inactivate);
	const isStateData = !Boolean(props.employees);
	const controlled = Boolean(props.setFilter !== undefined && props.filteredIds !== undefined);
	const multipleSelectable = Boolean(props.selectedIds !== undefined && props.selectedIds !== undefined && isStateData);

	useEffect(() => {
		if (isStateData && !controlled && props.stateEmployees.map == null && !props.stateEmployees.pending) {
			props.dispatch(actions.employees.load.request());
		}
	}, []);

	useEffect(() => {
		if (isStateData && popupContext && (!props.stateEmployees.pending && !props.stateEmployees.error)) onPopupClose();
	}, [props.stateEmployees]);

	const onPopupClose = () => {
		if (popupContext) setPopupContext(null);
	}

	const onIntent = (intent) => {
		const employee = intent.object();
		if (intent.action() == Intent.Action.Remove) {
			const popupContext = {employee, offset: intent.data().offset};
			if (!employee.inactivatedAt) {
				popupContext.popupType = RemovePopupType.removeOptions;
				setRemoveOption(RemoveOption.inactivate);
			} else {
				popupContext.popupType = RemovePopupType.deleteConfirmation;
				popupContext.message = fc('this will permanently delete employee NAME and associated data. Continue?', {name: designateEmployee(employee)});
				setRemoveOption(RemoveOption.delete);
			}
			setPopupContext(popupContext);
		} else if (intent.action() == Intent.Action.Edit) {
			if (props.onEdit) props.onEdit(employee);
		}
	}

	const onRemove = (employee) => {
		if (removeOption == RemoveOption.delete) props.dispatch(actions.employees.remove.request({ assetId: employee.assetId }));
		else props.dispatch(actions.employees.inactivate.request({ assetId: employee.assetId }));
	}

	const conditionMatch = (employee) => {
		return isStateData ? (props.showAll || !employee.inactivatedAt) : true;
	}
	useEffect(() => {
		const getFilteredEmployeesByName = () => {
			const _employees = isStateData ? props.stateEmployees.list : props.employees;
			return filter
				? _employees.filter(employee => {
					return conditionMatch(employee) && designateEmployee(employee).toLowerCase().includes(filter.toLowerCase());
				})
				: isStateData && !props.showAll ? _employees.filter(employee => !employee.inactivatedAt) : _employees
			;
		}
		const getFilteredEmployees = () => {
			let _employees = getFilteredEmployeesByName();
			if (props.byCategories?.length > 0) {
				_employees = _employees.filter(employee => {
					if (conditionMatch(employee) && employee.categoryIds) {
						return employee.categoryIds.some(categoryId => props.byCategories.includes(categoryId));
					}
					return false;
				});
			}
			return sort(_employees, designateEmployee);
		}
		const getEmployees = () => {
			if (isStateData) {
				if (controlled) {
					const _employees = [];
					props.filteredIds.forEach(id => {
						if (props.stateEmployees.map[id]) {
						 	if (conditionMatch(props.stateEmployees.map[id])) _employees.push(props.stateEmployees.map[id]);
						}
					});
					return _employees;
				}
			}
			return getFilteredEmployees();
		}

		if (props.stateEmployees.map != null || props.employees) {
			const validateCheckedIds = (employees) => {
				let isValid = true;
				const validedIds = props.selectedIds.filter(id => {
					const isChecked = employees.some(employee => employee.assetId == id);
					if (isValid && !isChecked) isValid = false;
					return isChecked;
				});
				if (!isValid) props.setSelectedIds(validedIds);
			}
			const employees = getEmployees();
			if (multipleSelectable) validateCheckedIds(employees);
			setEmployees(employees);
		}
	}, [props, filter, isStateData]);

	let content = null;
	if (employees) {
		if (employees.length > 0) {
			const isChecked = (employee) => {
				return props.selectedIds.includes(employee.assetId)
			}
			const onCheck = (employee, checked) => {
				if (checked) {
					const newChecked = [...props.selectedIds];
					newChecked.push(employee.assetId);
					props.setSelectedIds(newChecked);
				} else props.setSelectedIds(props.selectedIds.filter(id => employee.assetId != id));
			}

			const onSelect = (employee) => {
				if (multipleSelectable) {
					if (isChecked(employee) && props.selectedIds.length == 1) props.setSelectedIds([]);
					else props.setSelectedIds([employee.assetId]);
				} else props.onSelect(employee);
			}
			const items = employees.map((employee, i) => {
				return <EmployeeListItem
					selected={multipleSelectable ? isChecked(employee) : props.selectedId ? props.selectedId == employee.assetId : false}
					key={isStateData ? employee.assetId : i}
					employee={employee}
					onSelect={isStateData ? onSelect : null}
					onIntent={onIntent}
					editable={isStateData}
					checked={multipleSelectable ? isChecked(employee) : undefined}
					onCheck={multipleSelectable ? onCheck : undefined}
					withStatus={isStateData && props.showAll}
				/>
			});
			const onGroupCheck = (checked) => {
				if (checked) props.setSelectedIds(employees.map(employee => employee.assetId));
				else props.setSelectedIds([]);
			}
			content = (<>
				<EmployeeHeaderListItem
					key="EmployeeHeaderListItem"
					className="employee-header-list-item"
					allchecked={multipleSelectable ? items.length == props.selectedIds.length : undefined}
					checked={multipleSelectable ? props.selectedIds.length > 0 : undefined}
					onCheck={multipleSelectable ? onGroupCheck : undefined}
					withStatus={isStateData && props.showAll}
				/>
				<List className="employee-list">{items}</List>
			</>);
		} else {
			content = (<span className="empty capitalize">
				{(props.emptyText || isStateData && !props.showAll ? f('no active employees found') : f('no employee found'))}
			</span>)
		}
	}

	const onExport = () => {
		const attributes = (() => {
			let attributes = Object.entries(csvAttributes);
			return props.showAll ? attributes : attributes.filter(([name]) => name != 'inactivatedAt'); 
		})();
		const exportees = employees.map(employee => Object.fromEntries(attributes.map(([attribute, column]) => [column, employee[attribute]])));
		const csv = Papa.unparse(exportees, {escapeFormulae: true});
		download([csv], 'text/csv', fc('employees'));
	}

	function onImport(event) {
		if (!event.target.files[0]) return;
		const reader = new FileReader();
		reader.onload = async (event) => {
			const csv = event.target.result;
			const columnMap = Object.fromEntries(Object.entries(csvAttributes).map(([attribute, column]) => [column, attribute]));
			const transformHeader = header => columnMap[header];
			const parsedHeader = Papa.parse(csv, {header: true, transformHeader, preview: 1});
			if (0 < parsedHeader.errors?.length || !parsedHeader.meta?.fields) {
				props.onImport({errorMessage: f('failed to parse input data')});
				return;
			}
			const headerAttributes = new Set(parsedHeader.meta.fields);
			const missingHeaderAttributes = requiredCSVAttributes.filter(attribute => !headerAttributes.has(attribute));
			if (0 < missingHeaderAttributes.length) {
				props.onImport({errorMessage: f('NAMES columns missing', {
					columns: missingHeaderAttributes.map(attribute => `"${csvAttributes[attribute]}"`).join(', ')
					, quantity: missingHeaderAttributes.length
				})});
				return;
			}
			const requiredAttributes = new Set(requiredCSVAttributes);
			const importAttributes = Object.keys(csvAttributes).map(attribute => ({
				name: attribute, required: requiredAttributes.has(attribute)
			}));
			const pasredData = Papa.parse(csv, {header: true, transformHeader, transform: value => value.trim(), skipEmptyLines: true});
			const employees = [];
			pasredData.data.forEach(importee => {
				const values = {};
				for (const attribute of importAttributes) {
					const value = importee[attribute.name];
					if (value != null && 0 < value.length) values[attribute.name] = value;
					else if (attribute.required) return;
				}
				const employee = new ods.assets.EmployeeInfo(values);
				if (isValidEmployee(employee)) employees.push(employee);
			});
			props.onImport({employees, errors: pasredData.data.length - employees.length});
		};
		reader.readAsText(event.target.files[0]);
	}

	return (
		<div className="employee-list-wrap">
			<div className="section-header">
				<span className="caption capitalize">{f('employees')}</span>
				{employees && <div className="procedure-buttons">
					{props.onImport && <div className={className("import-button clickable")}>
						<input type="file" id="import-from-csv" name="file" hidden onChange={onImport} />
						<label htmlFor="import-from-csv">
							<img src={iconImport} alt="" title={fc('import from CSV')} />
						</label>
					</div>}
					{isStateData
						&& <div
							onClick={onExport}
							className={className("export-button", { 'clickable': employees.length > 0, 'disabled': employees.length == 0 })}
						>
							<img src={iconExport} alt="" title={fc('export to CSV')} />
						</div>
					}
				</div>}
			</div>
			<div className="filter">
				<Input
					placeholder={f('filter')}
					value={isStateData && controlled ? props.filter : filter}
					onChange={isStateData && controlled ? props.setFilter : setFilter}
					cleanable
				/>
				{isStateData && <Checkbox value={props.showAll} onChange={props.setShowAll} label={fc('show inactive')} />}
			</div>
			{content}
			{popupContext && (
				<ObjectActionDialogPopup
					offset={popupContext.offset}
					onClose={onPopupClose}
					title={f(popupContext.popupType)}
					disabled={props.stateEmployees.pending}
					error={props.stateEmployees.error}
				>
					<RemoveDialog
						object={popupContext.employee}
						disabled={props.stateEmployees.pending}
						text={popupContext.message}
						onSubmit={onRemove}
						onCancel={onPopupClose}
						onEnterKeydownHandler={onPopupClose}
					>
						{popupContext.popupType == RemovePopupType.removeOptions &&
							<RadioGroup value={removeOption} onChange={setRemoveOption}>
								{Object.entries(RemoveOptionLabels).map(
									([option, label]) => <Radio key={option} value={option}>{fc(label)}</Radio>
								)}
							</RadioGroup>
						}
					</RemoveDialog>
				</ObjectActionDialogPopup>
			)}
		</div>
	);
}

export default connect(state => ({
	stateEmployees: state.assets.employees
}))(EmployeeList);
