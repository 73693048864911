import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useI18n } from 'i18n';
import Form from 'core/react/general/form/Form';

/**
 * @param {Object} props
 * @param {function} props.onCancel
 * @param {function} porps.onSubmit
 * @param {cx.ods.processors.DeviceStatusDetector} [props.detector]
 */

const DEFAULT_TIMEOUT = 60;

function DeviceStatusDetectorFields({ detector }) {
	const { f } = useI18n();
	const { id } = useParams();

	const [name, setName] = useState('');
	const [onlineTimeout, setOnlineTimeout] = useState(DEFAULT_TIMEOUT);
	const [downTimeout, setDownTimeout] = useState(DEFAULT_TIMEOUT);

	const validateOnlineTimeout = (value) => {
		if (value === 0) {
			return f('please enter a positive value');
		}
	}

	const validateTimeouts = (value, { onlineTimeout }) => {
		if (value === 0) {
			return f('please enter a positive value');
		}

		if (!onlineTimeout && onlineTimeout !== 0 && !value) {
			return f('at least one timeout should be added');
		}
	};

	useEffect(() => {
		setName(detector?.name || '');
		setOnlineTimeout(id ? detector?.onlineTimeout : DEFAULT_TIMEOUT);
		setDownTimeout(id ? detector?.downTimeout : DEFAULT_TIMEOUT);
	}, [id, detector]);

	return (
		<div className="presence-detector-fields">
			<Form.ControlGroup>
				<Form.Control
					controlType={Form.Control.Type.Input}
					controlValidator={(value) => value == null && f('please enter a value')}
					autoFocus
					label={f('name')}
					controlName="name"
					value={name}
					onChange={setName}
				/>
				<Form.Control
					controlType={Form.Control.Type.InputNumber}
					controlName="onlineTimeout"
					controlValidator={validateOnlineTimeout}
					label={`${f('online timeout')}, ${f({ prefix: 'units', id: 's' })}`}
					value={onlineTimeout}
					onChange={setOnlineTimeout}
				/>
				<Form.Control
					controlType={Form.Control.Type.InputNumber}
					controlName="downTimeout"
					controlValidator={validateTimeouts}
					label={`${f('down timeout')}, ${f({ prefix: 'units', id: 'min' })}`}
					value={downTimeout}
					onChange={setDownTimeout}
				/>
			</Form.ControlGroup>
		</div>
	);
}

export default DeviceStatusDetectorFields;
