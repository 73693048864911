import React from 'react';

import { useI18n } from '../../../../i18n';

import './flowProcessorCard.scss';

/**
 * @param {Object} props
 * @param {cx.ods.processors.FlowProcessor} props.processor
 */
export default function FlowProcessorCard(props) {
	const { f } = useI18n();
	const processor = props.processor;

	return (
		<div className="flow-processor card">
			<div>
				<label>{f('attributes')}</label>
				{processor.counterId && <div className="option">{f('meter generation')}</div>}
				{processor.burstRate && <div className="option">{f('burst detection')}</div>}
				{processor.tolerance && <div className="option">{f('leak detection')}</div>}
			</div>
		</div>
	);
}
