import React from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../i18n';
import CategoriesControl from '../categories/CategoriesControl';

/**
 * @param {Object} props
 * @param {Array.<number>} [props.categoryIds]
 * @param {function} [props.onChange]
 */

function DeviceCategoriesControl(props) {
	const { f } = useI18n();
	return (
		<CategoriesControl
			categoryIds={props.categoryIds}
			comprisingIds={props.categories.root ? [props.categories.root.categoryId] : []}
			emptyText={f('no tags assigned')}
			onChange={props.onChange}
		/>
	);
}

export default connect(
	state => ({
		categories: state.categories.devices
	})
)(DeviceCategoriesControl);