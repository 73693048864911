import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Map } from '../../../general/location/Map';
import { Style, Stroke, Circle, Fill, Icon } from 'ol/style';
import { Point } from 'ol/geom';
import { fromEPSG4326 } from 'ol/proj/epsg3857';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import LayerGroup from 'ol/layer/Group';
import css from '../../../../defaults.scss';
import { Feature } from 'ol';
import untilIcon from '../../../../img/markers/tripend.png'
import useLocalStorage from '../../../../misc/useLocalStorage';
import { actions as zonesActions } from '../../../../redux/api/zones';
import { setProps } from '../../../../misc/ol';
import { readMultiPolygon } from '../../../../misc/wkt';
import { makeStyle, StyleType } from '../../zones/zoneStyle';
import MapControls from '../../map/controls/MapControls';
import MapOptions from '../../map/controls/MapOptions';
import MapOptionLayers from '../../map/controls/MapOptionLayers';
import MapOptionZones from '../../map/controls/MapOptionZones';
import MapOptionGoogleSatMode from '../../map/controls/MapOptionGoogleSatMode';

const MAP_NAME = 'check-since-until-locations';
const NAME_PROPERTY_FEATURES = {
	since: 'since',
	until: 'until'
}

/**
 * @param {Object} props
 * @param {ods.reports.MessageInterval} [props.interval]
 */

function CheckIntervalMap(props) {
	const map = useRef(null);
	const layerGroup = useRef(null);
	const zoneLayerGroup = useRef(null);
	const [displayZones, setDisplayZones] = useLocalStorage(MAP_NAME + '_display_zones');

	useEffect(() => {
		if (map.current && props.interval) {

			const styleFunction = (feature) => {
				const properties = feature.getProperties();
				const coordinates = feature.getGeometry().getCoordinates();
				const styles = [
					new Style({
						stroke: new Stroke({
							color: css.traceStrokeColor1,
							width: css.traceStrokeWidth1
						})
					}),
					new Style({
						stroke: new Stroke({
							color: css.traceStrokeColor2,
							width: css.traceStrokeWidth2
						})
					})
				];
				switch (properties.name) {
					case NAME_PROPERTY_FEATURES.since:
						styles.push(
							new Style({
								image: new Circle({
									radius: css.tracePointRadius,
									fill: new Fill({ color: css.tracePointFillColor }),
									stroke: new Stroke({
										color: css.traceStrokeColor1,
										width: css.tracePointStrokeWidth
									})
								}),
								geometry: () => {
									return new Point(coordinates);
								}
							})
						);
						break;
					case NAME_PROPERTY_FEATURES.until:
						styles.push(
							new Style({
								image: new Icon({
									src: untilIcon,
									anchor: [0.5, 1],
								}),
								zIndex: 4,
								geometry: () => {
									return new Point(coordinates);
								}
							})
						);
						break;
				}
				return styles;
			}

			const features = [];
			const coordinates = [fromEPSG4326([props.interval.since.longitude, props.interval.since.latitude])];
			if (props.interval.until) {
				coordinates.push(fromEPSG4326([props.interval.until.longitude, props.interval.until.latitude]));
			}
			features.push(
				new Feature({
					geometry: new Point(coordinates[0]),
					name: NAME_PROPERTY_FEATURES.since
				})
			);
			if (coordinates.length > 1) {
				features.push(
					new Feature({
						geometry: new Point(coordinates[1]),
						name: NAME_PROPERTY_FEATURES.until
					})
				);
			}

			const layer = new VectorLayer({
				source: new VectorSource({ features }),
				style: styleFunction
			});

			layerGroup.current = new LayerGroup({ layers: [layer] });
			map.current.getOlMap().addLayer(layerGroup.current);

			if (coordinates.length == 1) {
				map.current.getOwMap().focus(coordinates[0], 18);
			} else {
				map.current.getOwMap().fitExtent(layer.getSource().getExtent());
			}
		}
		return () => {
			if (map.current) {
				map.current.getOlMap().removeLayer(layerGroup.current);
				layerGroup.current = null;
			}
		};
	}, [props.interval]);

	useEffect(() => {
		if (props.zones.map != null) {
			const zones = Object.values(props.zones.map);
			const layers = {};
			zones.forEach(zone => {
				const layer = new VectorLayer({
					source: new VectorSource({
						features: setProps(readMultiPolygon(zone.geometry), {
							zoneId: zone.zoneId,
							name: zone.name,
							style: zone.style
						})
					}),
					style: makeStyle(StyleType.Default),
				});
				layer.setProperties({ zoneId: zone.zoneId });
				layers[zone.zoneId] = layer;
			});
			zoneLayerGroup.current = new LayerGroup({ layers: Object.values(layers) });
			map.current.getOlMap().addLayer(zoneLayerGroup.current);
			zoneLayerGroup.current.setVisible(displayZones);
		}
	}, [props.zones]);

	useEffect(() => {
		if (props.zones.map == null) {
			props.dispatch(zonesActions.load.request());
		}
		if (zoneLayerGroup.current != null) {
			zoneLayerGroup.current.setVisible(displayZones);
		}
	}, [displayZones]);

	return (
		<Map
			className="fleet"
			name={MAP_NAME}
			ref={map}
			baseLayer={Map.Layers.GOOGLE}
			controls={
				<MapControls>
					<MapOptions>
						<MapOptionLayers mapName={MAP_NAME} />
						<MapOptionGoogleSatMode mapName={MAP_NAME} />
						<MapOptionZones value={displayZones} onChange={setDisplayZones} />
					</MapOptions>
					{props.controls}
				</MapControls>
			}
		/>
	);
}

export default connect(state => ({
	zones: state.zones
}))(CheckIntervalMap);