import { cx } from "../../../../api";

/**
 * Filters property by all possible substrings (name, description, datatype name).
 * @param {cx.ods.meta.Property} property
 * @param {string} criterion
 * @param {Object.<number, cx.ods.meta.Datatype>} datatypes id => Datatype
 */

export function filter(property, criterion, datatypes) {
	if (!criterion) return true;
	const datatypeName = datatypes && datatypes[property.datatypeId] && datatypes[property.datatypeId].name;
	let passed = property.name.toLowerCase().includes(criterion.toLowerCase());
	if (!passed) passed = datatypeName && datatypeName.toLowerCase().includes(criterion.toLowerCase());
	if (!passed) passed = property.description && property.description.toLowerCase().includes(criterion.toLowerCase());
	return passed;
}

export function isBundle(property) {
	return cx.o.typeOf(property, cx.ods.meta.PropertyBundle);
}