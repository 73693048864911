import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import MapMarker from '../../general/location/MapMarker';
import { Map } from '../../general/location/Map'; // eslint-disable-line
import { getAssetType } from '../../../misc/assetTypes';
import { className } from '../../../lib/className';
import { DeviceDetailsProxy } from '../../../api/device'; // eslint-disable-line
import { actions } from '../../../redux/app/categories/visibility';

import './deviceMapMarker.scss';

/**
 * @param {Object} props
 * @param {DeviceDetailsProxy} [props.device] or uri
 * @param {string} [props.uri] or device
 * @param {string} [props.status]
 * @param {Array.<number>} props.coordinates
 * @param {React.ref} props.markerRef
 * @param {number} [props.heading]
 * @param {boolean} [props.selected]
 * @param {boolean} [props.alwaysCenter]
 * @param {Map} props.map implicitly passed by Map
 * @param {function} [props.onClick]
 */

function DeviceMapMarker(props) {
	const dispatch = useDispatch();


	useEffect(() => {
		if (props.alwaysCenter && props.device) props.map.focusMarkers(props.device.uri);
	}, [props.coordinates]);

	useEffect(() => {
		if (props.map && props.device && !props.isDeviceFocusDisabled) {
			const overlay = props.map.getOlMap().getOverlayById(props.device.uri);

			if (overlay) {
				const position = overlay.getPosition();
				props.map.focus(position, props.map.getDefaultStreetViewZoom());
			}
		}


		if (props.isDeviceFocusDisabled) {
			dispatch(actions.setDeviceFocusDisabled({ isDeviceFocusDisabled: false }));
		}
	}, [props.device]);

	const getStatusClass = () => {
		return props.status && props.status.toLowerCase();
	}

	let marker = null;
	if (props.coordinates != null && props.device) {
		let bodyStyle = null;
		if (props.heading != undefined) {
			bodyStyle = {
				transform: "rotate(" + props.heading + "deg)"
			};
		}
		const assetType = getAssetType(props.device.categoryIds, props.assetTypeCategoryMap);
		marker = (<MapMarker
			className={className(
				getStatusClass(),
				assetType && assetType.getType(),
				{
					'pointer':  props.heading != undefined,
					'selected': props.selected
				}
			)}
			ref={props.markerRef}
			map={props.map}
			coordinates={props.coordinates}
			onClick={props.onClick}
			id={props.device.uri}
			title={props.device.denomination()}
			label={props.device.denomination()}
			bodyStyle={bodyStyle}
			params={props.params}
			properties={{ domain: 'device' }}
		/>);
	}

	return (marker);
}

export default connect((state, props) => ({
	assetTypeCategoryMap: state.categories.assetTypes.map,
	device: props.device || (state.devices.map && state.devices.map[props.uri]),
	isDeviceFocusDisabled: state.categories.categoriesVisibility.isDeviceFocusDisabled,
}))(DeviceMapMarker);
