import React from 'react';
import { useI18n } from '../../../../i18n';

/**
 * @param {Object} props
 * @param {cx.ods.processors.FuelLevelProcessor} props.processor
 */

function FuelProcessorCard(props) {
	const { f } = useI18n();

	return (
		<div className="fuel-processor card">
			<div className="name">
				<label>{f('name')}</label>
				<div>{props.processor.name}</div>
			</div>
			<div>
				<label>{props.processor.ignitionOn && f('only when ignition on')}</label>
			</div>
			{(props.processor.riseParameters || props.processor.dropParameters) &&
				<div className="attributes">
					<label>{f('parameters')}</label>
					{props.processor.riseParameters && <label>
						{f('on rise') + ': '}
						{props.processor.riseParameters.sensitivity + ' '}
						{f({ prefix: 'units', id: '%'}) + ', '}
						{props.processor.riseParameters.period + ' '}
						{f({ prefix: 'units', id: 's'})}
					</label>}
					{props.processor.dropParameters && <label>
						{f('on drop') + ': '}
						{props.processor.dropParameters.sensitivity + ' '}
						{f({ prefix: 'units', id: '%'}) + ', '}
						{props.processor.dropParameters.period + ' '}
						{f({ prefix: 'units', id: 's'})}
					</label>}
				</div>
			}
		</div>
	);
}

export default FuelProcessorCard;