import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../general/Loader';
import { useI18n } from '../../../i18n';
import { cx } from '../../api';
import Intent from '../../misc/ObjectActionIntent';
import { AllRoutes } from 'constants/routes';
import { actions as zoneGuardsActions } from '../../redux/api/zoneGuards';
import Button from '../general/form/Button';
import PagePanel from '../general/panel/PagePanel';
import SidebarLayout from '../general/layout/SidebarLayout';
import DefaultActionBar from '../share/actionbar/DefaultActionBar';
import Form from '../general/form/Form';
import ActionAdd from '../share/actionbar/ActionAdd';
import RemoveDialog from '../general/RemoveDialog';
import ObjectActionDialogPopup from '../general/ObjectActionDialogPopup';
import ZoneGuardsList from '../custom/zoneGuards/ZoneGuardsList';
import ZoneGuardFields from '../custom/zoneGuards/ZoneGuardFields';
import ZoneGuardView from '../custom/zoneGuards/ZoneGuardView';
import ZoneGuardCard from '../custom/zoneGuards/ZoneGuardCard';
import { FiExternalLink as IconZoneGuards } from 'react-icons/fi';
import { setPageTitle } from '../../misc/page';
import ActionBack from '../share/actionbar/ActionBack';
import './zoneGuardsPage.scss';

const PAGE_TITLE = 'zone guards';

/**
 * @param {Object} props
 */

function ZoneGuardsPage(props) {
	const { f, fc } = useI18n();
	const history = useHistory();
	const form = useRef(null);
	const [intent, setIntent] = useState(null);
	const [zoneGuard, setZoneGuard] = useState(null);
	const submitHook = useRef(null);
	const pending = useRef(false);
	const processorId = props.match.params.id;
	const modifying = props.match.params[0] == 'edit' || props.match.params[0] == 'create';
	const disabled = props.zoneGuards.pending;

	useEffect(() => {
		setPageTitle(fc(PAGE_TITLE));
		if (props.zoneGuards.list == null) {
			props.dispatch(zoneGuardsActions.load.request());
		}
		return () => {
			props.zoneGuards.error && props.dispatch(zoneGuardsActions.clear());
		}
	}, []);

	useEffect(() => {
		props.zoneGuards.error && props.dispatch(zoneGuardsActions.clear());
	}, [intent]);

	useEffect(() => {
		form.current && form.current.purge();
	}, [processorId]);

	useEffect(() => {
		props.zoneGuards.error && props.dispatch(zoneGuardsActions.clear());
		onPopupClose();
	}, [props.match]);

	useEffect(() => {
		if (!props.zoneGuards.pending && pending.current) {
			pending.current = false;
			if (props.zoneGuards.error == null) {
				history.goBack();
			}
		}
	}, [props.zoneGuards]);

	useEffect(() => {
		if (props.zoneGuards.list != null && processorId) {
			const zoneGuard = props.zoneGuards.map[processorId];

			zoneGuard ? setZoneGuard(zoneGuard) : history.replace(AllRoutes.ZoneGuards);
		} else if (props.zoneGuards.list != null && !processorId) {
			setZoneGuard(null);
		}
		if (intent && props.zoneGuards.map && !props.zoneGuards.map[intent.object().processorId]) onPopupClose();
	}, [props]);

	const submit = () => {
		submitHook.current();
	}

	const onSubmit = (zoneGuard) => {
		const payload = Object.keys(zoneGuard).reduce(
			(guard, key) => ({
				...guard,
				[key]: key.includes('EventType') || key.includes('Action') ? zoneGuard[key] || null : zoneGuard[key],
			}),
			{},
		);

		const data = new cx.ods.processors.ZoneGuard(payload);

		processorId
			? props.dispatch(zoneGuardsActions.update.request({ processorId, data }))
			: props.dispatch(zoneGuardsActions.add.request({ data }));

		pending.current = true;
	};

	const onPopupClose = () => {
		intent && setIntent(null);
	}

	const onAdd = () => {
		history.push(`${AllRoutes.ZoneGuards}/create`);
	}

	const onBack = () => {
		setZoneGuard(null);
		history.goBack();
	}

	const onSelect = (detector) => {
		history.push(`${AllRoutes.ZoneGuards}/${detector.processorId}`);
	}

	const onIntent = (intent) => {
		if (intent.action() == Intent.Action.Remove) {
			setIntent(intent);
		} else if (intent.action() == Intent.Action.Edit) {
			history.push(`${AllRoutes.ZoneGuards}/${intent.object().processorId}/edit`);
		}
	}

	const onRemove = (_zoneGuard) => {
		props.dispatch(zoneGuardsActions.remove.request({ processorId: _zoneGuard.processorId }));
	}

	const sidebarContent = (<>
		<DefaultActionBar
			prependActions={modifying
				? <ActionBack onClick={onBack} />
				: <ActionAdd onClick={onAdd} />
			}
			hideEdit={modifying}
			disabled={disabled}
		/>
		{modifying ?
			<>
				<div className="content">
					<ZoneGuardFields zoneGuard={zoneGuard} />
				</div>
				<div className="submit-button">
					{!!props.zoneGuards.error && <div className="submit-error">{props.zoneGuards.error}</div>}
					<Button onClick={submit} disabled={disabled}>
						{f('save')}
					</Button>
				</div>
			</>
			: <>
				<ZoneGuardsList
					selectedId={zoneGuard ? zoneGuard.processorId : null}
					onSelect={onSelect}
					onIntent={onIntent}
				/>
				{zoneGuard && <ZoneGuardCard zoneGuard={zoneGuard} />}
			</>
		}
	</>);

	let content = null;
	if (props.zoneGuards.list != null) {
		content = (
			<Form
				onSubmit={onSubmit}
				submitHook={submitHook}
				disabled={disabled}
				customRef={form}
				objectType={cx.ods.processors.ZoneGuard}
			>
				<SidebarLayout
					className="body"
					icon={<IconZoneGuards size="20" />}
					title={modifying ? f("zone guard") : f("zone guards")}
					sidebarContent={sidebarContent}
				>
					<ZoneGuardView
						zoneGuard={zoneGuard}
						disabled={disabled}
						editMode={modifying}
					/>
					{intent && intent.action() == Intent.Action.Remove && (
						<ObjectActionDialogPopup
							offset={intent.data().offset}
							onClose={onPopupClose}
							title={f('delete zone guard')}
							error={props.zoneGuards.error}
							disabled={props.zoneGuards.pending}
						>
							<RemoveDialog
								object={intent.object()}
								text={f('are you sure you want to delete zone guard', { name: intent.object().name })}
								onSubmit={onRemove}
								onCancel={onPopupClose}
							/>
						</ObjectActionDialogPopup>
					)}
				</SidebarLayout>
			</Form>
		);
	} else if (props.zoneGuards.pending) {
		content = <div className="vh-center"><Loader size={Loader.Size.LG} /></div>
	}

	return (
		<div className="app-page zone-guards">
			<PagePanel>
				{content}
			</PagePanel>
		</div>
	);
}

ZoneGuardsPage.Url = AllRoutes.ZoneGuards;

export default connect(state => {
	return {
		zoneGuards: state.zoneGuards
	}
})(ZoneGuardsPage);
