import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { cx } from '../../api';
import { isEscapeButton, isInModal, isInside } from '../../misc/misc';
import ActionClose from '../share/actionbar/ActionClose';
import './objectActionDialogPopup.scss';

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {string | null} [props.error]
 * @param {Object} props.offset - { left: number, top: number }
 * @param {Object} [props.anchorRef]
 * @param {boolean} [props.disabled]
 * @param {number} [props.width] - px
 * @param {boolean} [props.preventAutoClose] - to prevent autoclose on click outside
 * @param {function} props.onClose
 * @param {ReactElement} props.children
 */

function ObjectActionDialogPopup(props) {
	const [position, setPosition] = useState(null);
	const box = useRef(null);
	const prevPosition = useRef(null);
	const handleClickRef = useRef(false);

	useEffect(() => {
		const handleEscape = event => {
			if (!props.disabled && isEscapeButton(event)) props.onClose();
		};
		window.addEventListener('keydown', handleEscape);
		return () => window.removeEventListener('keydown', handleEscape);
	}, [props.disabled, props.onClose]);

	useEffect(() => {
		const handleClick = (event) => {
			if (
				!props.preventAutoClose
				&& handleClickRef.current
				&& position
				&& !isInModal(event.target)
				&& !isInside(box.current, event.target)
			) props.onClose();
		}
		if (!props.preventAutoClose) {
			window.addEventListener('click', handleClick);
			handleClickRef.current = true;
		}
		return () => window.removeEventListener('click', handleClick);
	}, [position]);

	useLayoutEffect(() => {
		let offset;
		if (props.offset) offset = props.offset;
		else if (props.anchorRef?.current) {
			offset = cx.dom.at.offset(props.anchorRef.current);
			offset.left = offset.left + props.anchorRef.current.offsetWidth;
		}
		if (!offset) return;
		const boxOffset = cx.dom.at.offset(box.current.offsetParent);
		let correctionLeft = 0;
		let correctionTop = 0;
		if (offset.left + box.current.clientWidth > document.body.clientWidth) {
			correctionLeft = document.body.clientWidth - (offset.left + box.current.clientWidth);
		}
		if (offset.top + box.current.clientHeight > document.body.clientHeight) {
			correctionTop = document.body.clientHeight - (offset.top + box.current.clientHeight);
		}
		const _position = {
			top: offset.top > boxOffset.top ? (offset.top - boxOffset.top + correctionTop) : 0,
			left: offset.left > boxOffset.left ? (offset.left - boxOffset.left + correctionLeft) : 0,
			width: props.width ? props.width : 'max-content'
		};
		setPosition(_position);
		if (!props.preventAutoClose) {
			console.log(prevPosition.current, _position);
			handleClickRef.current = (prevPosition.current
				? (prevPosition.current.top === _position.top && prevPosition.current.left === _position.left)
				: true
			);
			prevPosition.current = _position;
		}
	}, [props]);

	const onClose = () => {
		if (!props.disabled) props.onClose();
	}

	const children = React.Children.map(props.children, (child) => {
		return React.cloneElement(child, {
			disabled: props.disabled,
			error: props.error
		})
	});

	return (
		<div ref={box} className="object-action-dialog-popup" style={position}>
			<div>
				<div className="header">
					<span>{props.title}</span>
					<div className="actions">
						<ActionClose disabled={props.disabled} onClick={onClose} />
					</div>
				</div>
				<div className="body">
					{children}
				</div>
			</div>
		</div>
	)
}

export default ObjectActionDialogPopup;
