import React, { useRef } from 'react';
import { useDrop } from 'react-dnd';

import ListItem from '../../general/list/ListItem';
import { generateId } from '../../../misc/misc';
import { DragItemType } from '../../share/dnd/DragItemType';
import { cx } from '../../../api';
import Intent from '../../../misc/ObjectActionIntent';
import { className, fromProps } from '../../../lib/className';

/**
 * @param {Object} props
 * @param {cx.ods.processors.MessagePublisher} props.publisher
 * @param {function} props.onSelect
 * @param {function} props.onIntent
 */

export const MessagePublisherListItem = (props) => {
	const { publisher, onSelect, onIntent } = props;

	const uid = useRef(generateId());

	const [dropState, dropRef] = useDrop({
		accept: [DragItemType.ACTION_EDIT, DragItemType.ACTION_REMOVE],
		drop: (item) => onDrop(item),
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		})
	});

	const onClick = () => {
		onSelect(publisher);
	}

	const onDrop = (item) => {
		if (item.type === DragItemType.ACTION_EDIT) {
		   return onIntent(new Intent(null, Intent.Action.Edit, publisher));
		}

		const offset = cx.dom.at.offset(document.getElementById(uid.current));

		onIntent(new Intent(null, Intent.Action.Remove, publisher, { offset }));
	}

	return (
		<ListItem
			customRef={dropRef}
			className={className(fromProps(props), { 'droppable':  dropState.isOver && dropState.canDrop })}
			onClick={onClick}
			id={uid.current}
		>
			{publisher?.name}
		</ListItem>
	);
}
