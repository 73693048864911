import { rx, api } from '../../../api';
import { ofType, combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { map as rxmap, exhaustMap, mergeMap, takeUntil, filter } from 'rxjs/operators';
import { actions } from './actions';
import { actions as zoneCategoryActions } from '../../app/categories/zone';
import { clone } from './clone';
import { errorMap } from '../../actions';

const externalize = (zone) => {
	if (zone.style != null && typeof zone.style == 'object') {
		zone.style = JSON.stringify(zone.style);
	}
	return zone;
}

const internalize = (zone) => {
	if (zone.style != null && typeof zone.style == 'string') try {
		zone.style = JSON.parse(zone.style);
	} catch (e) {
	}
	return zone;
}

const loadEpic = (action$) => {
	return action$.pipe(
		ofType(actions.load.request.type),
		exhaustMap(action =>
			rx(api.zones.load).pipe(
				rxmap(operation => {
					const zones = operation.response();
					zones.forEach(zone => internalize(zone));
					return actions.load.success({ zones });
				}),
				errorMap(actions.load.fail),
				takeUntil(action$.pipe(ofType(actions.load.cancel.type)))
			)
		)
	)
}

const addEpic = (action$) => {
	return action$.pipe(
		ofType(actions.add.request.type),
		mergeMap(action =>
			rx(api.zones.add, externalize(action.data)).pipe(
				mergeMap((operation) => {
					const zone = internalize(operation.response());
					return of(actions.add.success({ data: zone }), actions.load.request());
				}),
				errorMap(actions.add.fail),
				takeUntil(action$.pipe(ofType(actions.add.cancel.type)))
			)
		)
	)
}

const updateEpic = (action$) => {
	return action$.pipe(
		ofType(actions.update.request.type),
		mergeMap(action =>
			rx(api.zones.update, action.id, externalize(clone(action.data))).pipe(
				mergeMap((operation) => of(actions.update.success({ data: action.data }), actions.load.request())),
				errorMap(actions.update.fail),
				takeUntil(action$.pipe(ofType(actions.update.cancel.type)))
			)
		)
	)
}

const removeEpic = (action$) => {
	return action$.pipe(
		ofType(actions.remove.request.type),
		mergeMap(action =>
			rx(api.zones.remove, action.id).pipe(
				mergeMap(operation => of(actions.remove.success(), actions.load.request())),
				errorMap(actions.remove.fail),
				takeUntil(action$.pipe(ofType(actions.remove.cancel.type)))
			)
		)
	)
}

const watchSetCategoryEpic = (action$, state$) => {
	return action$.pipe(
		ofType(zoneCategoryActions.set.type),
		filter(action => action.categories && !!state$.value.zones.categoryFilter),
		rxmap(action => {
			const categoryFilter = state$.value.zones.categoryFilter;
			const newCategoryFilter = categoryFilter.filter(categoryId => action.categories.some(category => category.categoryId == categoryId));
			return actions.setCategoryFilter({ categoryIds: newCategoryFilter });
		})
	);
}

const epic = combineEpics(addEpic, loadEpic, updateEpic, removeEpic, watchSetCategoryEpic);

export { epic };
