import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../i18n';
import { assert } from '../../../lib/assert';
import { actions as contextActions } from '../../../redux/app/context';
import { actions } from '../../../redux/api/categories';
import ButtonGroup from '../../general/form/ButtonGroup';
import Button from '../../general/form/Button';
import './categoryRemover.scss';

/**
 * @param {Object} props
 * @param {number} props.categoryId
 * @param {string} props.title
 * @param {boolean} [props.standalone] indicates whether component uses redux context action
 * @param {function} [props.onCancel]
 * @param {function} [props.onReady]
 * @param {string} [props.text]
 */

function CategoryRemover(props) {
	const { f } = useI18n();
	assert(
		!props.standalone || props.standalone && props.onCancel !== undefined && props.onReady !== undefined,
		"CategoryRemover: if standalone - should receive onCancel and onReady callbacks"
	);
	const pending = useRef(false);

	useEffect(() => {
		if (!props.categories.pending && pending.current) {
			pending.current = false;
			if (!props.standalone) {
				props.dispatch(contextActions.actionClear());
			} else {
				props.onReady();
			}
		}
	}, [props.categories]);

	useEffect(() => {
		return () => {
			props.dispatch(actions.clear());
		}
	}, []);

	const onConfirm = () => {
		pending.current = true;
		props.dispatch(actions.remove.request({ categoryId: props.categoryId }));
	}

	const onCancel = () => {
		const prevState = props.context.prevAction;
		const actualState = props.context.action;
		if (props.standalone) {
			props.onCancel();
		} else {
			if (Object.keys(prevState).length !== 0 && actualState.type != prevState.type) {
				props.dispatch(contextActions.actionSet({
					actionType: prevState.type,
					name: prevState.name,
					data: prevState.data
				}));
			} else {
				props.dispatch(contextActions.actionClear());
			}
		}
	}

	const category = props.categories.map[props.categoryId];

	const title = props.title ? props.title : 'category';
	const text = props.text
		? props.text
		: f(('are you sure you want to delete ' + title), { name: category && category.name })
	;

	return (
		<div className="category-remover">
			<div className="content">
				{text}
			</div>
			<ButtonGroup>
				<Button
					disabled={props.categories.pending}
					loading={props.categories.pending}
					onClick={onConfirm}
				>{f('yes')}</Button>
				<Button
					disabled={props.categories.pending}
					onClick={onCancel}
				>{f('no')}</Button>
			</ButtonGroup>
		</div>
	)
}

export default connect(
	state => ({
		categories: state.categories.general,
		context: state.context
	})
)(CategoryRemover);
