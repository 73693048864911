import { ofType, combineEpics } from 'redux-observable';
import { map as rxmap, mergeMap, takeUntil, exhaustMap } from 'rxjs/operators';
import { rx, api, ods } from '../../../../api';
import { errorMap } from '../../../actions';
import { actions as assetActions } from '../actions';

const actions = assetActions.employees;

const addEpic = (action$) => {
	return action$.pipe(
		ofType(actions.add.request.type),
		mergeMap(action =>
			rx(api.assets.employees.add, action.employee).pipe(
				rxmap(operation => actions.load.request()),
				errorMap(actions.add.fail),
				takeUntil(action$.pipe(ofType(actions.add.cancel.type)))
			)
		)
	)
}

const loadEpic = (action$) => {
	return action$.pipe(
		ofType(actions.load.request.type),
		exhaustMap(action =>
			rx(api.assets.employees.load).pipe(
				rxmap(operation => actions.load.success({ employees: operation.response() })),
				errorMap(actions.load.fail),
				takeUntil(action$.pipe(ofType(actions.load.cancel.type)))
			)
		)
	)
}

const updateEpic = (action$) => {
	return action$.pipe(
		ofType(actions.update.request.type),
		mergeMap(action =>
			rx(api.assets.employees.update, action.assetId, action.employee).pipe(
				rxmap(operation => actions.load.request()),
				errorMap(actions.update.fail),
				takeUntil(action$.pipe(ofType(actions.update.cancel.type)))
			)
		)
	)
}

const removeEpic = (action$) => {
	return action$.pipe(
		ofType(actions.remove.request.type),
		mergeMap(action =>
			rx(api.assets.employees.remove, action.assetId).pipe(
				rxmap(operation => actions.load.request()),
				errorMap(actions.remove.fail),
				takeUntil(action$.pipe(ofType(actions.remove.cancel.type)))
			)
		)
	)
}

const uploadEpic = (action$) => {
	return action$.pipe(
		ofType(actions.upload.request.type),
		mergeMap(action =>
			rx(api.assets.employees.upload, action.upload).pipe(
				rxmap(operation => actions.load.request()),
				errorMap(actions.upload.fail),
				takeUntil(action$.pipe(ofType(actions.upload.cancel.type)))
			)
		)
	)
}

const inactivateAllEpic = (action$) => {
	return action$.pipe(
		ofType(actions.inactivateAll.request.type),
		mergeMap(action => {
			const filter = new ods.assets.EmployeeFilter();
			filter.assetIds = action.assetIds;
			return rx(api.assets.employees.inactivateAll, filter, action.activate).pipe(
				rxmap(operation => actions.load.request()),
				errorMap(actions.inactivateAll.fail),
				takeUntil(action$.pipe(ofType(actions.inactivateAll.cancel.type)))
			);
		})
	)
}

const removeAllEpic = (action$) => {
	return action$.pipe(
		ofType(actions.removeAll.request.type),
		mergeMap(action => {
			const filter = new ods.assets.EmployeeFilter();
			filter.assetIds = action.assetIds;
			return rx(api.assets.employees.removeAll, filter).pipe(
				rxmap(operation => actions.load.request()),
				errorMap(actions.removeAll.fail),
				takeUntil(action$.pipe(ofType(actions.removeAll.cancel.type)))
			);
		})
	)
}

const inactivateEpic = (action$) => {
	return action$.pipe(
		ofType(actions.inactivate.request.type),
		mergeMap(action =>
			rx(api.assets.employees.inactivate, action.assetId, action.activate).pipe(
				rxmap(operation => actions.load.request()),
				errorMap(actions.inactivate.fail),
				takeUntil(action$.pipe(ofType(actions.inactivate.cancel.type)))
			)
		)
	)
}

export const epic = combineEpics(addEpic, loadEpic, updateEpic, removeEpic, uploadEpic, inactivateAllEpic, removeAllEpic, inactivateEpic);
