import { cx } from 'core/api';
import { deltaReducer } from 'core/redux/actions';
import { actions as processorActions } from 'core/redux/api/processors/actions';

const actions = processorActions.downsampledMessageHistory;

const defaultState = { list: null, map: null, pending: false, error: null };

const reducer = deltaReducer((state, action) => {
	switch (action.type) {
		case actions.add.request.type:
		case actions.load.request.type:
		case actions.update.request.type:
		case actions.remove.request.type:
			return { pending: true, error: null };
		case actions.load.success.type:
			return {
				pending: false,
				list: action.processors,
				map: cx.i.hash(action.processors, (processor) => processor.processorId),
			};
		case actions.add.fail.type:
		case actions.load.fail.type:
		case actions.update.fail.type:
		case actions.remove.fail.type:
			return { pending: false, error: action.errorMessage };
		case actions.add.cancel.type:
		case actions.load.cancel.type:
		case actions.update.cancel.type:
		case actions.remove.cancel.type:
			return { pending: false };
		case actions.clear.type:
			return { error: null };
	}
}, defaultState);

export { reducer };
