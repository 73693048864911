import React from 'react';
import { capitalize } from '../../../misc/misc';
import { className, fromProps } from '../../../lib/className';
import './mapButton.scss';

/**
 * @param {Object} props
 * @param {function} [props.onClick]
 * @param {string} [props.title]
 * @param {React.RefObject} [props.customRef]
 * @param {boolean} [props.disabled]
 */

function MapButton(props) {

	const onClick = (event) => {
		!props.disabled && props.onClick && props.onClick(event);
	}

	return (
		<div
			className={className('map-button', fromProps(props), {
				'clickable': props.onClick != null,
				'disabled': props.disabled }
			)}
			onClick={onClick}
			title={capitalize(props.title)}
			ref={props.customRef}
		>
			<div className="content">
				{props.children}
			</div>
		</div>
	);
}

export default MapButton;