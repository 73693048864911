import React from 'react';
import { className, fromProps } from '../../../lib/className';
import './sidebarLayout.scss';

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {string} [props.className]
 * @param {ReactElement} props.icon
 * @param {ReactElement} props.sidebarContent
 * @param {ReactElement} props.children
 */

function SidebarLayout(props) {
	return (
		<div className={className('layout', 'sidebar-layout', fromProps(props))}>
			<div className="sidebar">
				<div className="header">
					<span className="icon">{props.icon}</span>
					<span className="title">{props.title}</span>
				</div>
				<div className="sidebar-content">
					{props.sidebarContent}
				</div>
			</div>
			<div className="content">
				{props.children}
			</div>
		</div>
	);
}

export default SidebarLayout;
