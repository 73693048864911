import React from 'react';
import { Button as RsButton } from 'rsuite';
import { capitalize } from '../../../misc/misc';
import { className as _className } from '../../../lib/className';
import "./button.scss";

/**
 * @description For parameters - see rsuite Button component
 */

function Button(props) {
	const { children, className, title, ..._props } = props;

	return (
		<RsButton
			appearance="primary"
			block
			className={_className('button', className)}
			title={capitalize(title)}
			{..._props}
		>
			<span className="capitalize">
				{children}
			</span>
		</RsButton>
	)
}

export default Button;