import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { cx } from '../../../api';
import { isEscapeButton } from '../../../misc/misc';
import { actions as contextActions } from '../../../redux/app/context';
import ActionClose from '../../share/actionbar/ActionClose';
import './deviceSidebarPopup.scss';

/**
 * @param {Object} props
 * @param {string} props.title
 */

function DeviceSidebarPopup(props) {
	const [style, setStyle] = useState({ top: 0, left: 10 });
	const box = useRef(null);

	useEffect(() => {
		const container = box.current.parentNode;
		const containerHeight = container.clientHeight;
		const containerOffsetTop = cx.dom.at.offset(container).top;
		const anchorOffsetTop = props.context.action && (props.context.action.data.offsetTop - containerOffsetTop);
		const anchorHeight = props.context.action && props.context.action.data.elementHeight;
		const elementHeight = box.current && box.current.offsetHeight;
		const left = props.context.action.data.offsetLeft || 10;
		if ((containerHeight - anchorOffsetTop) < elementHeight) {
			let bottom  = containerHeight - (anchorOffsetTop + anchorHeight);
			if (bottom < 0) bottom = 0;
			setStyle({ bottom, left });
		} else {
			setStyle({ top: anchorOffsetTop, left });
		}
		const handleEscape = (event) => {
			isEscapeButton(event) && props.dispatch(contextActions.actionClear());
		}
		window.addEventListener('keydown', handleEscape);
		return () => window.removeEventListener('keydown', handleEscape);
	}, [props.context.action]);

	const onClose = () => props.dispatch(contextActions.actionClear());

	useEffect(() => onClose, []);

	return (
		<div ref={box} className="device-sidebar-popup" style={style}>
			<div>
				<div className="header">
					{props.title}
				</div>
				<div className="actions">
					<ActionClose onClick={onClose} />
				</div>
				<div className="body">
					{props.children}
				</div>
			</div>
		</div>
	);
}

export default connect(state => {
	return ({
		context: state.context
	})
})(DeviceSidebarPopup);
