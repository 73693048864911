import { cx } from "../api";

class OperationException extends Error {

	constructor(operation) {
		const message = operation.failed()
			? operation.failed()
			: operation.notFound()
				? operation.notFound()
				: "unknown reason"
		;
		super("Operation (" + operation.getURL() + ") exception: " + message);
		this.userMessage = operation.failed();
		this.operation = operation;
		this.custom = true;
	}

	isUnauthorized() {
		const nack = this.operation.nack();
		return !!nack && cx.o.typeOf(nack, cx.ods.Unauthorized);
	}

	isNack() {
		return !!this.operation.nack() && !this.isUnauthorized();
	}

	isNotFound() {
		return !!this.operation.notFound();
	}

	getOperation() {
		return this.operation;
	}

	getMessage() {
		return this.message;
	}

	getUserMessage() {
		return this.userMessage;
	}
}

export default OperationException;