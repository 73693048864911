import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { className, fromProps } from '../../../lib/className';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useI18n } from '../../../../i18n';
import List from '../../general/list/List';
import { actions } from '../../../redux/api/devices';
import ListItem from '../../general/list/ListItem';
import { useDrop } from 'react-dnd';
import { DragItemType } from '../dnd/DragItemType';
import { DeviceDetailsProxy, sortUris } from '../../../api/device'; // eslint-disable-line
import './deviceSet.scss';

/**
 * @param {Object} props
 * @param {DeviceDetailsProxy} props.device
 * @param {string} [props.emptyMessage]
 * @param {React.RefObject} [props.customRef]
 * @param {function} props.onClick
 * @param {function} props.onRemove
 * @param {boolean} [props.canDrop]
 */

function DeviceSetItem(props) {

	const handleClick = () => {
		props.onClick(props.device.uri);
	}

	const handleDrop = (item) => {
		if (!props.canDrop) return;
		props.onRemove(props.device.uri);
	}

	const [dropState, drop] = useDrop({
		accept: DragItemType.ACTION_REMOVE,
		canDrop: () => props.canDrop,
		drop: item => handleDrop(),
		collect: monitor => ({
			isOver: monitor.isOver(),
		})
	});

	return (
		<ListItem
			customRef={drop}
			className={className(fromProps(props), { 'droppable': dropState.isOver && props.canDrop })}
			onClick={props.onClick && handleClick}
		>
			{props.device.denomination()}
		</ListItem>
	);
}

/**
 * Controlled Component
 * @param {Object} props
 * @param {Array.<string>} props.value device uris
 * @param {Array.<string>} [props.selectedUris] device uris
 * @param {function} [props.onChange]
 * @param {function} [props.onClick]
 * @param {boolean} [props.editMode]
 * @param {boolean} [props.collapsible]
 * @param {string} [props.label]
 * @param {string} [props.className]
 * @param {string} [props.emptyMessage]
 */

function DeviceSet(props) {
	const { fc } = useI18n();
	const [collapsed, setCollapsed] = useState(props.collapsible);
	let content = null;

	useEffect(() => {
		if (!props.devices) {
			props.dispatch(actions.load.request());
		}
	}, []);

	const handleRemove = (uri) => {
		const value = [...props.value];
		value.splice(value.indexOf(uri), 1);
		props.onChange(value);
	}

	if (props.devices) {
		if (props.value?.length > 0) {
			const items = sortUris(props.value).map(uri =>
				<DeviceSetItem
					key={uri}
					device={props.devices[uri]}
					onClick={props.onClick}
					onRemove={handleRemove}
					canDrop={props.editMode}
					className={className({ 'selected': (props.selectedUris && props.selectedUris.includes(uri)) })}
				/>
			);
			content = (<List>{items}</List>);
		} else {
			content = props.emptyMessage && (<span className="no-items capitalize">{props.emptyMessage}</span>);
		}
	}

	return (
		<div className={className("device-set", fromProps(props))}>
			{
				props.label
					&& <label>
						<span className="capitalize">{props.label}</span>
						{props.collapsible
							&& <span
								className="toggle-devices-view clickable"
								onClick={() => setCollapsed(!collapsed)}
								title={collapsed ? fc('show') : fc('hide')}
							>
								{collapsed ? <FiChevronDown /> : <FiChevronUp />}
							</span>
						}
					</label>
			}
			{!collapsed && content}
		</div>
	);
}

export default connect(
	state => ({
		devices: state.devices.map
	})
)(DeviceSet);
