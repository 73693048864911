import React, { useRef } from 'react';
import { useDrop } from 'react-dnd';
import { cx } from '../../../api'
import { DragItemType } from '../../share/dnd/DragItemType';
import Intent from '../../../misc/ObjectActionIntent';
import ListItem from '../../general/list/ListItem';
import { className, fromProps } from '../../../lib/className';

/**
 * @param {Object} props
 * @param {cx.ods.zones.ZoneData} props.zone
 * @param {function} props.onClick
 * @param {function} props.onIntent
 * @param {React.RefObject} [props.customRef]
 */

function ZoneListDropItem(props) {
	const box = useRef(null);

	const onClick = () => {
		props.onClick(props.zone.zoneId);
	}

	const onDrop = (item) => {
		const offset = cx.dom.at.offset(box.current);
		if (item.type == DragItemType.ACTION_EDIT) {
			props.onIntent(new Intent('zone', Intent.Action.Edit, props.zone, { offset }));
		} else {
			props.onIntent(new Intent('zone', Intent.Action.Remove, props.zone, { offset }));
		}
	}

	const [dropState, dropRef] = useDrop({
		accept: [DragItemType.ACTION_EDIT, DragItemType.ACTION_REMOVE],
		drop: (item) => onDrop(item),
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		}),
	})

	const onRef = (element) => {
		dropRef(element);
		box.current = element;
		props.customRef && (props.customRef.current = element);
	}

	return (
		<ListItem
			className={className(fromProps(props), { 'droppable': dropState.isOver && dropState.canDrop })}
			onClick={onClick}
			customRef={onRef}
		>
			{props.zone.name}
		</ListItem>
	);
}

export default ZoneListDropItem;