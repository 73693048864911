import React from 'react';
import { connect } from 'react-redux';
import { createIntl, IntlProvider } from 'react-intl';
import { IntlProvider as RSIntlProvider } from 'rsuite';
import Routes from './Routes';
import id from './i18n/resources/id.json';
import ru from './i18n/resources/ru.json';
import en from './i18n/resources/en.json';
import vi from './i18n/resources/vi.json';
import th from './i18n/resources/th.json';
import ar from './i18n/resources/ar.json';
import zhCn from './i18n/resources/zh-cn.json';
import { getRsLocale } from './i18n/rsuite';
import DragLayer from './core/react/share/dnd/DragLayer';

import "moment/locale/id";
import "moment/locale/ru";
import "moment/locale/vi";
import "moment/locale/th";
import "moment/locale/ar";
import "moment/locale/zh-cn";

const locales = { id, en, ru, vi, th, ar, 'zh-cn': zhCn };

export let intl = {};

/**
 * @param {Object} props
 */

function LocalizedApp(props) {
	const lang = props.locale.lang;

	intl = createIntl({
		locale: lang,
		messages: locales[lang]
	});

	return (
		<IntlProvider locale={lang} key={lang} messages={locales[lang]}>
			<RSIntlProvider locale={getRsLocale()}>
				<DragLayer />
				<Routes />
			</RSIntlProvider>
		</IntlProvider>
	)
}

export default connect(state => {
	return {
		locale: state.locale
	}
})(LocalizedApp);
