import React from 'react';
import { useI18n } from '../../../../i18n';
import { className, fromProps } from '../../../lib/className';
import ActionBar from '../../general/actionbar/ActionBar';
import { FiArrowLeftCircle } from 'react-icons/fi';

/**
 * @param {Object} props
 * @param {function} props.onClick
 * @param {string} [props.title]
 * @param {boolean} [props.disabled]
 */

function ActionBack(props) {
	const { f } = useI18n();
	return (
		<ActionBar.Action
			className={className('action-back', fromProps(props))}
			onClick={props.onClick}
			disabled={props.disabled}
			title={props.title || f('back')}
		>
			<FiArrowLeftCircle size="16" />
		</ActionBar.Action>
	)
}

export default ActionBack;