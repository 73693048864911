import React from 'react';
import { connect } from 'react-redux';

import { useI18n } from '../../../../i18n';
import { MessagePublisherListItem } from './MessagePublisherListItem';
import List from '../../general/list/List';
import { className } from '../../../lib/className';

import './messagePublishersList.scss';

/**
 * @param {Object} props
 * @param {number} props.selectedId
 * @param {function} props.onSelect
 * @param {function} props.onIntent
 */

const MessagePublishersList = (props) => {
	const { f } = useI18n();

	const { messagePublishers, selectedId, onSelect, onIntent } = props;

	return (
		<div className="message-publishers-list">
			<label>
				{f('message publishers')}
			</label>
			<List>{messagePublishers?.list?.map(publisher =>
				<MessagePublisherListItem
					className={className({ 'selected': (selectedId && selectedId === publisher.processorId) })}
					key={publisher.processorId}
					publisher={publisher}
					onSelect={onSelect}
					onIntent={onIntent}
				/>)}
			</List>
		</div>);
}

export default connect(
	state => ({
		messagePublishers: state.processors.messagePublishers
	})
)(MessagePublishersList);
