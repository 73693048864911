import React, { useState } from 'react';
import { fromEPSG4326 } from 'ol/proj/epsg3857';
import { boundingExtent } from 'ol/extent';
import { useI18n } from '../../../../../i18n';
import searchIcon from '../../../../img/icons/16/search.png';
import Form from '../../../general/form/Form';
import ActionClose from '../../../share/actionbar/ActionClose';
import './mapSearchControl.scss';

const geocoder = new window.google.maps.Geocoder();

/**
 * @param {Object} props
 * @param {function} props.onReady
 * @param {function} [props.onCancel]
 * @param {Object} [props.customRef]
 */

function MapSearchControl(props) {
	const { f } = useI18n();
	const [address, setAddress] = useState('');
	const [error, setError] = useState(null);
	const style = {
		color: error && 'red',
	}

	const onSubmit = () => {
		if (address.trim() != '') {
			geocoder.geocode({ 'address': address }, (results, status) => {
				if (status == 'OK') {
					const southWest = results[0].geometry.viewport.getSouthWest();
					const northEast = results[0].geometry.viewport.getNorthEast();
					const firstCoordinate = fromEPSG4326([northEast.lng(), northEast.lat()]);
					const secondCoordinate = fromEPSG4326([southWest.lng(), southWest.lat()]);
					const extent = new boundingExtent([firstCoordinate, secondCoordinate]);
					props.onReady(extent);
				} else {
					setError(status);
				}
			});
		}
	}

	const handleChange = (value) => {
		if (error) setError(null);
		setAddress(value);
	}

	return (
		<div className="map-search-control" ref={props.customRef}>
			<div className="header">
				<div className="title">
					{f('address')}
				</div>
				<ActionClose onClick={props.onCancel} />
			</div>
			<Form onSubmit={onSubmit}>
				<div className="wrap">
					<Form.Control
						controlName="searchMap"
						controlType={Form.Control.Type.Input}
						style={style}
						type="text"
						placeholder={f('search')}
						onChange={handleChange}
						value={address}
						autoFocus
					/>
					{address
						&& address.trim() !== ''
						&& <div className="icon-button" onClick={onSubmit}>
							<img src={searchIcon} alt="" className="icon" />
						</div>
					}
				</div>
			</Form>
		</div>
	)
}

export default MapSearchControl;