import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from 'react-redux';
import { actions as sessionActions } from './core/redux/api/session';

import ProtectedRoute from './ProtectedRoute';
import CenterLayout from './core/react/layout/CenterLayout';
import GeneralLayout from './core/react/layout/GeneralLayout';
import DashboardLayout from './core/react/layout/DashboardLayout';

import InitialPage from './core/react/pages/InitialPage';
import LoginPage from './core/react/pages/LoginPage';
import DashboardPage from './core/react/pages/DashboardPage';
import TimeMachinePage from './core/react/pages/TimeMachinePage';
import ReportsPage from './core/react/pages/ReportsPage';
import ZonesPage from './core/react/pages/ZonesPage';
import DevicePropertiesPage from './core/react/pages/DevicePropertiesPage';
import ZoneGaurdsPage from './core/react/pages/ZoneGuardsPage';
import DevicePresenceDetectorsPage from './core/react/pages/DevicePresenceDetectorsPage';
import AttributeRangeDetectorsPage from 'core/react/pages/attribute-range-detectors';
import FuelLevelProcessorsPage from './core/react/pages/FuelLevelProcessorsPage';
import DeviceStatusDetectorsPage from './core/react/pages/DeviceStatusDetectorsPage';
import DownsampledMessageHistoryPage from './core/react/pages/downsampled-message-history';
import MessagePublishersPage from './core/react/pages/MessagePublishersPage';
import MqttClientsPage from './core/react/pages/MqttClientsPage';
import DeviceControlPanelPage from './core/react/pages/DeviceControlPanelPage';
import DeviceCardPage from './core/react/custom/deviceControlPanel/pages/DeviceCardPage';
import DeviceCommandsPage from './core/react/pages/DeviceCommandsPage';
import EmployeeManagerPage from './core/react/pages/EmployeeManagerPage';
import CommuteDetectorsPage from './core/react/pages/CommuteDetectorsPage';
import FlowProcessorsPage from './core/react/pages/FlowProcessorsPage';

const buildCrudRoutes = (Component, Layout, extentions) => {
	const url = Component.Url;
	// keys are intentionally the same, different keys causes components unmount
	return (
		<Switch>
			{extentions && extentions.map(extention =>
				<ProtectedRoute key={1} path={url + extention} component={Component} wrap={Layout} exact />
			)}
			<ProtectedRoute key={1} path={url+ '(/)?'} component={Component} wrap={Layout} exact />
			<ProtectedRoute key={1} path={url + '/(create)'} component={Component} wrap={Layout} exact />
			<ProtectedRoute key={1} path={url + '/:id'} component={Component} wrap={Layout} exact />
			<ProtectedRoute key={1} path={url + '/:id/(edit)'} component={Component} wrap={Layout} exact />
		</Switch>
	)
}

function Routes(props) {
	useEffect(() => {
		if (!props.initialized) props.dispatch(sessionActions.restore());
	}, [props.initialized]);

	return (
		<Router basename={process.env.REACT_APP_URL_PREFIX}>
			<Route exact path="(/)?" render={() => (
				<CenterLayout>
					<InitialPage />
				</CenterLayout>
			)} />
			<Route path="/login" render={() => (
				<LoginPage />
			)} />
			<ProtectedRoute
				path={DashboardPage.Url + '(/devices)?/:uris?'}
				component={DashboardPage}
				wrap={DashboardLayout}
				reduxKey={'dashboard'}
			/>
			<ProtectedRoute
				path={TimeMachinePage.Url + '(/devices)?/:uris?'}
				component={TimeMachinePage}
				wrap={DashboardLayout}
				reduxKey={'timeMachine'}
			/>
			<ProtectedRoute
				 path={DevicePropertiesPage.Url}
				 component={DevicePropertiesPage}
				 wrap={GeneralLayout}
			/>
			<ProtectedRoute
				path={[
					ReportsPage.Url + '/:type/:mode(schedules)'
					, ReportsPage.Url + '/:type?/:phase(view)?'
				]}
				component={ReportsPage}
				wrap={GeneralLayout}
			/>
			<ProtectedRoute
				path={ReportsPage.Url}
				component={ReportsPage}
				wrap={GeneralLayout}
				exact
			/>
			{buildCrudRoutes(AttributeRangeDetectorsPage)}
			{buildCrudRoutes(CommuteDetectorsPage, GeneralLayout)}
			{buildCrudRoutes(DevicePresenceDetectorsPage, GeneralLayout)}
			{buildCrudRoutes(DeviceStatusDetectorsPage, GeneralLayout)}
			{buildCrudRoutes(DownsampledMessageHistoryPage)}
			{buildCrudRoutes(EmployeeManagerPage, GeneralLayout, ['/(import)'])}
			{buildCrudRoutes(FuelLevelProcessorsPage, GeneralLayout)}
			{buildCrudRoutes(FlowProcessorsPage, GeneralLayout)}
			{buildCrudRoutes(MessagePublishersPage, GeneralLayout)}
			{buildCrudRoutes(MqttClientsPage, GeneralLayout)}
			{buildCrudRoutes(ZoneGaurdsPage, GeneralLayout)}
			{buildCrudRoutes(ZonesPage, GeneralLayout, [
				'/:id/(edit)/:areaAt',
				'/:id/(edit)/:areaAt/(edit)',
				'/(create)/:areaAt',
				'/(create)/:areaAt/(edit)'
			])}
			<ProtectedRoute
				exact
				path="/device/:uri/control-panel(/)?"
				component={DeviceControlPanelPage}
				wrap={GeneralLayout}
			/>
			<ProtectedRoute
				path="/device/:uri/(card)"
				component={DeviceCardPage}
				wrap={GeneralLayout}
			/>
			<ProtectedRoute
				path="/device/:uri/commands"
				component={DeviceCommandsPage}
				wrap={GeneralLayout}
			/>
		</Router>
	);
}

export default connect(state => ({ initialized: state.session.initialized }))(Routes);
