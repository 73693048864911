import React from 'react';
import { NavLink } from 'react-router-dom';
import { capitalize } from '../../../misc/misc';
import { className, fromProps } from '../../../lib/className';
import './navLinkItem.scss';

/**
 * @param {Object} props
 * @param {string} props.to href
 * @param {string} [props.title]
 * @param {boolean} [props.disabled]
 */

function NavLinkItem(props) {

	const onClick = (event) => {
		props.disabled && event.preventDefault();
	}

	return (
		<li className={className('nav-link-item', fromProps(props))}>
			<NavLink
				to={props.to}
				className={className({ 'disabled': props.disabled })}
				title={props.disabled ? null : capitalize(props.title)}
				onClick={onClick}
				activeClassName="active"
			>
				{props.children}
			</NavLink>
		</li>
	)
}

export default NavLinkItem;