import React from 'react';
import { useI18n } from '../../../../i18n';
import { className, fromProps } from '../../../lib/className';
import ActionBar from '../../general/actionbar/ActionBar'
import icon from '../../../img/icons/plus-bound.png';
import './actionAdd.scss';

/**
 * @param {Object} props
 * @param {function} props.onClick
 * @param {string} [props.title]
 * @param {boolean} [props.disabled]
 */

function ActionAdd(props) {
	const { f } = useI18n();
	return (
		<ActionBar.Action
			className={className('add', fromProps(props))}
			onClick={props.onClick}
			disabled={props.disabled}
			title={props.title || f('add')}
		>
			<img src={icon} alt="" />
		</ActionBar.Action>
	);
}

export default ActionAdd;