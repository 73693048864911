import styled from 'styled-components';

import { Colors } from 'ui/constants/colors';

export const EntitiesActionsStyled = styled.div`
  display: flex;
  align-items: center;
  height: 70px;
  padding: 0 15px;

  .drag {
    cursor: move;
  }

  .action.disabled {
    color: ${Colors.SilverMedal};
  }

  .action.disabled img {
    filter: invert(47%) sepia(7%) saturate(21%) opacity(0.3) hue-rotate(357deg) brightness(90%) contrast(92%) !important;
  }

  .action.pale img {
    filter: invert(47%) sepia(7%) saturate(21%) opacity(0.3) hue-rotate(357deg) brightness(90%) contrast(92%);
  }

  & > * img {
    filter: invert(47%) sepia(7%) saturate(21%) hue-rotate(357deg) brightness(90%) contrast(92%);
    pointer-events: none;
  }

  .action.draggable img {
    filter: invert(85%) sepia(13%) saturate(1003%) hue-rotate(131deg) brightness(91%) contrast(87%);
  }

  .action {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  .action.drag {
    cursor: move;
  }

  .action.active:not(.disabled) {
    color: ${Colors.AquaBelt};
  }

  .action.active:not(.disabled) img {
    filter: invert(85%) sepia(13%) saturate(1003%) hue-rotate(131deg) brightness(91%) contrast(87%);
  }
`;

export const EntitiesActionsIconStyled = styled.div`
  margin-right: 5px;
`;

export const EntitiesActionsTitleStyled = styled.div`
  font-size: larger;
  font-weight: bold;
  margin-right: 5px;
`;

export const EntitiesActionsButtonsStyled = styled.div`
  display: flex;

  & > div {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
`;
