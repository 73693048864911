import React from 'react';
import { useI18n } from '../../../../i18n';

/**
 * @param {Object} props
 * @param {cx.ods.processors.DevicePresenceDetector} props.detector
 */

function PresenceDetectorCard(props) {
	const { f } = useI18n();
	return (
		<div className="presence-detector card">
			<div className="name">
				<label>{f('name')}</label>
				<div>{props.detector.name}</div>
			</div>
			{(props.detector.onPresent || props.detector.onAbsent) &&
				<div className="options">
					<label>{f('options')}</label>
					{props.detector.onPresent && <div className="option">{f('on present')}</div>}
					{props.detector.onAbsent && <div className="option">{f('on absent')}</div>}
				</div>
			}
		</div>
	);
}

export default PresenceDetectorCard;