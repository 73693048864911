import { store } from '../redux/store';

class AssetType {

	constructor(category) {
		this.category = category;
	}

	getCategory() {
		return this.category;
	}

	getType() {
		return this.category.mnemonics;
	}

	getName() {
		return this.category.name;
	}

}

/**
 * Looks through list of categories and find first occurance of asset type category.
 * @param {Array.<number>} categoryIds
 * @param {Object} [map] map of asset type categories
 * @returns {AssetType}
 */

export const getAssetType = (categoryIds, map) => {
	let result = null;
	if (categoryIds) {
		map = map || store.getState().categories.assetTypes.map;
		const categoryId = map && categoryIds.find(id => map[id]);
		if (categoryId != null) result = new AssetType(map[categoryId]);
	}
	return result;
}