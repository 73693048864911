import React from 'react';
import { Popover, Position } from '@blueprintjs/core';
import { className, fromProps } from '../../lib/className';

/**
 * @param {Object} props
 * @param {boolean} [props.usePortal]
 * @param {Position} props.position
 * @param {React.Component|Array.<React.Component>} props.content
 * @param {boolean} props.isOpen
 * @param {function} props.setIsOpen
 * @param {boolean} [props.disabled]
 * @param {boolean} [props.enforceFocus]
 * @param {React.Ref} [props.customRef]
 */

function Popup(props) {
	const _className = className(
		'popup',
		fromProps(props),
		{
			'active': props.isOpen,
			'clickable': props.setIsOpen,
			'disabled': props.disabled
		}
	);

	return (
		<div className={_className} title={props.title}>
			<Popover
				isOpen={props.isOpen}
				usePortal={props.usePortal !== undefined ? props.usePortal : false}
				fill={props.fill !== undefined ? props.fill : false}
				position={props.position || Position.BOTTOM_LEFT}
				content={props.content}
				enforceFocus={props.enforceFocus !== undefined ? props.enforceFocus : true}
				captureDismiss={true}
				disabled={props.disabled}
				onInteraction={props.setIsOpen}
				minimal={props.minimal !== undefined ? props.minimal : true}
				popoverClassName={(props.className ? (' ' + props.className) : '') + '-popover'}
				modifiers={{ preventOverflow: { enabled: false } }}
			>
				<div ref={props.customRef} className="wrap">
					{props.children}
				</div>
			</Popover>
		</div>
	);
}

export default Popup;
