import React, { useState, useEffect, useRef } from 'react';
import tinycolor from 'tinycolor2';
import { className, fromProps } from '../../../lib/className';
import ColorPreviewBox from './ColorPreviewBox';
import ColorPicker from './ColorPicker';
import { isEscapeButton } from '../../../misc/misc';
import { cx } from '../../../api';
import './colorPickerControl.scss';

/**
 * @readonly
 * @enum {Type}
 */
export const ColorFormat = {
	Hex: 'hex', // string
	OldHue: 'oldHue', // number
	Hsl: 'hsl', // object
	Rgb: 'rgb', // object
	Hsv: 'hsv' // object
}

/**
 * @param {Object} props
 * @param {string} props.value
 * @param {function} props.onChange
 * @param {ColorFormat} [props.colorFormat] by default tinycolor object should be used
 * @param {string} [props.label]
 * @param {boolean} [props.disabled]
 * @param {function} [props.onOpenChange]
 */

function ColorPickerControl(props) {
	const [color, setColor] = useState({});
	const [show, setShow] = useState(false);
	const pickerBox = useRef();

	const onShowHandler = (value) => {
		if (props.onOpenChange) props.onOpenChange(value);
		setShow(value);
	}

	useEffect(() => {
		const hex = props.value
			? tinycolor(props.value).toHexString()
			: '#ffffff'
		;
		setColor({ hex });
	}, [props]);

	useEffect(() => {
		const escapeHandler = (event) => {
			if (isEscapeButton(event)) {
				onShowHandler(false);
			}
		}
		const onClick = (event) => {
			const insidePopover = cx.dom.i.traverse(pickerBox.current, (node) => node == event.target);
			if (!insidePopover) {
				onShowHandler(false);
			}
		}
		props.onOpenChange && props.onOpenChange(show);
		if (show) {
			window.addEventListener('keydown', escapeHandler);
			document.body.addEventListener('click', onClick);
		}
		return () => {
			if (show) {
				window.removeEventListener('keydown', escapeHandler);
				document.body.removeEventListener('click', onClick);
			}
		}
	}, [show]);

	const onChange = (color) => {
		setColor(color);
		const result = props.colorFormat && color[props.colorFormat];
		props.onChange(result || color);
	}

	return (
		<div className={className('color-picker-control', fromProps(props))}>
			{props.label && <label>{props.label}</label>}
			<div className="swatch" onClick={() => !props.disabled && onShowHandler(!show)}>
				<ColorPreviewBox hex={color.hex} className={className({ 'disabled': props.disabled })} />
			</div>
			{show &&
				<div className="popover" ref={pickerBox}>
					<ColorPicker color={props.value} onChange={onChange} />
				</div>
			}
		</div>
	);
}

export default ColorPickerControl;