import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import { useI18n } from '../../../../../i18n';
import { actions as zonesActions } from '../../../../redux/api/zones';
import * as zoneCategories from '../../../../redux/app/categories/zone';
import { AllRoutes } from 'constants/routes';
import Intent from '../../../../misc/ObjectActionIntent';
import SidebarLayout from '../../../general/layout/SidebarLayout';
import ZoneCategorySelector from '../ZoneCategorySelector';
import ZoneList from '../ZoneList';
import ZoneListDropItem from '../ZoneListDropItem';
import ZoneSelectMap from './ZoneSelectMap';
import ActionAdd from './ActionAdd';
import DefaultActionBar from '../../../share/actionbar/DefaultActionBar';
import ZoneCategoryForm from '../popup/ZoneCategoryForm';
import reportIcon from '../../../../img/icons/report.png';
import RemoveDialog from '../../../general/RemoveDialog';
import ObjectActionDialogPopup from '../../../general/ObjectActionDialogPopup';
import ZoneFilter from '../ZoneFilter';
import './zoneViewer.scss';

/**
 * @param {Object} props
 */

function ZoneViewer(props) {
	const { f } = useI18n();
	const history = useHistory();
	const pending = useRef(false);
	const [intent, setIntent] = useState(null);
	const [zoneIds, setZoneIds] = useState([]);
	const [lastSelectedId, setLastSelectedId] = useState(null);
	const disabled = props.zones.pending || props.categories.pending;

	useEffect(() => {
		if (props.zones.map == null && !props.zones.pending) {
			props.dispatch(zonesActions.load.request());
		}
		return () => {
			props.dispatch(zonesActions.setCategoryFilter({}));
			props.dispatch(zonesActions.setFilter({}));
		};
	}, []);

	useEffect(() => {
		const zoneIds = props.match.params.id ? [parseInt(props.match.params.id)] : [];
		setZoneIds(zoneIds);
	}, [props.match.params]);

	useEffect(() => {
		props.categories.error != null && props.dispatch(zoneCategories.actions.clear());
		props.zones.error != null && props.dispatch(zonesActions.clear());
	}, [intent]);

	useEffect(() => {
		if (!props.categories.pending && pending.current) {
			pending.current = false;
			props.categories.error == null && onPopupClose();
		}
	}, [props.categories]);

	useEffect(() => {
		if (!props.zones.pending && pending.current) {
			pending.current = false;
			props.zones.error == null && onPopupClose();
		}
	}, [props.zones]);

	useEffect(() => { // Replace the URL when the selected zone is not in the list.
		if (props.zones.filtered != null && zoneIds.length > 0 && !props.zones.filtered.some(zone => zone.zoneId != [zoneIds[0]])) {
			history.replace(AllRoutes.Zones);
		}
	}, [props.zones.filteredList, zoneIds]);

	const onPopupClose = () => {
		setIntent(null);
	}

	const onSelect = (zoneIds) => {
		const zoneId = zoneIds.length > 0 ? zoneIds[0] : null;
		history.push(`${AllRoutes.Zones}/${zoneId || ''}`);
		setLastSelectedId(zoneId);
	}

	const onIntent = (intent) => {
		if (intent.subject() == 'zone') {
			if (intent.action() == Intent.Action.Edit) {
				history.push(`${AllRoutes.Zones}/${intent.object().zoneId}/edit`);
			} else if (intent.action() == Intent.Action.Remove) {
				setIntent(intent);
			} else if (intent.action() == Intent.Action.Add) {
				history.push(`${AllRoutes.Zones}/create`);
			}
		} else if (intent.subject() == 'category') {
			setIntent(intent);
		}
	}

	const onRemove = (zone) => {
		pending.current = true;
		props.dispatch(zonesActions.remove.request({ id: zone.zoneId }));
	}

	const onCategorySubmit = () => {
		onPopupClose();
	}

	const onCategoryRemove = (category) => {
		pending.current = true;
		props.dispatch(zoneCategories.actions.remove.request({ categoryId: category.categoryId }));
	}

	return (
		<SidebarLayout
			className="zone-x zone-viewer"
			icon={<img src={reportIcon} alt="" />}
			title={f('zones')}
			sidebarContent={<>
				<DefaultActionBar
					disabled={disabled}
					prependActions={<ActionAdd onIntent={onIntent} />}
				/>
				<ZoneFilter />
				<div className="lists">
					<ZoneCategorySelector
						selectedIds={props.zones.categoryFilter}
						onChange={(categoryIds) => props.dispatch(zonesActions.setCategoryFilter({ categoryIds }))}
						onIntent={onIntent}
					/>
					<ZoneList
						itemType={ZoneListDropItem}
						selectedIds={zoneIds}
						onChange={onSelect}
						lastSelectedId={lastSelectedId}
						onIntent={onIntent}
						singleSelection
					/>
				</div>
			</>}
		>
			<ZoneSelectMap
				selectedIds={zoneIds}
				onChange={onSelect}
				onIntent={onIntent}
				singleSelection
			/>
			{intent && intent.subject() == 'zone' && intent.action() == Intent.Action.Remove && (
				<ObjectActionDialogPopup
					disabled={disabled}
					error={props.zones.error}
					offset={intent.data().offset}
					onClose={onPopupClose}
					title={f('delete zone')}
				>
					<RemoveDialog
						object={intent.object()}
						text={f('are you sure you want to delete zone', { name: intent.object().name })}
						onSubmit={onRemove}
						onCancel={onPopupClose}
					/>
				</ObjectActionDialogPopup>
			)}
			{intent && intent.subject() == 'category' && intent.action() == Intent.Action.Remove && (
				<ObjectActionDialogPopup
					disabled={disabled}
					error={props.categories.error}
					offset={intent.data().offset}
					onClose={onPopupClose}
					title={f('delete tag')}
				>
					<RemoveDialog
						object={intent.object()}
						title={f('tag')}
						text={f('are you sure you want to delete tag', { name: intent.object().name })}
						onCancel={onPopupClose}
						onSubmit={onCategoryRemove}
					/>
				</ObjectActionDialogPopup>
			)}
			{intent
				&& intent.subject() == 'category'
				&& (intent.action() == Intent.Action.Edit || intent.action() == Intent.Action.Add) && (
				<ObjectActionDialogPopup
					offset={intent.data().offset}
					onClose={onPopupClose}
					title={intent.object() ? f('edit tag') : f('create tag')}
				>
					<ZoneCategoryForm category={intent.object()} onSubmit={onCategorySubmit} />
				</ObjectActionDialogPopup>
			)}
		</SidebarLayout>
	);
}

export default connect(
	state => ({
		zones: state.zones,
		categories: state.categories.zones
	})
)(withRouter(ZoneViewer));
