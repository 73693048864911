export const nullityEqual = (left, right, thenEqual = null) => {
	if ((left == null) != (right == null)) return false;
	if (left == null || thenEqual == null) return true;
	return thenEqual(left, right);
};

export const shallowEqual = (left, right) => {
	if (left === right) return true;
	const leftKeys = Object.keys(left);
	if (leftKeys.length != Object.keys(right).length) return false;
	for (const key of leftKeys) {
		if (!(key in right)) return false;
		if (left[key] !== right[key]) return false;
	}
	return true;
};
