import { cx, api, rx } from '../../../api';
import { reduxSwitch } from '../../tools';
import { ofType, combineEpics } from 'redux-observable';
import { exhaustMap, mergeMap, map as rxmap, takeUntil } from 'rxjs/operators';
import { of } from 'rxjs';
import { ActionGeneratorBuilder, errorMap } from '../../actions';

const defaultState = {
	list: null,
	map: null, // propertyId => cx.ods.meta.Property
	last: null, // property from the last add or retrieve
	pending: false,
	error: null
};

const actions = new ActionGeneratorBuilder('meta-properties')
	.subtype('add', add => add.request('property').success('property').fail().cancel())
	.subtype('load', load => load.request().success('properties').fail().cancel())
	.subtype('retrieve', retrieve => retrieve.request('propertyId').success('property').fail().cancel())
	.subtype('update', update => update.request({ propertyId: true, property: true }).success().fail().cancel())
	.subtype('remove', remove => remove.request('propertyId').success().fail().cancel())
	.type('clear')
	.build()
;

// ---------------------------------------------------------

function loadReducer(state, action) {
	switch (action.type) {
		case actions.load.request.type:
			return {
				...state,
				pending: true,
				error: null
			};
		case actions.load.success.type:
			return {
				...state,
				list: action.properties,
				map: cx.i.hash(action.properties, (property) => property.propertyId),
				pending: false
			};
		case actions.load.fail.type:
			return {
				...state,
				pending: false,
				error: action.errorMessage
			};
		case actions.load.cancel.type:
			return {
				...state,
				pending: false
			};
		default:
			return state;
	}
}

function addReducer(state, action) {
	switch (action.type) {
		case actions.add.request.type:
			return {
				...state,
				pending: true,
				error: null
			};
		case actions.add.success.type:
			return {
				...state,
				pending: false,
				last: action.property
			};
		case actions.add.fail.type:
			return {
				...state,
				pending: false,
				error: action.errorMessage
			};
		case actions.add.cancel.type:
			return {
				...state,
				pending: false
			};
		default:
			return state;
	}
}

function retrieveReducer(state, action) {
	switch (action.type) {
		case actions.retrieve.request.type:
			return {
				...state,
				pending: true,
				error: null
			};
		case actions.retrieve.success.type:
			return {
				...state,
				pending: false,
				last: action.property
			};
		case actions.retrieve.fail.type:
			return {
				...state,
				pending: false,
				error: action.errorMessage
			};
		case actions.retrieve.cancel.type:
			return {
				...state,
				pending: false
			};
		default:
			return state;
	}
}

function updateReducer(state, action) {
	switch (action.type) {
		case actions.update.request.type:
			return {
				...state,
				pending: true,
				error: null
			};
		case actions.update.success.type:
			return {
				...state,
				pending: false
			};
		case actions.update.fail.type:
			return {
				...state,
				pending: false,
				error: action.errorMessage
			};
		case actions.update.cancel.type:
			return {
				...state,
				pending: false
			};
		default:
			return state;
	}
}

function removeReducer(state, action) {
	switch (action.type) {
		case actions.remove.request.type:
			return {
				...state,
				pending: true,
				error: null
			};
		case actions.remove.success.type:
			return {
				...state,
				pending: false
			};
		case actions.remove.fail.type:
			return {
				...state,
				pending: false,
				error: action.errorMessage
			};
		case actions.remove.cancel.type:
			return {
				...state,
				pending: false
			};
		default:
			return state;
	}
}

function clearReducer(state, action) {
	switch (action.type) {
		case actions.clear.type:
			return {
				...state,
				error: null
			};
		default:
			return state;
	}
}

const reducer = reduxSwitch([loadReducer, addReducer, retrieveReducer, updateReducer, removeReducer, clearReducer], defaultState);

// ---------------------------------------------------------

const loadEpic = (action$) => {
	return action$.pipe(
		ofType(actions.load.request.type),
		exhaustMap(action =>
			rx(api.meta.properties.load).pipe(
				rxmap(operation => actions.load.success({ properties: operation.response() })),
				errorMap(actions.load.fail),
				takeUntil(action$.pipe(ofType(actions.load.cancel.type)))
			)
		)
	)
}

const addEpic = (action$) => {
	return action$.pipe(
		ofType(actions.add.request.type),
		mergeMap(action =>
			rx(api.meta.properties.add, action.property).pipe(
				mergeMap(operation => of(actions.add.success({ property: operation.response() }), actions.load.request())),
				errorMap(actions.add.fail),
				takeUntil(action$.pipe(ofType(actions.add.cancel.type)))
			)
		)
	)
}

const retrieveEpic = (action$) => {
	return action$.pipe(
		ofType(actions.retrieve.request.type),
		mergeMap(action =>
			rx(api.meta.properties.retrieve, action.propertyId).pipe(
				rxmap(operation => actions.retrieve.success({ property: operation.response() })),
				errorMap(actions.retrieve.fail),
				takeUntil(action$.pipe(ofType(actions.retrieve.cancel.type)))
			)
		)
	)
}

const updateEpic = (action$) => {
	return action$.pipe(
		ofType(actions.update.request.type),
		mergeMap(action =>
			rx(api.meta.properties.update, action.propertyId, action.property).pipe(
				mergeMap(operation => of(actions.update.success(), actions.load.request())),
				errorMap(actions.update.fail),
				takeUntil(action$.pipe(ofType(actions.update.cancel.type)))
			)
		)
	)
}

const removeEpic = (action$) => {
	return action$.pipe(
		ofType(actions.remove.request.type),
		mergeMap(action =>
			rx(api.meta.properties.remove, action.propertyId).pipe(
				mergeMap(operation => of(actions.remove.success(), actions.load.request())),
				errorMap(actions.remove.fail),
				takeUntil(action$.pipe(ofType(actions.remove.cancel.type)))
			)
		)
	)
}

const epic = combineEpics(loadEpic, addEpic, retrieveEpic, updateEpic, removeEpic);

// ---------------------------------------------------------

export { actions, reducer, epic };
