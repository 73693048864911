import { ActionGeneratorBuilder } from "../../actions";

export const actions = new ActionGeneratorBuilder('pages')
	.subtype('dashboard',
		dashboard => dashboard
			.type('setActive', 'status')
			.type('setMultiple', 'status')
			.type('selectionChanged', 'uri')
			.type('setSelected', 'uri')
			.type('setSelection', 'uris')
			.type('setSelectionAll')
			.type('selectionClear')
	)
	.subtype('timeMachine',
		timeMachine => timeMachine
			.type('setActive', 'status')
			.type('setMultiple', 'status')
			.type('selectionChanged', 'uri')
			.type('setSelection', 'uris')
			.type('setSelectionAll')
			.type('selectionClear')
	)
	.build()
;
