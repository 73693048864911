import { cx, api, rx } from '../../api';
import { reduxSwitch } from '../tools';
import { ofType, combineEpics } from 'redux-observable';
import { switchMap, exhaustMap, map as rxmap, takeUntil } from 'rxjs/operators';
import { actions as sessionActions } from './session';
import { ActionGeneratorBuilder, errorMap } from '../actions';

const defaultState = {
	list: null,
	map: null, // categoryId => CategoryData
	pending: false,
	error: null
};

const actions = new ActionGeneratorBuilder('categories')
	.subtype('add', add => add.request('data').success('data').fail().cancel())
	.subtype('load', load => load.request().success('categories').fail().cancel())
	.subtype('update', update => update.request({ categoryId: true, data:true }).success().fail().cancel())
	.subtype('remove', remove => remove.request('categoryId').success().fail().cancel())
	.type('clear')
	.build()
;

// ---------------------------------------------------------

function processCategories(categories) {
	return cx.i.hash(categories, category => category.categoryId);
}

function addReducer(state = defaultState, action) {
	switch (action.type) {
		case actions.add.request.type:
			return {
				...state,
				pending: true,
				error: null
			};
		case actions.add.fail.type:
			return {
				...state,
				pending: false,
				error: action.errorMessage
			};
		case actions.add.cancel.type:
			return {
				...state,
				pending: false
			}
		default:
			return state;
	}

}

function loadReducer(state, action) {
	switch (action.type) {
		case actions.load.request.type:
			state = {
				...state,
				pending: true
			};
			break;
		case actions.load.success.type:
			state = {
				...state,
				list: action.categories,
				map: processCategories(action.categories),
				pending: false,
				error: null
			};
			break;
		case actions.load.fail.type:
			state = {
				...state,
				pending: false,
				error: action.errorMessage
			};
			break;
		case actions.load.cancel.type:
			state = {
				...state,
				pending: false
			}
			break;
		default:
	}
	return state || defaultState;
}

function updateReducer(state = defaultState, action) {
	switch (action.type) {
		case actions.update.request.type:
			return {
				...state,
				pending: true
			};
		case actions.update.fail.type:
			return {
				...state,
				pending: false,
				error: action.errorMessage
			};
		case actions.update.cancel.type:
			return {
				...state,
				pending: false
			}
		default:
			return state;
	}
}

function removeReducer(state = defaultState, action) {
	switch (action.type) {
		case actions.remove.request.type:
			return {
				...state,
				pending: true,
				error: null
			};
		case actions.remove.fail.type:
			return {
				...state,
				pending: false,
				error: action.errorMessage
			};
		case actions.remove.cancel.type:
			return {
				...state,
				pending: false
			}
		default:
			return state;
	}
}

function clearReducer(state, action) {
	switch (action.type) {
		case actions.clear.type:
			return {
				...state,
				error: null
			};
		default:
			return state;
	}
}

const reducer = reduxSwitch([addReducer, loadReducer, updateReducer, removeReducer, clearReducer], defaultState);

// ---------------------------------------------------------

const addEpic = (action$) => {
	return action$.pipe(
		ofType(actions.add.request.type),
		switchMap(action =>
			rx(api.categories.add, action.data).pipe(
				rxmap(operation => actions.load.request()),
				errorMap(actions.add.fail),
				takeUntil(action$.pipe(ofType(actions.add.cancel.type)))
			)
		)
	)
}

const loadEpic = (action$) => {
	return action$.pipe(
		ofType(actions.load.request.type),
		exhaustMap(action =>
			rx(api.categories.load).pipe(
				rxmap(operation => actions.load.success({ categories: operation.response() })),
				errorMap(actions.load.fail),
				takeUntil(action$.pipe(ofType(actions.load.cancel.type)))
			)
		)
	)
}

const updateEpic = (action$) => {
	return action$.pipe(
		ofType(actions.update.request.type),
		switchMap(action =>
			rx(api.categories.update, action.categoryId, action.data).pipe(
				rxmap(operation => actions.load.request()),
				errorMap(actions.update.fail),
				takeUntil(action$.pipe(ofType(actions.update.cancel.type)))
			)
		)
	)
}

const removeEpic = (action$) => {
	return action$.pipe(
		ofType(actions.remove.request.type),
		switchMap(action =>
			rx(api.categories.remove, action.categoryId).pipe(
				rxmap(operation => actions.load.request()),
				errorMap(actions.remove.fail),
				takeUntil(action$.pipe(ofType(actions.remove.cancel.type)))
			)
		)
	)
}

const watchSessionEpic = (action$) => {
	return action$.pipe(
		ofType(sessionActions.events.started.type),
		rxmap(action => actions.load.request())
	)
}

const epic = combineEpics(addEpic, loadEpic, updateEpic, removeEpic, watchSessionEpic);

// ---------------------------------------------------------

export { actions, reducer, epic };
