import React, { useCallback, useRef } from 'react';
import ListItem from '../../general/list/ListItem';
import { DragItemType } from '../../share/dnd/DragItemType';
import { useDrop } from 'react-dnd';
import { cx } from '../../../api';
import Intent from '../../../misc/ObjectActionIntent';
import Checkbox from '../../general/form/Checkbox';
import { className, fromProps } from '../../../lib/className';
import { FiCheckCircle } from 'react-icons/fi';
import { FaRegTimesCircle } from 'react-icons/fa';
import { useI18n } from '../../../../i18n';
import { isValidEmployee } from '../../../redux/api/assets/employees/reducer';

/**
 * @param {Object} props
 * @param {cx.ods.assets.Employee} props.employee
 * @param {function} [props.onSelect]
 * @param {function} props.onIntent
 * @param {boolean} [props.selected]
 * @param {boolean} [props.checked]
 * @param {boolean} [props.onCheck]
 * @param {boolean} [props.editable]
 * @param {boolean} [props.withStatus]
 */

function EmployeeListItem(props) {
	const { fc } = useI18n();
	const boxRef = useRef();
	const checkable = Boolean(props.checked != undefined && props.onCheck != undefined);

	const onClick = () => {
		props.onSelect(props.employee);
	}

	const onDrop = useCallback((item) => {
		if (!props.employee.modifiable) return;
		if (item.type == DragItemType.ACTION_EDIT) props.onIntent(new Intent(null, Intent.Action.Edit, props.employee));
		else {
			const offset = cx.dom.at.offset(boxRef.current);
			offset.left = offset.left + boxRef.current.offsetWidth;
			props.onIntent(new Intent(null, Intent.Action.Remove, props.employee, { offset }));
		}
	}, []);

	const canDrop = useCallback(() => {
		return props.employee.modifiable && props.editable;
	}, [props.employee, props.editable]);

	const [dropState, dropRef] = useDrop({
		accept: [DragItemType.ACTION_EDIT, DragItemType.ACTION_REMOVE],
		canDrop,
		drop: onDrop,
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		})
	});

	const handleRef = (element) => {
		boxRef.current = element;
		dropRef(element);
	}

	const onCheck = (event) => {
		event.stopPropagation();
		props.onCheck(props.employee, !props.checked);
	}

	return (
		<ListItem
			customRef={handleRef}
			className={className(
				fromProps(props),
				{
					'selected': props.selected,
					'droppable':  dropState.isOver && dropState.canDrop,
					'canDrop': dropState.canDrop,
					'withStatus': props.withStatus,
					'wrong': !isValidEmployee(props.employee),
					checkable
				}
			)}
			onClick={props.onSelect ? onClick : null}
		>
			{checkable && <div onClick={onCheck}><Checkbox value={props.checked} /></div>}
			<div>
				{props.employee.designation}
			</div>
			<div>
				{props.employee.externalId}
			</div>
			<div>
				{props.employee.rfid}
			</div>
			{props.withStatus && <div title={props.employee.inactivatedAt ? fc('inactive') : fc('active')} className={className('status', props.employee.inactivatedAt ? 'inactive' : 'active')}>
				{props.employee.inactivatedAt ? <FaRegTimesCircle /> : <FiCheckCircle />}
			</div>}
		</ListItem>
	);
}

export default EmployeeListItem;
