export enum AllRoutes {
  Dashboard = '/dashboard',
  AttributeRangeDetectors = '/attribute-range-detectors',
  CommuteDetectors = '/commute-detectors',
  DevicePresenceDetectors = '/device-presence-detectors',
  DeviceProperties = '/device-properties',
  DeviceStatusDetectors = '/device-status-detectors',
  DownsampledMessageHistory = '/downsampled-message-history',
  EmployeeManager = '/employee-manager',
  FlowProcessors = '/flow-processors',
  FuelLevelProcessors = '/fuel-level-processors',
  MessagePublishers = '/message-publishers',
  MqttClients = '/mqtt-clients',
  ZoneGuards = '/zone-guards',
  Zones = '/zones',
}
