import React from 'react';
import { className as _className } from '../../../lib/className';
import { RadioGroup as RsRadioGroup } from 'rsuite';
import './radio.scss';

/**
 * For other parameters - see rsuite RadioGroup component
 * @param {Object} props
 * @param {string} [props.label]
 * @param {boolean} [props.inline]
 */

function RadioGroup(props) {
	const { label, className, inline, ..._props } = props;

	return (
		<div className={_className('radio-group', className, { inline })}>
			{label && <label>{label}</label>}
			<RsRadioGroup {..._props} />
		</div>
	);
}

export default RadioGroup;