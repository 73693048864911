import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, useParams, useHistory, useLocation } from 'react-router-dom';
import { AllRoutes } from 'constants/routes';
import Loader from '../../../general/Loader';
import { useI18n } from '../../../../../i18n';
import * as zoneCategories from '../../../../redux/app/categories/zone';
import Intent from '../../../../misc/ObjectActionIntent';
import { actions as zonesActions } from '../../../../redux/api/zones';
import ZoneForm from './ZoneForm';
import ZoneEditMap from './ZoneEditMap';
import ZoneFeatureList from './ZoneFeatureList';
import Zone from '../model/Zone';
import ActionAdd from './ActionAdd';
import DefaultActionBar from '../../../share/actionbar/DefaultActionBar';
import SidebarLayout from '../../../general/layout/SidebarLayout';
import ZoneCategoryForm from '../popup/ZoneCategoryForm';
import reportIcon from '../../../../img/icons/report.png';
import RemoveDialog from '../../../general/RemoveDialog';
import ObjectActionDialogPopup from '../../../general/ObjectActionDialogPopup';
import ZoneAreaEditMap from './area/ZoneAreaEditMap';
import ZoneAreaEditor from './area/ZoneAreaEditor';
import ActionBack from '../../../share/actionbar/ActionBack';
import './zoneEditor.scss';

export const SelectedFeatureContext = React.createContext({});
export const SelectedPointContext = React.createContext({});

/**
 * @param {Object} props
 * @param {string} props.action - 'edit' | 'create'
 * @param {number} props.areaAt
 * @param {boolean} props.areaEdit
 */

function ZoneEditor(props) {
	const { f } = useI18n();
	const history = useHistory();
	const { id: zoneId } = useParams();
	const location = useLocation();

	const zone = useRef(null);
	const map = useRef(null);
	const pending = useRef(false);
	const [pointAt, setPointAt] = useState(null);
	const [intent, setIntent] = useState(null);
	const basePath = props.action === 'edit' ? `${AllRoutes.Zones}/${zoneId}/edit` : `${AllRoutes.Zones}/create`;
	const disabled = props.zones.pending || props.categories.pending;

	if (props.zones.map != null) {
		const _zone = zoneId != null ? props.zones.map[zoneId] : null;
		if (zone.current == null) {
			if (props.action == 'edit' && !!_zone || props.action == 'create' && !_zone) {
				zone.current = new Zone(_zone);
			}
		}
	}

	let toRoot = false;
	let areaEdit = props.areaEdit;
	const areaId = zone.current && zone.current.areaAtId(props.areaAt);
	if (props.areaAt != null && areaId == null) {
		toRoot = true;
		areaEdit = false;
	}

	useEffect(() => {
		if (props.zones.map == null && !props.zones.pending) {
			props.dispatch(zonesActions.load.request());
		}
		return () => {
			if (zone.current != null) {
				zone.current.destroy();
				zone.current = null;
			}
		}
	}, []);

	useEffect(() => {
		if (props.categories.error != null) {
			props.dispatch(zoneCategories.actions.clear());
		}
	}, [intent]);

	useEffect(() => {
		if (props.zones.map != null && zoneId != null) { // edit existing zone
			const zone = props.zones.map[zoneId];
			if (zone == null) history.replace(AllRoutes.Zones);
		}
	}, [props.zones]);

	useEffect(() => {
		if (!props.categories.pending && pending.current) {
			pending.current = false;
			props.categories.error == null && onPopupClose();
		}
	}, [props.categories]);

	useEffect(() => {
		if (toRoot) {
			history.replace(basePath);
		}
	}, [toRoot]);

	const onSuccess = (id) => {
		let toId = props.action == 'create' && props.zones.addedId;
		if (!toId) toId = props.action == 'edit' && id;
		history.replace(`${AllRoutes.Zones}${toId ? `/${toId}` : ''}`);
	}

	const onIntent = (intent) => {
		if (intent.subject() == 'feature' && intent.action() == Intent.Action.Add) {
			map.current.addFeature();
		} else {
			setIntent(intent);
		}
	}

	const onPopupClose = () => {
		setIntent(null);
	}

	const onCategorySubmit = () => {
		onPopupClose();
	}

	const onCategoryRemove = (category) => {
		pending.current = true;
		props.dispatch(zoneCategories.actions.remove.request({ categoryId: category.categoryId }));
	}

	const onBack = () => {
		areaEdit ? history.goBack() : history.push(`${AllRoutes.Zones}${zoneId ? `/${zoneId}` : ''}`);
	}
	
	const onFeatureSet = (id, edit) => {
		if (!disabled) {
			const path = `${basePath}/${zone.current.areaIdAt(id)}${edit ? '/edit' : ''}`;

			location.pathname === basePath ? history.push(path) : history.replace(path);
		}
	}

	let sideContent = null;
	let content = null;
	if (zone.current != null) {
		sideContent = (<>
			<DefaultActionBar
				hideEdit={areaEdit}
				hideRemove={areaEdit}
				disabled={disabled}
				prependActions={<>
					<ActionBack disabled={disabled} onClick={onBack} />
					{!areaEdit && <ActionAdd onIntent={onIntent} />}
				</>}
			/>
			{areaEdit
				? <ZoneAreaEditor area={zone.current.area(areaId)} />
				: <ZoneForm
					zone={zone.current}
					onSuccess={onSuccess}
					onIntent={onIntent}
					disabled={disabled}
				>
					<div className="form-control">
						<label>{f('zone areas')}</label>
						<ZoneFeatureList zone={zone.current} emptyTitle={f('please create at least one area')} />
					</div>
				</ZoneForm>
			}
		</>);
		content = areaEdit
			? <ZoneAreaEditMap zone={zone.current} areaId={areaId} />
			: <ZoneEditMap customRef={map} zone={zone.current} />;
		;
	} else {
		sideContent = (<div className="vh-center"><Loader /></div>);
		content = (<div className="vh-center"><Loader /></div>);
	}

	return (
		<SelectedFeatureContext.Provider value={{
			value: areaId,
			set: onFeatureSet,
			clear: () => history.replace(basePath)
		}}>
			<SelectedPointContext.Provider value={{
				pointAt,
				set: (at) => setPointAt(at),
				clear: () => setPointAt(null)
			}}>
				<SidebarLayout
					className="zone-x zone-editor"
					icon={<img src={reportIcon} alt="" />}
					title={areaEdit ? f('zone area') : f('zone')}
					sidebarContent={sideContent}
				>
					{content}
					{intent && intent.subject() == 'category' && intent.action() == Intent.Action.Remove && (
						<ObjectActionDialogPopup
							offset={intent.data().offset}
							onClose={onPopupClose}
							disabled={disabled}
							error={props.categories.error}
						>
							<RemoveDialog
								object={intent.object()}
								title={f('tag')}
								text={f('are you sure you want to delete tag', { name: intent.object().name })}
								onCancel={onPopupClose}
								onSubmit={onCategoryRemove}
							/>
						</ObjectActionDialogPopup>
					)}
					{intent
						&& intent.subject() == 'category'
						&& (intent.action() == Intent.Action.Edit || intent.action() == Intent.Action.Add) && (
						<ObjectActionDialogPopup
							offset={intent.data().offset}
							onClose={onPopupClose}
							title={intent.category ? f('edit tag') : f("create tag")}
						>
							<ZoneCategoryForm
								category={intent.object()}
								onSubmit={onCategorySubmit}
							/>
						</ObjectActionDialogPopup>
					)}
				</SidebarLayout>
			</SelectedPointContext.Provider>
		</SelectedFeatureContext.Provider>
	);
}

export default connect(
	state => ({
		zones: state.zones,
		categories: state.categories.zones
	})
)(withRouter(ZoneEditor));
