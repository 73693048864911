import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../i18n';
import { actions as deviceFilterActions } from '../../../redux/app/deviceFilter';
import Input from '../../general/form/Input';

function DeviceSearchControl(props) {
	const { f } = useI18n();
	const inputRef = useRef(null);

	const keydownHandler = (event) => {
		if (event.ctrlKey && event.keyCode == 70) {
			event.preventDefault();
			inputRef.current.focus();
		}
	}

	useEffect(() => {
		window.addEventListener('keydown', keydownHandler);
		return () => {
			window.removeEventListener('keydown', keydownHandler);
			onClear(); // maybe bad idea...
		}
	}, []);

	const handleSearch = (value) => {
		props.dispatch(deviceFilterActions.search({ name: value }));
	}

	const onClear = () => {
		props.dispatch(deviceFilterActions.search({ name: '' }));
	}

	let content = null;
	if (props.groupings.root != null) {
		content = (
			<div className="device-search-control">
				<Input
					placeholder={f('device filter')}
					value={props.deviceFilter.name}
					onChange={handleSearch}
					inputRef={(element) => { inputRef.current = element; }}
					cleanable
				/>
			</div>
		)
	}

	return (content);
}

export default connect(
	state => ({
		groupings: state.categories.groupings,
		deviceFilter: state.deviceFilter
	})
)(DeviceSearchControl);
