import { Fill, Stroke, Circle, Style, Text } from 'ol/style';
import { MultiPoint, Point } from 'ol/geom';
import tinycolor from 'tinycolor2';
import GeometryType from 'ol/geom/GeometryType';
import { plainCoordinates } from '../../../misc/ol';

const STROKE_WIDTH = 2;
const ALPHA_DEFAULT = 0.3;
const ALPHA_BOLD = 0.8;
const COLOR_DEFAULT = '#c0c0c0';

/**
 * @enum {string}
 */
export const StyleType = {
	Default: 'default',
	Selected: 'selected',
	Modifying: 'modifying',
	AdvancedModifying: 'advancedModifying'
};

/**
 * @enum {number}
 */
export const ZIndex = {
	Default: 0,
	Selected: 2
};

const alpha = (styleType) => {
	return styleType == StyleType.Default ? ALPHA_DEFAULT : ALPHA_BOLD;
}

// text

const textStyle = (color, text) => {
	return new Text({
		font: 'bold 0.8rem \'Muli\'',
		text,
		fill: new Fill({ color }),
		stroke: new Stroke({ color: '#ffffff', width: 3 }),
	});
}

// point

const vertecesStyle = (color) => {
	return new Style({
		image: new Circle({
			radius: 5,
			fill: new Fill({ color })
		}),
		geometry: function (feature) {
			const geometry = feature.getGeometry();
			let coordinates = null;
			if (geometry.getType() == GeometryType.POINT) {
				coordinates = [geometry.getCoordinates()];
			} else if (geometry.getType() == GeometryType.LINE_STRING) {
				coordinates = geometry.getCoordinates();
			} else {
				coordinates = geometry.getCoordinates()[0];
			}
			return new MultiPoint(coordinates);
		}
	});
}

const invertedVertecesStyle = (color) => {
	return new Style({
		image: new Circle({
			radius: 5,
			fill: new Fill({ color: 'white' }),
			stroke: new Stroke({ color, width: 2 })
		}),
		geometry: function (feature) {
			const geometry = feature.getGeometry();
			let coordinates = null;
			if (geometry.getType() == GeometryType.POINT) {
				coordinates = [geometry.getCoordinates()];
			} else if (geometry.getType() == GeometryType.LINE_STRING) {
				coordinates = geometry.getCoordinates();
			} else {
				coordinates = geometry.getCoordinates()[0];
			}
			return new MultiPoint(coordinates);
		}
	});
}

// const _vertecesStyle = (style, color) => {
// 	style.setImage(new Circle({
// 		radius: 5,
// 		fill: new Fill({ color })
// 	}));
// 	style.setGeometry(function (feature) {
// 		const geometry = feature.getGeometry();
// 		let coordinates = null;
// 		if (geometry.getType() == GeometryType.POINT) {
// 			coordinates = [geometry.getCoordinates()];
// 		} else if (geometry.getType() == GeometryType.LINE_STRING) {
// 			coordinates = geometry.getCoordinates();
// 		} else {
// 			coordinates = geometry.getCoordinates()[0];
// 		}
// 		return new MultiPoint(coordinates);
// 	});
// }

export const vertexStyle = (color, pointAt) => {
	return new Style({
		image: new Circle({
			radius: 5,
			fill: new Fill({ color }),
			stroke: new Stroke({ color: 'white', width: 2 })
		}),
		geometry: function (feature) {
			const coords = plainCoordinates(feature.getGeometry());
			return new Point(coords[pointAt]);
		}
	});
}

export const invertedVertexStyle = (color, pointAt) => {
	return new Style({
		image: new Circle({
			radius: 5,
			fill: new Fill({ color: 'white' }),
			stroke: new Stroke({ color, width: 2 })
		}),
		geometry: function (feature) {
			const coords = plainCoordinates(feature.getGeometry());
			return new Point(coords[pointAt]);
		}
	});
}

// geometry styles

const baseStyle = (color, alpha, strokeWidth = STROKE_WIDTH) => {
	const rgb = tinycolor(color).toRgb();
	return new Style({
		fill: new Fill({
			color: 'rgba('+ [rgb.r, rgb.g, rgb.b, alpha].join(',') + ')'
		}),
		stroke: new Stroke({
			color: color,
			width: strokeWidth
		})
	});
}

// const pointStyle = (color) => {
// 	const style = baseStyle(color);
// 	style.setImage(new Circle({
// 		radius: 5,
// 		fill: new Fill({ color })
// 	}));
// 	return style;
// }

const lineStyle = (color, alpha, strokeWidth) => {
	return baseStyle(color, alpha, strokeWidth);
}

const polygonStyle = (color, alpha, strokeWidth) => {
	return baseStyle(color, alpha, strokeWidth);
}

//

export const makeStyle = (styleType, pointAt) => {
	return (feature) => {
		const geometryType = feature.getGeometry().getType();
		const color = feature.get('style').color || COLOR_DEFAULT;
		let styles = [];
		switch (geometryType) {
			case GeometryType.LINE_STRING:
				styles.push(lineStyle(color), vertecesStyle(color));
				if (pointAt != null) {
					styles.push(vertexStyle("#ff0000", pointAt));
				}
				return styles;
			case GeometryType.POLYGON:
				const style = polygonStyle(color, alpha(styleType));
				style.setText(textStyle(color, feature.get('name')));
				styles.push(style);
				if (styleType == StyleType.Modifying) {
					styles.push(vertecesStyle(color));
				} else if (styleType == StyleType.AdvancedModifying) {
					styles.push(invertedVertecesStyle(color));
				}
				if (pointAt != null) {
					styles.push(invertedVertexStyle("#ff0000", pointAt));
				}
				return styles;
		}
	}
}
