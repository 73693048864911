import React, { useRef, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Map, getOwMap } from '../../general/location/Map';
import DeviceStateMapMarker from './DeviceStateMapMarker';
import { actions as zonesActions } from '../../../redux/api/zones';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { Group as LayerGroup } from 'ol/layer';
import { setProps } from '../../../misc/ol';
import { readMultiPolygon } from '../../../misc/wkt';
import { makeStyle, StyleType } from '../zones/zoneStyle';
import { URL_DELIMITER } from '../../../misc/url';
import useLocalStorage from '../../../misc/useLocalStorage';
import { className } from '../../../lib/className';
import { MarkerOrderManager } from '../../general/location/MarkerOrderManager';
import { MarkerHoverManager } from '../../general/location/MarkerHoverManager';
import MarkerOrderOption from './controls/MarkerOrderOption';

import './overview.scss';

const DEFAULT_MAP_NAME = 'overview';

/**
 * @param {Object} props
 * @param {string} [props.name]
 * @param {React.Component} [props.control]
 * @param {React.Ref} [props.customRef]
 */

function OverviewMap(props) {
	const map = useRef(null);
	const [inited, setInited] = useState(false);
	const layerGroup = useRef(null);
	const mapName = props.name || DEFAULT_MAP_NAME;
	const [displayZones, setDisplayZones] = useLocalStorage(mapName + '_display_zones');
	const [orderOption, setOrderOption] = useLocalStorage(mapName + '_marker_order_option');
	const [defaultView, setDefaultView] = useLocalStorage(`${mapName}_default_view`);
	const uris = props.match.params.uris ? props.match.params.uris.split(URL_DELIMITER) : [];
	const { currentCategoriesIds, hiddenCategoriesIds } = props.categoriesVisibility;

	const focusMarkers = () => {
		if (inited && !!uris.length) {
			getOwMap(mapName).focusMarkers(uris);
		}
	};

	const focusDefaultView = () => {
		if (defaultView && map.current) {
			map.current.getOlMap().getView().animate({ center: defaultView.center, zoom: defaultView.zoom, duration: 0 });
		}
	};

	const saveMapView = () => {
		if (inited && map.current) {
			const zoom = map.current.getOlMap().getView().getZoom();
			const center = map.current.getOlMap().getView().getCenter();

			setDefaultView({ zoom, center });
		}
	};

	useEffect(() => {
		if (props.customRef) props.customRef.current = map.current;
		if (props.zones.map == null) {
			props.dispatch(zonesActions.load.request());
		}
		map.current.updateSize();
		const timeOut = setTimeout(() => {
			setInited(true);
		}, 1000);
		return () => clearTimeout(timeOut);
	}, []);

	useEffect(() => {
		uris.length ? focusMarkers() : focusDefaultView();
	}, [inited]);

	useEffect(() => {
		focusMarkers();
	}, [props.match.params.uris]);

	useEffect(() => {
		if (props.zones.map != null) {
			const zones = Object.values(props.zones.map);
			const layers = {};
			zones.forEach((zone) => {
				const layer = new VectorLayer({
					source: new VectorSource({
						features: setProps(readMultiPolygon(zone.geometry), {
							zoneId: zone.zoneId,
							name: zone.name,
							style: zone.style,
						}),
					}),
					style: makeStyle(StyleType.Default),
				});
				layer.setProperties({ zoneId: zone.zoneId });
				layers[zone.zoneId] = layer;
			});
			layerGroup.current = new LayerGroup({ layers: Object.values(layers) });
			map.current.getOlMap().addLayer(layerGroup.current);
			layerGroup.current.setVisible(displayZones);
		}
	}, [props.zones]);

	useEffect(() => {
		if (layerGroup.current != null) {
			layerGroup.current.setVisible(displayZones);
		}
	}, [displayZones]);

	const focusExtent = (extent) => {
		map.current.getOwMap().fitExtent(extent);
	};

	const markers = useMemo(() => {
		if (!props.devices.list || !props.deviceStates || !currentCategoriesIds || !hiddenCategoriesIds) {
			return [];
		}

		return props.devices.list.reduce((devices, device) => {
			const isDeviceSelected = uris.includes(device.uri);
			const params = { autoPan: isDeviceSelected, autoPanMargin: 100 };

			const message = props.deviceStates[device.uri]?.message;
			const isCoordinates = message && message.longitude && message.latitude;

			const isCategorizedHidden =
        device.categoryIds && hiddenCategoriesIds.some((id) => device.categoryIds.includes(id));

			const isNotCategorizedHidden =
        hiddenCategoriesIds.includes(0) &&
        (!device.categoryIds || !currentCategoriesIds.some((id) => device.categoryIds.includes(id)));

			return !isCoordinates || isCategorizedHidden || isNotCategorizedHidden
				? devices
				: [...devices, <DeviceStateMapMarker key={device.uri} params={params} device={device} />];
		}, []);
	}, [uris, props.devices.list, props.deviceStates, currentCategoriesIds, hiddenCategoriesIds]);

	const MapControl = props.control;

	return (
		<MarkerOrderManager orderOption={orderOption} markersCount={markers.length}>
			<MarkerHoverManager map={map.current}>
				<Map className={className('overview', mapName)} name={mapName} ref={map} baseLayer={Map.Layers.GOOGLE}>
					{markers}
				</Map>
				{MapControl && (
					<MapControl
						mapName={mapName}
						focusExtent={focusExtent}
						displayZones={displayZones}
						setDisplayZones={setDisplayZones}
						saveMapView={saveMapView}
						dropdownOptions={<MarkerOrderOption value={orderOption} onChange={setOrderOption} />}
					/>
				)}
			</MarkerHoverManager>
		</MarkerOrderManager>
	);
}

export default connect((state) => {
	return {
		deviceStates: state.deviceStates.map,
		devices: state.devices,
		zones: state.zones,
		categoriesVisibility: state.categories.categoriesVisibility,
	};
})(withRouter(OverviewMap));
