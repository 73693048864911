export class EllipsoidalLocality {
	constructor(coordinates) {
		this.coordinates = coordinates;
		this.latRef = this.coordinates[1] * Math.PI / 180;
		this.K1 = (111.13209 - 0.56605 * Math.cos(2 * this.latRef) + 0.00120 * Math.cos(4 * this.latRef)) * 1000;
		this.K2 = (111.41513 * Math.cos(this.latRef) - 0.09455 * Math.cos(3 * this.latRef) + 0.00012 * Math.cos(5 * this.latRef)) * 1000;
	}

	distance2(left,  right) {
		const latRef = left[1] * Math.PI / 180;
		const K1 = (111.13209 - 0.56605 * Math.cos(2 * latRef) + 0.00120 * Math.cos(4 * latRef)) * 1000;
		const K2 = (111.41513 * Math.cos(latRef) - 0.09455 * Math.cos(3 * latRef) + 0.00012 * Math.cos(5 * latRef)) * 1000;
		const distanceLat = (right[1] - left[1]) * K1, distanceLon = (right[0] - left[0]) * K2;
		return distanceLat * distanceLat + distanceLon * distanceLon;
	}

	distance2To(coordinates) {
		const distanceLat = (coordinates[1] - this.coordinates[1]) * this.K1, distanceLon = (coordinates[0] - this.coordinates[0]) * this.K2;
		return distanceLat * distanceLat + distanceLon * distanceLon;
	}
}
