import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { useDrop } from 'react-dnd';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { Group as LayerGroup } from 'ol/layer';
import { extend } from 'ol/extent';
import {
	createEmpty as emptyExtent,
	isEmpty as isEmptyExtent,
	getSize as extentSize,
	buffer as bufferExtent,
} from 'ol/extent';
import { useI18n } from '../../../../../i18n';
import { Map, FocusMode } from '../../../general/location/Map';
import { actions as zonesActions } from '../../../../redux/api/zones';
import { DragItemType } from '../../../share/dnd/DragItemType';
import { cx } from '../../../../api';
import { readMultiPolygon } from '../../../../misc/wkt';
import { setProps, isExtentFinite } from '../../../../misc/ol';
import Intent from '../../../../misc/ObjectActionIntent';
import MapControls from '../../map/controls/MapControls';
import MapButton from '../../map/MapButton';
import MapOptions from '../../map/controls/MapOptions';
import MapOptionLayers from '../../map/controls/MapOptionLayers';
import { makeStyle, StyleType, ZIndex } from '../zoneStyle';
import { FiCrosshair } from 'react-icons/fi';
import { className } from '../../../../lib/className';
import MapOptionGoogleSatMode from '../../map/controls/MapOptionGoogleSatMode';
import MapOptionGoogleTraffic from '../../map/controls/MapOptionGoogleTraffic';
import './zoneSelectMap.scss';

const MAP_NAME = "ZoneSelectMap";

/**
 * @param {Object} props
 * @param {string} [props.mapName]
 * @param {function} [props.onChange]
 * @param {function} props.onIntent
 * @param {boolean} [props.noDrop]
 * @param {boolean} [props.singleSelection]
 * @param {Array.<number>} [props.zoneIds] display only zones in this array
 * @param {Array.<number>|number} [props.selectedIds] display selected zones with different style
 * @param {Array.<number>} [props.focusIds] focus on specified zones only (performed every time "focusIds" change)
 * @param {boolean} [props.selectedOnly] whether focus button will focus all or only selected zones (if any)
 */

function ZoneSelectMap(props) {
	const { f } = useI18n();
	const map = useRef(null);
	const layers = useRef(null);
	const layerGroup = useRef(null);
	const featureInHover = useRef(null);
	const focusTimer = useRef(null);
	const mapName = props.mapName || MAP_NAME;

	let selectedIds = [];
	if (props.selectedIds != null) {
		selectedIds = Array.isArray(props.selectedIds) ? props.selectedIds : [props.selectedIds];
	}
	const zones = props.zones.filteredList ? props.zones.filteredList : props.zones.list;

	/**
	 * Whether there are no zones loaded from server.
	 */
	const empty = () => {
		return zones == null || zones != null && zones.length == 0;
	}

	/**
	 * Return zones filtered by "zoneIds" or all zones if "zoneIds" is not present.
	 * @returns {Array.<cx.ods.zones.ZoneData>}
	 */
	const visibleZones = () => {
		const _zones = (zones != null ? zones : []);
		return props.zoneIds
			? _zones.filter(zone => props.zoneIds.includes(zone.zoneId))
			: _zones
		;
	}

	/**
	 * Return zones filtered by "focusIds" or if no "focusIds" - by "selectedIds".
	 * If both are not present - return all zones.
	 * @returns {Array.<cx.ods.zones.ZoneData>}
	 */
	const focusableZones = () => {
		let zones = visibleZones();
		if (props.focusIds != null && props.focusIds.length > 0) {
			zones = zones.filter(zone => props.focusIds.includes(zone.zoneId))
		} else if (selectedIds.length > 0) {
			zones = zones.filter(zone => selectedIds.includes(zone.zoneId))
		}
		return zones;
	}

	/**
	 * Focus map on "focusableZones" or on specified array of ids or on id.
	 * @param {Array.<number>|number|boolean} [arg] if true - force to focus on all visible zones
	 */
	const focus = (arg) => {
		if (layers.current == null) return; // CHECKME
		const force = typeof arg === 'boolean';
		const ids = arg != null && typeof arg !== 'boolean'
			? Array.isArray(arg) ? arg : [arg]
			: null
		;
		const focusIds = ids || (force
			? visibleZones().map(zone => zone.zoneId).filter(zoneId =>
				props.selectedOnly && selectedIds.length > 0 ? selectedIds.includes(zoneId): true
			)
			: focusableZones().map(zone => zone.zoneId)
		);
		let extent = new emptyExtent();
		focusIds.forEach(zoneId => {
			const layer = layers.current[zoneId];
			layer && extend(extent, layer.getSource().getExtent());
		});
		if (!isEmptyExtent(extent) && isExtentFinite(extent)) {
			if (focusIds.length == 1) {
				const size = extentSize(extent);
				if (size[0] < 1200 || size[1] < 1200) {
					const width = size[0] * 1.6;
					const height = size[1] * 1.6;
					const dimention = Math.max(width, height);
					extent = bufferExtent(extent, Math.max(dimention - size[0], dimention - size[1]));
				}
			}
			map.current.getOwMap().focusExtent(extent, focusIds.sort().join('-'), force, FocusMode.DoubleFocus);
		}
	}

	const updateStyle = (zoneId) => {
		Object.values(layers.current).forEach(layer => {
			if (
				selectedIds.length > 0 && selectedIds.includes(layer.getProperties().zoneId)
				|| zoneId == layer.getProperties().zoneId
			) {
				layer.setStyle(makeStyle(StyleType.Selected));
				layer.setProperties({ zIndex: ZIndex.Selected });
			} else {
				layer.setStyle(makeStyle(StyleType.Default));
				layer.setProperties({ zIndex: ZIndex.Default });
			}
		});
	}

	const onFocusAll = () => {
		focus(true);
	}

	// !!! order of useEffects is important

	useEffect(() => {
		if (props.zones.map == null && !props.zones.pending) {
			props.dispatch(zonesActions.load.request());
		}
	}, []);

	useEffect(() => {
		map.current.updateSize();
	});

	useEffect(() => {
		const zones = visibleZones();
		if (zones) {
			layers.current = {};
			zones.forEach(zone => {
				const layer = new VectorLayer({
					source: new VectorSource({
						features: setProps(readMultiPolygon(zone.geometry), {
							zoneId: zone.zoneId,
							name: zone.name,
							style: zone.style
						})
					}),
					style: selectedIds.includes(zone.zoneId)
						? makeStyle(StyleType.Selected)
						: makeStyle(StyleType.Default)
				});
				layer.setProperties({ zoneId: zone.zoneId });
				layers.current[zone.zoneId] = layer;
			});
			layerGroup.current = new LayerGroup({
				layers: Object.values(layers.current)
			});
			map.current.getOlMap().addLayer(layerGroup.current);
		}
		const selectedZones = zones.filter(zone => selectedIds.includes(zone.zoneId));
		if (selectedIds.length != selectedZones.length && props.onChange) {
			props.onChange(selectedZones.map(zone => zone.zoneId));
		}
		if (focusTimer.current) {
			clearTimeout(focusTimer.current);
			focusTimer.current = null;
		}
		if (!!props.zones.filteredList) {
			focusTimer.current = setTimeout(onFocusAll, 500);
		} else {
			onFocusAll();
		}
		updateStyle();
		return () => {
			map.current.getOlMap().removeLayer(layerGroup.current);
			layerGroup.current = null;
			layers.current = {};
		};
	}, [zones, props.zoneIds]);

	useEffect(() => {
		if (zones != null) {
			selectedIds.length > 0 && focus();
			updateStyle();
		}
	}, [selectedIds]);

	useEffect(() => {
		if (zones != null && props.focusIds != null && !props.singleSelection) {
			focus();
			updateStyle();
		}
	}, [props.focusIds]);

	useEffect(() => {
		const onClick = (event) => {
			const features = map.current.getOlMap().getFeaturesAtPixel(event.pixel);
			const zoneId = features && features.length > 0 && features[0].getProperties().zoneId;
			if (props.singleSelection) {
				props.onChange(selectedIds.includes(zoneId) ? [] : (zoneId != null ? [zoneId] : []));
			} else if (zoneId) {
				const _selectedIds = [...selectedIds];
				_selectedIds.indexOf(zoneId) == -1
					? _selectedIds.push(zoneId)
					: _selectedIds.splice(props.selectedIds.indexOf(zoneId), 1)
				;
				props.onChange(_selectedIds, zoneId);
			}
		}
		if (zones != null && props.onChange) {
			map.current.getOlMap().on('click', onClick);
		}
		return () => {
			map.current.getOlMap().un('click', onClick);
		};
	}, [selectedIds]);

	// ------------------drop actions-------------------

	const getMapPixel = (monitor) => {
		const mapOffset = cx.dom.at.client(map.current.getDomBox());
		const dropOffset = monitor.getClientOffset();
		return [dropOffset.x - mapOffset.left, dropOffset.y - mapOffset.top];
	}

	const onDrop = (monitor, pixel, item) => {
		const offset = monitor.getClientOffset();
		const features = map.current.getOlMap().getFeaturesAtPixel(pixel);
		if (features && features.length > 0) props.onIntent(
			new Intent(
				'zone',
				item.type == DragItemType.ACTION_EDIT ? Intent.Action.Edit : Intent.Action.Remove,
				props.zones.map[features[0].getProperties().zoneId],
				{ offset: { left: offset.x, top: offset.y }}
			)
		);
	}

	const onHover = (pixel) => {
		const features = map.current.getOlMap().getFeaturesAtPixel(pixel);
		const feature = features && features[0];
		if (feature != featureInHover.current) {
			featureInHover.current = feature;
			updateStyle(feature ? feature.getProperties().zoneId : null);
		}
	}

	const [, dropRef] = useDrop({
		accept: [DragItemType.ACTION_EDIT, DragItemType.ACTION_REMOVE],
		canDrop: () => !props.noDrop,
		drop: (item, monitor) => onDrop(monitor, getMapPixel(monitor), item),
		hover: (item, monitor) => !props.noDrop && onHover(getMapPixel(monitor)),
	});

	// ----------------------------------------------

	return (
		<div className="zone-select-map" ref={dropRef}>
			<Map name={mapName} ref={map} baseLayer={Map.Layers.GOOGLE} />
			<MapControls>
				<MapOptions>
					<MapOptionLayers mapName={mapName} />
					<MapOptionGoogleSatMode mapName={mapName} />
					<MapOptionGoogleTraffic mapName={mapName} />
				</MapOptions>
				<MapButton
					className={className('focus-all', { 'disabled': empty() })}
					title={props.selectedOnly ? f('show selected zones') : f('show all zones')}
					onClick={onFocusAll}
				>
					<FiCrosshair />
				</MapButton>
			</MapControls>
		</div>
	);
}

export default connect(
	state =>({
		zones: state.zones
	})
)(ZoneSelectMap);