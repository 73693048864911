import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../i18n';
import { meta } from '../../../redux/api/meta';
import './propertyInfo.scss';

/**
 * @param {Object} props
 * @param {cx.ods.meta.Property} props.property
 * @param {boolean} [props.detailed]
 */

function PropertyInfo(props) {
	const { f } = useI18n();

	useEffect(() => {
		if (props.datatypes.map == null && !props.datatypes.pending) {
			props.dispatch(meta.datatypes.actions.datatypes.request());
		}
		if (props.units.map == null && !props.units.pending) {
			props.dispatch(meta.units.actions.units.request());
		}
	}, []);

	let datatypeName = null;
	if (props.datatypes.map != null) {
		datatypeName = props.datatypes.map[props.property.datatypeId] && f(props.datatypes.map[props.property.datatypeId].name);
	}

	let unitName = null;
	if (props.units.map != null) {
		unitName = props.units.map[props.property.unitId] && f({ prefix: 'units', id: props.units.map[props.property.unitId].name });
	}

	return (
		<div className="property-info">
			<div>
				<div className="name">
					{props.property.name || props.property.label}
				</div>
				<div className="data-type">
					<div>{datatypeName}</div>
					<div>{unitName}</div>
				</div>
			</div>
			{props.detailed && props.property.description &&
				<div className="description">
					{props.property.description}
				</div>
			}
		</div>
	);
}

export default connect(state => ({
	datatypes: state.meta.datatypes,
	units: state.meta.units
}))(PropertyInfo);