import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { IProcessorForm } from 'hooks/downsampled-message-history/useProcessorForm';
import { DeviceManager } from 'core/react/share/devices/DeviceManager';

interface IDevicesSelectProps {
  isDisabled?: boolean;
}

export const DevicesSelect: FC<IDevicesSelectProps> = ({ isDisabled }) => {
  const { control } = useFormContext<IProcessorForm>();

  return (
    <Controller
      control={control}
      name="uris"
      render={({ value, onChange }) => (
        <DeviceManager uris={value} setUris={onChange} isDisabled={isDisabled} isEditMode />
      )}
    />
  );
};
