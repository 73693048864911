import React from 'react';
import { Menu } from '@blueprintjs/core';
import { useI18n } from '../../../../i18n';
import NavLinkItem from './NavLinkItem';

import { AllRoutes } from 'constants/routes';
import CommuteDetectorsPage from '../../pages/CommuteDetectorsPage';
import DevicePresenceDetectorsPage from '../../pages/DevicePresenceDetectorsPage';
import DevicePropertiesPage from '../../pages/DevicePropertiesPage';
import DeviceStatusDetectorsPage from '../../pages/DeviceStatusDetectorsPage';
import DownsampledMessageHistoryPage from "../../pages/downsampled-message-history";
import EmployeeManagerPage from '../../pages/EmployeeManagerPage';
import FlowProcessorsPage from '../../pages/FlowProcessorsPage';
import FuelLevelProcessorsPage from '../../pages/FuelLevelProcessorsPage';
import MessagePublishersPage from '../../pages/MessagePublishersPage';
import MqttClientsPage from '../../pages/MqttClientsPage';
import ZonesPage from '../../pages/ZonesPage';
import ZoneGuardsPage from '../../pages/ZoneGuardsPage';

import './settingsMenu.scss';

function SettingsSubmenu() {
	const { fc } = useI18n();

	const items = {
		[fc('attribute range detectors')]: AllRoutes.AttributeRangeDetectors,
		[fc('commute detectors')]: CommuteDetectorsPage.Url,
		[fc('device presence detectors')]: DevicePresenceDetectorsPage.Url,
		[fc('device properties')]: DevicePropertiesPage.Url,
		[fc('device status detectors')]: DeviceStatusDetectorsPage.Url,
		[fc('downsampled message history processors')]: DownsampledMessageHistoryPage.Url,
		[fc('employee manager')]: EmployeeManagerPage.Url,
		[fc('flow processors')]: FlowProcessorsPage.Url,
		[fc('fuel level processors')]: FuelLevelProcessorsPage.Url,
		[fc('message publishers')]: MessagePublishersPage.Url,
		[fc('mqtt clients')]: MqttClientsPage.Url,
		[fc('zones')]: ZonesPage.Url,
		[fc('zone guards')]: ZoneGuardsPage.Url
	};

	return (
		<Menu className="settings-menu">
			{Object.keys(items).sort().map(key => <NavLinkItem key={key} to={items[key]}>{key}</NavLinkItem>)}
		</Menu>
	);
}

export default SettingsSubmenu;
