import React, { useEffect, useState, useRef } from 'react';
import { useDrop } from 'react-dnd';
import { useI18n } from '../../../../../i18n';
import { fromProps, className } from '../../../../lib/className';
import { DragItemType } from '../../dnd/DragItemType';
import DefaultActionBar from '../../actionbar/DefaultActionBar';
import ActionAdd from '../../../share/actionbar/ActionAdd';
import PropertyElement from './PropertyElement';
import Form from '../../../general/form/Form';
import Modal from '../../../general/Modal';
import DevicePropertySwitch from '../../../custom/deviceProperties/meta/DevicePropertySwitch';
import DevicePropertyEditor from '../../../custom/deviceProperties/meta/DevicePropertyEditor';
import './xElements.scss';

/**
 * @param {Object} props
 * @param {cx.ods.meta.ComposableProperty} props.element
 * @param {function} props.onRemove
 * @param {function} props.onEdit
 */

function RecordElement(props) {
	const onDrop = (type) => {
		type == DragItemType.ACTION_REMOVE ? props.onRemove(props.element) : props.onEdit(props.element);
	}

	const [dropState, dropRef] = useDrop({
		accept: [DragItemType.ACTION_REMOVE, DragItemType.ACTION_EDIT],
		drop: (item) => onDrop(item.type),
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		}),
	})

	return <PropertyElement customRef={dropRef} element={props.element} className={className({ 'droppable': dropState.isOver && dropState.canDrop })} />;
}

/**
 * @param {Object} props
 * @param {cx.ods.meta.PropertyRecord} props.record
 * @param {Array.<cx.ods.meta.ComposableProperty>} props.value
 * @param {function} props.onChange
 */

function RecordElements(props) {
	const { f } = useI18n();
	const [adding, setAdding] = useState(false);
	const property = useRef(null);
	const [editing, setEditing] = useState(false);
	const value = props.value != null ? props.value : [];
	const [serial, setSerial] = useState(0);

	useEffect(() => {
		if (property.current != null) {
			property.current = props.value.find(element => element.propertyId == property.current.propertyId);
			setSerial(serial+1);
		}
	}, [props.value]);

	const onRemove = (element) => {
		const change = value.filter((item) => item.propertyId != element.propertyId);
		props.onChange(change.length > 0 ? change : null);
	}

	// -------------------------------------------

	const onEdit = (_property) => {
		property.current = _property;
		setEditing(true);
	}

	const onEditCancel = () => {
		property.current = null;
		setEditing(false);
	}

	const onEditReady = () => {
		property.current = null;
		setEditing(false);
	}

	// -------------------------------------------

	const onAdd = () => {
		setAdding(true);
	}

	const onAddCancel = () => {
		setAdding(false);
	}

	const onAddReady = (properties) => {
		setAdding(false);
		const change = [...value].concat(properties);
		props.onChange(change.length > 0 ? change : null);
	}

	// -------------------------------------------

	let list = null, excludeIds = [props.record.propertyId];
	if (value && value.length > 0) {
		list = value.map(element =>
			<RecordElement
				key={element.propertyId}
				element={element}
				onRemove={onRemove}
				onEdit={onEdit}
			/>
		);
		excludeIds = excludeIds.concat(value.map(property => property.propertyId));
		if (props.record && props.record.elements) {
			const diff = props.record.elements.filter(property => {
				return value.includes(property);
			});
			excludeIds = excludeIds.concat(diff.map(property => property.propertyId));
		}
	}

	return (
		<div className={className('x-elements', 'record-elements', fromProps(props))}>
			<div className="header">
				<div className="label">{f('members')}</div>
				<DefaultActionBar
					prependActions={<ActionAdd title={f('add member')} onClick={onAdd} />}
					hideEdit={list == null}
					hideRemove={list == null}
					disabled={props.disabled}
				/>
				{adding &&
						<Modal
							show
							onClose={onAddCancel}
							title={f('select property')}
						>
							<DevicePropertySwitch
								onCancel={onAddCancel}
								onReady={onAddReady}
								extraProperties={props.record.elements}
								excludeIds={excludeIds}
							/>
						</Modal>
				}
				{editing &&
					<Modal
						show
						onClose={onEditCancel}
						title={property.current.name}
					>
						<DevicePropertyEditor
							property={property.current}
							onCancel={onEditCancel}
							onReady={onEditReady}
							nested
							hideHeader
						/>
					</Modal>
				}
			</div>
			<div className="elements">{list}</div>
		</div>
	);
}

/**
 * See RecordElements parameters.
 * @param {Object} props
 */

function RecordElementsControl(props) {
	const [elements, setElements] = useState(null);

	useEffect(() => {
		props.record.elements && setElements([...props.record.elements]);
	}, [props.record, props.record.elements]);

	return (
		<Form.Control
			controlType={RecordElements}
			controlName="elements"
			value={elements}
			onChange={setElements}
			record={props.record}
			{...props}
		/>
	);
}

export default RecordElementsControl;