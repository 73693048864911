import React from 'react';
import { className, fromProps } from '../../../lib/className';
import './tabLayout.scss';

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {string} props.type
 * @param {string} props.icon
 * @param {boolean} props.active
 * @param {boolean} [props.disabled]
 * @param {function} props.onClick
 */

function Tab(props) {
	return (
		<div
			className={className('tab', fromProps(props), { 'active': props.active, 'disabled': props.disabled })}
			onClick={() => !props.disabled && props.onClick(props.type)}
		>
			{props.icon && <img src={props.icon} alt="" />}
			{props.title && <span className="capitalize">{props.title}</span>}
		</div>
	)
}

/**
 * @param {Object} props
 * @param {Array.<Tab>} props.tabs
 * @param {function} props.onChange
 * @param {boolean} [props.disabled]
 */

function TabLayout(props) {

	const tabs = props.tabs.map(tab =>
		React.cloneElement(tab, {
			onClick: (type) => props.onChange(type),
			disabled: props.disabled
		})
	);

	return (
		<div className={className('layout', 'tab-layout', fromProps(props))}>
			<div className="tabs">
				<div className="wrap">{tabs}</div>
			</div>
			<div className="content">
				{props.children}
			</div>
		</div>
	);
}

TabLayout.Tab = Tab;

export default TabLayout;