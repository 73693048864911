import React from 'react';

import ObjectDeviceManager from '../../share/devices/ObjectDeviceManager';
import FlowProcessorDetails from './FlowProcessorDetails';

import './flowProcessorView.scss';

/**
 * @param {Object} props
 * @param {cx.ods.processors.FlowProcessor} [props.processor]
 * @param {boolean} [props.editMode]
 * @param {boolean} [props.disabled]
 */
function FlowProcessorView(props) {

	if (props.processor || props.editMode) {
		return (
			<div className="flow-processor body">
				<ObjectDeviceManager
					uris={props.processor?.uris}
					disabled={props.disabled}
					editMode={props.editMode}
				/>
				<FlowProcessorDetails
					processor={props.processor}
					disabled={props.disabled}
					editMode={props.editMode}
				/>
			</div>
		);
	}

	return null;
}

export default FlowProcessorView;
