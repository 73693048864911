import React from 'react';
import ObjectDeviceManager from '../../share/devices/ObjectDeviceManager';
import PresenceDetectorCategories from './PresenceDetectorCategories';
import './presenceDetectorView.scss';

/**
 * @param {Object} props
 * @param {cx.ods.processors.DevicePresenceDetector} [props.presenceDetector]
 * @param {boolean} [props.editMode]
 * @param {boolean} [props.disabled]
 */

function PresenceDetectorView(props) {
	let content = null;

	if (props.presenceDetector || props.editMode) {
		content = (
			<div className="presence-detector body">
				<ObjectDeviceManager
					uris={props.presenceDetector ? props.presenceDetector.uris : null}
					disabled={props.disabled}
					editMode={props.editMode}
				/>
				<PresenceDetectorCategories
					presenceDetector={props.presenceDetector}
					disabled={props.disabled}
					editMode={props.editMode}
				/>
			</div>
		);
	}

	return (content);
}

export default PresenceDetectorView;