import React from 'react';

import { useI18n } from '../../../../i18n';

/**
 * @param {Object} props
 * @param {cx.ods.processors.MessagePublisher} props.messagePublisher
 */

export const MessagePublisherCard = ({ messagePublisher }) => {
	const { f } = useI18n();

	return (
		<div className="message-publisher-card">
			<div className="publications">
				<label>{f('publishing')}</label>
				{messagePublisher.states && <div className="option">{f('state updates')}</div>}
				{messagePublisher.events && <div className="option">{f('events')}</div>}
			</div>
		</div>
	);
}
