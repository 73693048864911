import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useDrop } from 'react-dnd';
import { className, fromProps } from '../../../lib/className';
import { useI18n } from '../../../../i18n';
import { actions as zonesActions } from '../../../redux/api/zones';
import { sortZoneIds } from '../../../api/zone';
import { DragItemType } from '../dnd/DragItemType';
import List from '../../general/list/List';
import ZoneListItem from './ZoneListItem';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { scrollIntoView } from '../../../misc/misc';
import './zoneSet.scss';

/**
 * @param {Object} props
 * @param {cx.ods.zones.ZoneData} props.zone
 * @param {function} props.onClick
 * @param {function} props.onRemove
 * @param {boolean} [props.canDrop]
 */

function ZoneSetItem(props) {

	const onClick = () => {
		props.onClick(props.zone.zoneId);
	}

	const onDrop = () => {
		if (!props.canDrop) return;
		props.onRemove(props.zone.zoneId);
	}

	const [dropState, dropRef] = useDrop({
		accept: DragItemType.ACTION_REMOVE,
		canDrop: () => props.canDrop,
		drop: () => onDrop(),
		collect: monitor => ({
			isOver: monitor.isOver(),
		}),
	});

	const onRef = (element) => {
		dropRef(element);
		props.customRef && (props.customRef.current = element);
	}

	return (
		<ZoneListItem
			className={className(fromProps(props), { 'droppable': dropState.isOver && props.canDrop })}
			customRef={onRef}
			zone={props.zone}
			onClick={onClick}
		/>
	);
}


/**
 * Controlled Component
 * @param {Object} props
 * @param {Array.<number>} props.value - zones zoneId
 * @param {function} props.onChange
 * @param {function} props.onClick
 * @param {boolean} props.editMode
 * @param {string} [props.label]
 * @param {string} [props.emptyMessage]
 * @param {boolean} [props.collapsible]
 * @param {Array.<number>} [props.selectedIds]
 * @param {string||number} [props.lastSelectedId]
 */

function ZonesSet(props) {
	const { fc } = useI18n();
	const focusZoneRef = useRef();
	const [collapsed, setCollapsed] = useState(props.collapsible);
	let content = null;

	let selectedIds = [];
	if (props.selectedIds != null) {
		selectedIds = Array.isArray(props.selectedIds) ? props.selectedIds : [props.selectedIds];
	}

	useEffect(() => {
		if (!props.zones) {
			props.dispatch(zonesActions.load.request());
		}
	}, []);

	useEffect(() => {
		if (focusZoneRef.current) scrollIntoView(focusZoneRef.current);
	}, [props.lastSelectedId]);

	const onRemove = (zoneId) => {
		const value = [...props.value];
		value.splice(value.indexOf(zoneId), 1);
		props.onChange(value);
	}

	if (props.zones && props.value) {
		if (props.value.length > 0) {
			const items = sortZoneIds(props.value).map(zoneId =>
				<ZoneSetItem
					key={zoneId}
					className={className({ 'selected': selectedIds.includes(zoneId) })}
					zone={props.zones[zoneId]}
					customRef={props.lastSelectedId == zoneId ? focusZoneRef : null}
					onClick={props.onClick}
					onRemove={onRemove}
					canDrop={props.editMode}
				/>
			);
			content = (
				<List className={className('zone-list', fromProps(props))}>
					{items}
				</List>
			);
		} else {
			content = props.emptyMessage && (<span className="no-items capitalize">{props.emptyMessage}</span>);
		}
	}
	return (
		<div className={className("zone-set", fromProps(props))}>
			{
				props.label
					&& <label>
						<span className="capitalize">{props.label}</span>
						{props.collapsible
							&& <span
								className="toggle-zones-view clickable"
								onClick={() => setCollapsed(!collapsed)}
								title={collapsed ? fc('show') : fc('hide')}
							>
								{collapsed ? <FiChevronDown /> : <FiChevronUp />}
							</span>
						}
					</label>
			}
			{!collapsed && content}
		</div>
	);
}

export default connect(state => {
	return {
		zones: state.zones.map
	}
})(ZonesSet);