import React, {useEffect, useRef, useState} from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from "react-router-dom";
import { FiExternalLink as IconZoneGuards } from "react-icons/fi";

import { AllRoutes } from 'constants/routes';
import { setPageTitle } from '../../misc/page';
import { useI18n } from '../../../i18n';
import { actions as processorActions } from '../../redux/api/processors';
import PagePanel from "../general/panel/PagePanel";
import { cx } from "../../api";
import SidebarLayout from "../general/layout/SidebarLayout";
import { MessagePublisherCard } from "../custom/messagePublishers/MessagePublisherCard";
import Intent from "../../misc/ObjectActionIntent";
import ObjectActionDialogPopup from "../general/ObjectActionDialogPopup";
import RemoveDialog from "../general/RemoveDialog";
import Form from "../general/form/Form";
import DefaultActionBar from "../share/actionbar/DefaultActionBar";
import ActionBack from "../share/actionbar/ActionBack";
import ActionAdd from "../share/actionbar/ActionAdd";
import MessagePublishersList from "../custom/messagePublishers/MessagePublishersList";
import { MessagePublisherView } from "../custom/messagePublishers/MessagePublisherView";
import Button from '../general/form/Button';
import { MessagePublisherForm } from "../custom/messagePublishers/MessagePublisherForm";

import './messagePublishersPage.scss';

const actions = processorActions.messagePublishers;

const PAGE_TITLE = 'message publishers';

const MessagePublishersPage = (props) => {
	const { f, fc } = useI18n();
	const history = useHistory();
	const params = useParams();
	const form = useRef(null);
	const submitHook = useRef(null);
	const pendingRef = useRef(false);

	const [publisher, setPublisher] = useState(null);
	const [intent, setIntent] = useState(null);

	const { messagePublishers, dispatch } = props;
	const { list, map, pending, error } = messagePublishers;
	const processorId = params.id;
	const isEdit = params[0] === 'edit' || params[0] === 'create';

	const getMessagePublishers = () => {
		if (!list) {
			dispatch(actions.load.request());
		}
	}

	const clearError = () => {
		!!error && dispatch(actions.clear());
	}

	const onSelect = (publisher) => {
		history.push(`${AllRoutes.MessagePublishers}/${publisher.processorId}`);
	}

	const onIntent = (intent) => {
		if (intent.action() === Intent.Action.Remove) {
			return setIntent(intent);
		}

		if (intent.action() === Intent.Action.Edit) {
			history.push(`${AllRoutes.MessagePublishers}/${intent.object().processorId}/edit`);
		}
	}

	const onPopupClose = () => {
		intent && setIntent(null);
	}

	const onAdd = () => {
		history.push(`${AllRoutes.MessagePublishers}/create`);
	}

	const onBack = () => {
		setPublisher(null);
		history.goBack();
	}

	const onRemove = (messagePublisher) => {
		dispatch(actions.remove.request({ processorId: messagePublisher.processorId }));
	}

	const submit = () => {
		submitHook.current();
	}

	const onSubmit = (publisherInfo) => {
		const { eventTypes, fieldIds, service, statesDestination, eventsDestination, ...rest } = publisherInfo;
		const payload = {
			...rest,
			eventTypes: eventTypes?.length ? eventTypes : null,
			fieldIds: fieldIds?.length ? fieldIds : null,
			service: service || null,
			statesDestination: statesDestination || null,
			eventsDestination: eventsDestination || null,
		};
		const data = new cx.ods.processors.MessagePublisher(payload);

		publisher
			? dispatch(actions.update.request({ processorId: publisher.processorId, data }))
			: dispatch(actions.add.request({ data }));

		pendingRef.current = true;
	}

	const sidebarContent = (<>
		<DefaultActionBar
			prependActions={isEdit
				? <ActionBack onClick={onBack}/>
				: <ActionAdd onClick={onAdd}/>
			}
			hideEdit={isEdit}
			disabled={pending}
		/>
		{isEdit
			? <div className="content">
				<MessagePublisherForm messagePublisher={publisher}/>
				<div className="error">
					{error}
				</div>
				<Button onClick={submit} disabled={pending}>
					{f('save')}
				</Button>
			</div>
			: <>
				<MessagePublishersList
					selectedId={publisher?.processorId || null}
					onSelect={onSelect}
					onIntent={onIntent}
				/>
				{publisher && <MessagePublisherCard messagePublisher={publisher}/>}
			</>
		}
	</>);

	useEffect(() => {
		setPageTitle(fc(PAGE_TITLE));
		getMessagePublishers();

		return () => {
			clearError();
		}
	}, []);

	useEffect(() => {
		clearError();
	}, [intent]);

	useEffect(() => {
		form.current && form.current.purge();
	}, [processorId]);

	useEffect(() => {
		clearError();
		onPopupClose();
	}, [params]);

	useEffect(() => {
		if (!pending && pendingRef.current) {
			pendingRef.current = false;

			if (error === null) {
				history.goBack();
			}
		}
	}, [messagePublishers]);

	useEffect(() => {
		if (list !== null && processorId) {
			map[processorId] ? setPublisher(map[processorId]) : history.replace(AllRoutes.MessagePublishers);
		} else if (list !== null && !processorId) {
			setPublisher(null);
		}

		if (intent && map && !map[intent.object().processorId]) {
			onPopupClose();
		}
	}, [props]);

	return (
		<div className="app-page message-publisher">
			<PagePanel>{!!list &&
				<Form
					onSubmit={onSubmit}
					submitHook={submitHook}
					objectType={cx.ods.processors.MessagePublisher}
					disabled={pending}
					customRef={form}
				>
					<SidebarLayout
						className="body"
						icon={<IconZoneGuards size="20"/>}
						title={f(isEdit ? 'message publisher' : 'message publishers')}
						sidebarContent={sidebarContent}
					>
						<MessagePublisherView
							messagePublisher={publisher}
							isEdit={isEdit}
							isDisabled={pending}
						/>
						{intent && intent.action() === Intent.Action.Remove && (
							<ObjectActionDialogPopup
								offset={intent.data().offset}
								onClose={onPopupClose}
								title={f('delete message publisher')}
								disabled={pending}
								error={error}
							>
								<RemoveDialog
									object={intent.object()}
									title={f('message publisher')}
									text={f('are you sure you want to delete message publisher', {name: intent.object().name})}
									onSubmit={onRemove}
									onCancel={onPopupClose}
								/>
							</ObjectActionDialogPopup>
						)}
					</SidebarLayout>
				</Form>}
			</PagePanel>
		</div>
	)
}

MessagePublishersPage.Url = AllRoutes.MessagePublishers;

export default connect(
	state => ({
		messagePublishers: state.processors.messagePublishers
	})
)(MessagePublishersPage);
