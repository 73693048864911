import { cx, api, rx } from '../../../api';
import { ofType } from 'redux-observable';
import { exhaustMap, map as rxmap, takeUntil } from 'rxjs/operators';
import { ActionGeneratorBuilder, errorMap } from '../../actions';

const defaultState = {
	map: null, // datatypeId => cx.ods.meta.Datatype
	mnemonicsMap: null, // mnemonics => cx.ods.meta.Datatype
	pending: false,
	error: null
};

const actions = new ActionGeneratorBuilder('metaDatatypes')
	.subtype('datatypes', datatypes => datatypes.request().success('classifier').fail().cancel())
	.build()
;

// ---------------------------------------------------------

function reducer(state = defaultState, action) {
	switch (action.type) {
		case actions.datatypes.request.type:
			return {
				...state,
				pending: true
			};
		case actions.datatypes.success.type:
			return {
				...state,
				map: cx.i.hash(action.classifier, (datatype) => datatype.datatypeId),
				mnemonicsMap: cx.i.hash(action.classifier, (datatype) => datatype.mnemonics),
				pending: false,
				error: null
			};
		case actions.datatypes.fail.type:
			return {
				...state,
				pending: false,
				error: action.errorMessage
			};
		case actions.datatypes.cancel.type:
			return {
				...state,
				pending: false
			};
		default:
			return state;
	}
}

// ---------------------------------------------------------

const epic = (action$) => {
	return action$.pipe(
		ofType(actions.datatypes.request.type),
		exhaustMap(action =>
			rx(api.meta.classifier.datatype).pipe(
				rxmap(operation => actions.datatypes.success({ classifier: operation.response() })),
				errorMap(actions.datatypes.fail),
				takeUntil(action$.pipe(ofType(actions.datatypes.cancel.type)))
			)
		)
	)
}

// ---------------------------------------------------------

export { actions, reducer, epic };
