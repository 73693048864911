import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions as widgetActions } from '../../../redux/app/widgets';
import { WidgetType } from './widgetRegistry';
import WidgetLayoutManager from './WidgetLayoutManager';
import CommutersWidget from '../../custom/widgets/CommutersWidget';
import DeviceInfoWidget from '../../custom/widgets/DeviceInfoWidget';
import PresenceDetectorWidget from '../../custom/dashboard/widgets/PresenceDetectorWidget';
import AttributeRangeDetectorWidget from '../../custom/dashboard/widgets/AttributeRangeDetectorWidget';
import TripsWidget from '../../custom/dashboard/widgets/tripsWidget/TripsWidget';
import EventsWidget from '../../custom/dashboard/widgets/eventsWidget/EventsWidget';
import DeviceTrackingWidget from '../../custom/dashboard/widgets/deviceTrackingWidget/DeviceTrackingWidget';
import { ReduxKeyContext } from '../../../misc/ReduxKeyContext';
import TimeMachineSettingsWidget from '../../custom/widgets/TimeMachineSettingsWidget';
import TimeMachinePlayerWidget from '../../custom/timeMachine/widgets/TimeMachinePlayerWidget';
import DeviceEventsWidget from '../../custom/timeMachine/widgets/deviceEventsWidget/DeviceEventsWidget';
import WidgetContainer from './WidgetContainer';

const componentByType = (type) => {
	switch (type) {
		case WidgetType.Commuters: return CommutersWidget;
		case WidgetType.DeviceInfo: return DeviceInfoWidget;
		case WidgetType.DevicePresenceDetector: return PresenceDetectorWidget;
		case WidgetType.AttributeRangeDetector: return AttributeRangeDetectorWidget;
		case WidgetType.Trips: return TripsWidget;
		case WidgetType.Events: return EventsWidget;
		case WidgetType.DeviceTrackingWidget: return DeviceTrackingWidget;
		case WidgetType.TimeMachineSettingsWidget: return TimeMachineSettingsWidget;
		case WidgetType.TimeMachinePlayerWidget: return TimeMachinePlayerWidget;
		case WidgetType.DeviceEventsWidget: return DeviceEventsWidget;
	}
}

/**
 * @param {Object} props
 */

function WidgetManager(props) {
	const reduxKey = useContext(ReduxKeyContext);
	const config = useSelector(state => state.widgets[reduxKey]);
	const dispatch = useDispatch();

	const setLayout = (layout) => {
		dispatch(widgetActions.updateLayout({ domain: reduxKey, layout }));
	}

	const layout = config && config.map
		? Object.values(config.map).map(element => element.layout)
		: []
	;

	const onResizeWidget = (element) => {
		if (config && config.map && config.map[element.i] && config.map[element.i].autoSize) {
			dispatch(widgetActions.setAutoSize({ domain: reduxKey, uid: element.i, autoSize: false }));
		}
	}

	let content = null;
	if (config && config.map && config.maximized && config.map[config.maximized]) {
		const Component = componentByType(config.map[config.maximized].widgetType);
		content = (
			<WidgetContainer uid={config.maximized} maximized>
				<Component maximized />
			</WidgetContainer>
		);

	} else {
		if (config && config.display) {
			const widgets = layout.map(item => {
				const dataItem = config.map[item.i];
				const Component = componentByType(dataItem.widgetType);
				return <Component key={item.i} />;
			});
			content = (
				<>
					{props.children}
					<WidgetLayoutManager layout={layout} setLayout={setLayout} onResizeWidget={onResizeWidget}>
						{widgets}
					</WidgetLayoutManager>
				</>
			);
		} else {
			content = props.children;
		}
	}

	return (content);
}

export default WidgetManager;
