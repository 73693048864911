import { combineEpics } from "redux-observable";
import { filter, tap, ignoreElements } from 'rxjs/operators';

import { ActionGeneratorBuilder } from "./actions";
import { rootLogger } from "core/lib/log";

const logger = rootLogger.logger('root');

const actions = new ActionGeneratorBuilder('root').type('clear').build();

const reducer = (state, action) => {
	switch (action.type) {
		case actions.clear.type: return undefined;
	}
	return state;
};

const epic = combineEpics(
	action$ => action$.pipe(
		filter(action =>  Boolean(action.errorMessage))
		, tap(action => logger.info('Error', action))
		, ignoreElements()
	)
);

export {actions, reducer, epic};
