import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../i18n';
import { cx } from '../../../api';
import { className, fromProps } from '../../../lib/className';
import { actions } from '../../../redux/api/categories';
import { actions as contextActions } from '../../../redux/app/context';
import ButtonGroup from '../../general/form/ButtonGroup';
import Button from '../../general/form/Button';
import Form from '../../general/form/Form';

/**
 * @param {Object} props
 * @param {number} props.categoryId
 * @param {Array.<number>} [props.comprisingIds]
 * @param {boolean} [props.standalone] indicates whether component uses redux context action
 * @param {function} [props.onCancel]
 * @param {function} [props.onReady]
 */

function CategoryForm(props) {
	const { f } = useI18n();
	const category = props.categories.map[props.categoryId];
	const initialName = category ? category.name : null;
	const [name, setName] = useState(initialName);
	const submitHook = useRef(null);
	const pending = useRef(false);

	useEffect(() => {
		setName(initialName);
		return () => {
			props.dispatch(actions.clear());
		}
	}, [props.categoryId]);

	useEffect(() => {
		if (!props.categories.pending && pending.current) {
			pending.current = false;
			if (!props.standalone) {
				props.dispatch(contextActions.actionClear());
			} else {
				if (!props.categories.error && !!props.onReady) props.onReady();
			}
		}
	}, [props.categories]);

	const onSubmit = () => {
		const categoryData = new cx.ods.categories.CategoryData();
		categoryData.name = name.trim();
		categoryData.comprisingIds = category && category.comprisingIds || props.comprisingIds;
		pending.current = true;
		if (!category) {
			props.dispatch(actions.add.request({ data: categoryData }));
		} else {
			props.dispatch(actions.update.request({ categoryId: props.categoryId, data: categoryData }));
		}
	}

	const submit = () => {
		submitHook.current();
	}

	const removeClick = () => {
		props.dispatch(contextActions.actionSet({
			actionType: 'remove',
			name: 'category',
			data: {
				...props.contextAction.data,
				id: props.categoryId
			}
		}));
	}

	return (
		<Form
			className={className('category-form', fromProps(props))}
			onSubmit={onSubmit}
			submitHook={submitHook}
			disabled={props.categories.pending}
			objectType={cx.ods.categories.CategoryData}
			error={props.categories.error}
			footer={
				<ButtonGroup>
					{props.onCancel &&
						<Button
							key="cancel"
							disabled={props.categories.pending}
							onClick={props.onCancel}
						>
							{f('cancel')}
						</Button>
					}
					<Button
						key="save"
						disabled={props.categories.pending}
						onClick={submit}
					>
						{f('save')}
					</Button>
					{category && !props.standalone &&
						<Button
							key="delete"
							disabled={props.categories.pending}
							onClick={removeClick}
						>
							{f('delete')}
						</Button>
					}
				</ButtonGroup>
			}
		>
			<Form.Control
				controlName="name"
				controlType={Form.Control.Type.Input}
				controlValidator={(value) => {
					if (value == null || value.trim() == '') {
						return f('please enter a value');
					}
				}}
				label={f('name')}
				value={name}
				onChange={setName}
				autoFocus
			/>
		</Form>
	);
}

export default connect(
	state => ({
		categories: state.categories.general,
		contextAction: state.context.action
	})
)(CategoryForm);
