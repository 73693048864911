import { ActionGeneratorBuilder } from "../actions";

const defaultState = {
	name: '',
	excludeUris: null
};

const actions = new ActionGeneratorBuilder('deviceFilter')
	.type('search', 'name')
	.type('excludeUris', { excludeUris: false })
	.build()
;

function reducer(state = defaultState, action) {
	switch (action.type) {
		case actions.search.type:
			return { ...state, name: action.name };
		case actions.excludeUris.type:
			return { ...state, excludeUris: action.excludeUris };
		default:
			return state || defaultState;
	}
}

let store = null;
const connect = _store => store = _store;

function getDevicesList() {
	const devices = store.getState().devices.list || [];
	const deviceFilter = store.getState().deviceFilter;
	const name = deviceFilter.name;
	const excludeUris = deviceFilter.excludeUris;
	if (!name && !excludeUris) return devices;
	let resultFilter = devices;
	if (excludeUris) {
		resultFilter = resultFilter.filter(device => !excludeUris.includes(device.uri));
	}
	if (name) {
		resultFilter = resultFilter.filter(device => device.denomination().toUpperCase().includes(name.toUpperCase()));
	}
	return resultFilter;
}

function getDevicesCategoryList(id) {
	const state = store.getState();
	const devices = state.devices.categoriesMap && state.devices.categoriesMap[id];
	const deviceFilter = state.deviceFilter;
	const name = deviceFilter.name;
	const excludeUris = deviceFilter.excludeUris;
	if (!devices) return [];
	if (!name && !excludeUris) return devices;
	let resultFilter = devices;
	if (excludeUris) {
		resultFilter = resultFilter.filter(device => !excludeUris.includes(device.uri));
	}
	if (name) {
		  resultFilter = resultFilter.filter(device => device.denomination().toUpperCase().includes(name.toUpperCase()));
	}
	return resultFilter;
}


export {
	actions, reducer, connect,
	getDevicesList,
	getDevicesCategoryList
};
