import React from 'react';
import { DateRangePicker as RsDateRangePicker } from 'rsuite';
import { className, fromProps } from '../../../lib/className';
import './xPicker.scss';
import './dateRangePicker.scss';

/**
 * For other params - see rsuite DateRangePicker
 * @param {Object} props
 * @param {number} [props.maxDays]
 * @param {boolean} [props.noFutureDates]
 * @param {string} [props.label]
 * @param {React.RefObject} [props.customRef]
 */

function DateRangePicker(props) {
	const { combine, allowedMaxDays, afterToday } = RsDateRangePicker;
	const { maxDays, noFutureDates, customRef, ..._props } = props;
	let restrictions = [];
	if (maxDays) restrictions.push(allowedMaxDays(maxDays));
	if (noFutureDates) restrictions.push(afterToday());
	const disabledDate = restrictions.length > 0 && combine.apply(null, restrictions) || null;
	return (
		<div className={className('x-picker', 'date-range-picker', fromProps(props))} ref={customRef}>
			{props.label && <label>{props.label}</label>}
			<RsDateRangePicker
				block
				disabledDate={disabledDate}
				{..._props}
			/>
		</div>
	)
}

export default DateRangePicker;