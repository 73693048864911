import React, { FC, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { ICommuteDetector } from 'types/detectors';
import { DeviceManager } from 'core/react/share/devices/DeviceManager';
import { ICommuteDetectorFormData } from 'core/react/pages/CommuteDetectorsPage';
import { DEFAULT_SET_VALUE_PROPS } from 'constants/form';

import './commuteDetectorView.scss';

interface ICommuteDetectorViewProps {
  commuteDetector: ICommuteDetector | null;
  isEditMode?: boolean;
  isDisabled?: boolean;
}

export const CommuteDetectorView: FC<ICommuteDetectorViewProps> = ({ commuteDetector, isEditMode, isDisabled }) => {
  const { control, setValue } = useFormContext<ICommuteDetectorFormData>();

  const setUris = (uris: string[]) => {
    setValue('uris', uris, DEFAULT_SET_VALUE_PROPS);
  };

  useEffect(() => {
    if (!!commuteDetector && isEditMode) {
      setUris(commuteDetector.uris);
    }
  }, [commuteDetector, isEditMode]);

  useEffect(() => {
    return () => {
      setUris([]);
    };
  }, []);

  return (
    <div className="commute-detector body">
      {isEditMode ? (
        <Controller
          control={control}
          name="uris"
          render={({ value, onChange }) => (
            <DeviceManager uris={value} setUris={onChange} isDisabled={isDisabled} isEditMode />
          )}
        />
      ) : (
        <DeviceManager uris={commuteDetector?.uris || []} />
      )}
    </div>
  );
};
