import { useDispatch, useSelector } from 'react-redux';

import { isSidebarCollapsedSelector } from 'core/redux/selectors/context';
import { STORAGE_KEY } from 'core/redux/app/categories/grouping';
import { actions as contextActions } from 'core/redux/app/context';
import useLocalStorage from 'core/misc/useLocalStorage';

export const useDevicesSideMenu = () => {
  const dispatch = useDispatch();
  const isCollapsed = useSelector(isSidebarCollapsedSelector);

  const [groupingId, setGroupingId] = useLocalStorage(STORAGE_KEY);
  const [hiddenCategories, setHiddenCategories] = useLocalStorage('hidden_device_categories');

  const onToggleChanged = (value: boolean) => {
    dispatch(contextActions.setSidebarCollapsed({ collapsed: value }));
  };

  const categoryListProps = { groupingId, hiddenCategories: hiddenCategories || [], setHiddenCategories };
  const actionBarProps = { groupingId, setHiddenCategories };

  return { isCollapsed, groupingId, categoryListProps, actionBarProps, setGroupingId, onToggleChanged };
};
