import React, { useState, useRef, useContext, useEffect, useImperativeHandle } from 'react';
import { assert } from '../../../lib/assert';
import Input from './Input';
import Checkbox from './Checkbox';
import Button from './Button';
import InputPicker from './InputPicker';
import SelectPicker from './SelectPicker';
import DateRangePicker from './DateRangePicker';
import InputNumber from './InputNumber';
import { generateId, isEnterButton, isInModal } from '../../../misc/misc';
import { className, fromProps } from '../../../lib/className';
import './form.scss';

const FormContext = React.createContext({});

const isModalOpen = () => {
	const children = document.body.children;
	let isOpen = false;
	for (let i = 0; i < children.length && !isOpen; ++i) {
		isOpen = children.item(i).classList.contains('rs-modal-wrapper');
	}
	return isOpen;
}

/**
 * @param {Object} props
 * @param {function} props.onSubmit
 * @param {React.RefObject} [props.submitHook]
 * @param {boolean} [props.disabled]
 * @param {function} [props.objectType]
 * @param {function} [props.onChange]
 * @param {string} [props.error]
 * @param {React.Component} [props.header]
 * @param {React.Component} [props.footer]
 * @param {React.RefObject} [props.customRef]
 */

function Form(props) {
	const inputRef = useRef(null);
	const values = useRef({});
	const controls = {};
	const [serial, setSerial] = useState(0);

	useImperativeHandle(props.customRef, () => ({
		purge: () => {
			values.current = {};
			setSerial(serial+1);
		}
	}));

	const onSubmit = (event) => {
		event && event.preventDefault();
		if (props.disabled || isModalOpen() && !isInModal(inputRef.current)) return;
		const invalid = Object.values(controls).reduce((invalid, {validate}) => {
			return validate && validate(values.current) || invalid;
		}, false);
		if (invalid || !props.onSubmit) return;
		const result = props.objectType ? new props.objectType() : {};
		Object.values(controls).forEach(({read}) => {
			if (read) read(values.current, result);
		});
		props.onSubmit(result);
	};

	useEffect(() => {
		const onKeydown = (event) => {
			if (isEnterButton(event)) onSubmit(event);
		}
		window.addEventListener('keydown', onKeydown);
		return () => window.removeEventListener('keydown', onKeydown);
	});

	const onControlChange = (name, value) => {
		if (values.current[name] != value) {
			values.current[name] = value;
			if (props.onChange) props.onChange(values.current);
		}
	}

	if (props.submitHook) {
		props.submitHook.current = onSubmit;
	}

	const context = {
		values: values.current,
		controls,
		onControlChange: onControlChange,
		disabled: props.disabled,
	};

	return (
		<FormContext.Provider value={context}>
			<form className={className('form', fromProps(props))} onSubmit={onSubmit} ref={props.formRef}>
				{props.header && <div className="header">{props.header}</div>}
				<fieldset>
					{props.children}
				</fieldset>
				{props.error && <div className="error">{props.error}</div>}
				{props.footer && <div className="footer">{props.footer}</div>}
				<input ref={inputRef} type="submit" disabled={props.disabled} />
			</form>
		</FormContext.Provider>
	)
}

// -----------------------------------------------------------------
const GroupContext = React.createContext({});

function FormControlGroup(props) {
	const groupContext = useContext(GroupContext);
	const context = {
		disabled: props.disabled || groupContext.disabled,
	};

	return (
		<GroupContext.Provider value={context}>
			<div
				className={className('form-control-group', fromProps(props), { 'labelled': props.label })}
				ref={props.customRef}
			>
				{props.label && <label>{props.label}</label>}
				<div className="content">
					{props.children}
				</div>
			</div>
		</GroupContext.Provider>
	)
}

Form.ControlGroup = FormControlGroup;

// -----------------------------------------------------------------

function FormControl(props) {
	const {
		controlType, controlName, controlValidator,
		formContext, value, onChange, initialValue,
		customRef,
		..._props
	} = props;
	const groupContext = useContext(GroupContext);
	const [error, setError] = useState(null);
	const [_value, _setValue] = useState(initialValue || null);

	useEffect(() => {
		if (initialValue !== undefined) _setValue(initialValue);
	}, [initialValue]);

	const controlled = props.value !== undefined && props.onChange !== undefined;
	const transient = props.controlName == null; // not merged into result
	const uid = useRef(transient && generateId());
	const name = controlName || uid.current;
	const controlValue = controlled ? value : _value;
	const disabled = props.disabled || groupContext.disabled || formContext.disabled;

	assert(controlType != null, "FormControl requires 'controlType' property");
	assert(controlled && initialValue === undefined || !controlled,
		"FormControl can be controlled (value and onChange are specified) or uncontrolled (initialValue could be specified)"
	);
	assert(!transient || transient && controlled,
		"FormControl: transient control (without controlName) should be controlled"
	);

	const process = (value) => {
		return props.controlType.process ? props.controlType.process(value) : value;
	}

	// analyze usages for transient controls using validators to get rid of dependency on value
	useEffect(() => {
		const control = formContext.controls[name] || (formContext.controls[name] = {});
		control.validate = disabled || !controlValidator ? null : values => {
			const error = controlValidator(transient ? value : values[name], values, process(value));
			setError(error ? error : null);
			return Boolean(error);
		};
		if (error) setError(null);
	}, [value, disabled, name, controlValidator, formContext]);

	useEffect(() => {
		const control = formContext.controls[name] || (formContext.controls[name] = {});
		control.read = transient || disabled ? null : (values, target) => target[name] = values[name];
	}, [disabled, name, formContext]);

	const _onChange = (value) => {
		if (error) setError(null);
		controlled ? onChange(value) : _setValue(value);
	}

	useEffect(() => {
		if (!transient) formContext.onControlChange(name, process(controlValue));
	}, [controlValue, name]);

	const Type = props.controlType;
	return (<>
		{!props.hidden &&
			<div className={className('form-control', fromProps(props))} ref={customRef}>
				<Type
					value={controlled ? value : _value}
					onChange={_onChange}
					disabled={disabled}
					{..._props}
				/>
				{error && <div className="error">{error}</div>}
			</div>
		}
	</>);
}

/**
 * @param {Object} props
 * @param {function} props.controlType - react component
 * @param {string} [props.controlName]
 * @param {function} [props.controlValidator]
 * @param {any} [props.value]
 * @param {function} [props.onChange]
 * @param {any} [props.initialValue]
 * @param {boolean} [props.hidden]
 * @param {React.MutableRefObject} [props.customRef]
 */

function FormControlWrapper(props) {
	const context = useContext(FormContext);
	if (props.contextRef != null) props.contextRef.current = context;
	return (<FormControl formContext={context} {...props} />);
}

Form.Control = FormControlWrapper;
Form.Control.Type = { Input, Checkbox, Button, InputPicker, SelectPicker, DateRangePicker, InputNumber };

// -----------------------------------------------------------------

export default Form;
