import React from 'react';
import { className, fromProps } from '../../../lib/className';
import { Toggle as RsToggle } from 'rsuite';
import { extract } from '../../../misc/object';
import { useI18n } from '../../../../i18n';
import './toggle.scss';

/**
 * For other parameters - see rsuite Toggle.
 * @param {Object} props
 * @param {boolean} props.value
 * @param {function} props.onChange
 * @param {boolean} [props.block]
 * @param {string} [props.label]
 * @param {string} [props.titleOn=ON]
 * @param {string} [props.titleOff=OFF]
 */

function Toggle(props) {
	const { f } = useI18n();
	const [params, passthrough] = extract(props, ['block', 'titleOn', 'titleOff', 'label', 'onChange', 'value']);

	const onChange = (value) => {
		params.onChange && params.onChange(value);
	}

	return (
		<div className={className('toggle', fromProps(props), { 'block': params.block })}>
			{params.label && (<div className="label"><span className="capitalize">{params.label}</span></div>)}
			<div>
				<RsToggle
					checkedChildren={params.titleOn || f('ON')}
					unCheckedChildren={params.titleOff || f('OFF')}
					checked={params.value}
					onChange={onChange}
					{...passthrough}
				/>
			</div>
		</div>
	);
}

export default Toggle;
