import React, { useState, useRef } from 'react';
import { className, fromProps } from '../../../lib/className';
import { useI18n } from '../../../../i18n';
import { capitalize } from '../../../misc/misc';
import Popup from '../../general/Popup';
import List from '../../general/list/List';
import ListItem from '../../general/list/ListItem';
import arrowIcon from '../../../img/icons/arrow.png';
import './categoryPicker.scss';

/**
 * @param {Object} props
 * @param {function} props.onSelect
 * @param {Array.<cx.ods.categories.CategoryData>} [props.categories]
 * @param {cx.ods.categories.CategoryData} [props.selected]
 * @param {string} [props.label]
 * @param {string} [props.title]
 */

function CategoryPicker(props) {
	const { f, fc } = useI18n();
	const [opened, setOpened] = useState(false);
	const boardBox = useRef(null);

	const setIsOpen = (value) => {
		setOpened(value);
	}

	const onClose = () => {
		setOpened(false);
	}

	const onSelect = (category) => {
		props.onSelect(category);
		onClose();
	}

	const onClear = (event) => {
		event.stopPropagation();
		props.onSelect(null);
	}

	const compare = (a, b) => {
		const aName = a.custom ? a.name : f({ prefix: 'category', id: a.name });
		const bName = b.custom ? b.name : f({ prefix: 'category', id: b.name });
		if (aName.toLowerCase() > bName.toLowerCase()) return 1;
		if (aName.toLowerCase() < bName.toLowerCase()) return -1;
		return 0;
	};

	const categoryItems = props.categories && props.categories.sort(compare).map(category => {
		const categoryName = !category.custom
			? fc({ prefix: 'category', id: category.name })
			: capitalize(category.name)
		;
		return (
			<ListItem
				key={category.categoryId}
				className={className({ 'selected': props.selected && props.selected.categoryId == category.categoryId })}
				onClick={() => onSelect(category)}
			>
				{categoryName}
			</ListItem>
		);
	});

	const width = boardBox.current ? boardBox.current.offsetWidth + 15 : 'auto';

	let selectedCategoryName = null;
	if (props.selected) {
		selectedCategoryName = !props.selected.custom
			? fc({ prefix: 'category', id: props.selected.name })
			: capitalize(props.selected.name)
		;
	}

	return (
		<Popup
			isOpen={opened}
			position="bottom-left"
			disabled={props.disabled}
			setIsOpen={setIsOpen}
			customRef={boardBox}
			fill
			className={className('category-picker', fromProps(props))}
			content={(
				<div className="category-picker-content" style={{ width }}>
					<List>
						{categoryItems}
					</List>
				</div>
			)}
		>
			<div className="category-picker-board" title={capitalize(props.title)}>
				<div className="label">
					<label>{props.label ? props.label + ':' : ''}</label>
					<div className="value">
						{selectedCategoryName ? selectedCategoryName : <span className="none">&mdash; {fc('none')} &mdash;</span>}
					</div>
				</div>
				<div className="actions">
					{props.selected
						&& <div className="clear" onClick={onClear}>
							x
						</div>
					}
					<div className="arrow-button">
						<img className={className({ 'rotate': opened })} src={arrowIcon} title="" alt="" />
					</div>
				</div>
			</div>
		</Popup>
	);
}

export default CategoryPicker;
