import React, { useState, useRef, useEffect } from 'react';
import DatePicker from '../form/DatePicker';
import { isEscapeButton, generateId } from '../../../misc/misc';
import { hasTarget } from '../../../misc/events';
import { datetime } from '../../../misc/datetime';
import { useI18n } from '../../../../i18n';

/**
 * @param {Object} props
 * @param {Date} props.since
 * @param {Date} props.until
 * @param {Date} props.now
 * @param {function} props.onChange
 * @param {number} [props.maxInterval] ms
 * @param {number} props.minInterval ms
 */

function StatusLine(props) {
	const { fc } = useI18n();
	const [sincePicker, setSincePicker] = useState(false);
	const [untilPicker, setUntilPicker] = useState(false);
	const [atPicker, setAtPicker] = useState(false);
	const sincePickerRef = useRef(null);
	const untilPickerRef = useRef(null);
	const atPickerRef = useRef(null);
	const id = useRef(generateId());

	const toggleSincePicker = () => {
		setSincePicker(!sincePicker);
	}

	const toggleUntilPicker = () => {
		setUntilPicker(!untilPicker);
	}

	const getAppropriateNow = (since, until, now) => {
		if (now.getTime() < since.getTime()) now = since;
		else if (until.getTime() < now.getTime()) now = until;
		return now;
	}

	const onSinceChange = (since) => {
		if (sincePicker) toggleSincePicker();
		let until = props.until;
		if (props.until.getTime() - props.minInterval < since.getTime()) {
			until = new Date(since.getTime() + props.until.getTime() - props.since.getTime());
		} else if (props.maxInterval) {
			const maxUntil = since.getTime() + props.maxInterval;
			if (maxUntil < props.until.getTime()) until = new Date(maxUntil);
		}
		props.onChange(since, until, getAppropriateNow(since, until, props.now));
	}

	const onUntilChange = (until) => {
		if (untilPicker) toggleUntilPicker();
		let since = props.since;
		if (until.getTime() < props.since.getTime() + props.minInterval) {
			since = new Date(until.getTime() - (props.until.getTime() - props.since.getTime()));
		} else if (props.maxInterval) {
			const minSince = until.getTime() - props.maxInterval;
			if (minSince > props.since.getTime()) since = new Date(minSince);
		}
		props.onChange(since, until, getAppropriateNow(since, until, props.now));
	}

	const toggleAtPicker = () => {
		setAtPicker(!atPicker);
	}

	const onAtChange = (now) => {
		if (atPicker) toggleAtPicker();
		let since = props.since;
		let until = props.until;
		if (now.getTime() < since.getTime() || until.getTime() < now.getTime()) {
			const sinceDifference = props.now.getTime() - since.getTime();
			const untilDifference = until.getTime() - props.now.getTime();
			since = new Date(now.getTime() - sinceDifference);
			until = new Date(now.getTime() + untilDifference);
			now = new Date(now.getTime());
		}
		props.onChange(since, until, now);
	}

	useEffect(() => {
		const onKeyDown = (event) => {
			if (isEscapeButton(event)) {
				if (sincePicker) toggleSincePicker();
				if (untilPicker) toggleUntilPicker();
				if (atPicker) toggleAtPicker();
			}
		}
		const onPointerDown = (event) => {
			const elements = document.getElementsByClassName(id.current);
			const hit = hasTarget(event, elements);
			if (sincePicker && !hit) toggleSincePicker();
			if (untilPicker && !hit) toggleUntilPicker();
			if (atPicker && !hit) toggleAtPicker();
		}
		if (sincePicker || untilPicker || atPicker) {
			window.addEventListener('keydown', onKeyDown);
			window.addEventListener('pointerdown', onPointerDown);
		}
		return () => {
			window.removeEventListener('keydown', onKeyDown);
			window.removeEventListener('pointerdown', onPointerDown);
		}
	}, [sincePicker, atPicker, untilPicker]);

	return (
		<div className="status-line">
			<span>
				<span className="clickable" onClick={toggleSincePicker}>{props.since.toLocaleString()}</span>
				{sincePicker &&
					<DatePicker
						ranges={[
							{
								label: 'yesterday',
								value: new Date(datetime.today() - datetime.DAY)
							},
							{
								label: 'today',
								value: datetime.today()
							}
						]}
						customRef={sincePickerRef}
						className="inline-control hide-control"
						menuClassName={id.current}
						format="YYYY-MM-DD HH:mm"
						value={props.since}
						onChange={onSinceChange}
						open={true}
						placement="auto"
						block={false}
						size="xs"
					/>
				}
			</span>
			<span>
				<span className="clickable" onClick={toggleAtPicker}>{props.now && props.now.toLocaleString()}</span>
				{atPicker &&
					<DatePicker
						ranges={[
							{
								label: 'yesterday',
								value: new Date(datetime.today() - datetime.DAY)
							},
							{
								label: 'today',
								value: datetime.today()
							}
						]}
						customRef={atPickerRef}
						className="inline-control hide-control"
						menuClassName={id.current}
						format="YYYY-MM-DD HH:mm"
						value={props.now}
						onChange={onAtChange}
						open={true}
						placement="auto"
						block={false}
						size="xs"
					/>
				}
			</span>
			<span>
				{untilPicker &&
					<DatePicker
						ranges={[
							{
								label: 'yesterday',
								value: new Date(datetime.today() - datetime.DAY)
							},
							{
								label: 'today',
								value: datetime.today()
							},
							{
								label: fc('now'),
								value: new Date()
							}
						]}
						customRef={untilPickerRef}
						className="inline-control hide-control"
						menuClassName={id.current}
						format="YYYY-MM-DD HH:mm"
						value={props.until}
						onChange={onUntilChange}
						open={true}
						placement="auto"
						block={false}
						size="xs"
					/>
				}
				<span className="clickable" onClick={toggleUntilPicker}>{props.until.toLocaleString()}</span>
			</span>
		</div>
	)
}

export default StatusLine;