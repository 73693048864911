import React from 'react';
import { useI18n } from '../../../../i18n';
import icon from '../../../img/icons/ignition.png';
import { className } from '../../../lib/className';
import './deviceStateIcon.scss';

/**
 * @param {Object} props
 * @param {cx.ods.devices.MessageDetails} props.message
 */

function IgnitionIcon(props) {
	const { message } = props;
	const { fc } = useI18n();
	let status = 'unknown', title = fc('unknown');
	if (message != null) {
		if (message && message.ignition === true) {
			status = 'active'; title = fc('on');
		} else if (message && message.ignition === false) {
			status = 'inactive'; title = fc('off');
		}
	}
	return (
		<div className={className('device-state-icon', status)} title={fc('ignition') + ": " + title}>
			<img src={icon} alt="" />
		</div>
	);
}

export default IgnitionIcon;