import React from 'react';
import { className, fromProps } from '../../../lib/className';
import './buttonGroup.scss';

function ButtonGroup(props) {
	return (
		<div className={className('button-group', fromProps(props))}>
			{props.children}
		</div>
	);
}

export default ButtonGroup;