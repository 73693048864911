import * as objects from "../../lib/objects";

export const ComponentType = Object.fromEntries(Object.entries({
	country: 'country', subdivision1: 'subdivision1', subdivision2: 'subdivision2', subdivision3: 'subdivision3'
	, locality: 'locality', neighborhood: 'neighborhood'
	, street: 'street', streetNumber: 'streetNumber'
	, streetAddress: 'streetAddress', reference: 'reference'
	, postalCode: 'postalCode'
}).map(([name, label], index) => [name, {toString: () => label, index}]));


export class Address {

	constructor(formatted, components) {
		this.formatted = formatted;
		if (components) this.components = components;
	}

	format() {
		if (this.formatted != null) return this.formatted;
		if (this.formattedComponents == null) this.formattedComponents = this.formatComponents();
		return this.formattedComponents; 
	}

	formatComponents() {
		if (!this.components) return "";
		const address = [];
		let addressLine = this.components[ComponentType.addressLine];
		if (!addressLine) addressLine = [ComponentType.streetNumber, ComponentType.street]
			.map(componentType => this.components[componentType])
			.filter(Boolean).join(" ")
		;
		if (addressLine) address.push(addressLine);
		else {
			const reference = this.components[ComponentType.reference] || this.components[ComponentType.neighborhood];
			if (reference) address.push(reference);
		}
		const locality = this.components[ComponentType.locality] 
			|| this.components[ComponentType.district2] || this.components[ComponentType.district1]
		;
		const localityLine = [locality, this.components[ComponentType.postalCode]].filter(Boolean).join(" ");
		if (localityLine) address.push(localityLine);
		const country = this.components[ComponentType.country];
		if (country && country != locality) address.push(country);
		return address.join(", ");
	}

	toString() {
		const items = [];
		if (this.formatted != null) items.push(this.formatted);
		if (this.components != null) items.push(Object.entries(this.components).map(([type, name]) => `${type}: ${name}`).join(', '));
		return items.join(', ');
	}
}


export const compactAddresses = addresses => {
	const tuples = trimPrefix(addresses);
	const numbers = numberEquivalents(tuples);
	return tuples.map((components, index) => {
		const formatted = new Address(null, components).format(), number = numbers.get(index);
		return number == null ? formatted : `${formatted} (${number})`;
	});
};

const numberEquivalents = tuples => {
	const indexedTuples = tuples.map((components, index) => ({components, index}));
	const equivalence = new Map();
	while (0 < indexedTuples.length) {
		const {components, index} = indexedTuples.pop();
		const entry = {qunatity: 1, number: 0};
		equivalence.set(index, entry);
		for (let at = indexedTuples.length - 1; 0 <= at; --at) {
			if (objects.shallowEqual(indexedTuples[at].components, components)) {
				equivalence.set(indexedTuples[at].index, entry);
				++entry.qunatity;
				indexedTuples.splice(at, 1);
			}
		}
	}
	const numbers = new Map();
	for (const [index, entry] of equivalence.entries()) {
		if (1 < entry.qunatity) numbers.set(index, (entry.qunatity - entry.number++));
	};
	return numbers;
};

const trimPrefix = (addresses) => {
	const types = Object.values(ComponentType);
	const prefix = commonPrefix(types, addresses);
	return addresses.map(({components}) => {
		if (prefix == 0 || !components) return components;
		const trimmed = {};
		let at = types.length - 1, readable = false; 
		for (; 0 <= at && (!readable || prefix <= at); --at) {
			const type = types[at], component = components[type];
			if (component) {
				if (at <= ComponentType.street.index) readable = true;
				trimmed[type] = components[type];
			}
		}
		return readable ? trimmed : null;
	});
};

const commonPrefix = (types, addresses) => {
	const limit = ComponentType.street.index;
	if (addresses.length < 2) return limit;
	const first = addresses[0].components;
	let at = 0;
	for (; at < limit; ++at) {
		const type = types[at];
		if (addresses.find(address => address.components?.[type] != first?.[type])) return at;
	}
	return at;
};
