const defaultState = {
	action: null,
	prevAction: null
};

// ---------------------------------------------------------

const types = {
	APP_ACTIONS_DISPATCH: 'APP_ACTIONS_DISPATCH'
}

const actions = {
	action: (type, payload) => {
		return { type: types.APP_ACTIONS_DISPATCH, payload: { type, ...payload }}
	}
}

// ---------------------------------------------------------

function reducer(state, action) {
	switch (action.type) {
		case types.APP_ACTIONS_DISPATCH:
			const prevAction = state.action;
			return {
				action: action.action,
				prevAction
			};
		default:
			return defaultState
	}
}

// ---------------------------------------------------------

export { types, actions, reducer };
