import React, { Dispatch, FC, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useDrop, DragObjectWithType } from 'react-dnd';
import moment from 'moment/moment';

import { cx } from 'core/api';
import { className } from 'core/lib/className';
import { IMqttClient } from 'types/mqttClient';
import Intent from 'core/misc/ObjectActionIntent';
import ListItem from 'core/react/general/list/ListItem';
import { DragItemType } from 'core/react/share/dnd/DragItemType';
import { localeSelector } from 'core/redux/selectors/locale';

interface IMqttTableRowProps {
  mqttClient: IMqttClient;
  onIntent: Dispatch<Intent>;
}

export const MqttTableRow: FC<IMqttTableRowProps> = ({ mqttClient, onIntent }) => {
  const locale = useSelector(localeSelector);

  const rowRef = useRef<HTMLDivElement | null>(null);

  const onEdit = () => {
    onIntent(new Intent(null, Intent.Action.Edit, mqttClient));
  };

  const onRemove = () => {
    const offset = cx.dom.at.offset(rowRef.current);
    const offsetWidth = rowRef.current?.offsetWidth;
    offset.left = offsetWidth ? (offset.left + offsetWidth) / 2 : offset.left;

    onIntent(new Intent(null, Intent.Action.Remove, mqttClient, { offset }));
  };

  const onDrop = useCallback((item: DragObjectWithType) => {
    item.type === DragItemType.ACTION_EDIT ? onEdit() : onRemove();
  }, []);

  const [dropState, dropRef] = useDrop({
    accept: [DragItemType.ACTION_EDIT, DragItemType.ACTION_REMOVE],
    canDrop: () => true,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const handleRef = (element: HTMLDivElement) => {
    rowRef.current = element;
    dropRef(element);
  };

  return (
    <ListItem
      //TODO: it's necessary to refactor how we deal with dnd & lists
      //eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      customRef={handleRef}
      className={className({ droppable: dropState.isOver && dropState.canDrop, canDrop: dropState.canDrop })}
    >
      <div className="mqtt-clients-table--column-id">{mqttClient.clientId}</div>
      <div className="mqtt-clients-table--column-identifier">{mqttClient.identifier}</div>
      <div className="mqtt-clients-table--column-description">{mqttClient.description}</div>
      <div className="mqtt-clients-table--column">
        {moment(mqttClient.createdAt).locale(locale).format('DD/MM/YYYY')}
      </div>
    </ListItem>
  );
};
