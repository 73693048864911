import React, { useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useI18n } from '../../../../i18n';
import { className, fromProps } from '../../../lib/className';
import { DragItemType } from '../dnd/DragItemType';
import ActionBar from '../../general/actionbar/ActionBar';
import icon from '../../../img/icons/pin-empty.png';

/**
 * @param {Object} props
 * @param {function} [props.onClick]
 * @param {string} [props.title]
 * @param {boolean} [props.active]
 * @param {boolean} [props.disabled]
 * @param {boolean} [props.dragDisabled]
 */

function ActionPin(props) {
	const { f } = useI18n();

	const [{ isDragging }, dragRef, dragPreview] = useDrag({
		item: { type: DragItemType.ACTION_PIN },
		canDrag: () => !props.disabled && !props.dragDisabled,
		collect: monitor => ({
			isDragging: monitor.isDragging()
		})
	});

	useEffect(() => {
		dragPreview(getEmptyImage(), { captureDraggingState: true });
	}, []);

	return (
		<ActionBar.Action
			className={className('pin', fromProps(props), { 'draggable': isDragging, 'drag': !props.dragDisabled })}
			customRef={dragRef}
			onClick={props.onClick}
			disabled={props.disabled}
			active={props.active}
			title={props.title || f('pin')}
		>
			<img src={icon} alt="" />
		</ActionBar.Action>
	)
}

export default ActionPin;
