import React from 'react';
import { DatePicker as RsDatePicker } from 'rsuite';
import { className, fromProps } from '../../../lib/className';
import './xPicker.scss';

/**
 * @param {Object} props
 * @param {string} [props.label]
 * @description For other parameters - see rsuite DatePicker component
 */

function DatePicker(props) {
	const { label, customRef, ..._props } = props;
	return (
		<div className={className('x-picker', 'date-picker', fromProps(props))} ref={customRef}>
			{label && <label>{label}</label>}
			<RsDatePicker
				block
				{..._props}
			/>
		</div>
	)
}

export default DatePicker;