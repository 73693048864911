import React from 'react';
import { Container, FlexboxGrid } from 'rsuite';

function CenterLayout(props) {

	const content = (
		<Container style={{height: "100%"}}>
			<FlexboxGrid justify="center" align="middle" style={{height: "100%"}}>
				<FlexboxGrid.Item>{props.children}</FlexboxGrid.Item>
			</FlexboxGrid>
		</Container>
	);

	return (content);
}

export default CenterLayout;