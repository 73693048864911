import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Loader from '../general/Loader';
import { useI18n } from '../../../i18n';
import { meta } from '../../redux/api/meta';
import Intent from '../../misc/ObjectActionIntent';
import { AllRoutes } from 'constants/routes';
import Input from '../general/form/Input';
import PagePanel from '../general/panel/PagePanel';
import TabLayout from '../general/layout/TabLayout';
import SidebarLayout from '../general/layout/SidebarLayout';
import DefaultActionBar from '../share/actionbar/DefaultActionBar';
import ActionAdd from '../share/actionbar/ActionAdd';
import ObjectActionDialogPopup from '../general/ObjectActionDialogPopup';
import DevicePropertiesView, { ViewMode } from '../custom/deviceProperties/DevicePropertiesView';
import RemoveDialog from '../general/RemoveDialog';
import DevicePropertyEditor from '../custom/deviceProperties/meta/DevicePropertyEditor';
import DeviceBundleEditor from '../custom/deviceProperties/meta/DeviceBundleEditor';
import propertiesIcon from '../../img/icons/settings.png';
import bundleIcon from '../../img/icons/explore.png';
import { setPageTitle } from '../../misc/page';
import './devicePropertiesPage.scss';

const PAGE_TITLE = 'device properties';

const switched = (left, right, condition) => {
	return condition ? left : right;
}

function DevicePropertiesPage(props) {
	const { f, fc } = useI18n();
	const [activeTab, setActiveTab] = useState(ViewMode.Properties);
	const [filter, setFilter] = useState('');
	const [intent, setIntent] = useState(null);
	const [error, setError] = useState(null);
	const pending = useRef(false);
	const disabled = props.properties.pending;

	useEffect(() => {
		setPageTitle(fc(PAGE_TITLE));
		if (props.properties.map == null && !props.properties.pending) {
			props.dispatch(meta.properties.actions.load.request());
		}
	}, []);

	useEffect(() => {
		if (props.properties.error != null) {
			setError(null);
			props.dispatch(meta.properties.actions.clear());
		}
	}, [intent]);

	useEffect(() => {
		if (!props.properties.pending && pending.current) {
			pending.current = false;
			if (props.properties.error != null) {
				setError(props.properties.error);
			} else {
				setIntent(null);
			}
		}
	}, [props.properties]);

	const onAddAction = () => {
		setIntent(new Intent('property', Intent.Action.Edit));
	}

	const onEditCancel = () => {
		setIntent(null);
	}

	const onEditReady = () => {
		setIntent(null);
	}

	const onTabChange = (type) => {
		setFilter('');
		setIntent(null);
		setActiveTab(type);
	}

	const onRemove = (property) => {
		pending.current = true;
		setError(null);
		props.dispatch(meta.properties.actions.remove.request({ propertyId: property.propertyId }));
	}

	const onRemoveCancel = () => {
		setError(null);
		setIntent(null);
	}

	let content = null;
	if (props.properties.map != null) {
		content = (
			<TabLayout
				tabs={[
					<TabLayout.Tab
						key={ViewMode.Properties}
						title={f(ViewMode.Properties)}
						type={ViewMode.Properties}
						icon={propertiesIcon}
						active={activeTab == ViewMode.Properties}
					/>,
					<TabLayout.Tab
						key={ViewMode.Bundles}
						title={f(ViewMode.Bundles)}
						type={ViewMode.Bundles}
						icon={bundleIcon}
						active={activeTab == ViewMode.Bundles}
					/>
				]}
				onChange={onTabChange}
				disabled={disabled}
			>
				<SidebarLayout
					className="body"
					icon={<img src={switched(bundleIcon, propertiesIcon, activeTab == ViewMode.Bundles)} alt="" />}
					title={f(activeTab)}
					sidebarContent={<>
						{(!intent || intent && intent.action() == Intent.Action.Remove) && <>
							<DefaultActionBar
								disabled={disabled}
								prependActions={<ActionAdd title={f('create property')} onClick={onAddAction} />}
							/>
							<div className="filter">
								<Input label={f('filter')} value={filter} onChange={setFilter} cleanable />
							</div>
						</>}
						{intent && intent.action() == Intent.Action.Edit && activeTab == ViewMode.Properties &&
							<DevicePropertyEditor
								onCancel={onEditCancel}
								onReady={onEditReady}
								property={intent.object()}
							/>
						}
						{intent && intent.action() == Intent.Action.Edit && activeTab == ViewMode.Bundles &&
							<DeviceBundleEditor
								onCancel={onEditCancel}
								onReady={onEditReady}
								bundle={intent.object()}
							/>
						}
					</>}
				>
					<DevicePropertiesView
						viewMode={activeTab}
						filter={filter}
						onSelect={() => {}}
						onIntent={setIntent}
					/>
					{intent && intent.action() == Intent.Action.Remove && (
						<ObjectActionDialogPopup
							offset={intent.data().offset}
							onClose={onEditCancel}
							title={f('delete property')}
							disabled={pending.current}
							error={error}
						>
							<RemoveDialog
								object={intent.object()}
								text={f('are you sure you want to delete property', { name: intent.object().name })}
								onSubmit={onRemove}
								onCancel={onRemoveCancel}
							/>
						</ObjectActionDialogPopup>
					)}
				</SidebarLayout>
			</TabLayout>
		);
	} else if (props.properties.pending) {
		content = <div className="vh-center"><Loader size={Loader.Size.LG} /></div>;
	}

	return (
		<div className="app-page device-properties">
			<PagePanel>{content}</PagePanel>
		</div>
	);
}

DevicePropertiesPage.Url = AllRoutes.DeviceProperties;

export default connect(state => ({
	properties: state.meta.properties
}))(DevicePropertiesPage);
