import { api, rx, cx, ods } from '../../../api';
import { ofType, combineEpics } from 'redux-observable';
import { switchMap, map } from 'rxjs/operators';
import { reduxSwitch } from '../../tools';
import { actions as sessionActions } from '../session';
import { APP_NAME } from './const';
import { ActionGeneratorBuilder, errorMap } from '../../actions';

const valueTypes = {
	devices: {
		pin: 'devices-pin'
	}
}

const defaultState = {
	map: null,
	pending: null,
	error: null
}

const actions = new ActionGeneratorBuilder('appValues')
	.subtype('add', add => add.request({ name: true, value: true }).success().fail())
	.subtype('load', load => load.request().success('values').fail())
	.subtype('update', update => update.request({ name: true, value: true }).success().fail())
	.subtype('remove', remove => remove.request('name').success().fail())
	.build()
;

// ----------------------------------------------------------------------------------

const hashmap = (list) => {
	list.forEach(data => data.value = JSON.parse(data.value));
	return cx.i.hash(list, (data) => data.name);
}

const addReducer = (state, action) => {
	switch (action.type) {
		case actions.add.request.type:
			return {
				...state,
				pending: true
			};
		case actions.add.fail.type:
			return {
				...state,
				pending: false,
				error: action.errorMessage
			};
		default:
			return state;
	}
}

const loadReducer = (state, action) => {
	switch (action.type) {
		case actions.load.request.type:
			return {
				...state,
				pending: true
			};
		case actions.load.success.type:
			return {
				...state,
				map: hashmap(action.values),
				pending: false,
				error: null
			};
		case actions.load.fail.type:
			return {
				...state,
				pending: false,
				error: action.errorMessage
			};
		default:
			return state;
	}
}

const updateReducer = (state, action) => {
	switch (action.type) {
		case actions.update.request.type:
			return {
				...state,
				pending: true
			};
		case actions.update.fail.type:
			return {
				...state,
				pending: false,
				error: action.errorMessage
			};
		default:
			return state;
	}
}

const removeReducer = (state, action) => {
	switch (action.type) {
		case actions.remove.request.type:
			return {
				...state,
				pending: true
			};
		case actions.remove.fail.type:
			return {
				...state,
				pending: false,
				error: action.errorMessage
			};
		default:
			return state;
	}
}

const reducer = reduxSwitch([addReducer, loadReducer, updateReducer,removeReducer], defaultState);

// ----------------------------------------------------------------------------------

const ws = api.application.value;

const addEpic = (action$) => {
	return action$.pipe(
		ofType(actions.add.request.type),
		switchMap(action => {
			const data = new ods.application.ValueData();
			data.name = action.name;
			data.value = JSON.stringify(action.value);
			return rx(ws.add, APP_NAME, data).pipe(
				map(operation => actions.load.request()),
				errorMap(actions.add.fail)
			)
		})
	)
}

const loadEpic = (action$) => {
	return action$.pipe(
		ofType(actions.load.request.type),
		switchMap(action =>
			rx(ws.load, APP_NAME).pipe(
				map(operation => actions.load.success({ values: operation.response() })),
				errorMap(actions.load.fail)
			)
		)
	)
}

const updateEpic = (action$) => {
	return action$.pipe(
		ofType(actions.update.request.type),
		switchMap(action => {
			const data = new ods.application.ValueData();
			data.value = JSON.stringify(action.value);
			return rx(ws.update, APP_NAME, action.name, data).pipe(
				map(operation => actions.load.request()),
				errorMap(actions.update.fail)
			)
		})
	)
}

const removeEpic = (action$) => {
	return action$.pipe(
		ofType(actions.remove.request.type),
		switchMap(action =>
			rx(ws.remove, APP_NAME, action.name).pipe(
				map(operation => actions.load.request()),
				errorMap(actions.remove.fail)
			)
		)
	)
}

const watchSessionEpic = (action$) => {
	return action$.pipe(
		ofType(sessionActions.events.started.type),
		map(action => actions.load.request())
	)
}

const epic = combineEpics(addEpic, loadEpic, updateEpic, removeEpic, watchSessionEpic);

// ----------------------------------------------------------------------------------

export { actions, reducer, epic, valueTypes };
