import React, { FC } from 'react';

import { useI18n } from 'i18n';
import { ICommuteDetector } from 'types/detectors';

import './commuteDetectorCard.scss';

interface ICommuteDetectorCardProps {
  commuteDetector: ICommuteDetector;
}

export const CommuteDetectorCard: FC<ICommuteDetectorCardProps> = ({ commuteDetector }) => {
  const { f, fc } = useI18n();
  const { name, debounceTimeout, resetTimeout, onTapIn, onTapOut } = commuteDetector;

  return (
    <div className="commute-detector card">
      <div className="name">
        <label>{f('name')}</label>
        <div>{name}</div>
      </div>
      {!!debounceTimeout && (
        <div className="name" title={fc('ignore repeated ID taps within specified interval')}>
          <label className="inline">{`${f('ID tap debounce timeout')}: `}</label>
          {`${debounceTimeout} ${f({ prefix: 'units', id: 's' })}`}
        </div>
      )}
      {!!resetTimeout && (
        <div className="name" title={fc('reset detector after specified period of device being idle')}>
          <label className="inline">{`${f('reset timeout')}: `}</label>
          {`${resetTimeout} ${f({ prefix: 'units', id: 's' })}`}
        </div>
      )}
      {(onTapIn || onTapOut) && (
        <div className="attributes">
          <label>{f('attributes')}</label>
          {onTapIn && (
            <div className="option" title={fc('fire an event when commuter taps in')}>
              <span className="capitalize">{f('on tap in')}</span>
            </div>
          )}
          {onTapOut && (
            <div className="option" title={fc('fire an event when commuter taps out')}>
              <span className="capitalize">{f('on tap out')}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
