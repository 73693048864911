import React from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../i18n';
import CategoryForm from '../../share/categories/CategoryForm';
import CategoryRemover from '../../share/categories/CategoryRemover';
import SidebarPopup from './DeviceSidebarPopup';

function DevicePopupActionDispatcher(props) {
	const { f } = useI18n();
	let body = null;
	let title = null;
	let content = null;

	const context = props.context.action;
	if (context) {
		if (context.type == 'edit' && context.name == 'category') {
			const type = context.data.title;
			title = context.data.id ? f('edit ' + type) : f('create ' + type);
			body = <CategoryForm standalone={context.data.standalone} categoryId={context.data.id} comprisingIds={[context.data.comprisingId]} />
		} else if (context.type == 'remove' && context.name == 'category') {
			title = f('delete');
			body = <CategoryRemover categoryId={context.data.id} title={context.data.title} />
		}

		content = (
			<SidebarPopup title={title}>
				{body}
			</SidebarPopup>
		)
	}
	return (content);
}

export default connect(state => {
	return ({
		context: state.context
	})
})(DevicePopupActionDispatcher);