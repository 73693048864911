import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../i18n';
import { actions as categoriesActions } from '../../../redux/api/categories';
import CategoriesControl from '../../share/categories/CategoriesControl';

/**
 * @param {Object} props
 * @param {boolean} props.editMode
 * @param {boolean} props.disabled
 * @param {cx.ods.assets.Employee} [props.employee]
 */

function EmployeeCategories(props) {
	const [categoryIds, setCategoryIds] = useState(props.employee?.categoryIds || []);
	const { f } = useI18n();
	let content = null;

	useEffect(() => {
		if (props.categories.map == null) props.dispatch(categoriesActions.load.request());
		setCategoryIds(props.employee?.categoryIds || []);
	}, [props.employee]);

	if (props.categories.root != null) {
		content = (
			<CategoriesControl
				className="employee-categories"
				categoryIds={categoryIds}
				comprisingIds={[props.categories.root.categoryId]}
				emptyText={f('no tags assigned')}
				disabled={props.disabled}
			/>
		);
	}

	return (content);
}

export default connect(state => ({ categories: state.categories.employees }))(EmployeeCategories);
