import React from 'react';
import { InputNumber as RsInputNumber } from 'rsuite';
import { className, fromProps } from '../../../lib/className';
import './input.scss';

/**
 * For other parameters - see rsuite InputNumber component
 * @param {Object} props
 * @param {string} [props.title]
 * @param {string} [props.label]
 * @param {string} [props.value]
 * @param {number} [props.min]
 * @param {boolean | undefined} [props.disabled]
 * @param {function} [props.onChange]
 */

function InputNumber(props) {
	const { onChange, ..._props } = props;

	const _onChange = (value) => {
		// prevent rsuite control return 'number' value instead of string on blur event
		if (typeof value == 'number') return;
		if (onChange) onChange(value);
	}

	return (
		<div className={className('input', 'input-number', fromProps(props))}>
			{props.label && <label>{props.label}</label>}
			<RsInputNumber onChange={_onChange} {..._props} />
		</div>
	)
}

// For internal use in FormControl component
InputNumber.process = (value) => {
	return value != null && value !== '' ? +value : null;
}

export default InputNumber;
