import './../index.scss';
import 'ol/ol.css';
import 'olgm/olgm.css';
import './../rsuite.scss';

import { combineReducers, createStore, applyMiddleware } from "redux";
import { combineEpics, createEpicMiddleware } from 'redux-observable';

import { epic as storageEpic } from './app/storage';

import * as root from './root';

// API reducers
import * as authorization from './api/authorization';
import * as session from './api/session';
import * as account from './api/account';
import * as devices from './api/devices';
import * as announcer from './api/announcer';
import * as deviceStates from './api/deviceStates';
import * as deviceCommands from './api/deviceCommands';
import * as latestEvents from './api/latestEvents';
import * as assets from './api/assets';
import * as categories from './api/categories';
import * as registry from './api/registry';
import * as eventTypesRegistry from './api/registry/eventTypes';
import * as messageFieldsRegistry from './api/registry/messageFields';
import * as attributeTypesRegistry from './api/registry/attributeTypes';
import * as countriesRegistry from "./api/registry/countries";
import * as rolesRegistry from "./api/registry/roles";
import * as timeZonesRegistry from "./api/registry/timeZones";
import * as category from "./api/registry/category";
import * as reports from './api/reports';
import * as deviceEvents from './api/deviceEvents';
import * as deviceFilteredEvents from './api/deviceFilteredEvents';
import * as zones from './api/zones';
import * as zoneGuards from "./api/zoneGuards";
import * as mqttClients from "./api/mqttClients";
import * as processors from "./api/processors";
import * as tripTrace from './api/tripTrace';
import * as trace from './api/trace';
import * as stomach from './api/stomach';
import * as appValues from './api/application/values';
import * as appLog from './api/application/log';
import * as deviceSummary from './api/deviceSummary';
import * as deviceDigest from './api/deviceDigest';
import * as history from './api/history';
import { meta } from './api/meta';

// App reducers
import * as context from './app/context';
import * as deviceStatus from './app/deviceStatus';
import * as deviceFilter from './app/deviceFilter';
import * as groups from './app/categories/groups';
import * as groupings from './app/categories/grouping';
import * as categoriesVisibility from './app/categories/visibility';
import * as zoneCategories from './app/categories/zone';
import * as deviceCategories from './app/categories/device';
import * as assetTypeCategories from './app/categories/assetType';
import * as eventTypeCategories from './app/categories/eventType';
import * as employeeCategories from './app/categories/employee';
import * as appActions from './app/appActions';
import * as pages from './app/pages';
import * as timeMachineState from './app/timeMachine/state';
import * as timeMachineDeviceStatus from './app/timeMachine/deviceStatus';
import * as timeMachinePlayer from './app/timeMachine/player';
import * as widgets from './app/widgets';
import * as locale from './app/locale';
import * as deviceTrace from './app/deviceTrace';
import * as appDeviceEvents from './app/appDeviceEvents';
import * as resolver from './app/resolver';

// ---------------------------------------------------

const combined = combineReducers({
	// API reducers
	authorization: authorization.reducer,
	session: session.reducer,
	account: account.reducer,
	devices: devices.reducer,
	announcer: announcer.reducer,
	deviceStates: deviceStates.reducer,
	deviceCommands: deviceCommands.reducer,
	latestEvents: latestEvents.reducer,
	assets: assets.reducer,
	registry: registry.reducer,
	reports: reports.reducer,
	zones: zones.reducer,
	deviceEvents: deviceEvents.reducer,
	deviceFilteredEvents: deviceFilteredEvents.reducer,
	application: appValues.reducer,
	zoneGuards: zoneGuards.reducer,
	mqttClients: mqttClients.reducer,
	processors: processors.reducer,
	stomach: stomach.reducer,
	tripTrace: tripTrace.reducer,
	trace: trace.reducer,
	meta: combineReducers({
		properties: meta.properties.reducer,
		datatypes: meta.datatypes.reducer,
		units: meta.units.reducer
	}),
	deviceSummary: deviceSummary.reducer,
	deviceDigest: deviceDigest.reducer,
	history: history.reducer,

	//
	categories: combineReducers({
		general: categories.reducer,
		groups: groups.reducer,
		groupings: groupings.reducer,
		categoriesVisibility: categoriesVisibility.reducer,
		zones: zoneCategories.reducer,
		devices: deviceCategories.reducer,
		assetTypes: assetTypeCategories.reducer,
		eventTypes: eventTypeCategories.reducer,
		employees: employeeCategories.reducer
	}),

	// app reducers
	context: context.reducer,
	deviceStatuses: deviceStatus.reducer,
	deviceFilter: deviceFilter.reducer,
	appActions: appActions.reducer,
	pages: pages.reducer,
	timeMachine: combineReducers({
		state: timeMachineState.reducer,
		deviceStatus: timeMachineDeviceStatus.reducer,
		player: timeMachinePlayer.reducer,
	}),
	widgets: widgets.reducer,
	locale: locale.reducer,
	deviceTrace: deviceTrace.reducer,
	appDeviceEvents: appDeviceEvents.reducer
});

const reducer = (state, action) => combined(root.reducer(state, action), action);

const epic = combineEpics(
	// priority
	root.epic,
	storageEpic,

	// api
	announcer.epic, // should be before session and account
	authorization.epic,
	session.epic,
	account.epic,
	devices.epic,
	deviceStates.epic,
	deviceCommands.epic,
	latestEvents.epic,
	assets.epic,
	categories.epic,
	reports.epic,
	deviceEvents.epic,
	deviceFilteredEvents.epic,
	zones.epic,
	zoneGuards.epic,
	mqttClients.epic,
	processors.epic,
	stomach.epic,
	tripTrace.epic,
	trace.epic,
	appValues.epic,
	appLog.epic,
	meta.properties.epic,
	meta.datatypes.epic,
	meta.units.epic,
	deviceSummary.epic,
	deviceDigest.epic,
	history.epic,

	// registry
	eventTypesRegistry.epic,
	messageFieldsRegistry.epic,
	attributeTypesRegistry.epic,
	countriesRegistry.epic,
	rolesRegistry.epic,
	timeZonesRegistry.epic,
	category.epic,

	// app
	deviceStatus.epic,
	groups.epic,
	groupings.epic,
	zoneCategories.epic,
	deviceCategories.epic,
	assetTypeCategories.epic,
	eventTypeCategories.epic,
	employeeCategories.epic,
	pages.epic,
	timeMachineState.epic,
	timeMachineDeviceStatus.epic,
	timeMachinePlayer.epic,
	widgets.epic,
	deviceTrace.epic,
	appDeviceEvents.epic,
	resolver.epic
);

const epicMiddleware = createEpicMiddleware();
const store = createStore(
	reducer,
	applyMiddleware(epicMiddleware)
);

window._store = store;

epicMiddleware.run(epic);

session.connect(store);
deviceStates.connect(store);
latestEvents.connect(store);
deviceFilter.connect(store);
groupings.connect(store);

// reports
reports.locationHistoryConnect(store);
reports.tripConnect(store);
reports.tripHistoryConnect(store);
reports.temperatureHistoryConnect(store);
reports.deviceSituationsConnect(store);
reports.deviceStatusConnect(store);
reports.speedingConnect(store);
reports.idlingConnect(store);
reports.ridershipConnect(store);
reports.commuteConnect(store);

export { store };
