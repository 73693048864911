import React, { useRef, useContext, useImperativeHandle } from 'react';
import { connect } from 'react-redux';
import { List } from 'rsuite';
import Loader from '../../../general/Loader';
import DeviceListItem from './DeviceListItem';
import { GroupingIdContext } from '../device-side-menu';
import * as appValues from '../../../../redux/api/application/values';
import { DeviceDetailsProxy } from '../../../../api/device'; // eslint-disable-line
import './deviceList.scss';

/**
 * @param {Object} props
 * @param {number} [props.categoryId] - renders devices which belongs to the category
 * @param {Array.<DeviceDetailsProxy>} [props.devices] - renders only specified devices
 */

function DeviceList(props) {
	const childrenRef = useRef({});
	const groupingId = useContext(GroupingIdContext);
	const pinnedDevices = props.application
		&& props.application[appValues.valueTypes.devices.pin]
		&& props.application[appValues.valueTypes.devices.pin].value
	;

	useImperativeHandle(props.customRef, () => ({
		focus: (uri) => {
			const deviceRef = childrenRef.current[uri];
			if (deviceRef?.current?.focus) deviceRef.current.focus();
		}
	}));

	const sortDevices = (devices) => {
		devices.sort((left, right) => left.compare(right));
		if (!pinnedDevices) return devices;
		const pinned = [];
		const remaining = [];
		devices.forEach(device => {
			if (
				pinnedDevices[props.categoryId] && pinnedDevices[props.categoryId][device.uri]
				|| pinnedDevices[groupingId] && pinnedDevices[groupingId][device.uri]
			) {
				pinned.push(device);
			} else {
				remaining.push(device);
			}
		});
		return pinned.concat(remaining);
	}

	const getDevices = () => {
		return props.devices != null
			? sortDevices(props.devices)
			: props.categoryId != null
				? sortDevices(props.devices.list.filter(
					device => device.categoryIds && device.categoryIds.indexOf(props.categoryId) >= 0
				))
				: sortDevices(props.storeDevices.list)
		;
	}

	let content = null;
	if (props.storeDevices.list != null || props.devices != null) {
		const items = getDevices().map(device => {
			childrenRef.current[device.uri] = { current: null };
			return (<DeviceListItem
				key={device.uri}
				customRef={childrenRef.current[device.uri]}
				pinnedDevices={pinnedDevices}
				device={device}
				categoryId={props.categoryId}
			>
			</DeviceListItem>);
		});
		content = (<List bordered={false} className="device-list">{items}</List>);
	} else {
		content = (<Loader />);
	}

	return (content);
}

export default connect(state => {
	return {
		storeDevices: state.devices,
		application: state.application.map
	};
})(DeviceList);
