import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../../i18n';
import { meta } from '../../../../redux/api/meta';
import SelectPicker from '../../../general/form/SelectPicker';
import Loader from '../../../general/Loader';
import { className, fromProps } from '../../../../lib/className';

/**
 * Control for selecting data units for cx.ods.meta.IntegerProperty and cx.ods.meta.DecimalProperty (and possibly for others in the future).
 * @param {Object} props
 * @param {string} [props.value] can be controlled component
 * @param {function} props.onChange
 * @param {boolean} [props.disabled]
 */

function PropertyUnitsPicker(props) {
	const { f, fc } = useI18n();
	let content = null;

	useEffect(() => {
		if (props.units.map == null && !props.units.pending) {
			props.dispatch(meta.units.actions.units.request());
		}
	}, []);

	if (props.units.map != null) {
		const items = Object.values(props.units.map).map(unit => ({
			value: unit.unitId,
			label: fc({ prefix: 'units', id: unit.name }) + ' (' + f({ prefix: 'units', id: unit.symbol }) + ')'
		}));
		content = <SelectPicker
			disabled={props.disabled}
			data={items}
			searchable={true}
			cleanable={true}
			value={props.value}
			onChange={props.onChange}
			label={props.label}
			placeholder={props.placeholder}
		/>;
	} else {
		content = <Loader />;
	}

	return (
		<div className={className('property-units-picker', fromProps(props))}>
			{content}
		</div>
	);
}

export default connect(state => {
	return {
		units: state.meta.units
	}
})(PropertyUnitsPicker);