import React from 'react';

import { ISVGPersonalProps } from 'types/svg';

export const hideAll: ISVGPersonalProps = {
  viewBox: '0 0 16 16',
  content: (
    <g>
      <path
        d="M12.2,3.033l-9.1,9.1a6.483,6.483,0,0,0,9.1-9.1m-5.123,7.22a3.2,3.2,0,0,1-.836-.114l.707-.707c.043,0,.086.007.13.01A2.434,2.434,0,0,0,9.51,7.008c0-.045,0-.091,0-.136l.7-.7a3.243,3.243,0,0,1-3.134,4.084"
        transform="translate(0.845 0.858)"
        fill="#798699"
      />
      <path
        d="M6.491,0a6.491,6.491,0,1,0,6.491,6.491A6.491,6.491,0,0,0,6.491,0m0,1.623A4.868,4.868,0,1,1,1.623,6.491,4.868,4.868,0,0,1,6.491,1.623"
        transform="translate(1.43 1.43)"
        fill="#798699"
      />
      <path
        d="M7.245,4A3.23,3.23,0,0,0,4.114,8.082l.707-.707c0-.043-.008-.087-.01-.13A2.434,2.434,0,0,1,7.245,4.811c.045,0,.091,0,.136,0l.7-.7A3.221,3.221,0,0,0,7.245,4"
        transform="translate(0.676 0.676)"
        fill="#798699"
      />
      <path
        d="M10.789,1.682l-9.1,9.1A6.452,6.452,0,0,0,2.84,11.925l9.1-9.1a6.452,6.452,0,0,0-1.147-1.147"
        transform="translate(1.111 1.113)"
        fill="#798699"
      />
    </g>
  ),
};
