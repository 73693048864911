export const DEFAULT_SET_VALUE_PROPS = { shouldValidate: true, shouldDirty: true };

export const SUPPORTED_ATTRIBUTE_TYPES = [
  'm',
  'kph',
  's',
  'min',
  '%',
  'deg-C',
  'l',
  'rpm',
  'gps',
  'h',
  'V',
  'km',
  'dBm',
  'lph',
  'counter',
  'ms',
  'ms-2',
  'dB',
  'kPa',
  'distance',
  'A',
  'mm',
  'bpm',
  'mmHg',
  'cm',
  'kg',
  'boolean',
];
