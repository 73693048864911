import React, { useEffect, useState } from 'react';
import { useI18n } from '../../../../../i18n';
import Toggle from '../../../general/form/Toggle';
import { getOwMap } from '../../../general/location/Map';

/**
 * @param {Object} props
 * @param {boolean} props.mapName
 */

function MapOptionGoogleSatMode(props) {
	const map = getOwMap(props.mapName);
	const layerName = map.getBaseLayerName();
	const isGoogleLayer = layerName.toLowerCase() == 'google';
	const [value, setValue] = useState(map.getGoogleMapType() == 'hybrid');
	const [serial, setSerial] = useState(0);

	useEffect(() => {
		const onLayerChanged = () => {
			setSerial(serial+1);
		}
		map.addObserver(map.events.layerChanged, onLayerChanged, this);
		return () => {
			map.removeObserver(map.events.layerChanged, onLayerChanged, this);
		}
	}, [serial]);

	const onChange = () => {
		const newValue = !value;
		map.setGoogleMapType(newValue ? 'hybrid' : 'roadmap');
		setValue(newValue);
	}

	const { f } = useI18n();
	let content = null;
	if (isGoogleLayer) {
		content = (
			<div className="map-option google-sat-mode">
				<Toggle
					label={f('satellite view')}
					value={value}
					onChange={onChange}
				/>
			</div>
		);
	}

	return (content);
}

export default MapOptionGoogleSatMode;