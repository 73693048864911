import { AbstractResolver, ResolverFialedError, ResolverThrottledError } from './resolver';
import { Address, ComponentType } from './address';
import { store } from '../../redux/store';
import { localStorage } from 'core/app/storage';

const AUTH_URL = 'https://www.onemap.gov.sg/api/auth/post/getToken';
const BASE_URL = 'https://www.onemap.gov.sg/api/public/revgeocode';

const epsg3414 = {
	sw: {longitude: 103.59, latitude: 1.13}
	, ne: {longitude: 104.07, latitude: 1.47}
	, includes: function (longitude, latitude) {
		return this.sw.longitude <= longitude && longitude <= this.ne.longitude
			&& this.sw.latitude <= latitude && latitude <= this.ne.latitude
		;
	}
};

const STORAGE_KEY = 'one-map';

export default class OneMapResolver extends AbstractResolver {
	tokenRequest = null;

	covers(entry) {
		return epsg3414.includes(entry.longitude, entry.latitude);
	}

	name() {
		return 'one-map';
	}

	async obtainToken() {
		const storage = localStorage.get(STORAGE_KEY) || {};
		if (storage.token != null && new Date().getTime() < storage.expiresAt - 30 * 1000) return storage.token;

		if (this.tokenRequest != null) return this.tokenRequest;

		const credentials = store.getState().application.map['onemap-credentials']?.value;
		if (credentials == null) return null;

		this.tokenRequest = this.requestToken(credentials);
		const token  = await this.tokenRequest;
		this.tokenRequest = null;
		return token;
	}

	async requestToken(credentials) {
		const response = await fetch(AUTH_URL, {
			method: 'POST'
			, headers: { 'Content-Type': 'application/json' }
			, body: JSON.stringify({ email: credentials.email, password: credentials.password }),
		});
		if (response.status != 200) throw new ResolverFialedError(`Authentication failed, ${response.status}`);
		const reply = await response.json();
		localStorage.assign(STORAGE_KEY, {
			token: reply.access_token
			, expiresAt: reply.expiry_timestamp * 1000
		});
		return reply.access_token;
	}

	async updateToken(token) {
		const storedToken = localStorage.get(STORAGE_KEY)?.token;
		if (token != storedToken) return storedToken;
		localStorage.remove(STORAGE_KEY)
		return this.obtainToken();
	}

	async submit(entry) {
		if (!this.covers(entry)) return null;

		let token = await this.obtainToken();
		if (token == null) return null;

		const resolve = token => fetch(`${BASE_URL}?location=${entry.latitude},${entry.longitude}`, {
			headers: {'Authorization': `Bearer ${token}`}
		});

		let response = await resolve(token);
		if (response.status == 401) {
			token = await this.updateToken(token);
			if (token == null) return null;
			response = await resolve(token);
		}

		if (!response.ok) {
			if (response.status == 404) return null;
			if (response.status == 429) throw ResolverThrottledError.getInstance();
			const message = response.statusText ? `${response.status}. ${response.statusText}` : `Status ${response.status}`;
			if (400 <= response.status && response.status < 500) throw new Error(message);
			throw new ResolverFialedError(message);
		}

		const geocode = (await response.json())?.GeocodeInfo?.[0];
		return geocode != null ? this.constructAddress(geocode) : null;
	}

	static componentTypeMap = {
		'ROAD': ComponentType.street
		, 'BLOCK': ComponentType.streetNumber
		, 'BUILDINGNAME': ComponentType.reference, 'FEATURE_NAME': ComponentType.reference
		, 'POSTALCODE': ComponentType.postalCode
	};

	constructAddress(geocode) {
		const componentList = Object.entries(OneMapResolver.componentTypeMap).map(([name, componentType]) => {
			const value = geocode[name];
			return value != null && value != 'NIL' ? [componentType, value] : null
		}).filter(Boolean);
		if (componentList.length == 0) return null;
		return new Address(null, Object.fromEntries(componentList));
	}
}
