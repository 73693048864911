import React, { useState } from 'react';
import { InputGroup } from 'rsuite';
import InputNumber from '../../general/form/InputNumber';
import Button from '../../general/form/Button';
import { useI18n } from '../../../../i18n';
import { cx } from '../../../api';
import DeviceInputVoltageSelector from './DeviceInputVoltageSelector';
import './createLevelFields.scss';

/**
 * @param {Object} props
 * @param {function} props.onCreate
 * @param {Array.<string>} [props.uris]
 */

function CreateLevelFields(props) {
	const { f, fc, pc } = useI18n();
	const [errorMessage, setErrorMessage] = useState(null);
	const [voltage1, setVoltage1] = useState('');
	const [level1, setLevel1] = useState('');
	const [voltage2, setVoltage2] = useState('');
	const [level2, setLevel2] = useState('');

	const disabled = !(voltage1.trim() && level1.trim() && voltage2.trim() && level2.trim());

	const isNegative = (value) => +value < 0;

	const onCreate = () => {
		if (voltage1 === voltage2) {
			setErrorMessage(fc('please specify at least two entries with different voltages'));
		} else if (isNegative(voltage1) || isNegative(level1) || isNegative(voltage2) || isNegative(level2)) {
			setErrorMessage(fc('please enter a positive value'));
		} else if (+level1 > 100 || +level2 > 100) {
			setErrorMessage(pc('please enter level value not greater then', 100));
		} else {
			props.onCreate([
				new cx.ods.processors.VoltageLevel({ voltage: voltage1, level: level1 }),
				new cx.ods.processors.VoltageLevel({ voltage: voltage2, level: level2 })
			]);
		}
	}

	return (
		<div className="create-level-fields">
			<div>
				<div>
					<label>{f({ prefix: 'units', id: 'volt' }) + ' (' + fc({ prefix: 'units', id: 'V' }) + ')'}</label>
					<InputGroup>
						<InputNumber
							value={voltage1}
							onChange={setVoltage1}
							autoFocus
						/>
						{props.uris
							&& props.uris.length > 0
							&& <InputGroup.Addon className="addon">
								<DeviceInputVoltageSelector uris={props.uris} onSelect={setVoltage1} />
							</InputGroup.Addon>
						}
					</InputGroup>
				</div>
				<InputNumber
					label={fc('level') + ' (' + fc({ prefix: 'units', id: '%' }) + ')'}
					value={level1}
					onChange={setLevel1}
				/>
			</div>
			<div>
				<div>
					<label>{f({ prefix: 'units', id: 'volt' }) + ' (' + fc({ prefix: 'units', id: 'V' }) + ')'}</label>
					<InputGroup>
						<InputNumber
							value={voltage2}
							onChange={setVoltage2}
						/>
						{props.uris
							&& props.uris.length > 0
							&& <InputGroup.Addon className="addon">
								<DeviceInputVoltageSelector uris={props.uris} onSelect={setVoltage2} />
							</InputGroup.Addon>
						}
					</InputGroup>
				</div>
				<InputNumber
					label={fc('level') + ' (' + fc({ prefix: 'units', id: '%' }) + ')'}
					value={level2}
					onChange={setLevel2}
				/>
			</div>
			<div>
				<Button disabled={disabled} onClick={onCreate}>{f('create')}</Button>
			</div>
			{errorMessage && <div className="error">{errorMessage}</div>}
		</div>
	);
}

export default CreateLevelFields;
