import React from 'react';
import { CustomPicker } from 'react-color';
import { Saturation, Hue, EditableInput as Input } from 'react-color/lib/components/common';
import ColorPreviewBox from './ColorPreviewBox';
import HsvSwatches from './HsvSwatches';
import './colorPicker.scss';

function HuePointer() {
	return (<div className="hue-pointer"></div>);
}

/**
 * @param {Object} props
 * @param {string|Object} props.color - tinycolor color object
 * @param {function} props.onChange
 * @param {function} props.onChangeComplete
 */

function ColorPicker(props) {
	const labels = { h: 'H', s: 'S', v: 'V', hex: 'Hex' };

	return (
		<div className="color-picker">
			<div className="body">
				<div>
					<div className="saturation-wrap">
						<Saturation {...props} onChange={props.onChange} />
					</div>
					<div className="values">
						<ColorPreviewBox hex={props.hex} />
						<Input
							label={labels.h}
							value={Math.round(props.hsv.h)}
							onChange={(data, e) => props.onChange({
								h: data[labels.h],
								s: props.hsv.s,
								v: props.hsv.v,
								source: 'hsv'
							}, e)}
						/>
						<Input
							label={labels.s}
							value={Math.round(props.hsv.s * 100)}
							onChange={(data, e) => props.onChange({
								h: props.hsv.h,
								s: data[labels.s],
								v: props.hsv.v,
								source: 'hsv'
							}, e)}
						/>
						<Input
							label={labels.v}
							value={Math.round(props.hsv.v * 100)}
							onChange={(data, e) => props.onChange({
								h: props.hsv.h,
								s: props.hsv.s,
								v: data[labels.v],
								source: 'hsv'
							}, e)}
						/>
						<Input
							label={labels.hex}
							value={props.hex.replace('#', '')}
							onChange={(data, e) => props.onChange({
								hex: data[labels.hex],
								source: 'hex'
							}, e)}
						/>
					</div>
				</div>
				<div className="hue-group">
					<div className='hue-wrap'>
						<Hue width={20} {...props} pointer={HuePointer} direction='vertical' onChange={props.onChange} />
					</div>
					<div className="v-swatches">
						<HsvSwatches hsv={props.hsv} axis="v" onClick={props.onChange} />
					</div>
				</div>
			</div>
			<div className="h-swatches">
				<HsvSwatches hsv={props.hsv} axis="h" quantity={10} onClick={props.onChange} />
			</div>
		</div>
	);
}

export default CustomPicker(ColorPicker);