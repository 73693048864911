import { filter, map as rxmap } from 'rxjs/operators';
import { ofType, combineEpics } from 'redux-observable';
import { types as appActionTypes } from '../appActions';
import { AppActionType } from '../../../app/AppActionType';
import { actions } from './actions';

const defaultState = {
	active: false,
	multiple: false,
	lastSelected: {}, // uri, changedAt
	beforeMultipleSelected: {}, // uri, changedAt
	selectionMap: {}, // [uri] => boolean
	selection: [] // [uri]
};

// ---------------------------------------------------------

const sorting = (a, b) => {
	if (Array.isArray(a)) {
		if (a[0] > b[0]) return 1;
		if (a[0] < b[0]) return -1;
	} else {
		if (a > b) return 1;
		if (a < b) return -1;
	}
	return 0;
}

const reducer = (state, action) => {
	switch (action.type) {
		case actions.dashboard.setActive.type:
			state = {
				...state,
				active: action.status,
			};
			break;
		case actions.dashboard.setMultiple.type:
			let copyState = { ...state };
			if (state.multiple) {
				const uri = Object.keys(state.beforeMultipleSelected);
				if (uri.length > 0 && state.selectionMap[uri[0]]) {
					copyState.selectionMap = state.beforeMultipleSelected;
					copyState.lastSelected = {
						uri: uri[0],
						changedAt: JSON.stringify(new Date())
					};
				} else {
					copyState.selectionMap = {};
				}
			} else {
				copyState.selectionMap = state.selectionMap;
				copyState.beforeMultipleSelected = state.selectionMap
			}
			copyState.selection = Object.keys(copyState.selectionMap);
			copyState.multiple = action.status;
			state = copyState;
			break;
		case actions.dashboard.selectionChanged.type:
			const copySelection = state.multiple
				? { ...state.selectionMap }
				: {}
			;
			if (copySelection[action.uri]) {
				delete copySelection[action.uri];
			} else {
				copySelection[action.uri] = true;
			}
			const sortedCopy = Object.entries(copySelection).sort(sorting);
			const newSelection = Object.fromEntries(sortedCopy);
			state = {
				...state,
				selectionMap: newSelection,
				selection: Object.keys(newSelection),
				lastSelected: {
					uri: action.uri,
					changedAt: JSON.stringify(new Date())
				}
			};
			break;
		case actions.dashboard.setSelected.type:
			let newSelect = null;
			if (state.multiple) {
				const copy = { ...state.selectionMap }
				if (!copy[action.uri]) copy[action.uri] = true;
				const sorted = Object.entries(copy).sort(sorting);
				newSelect = Object.fromEntries(sorted);
			} else {
				newSelect = { [action.uri]: true };
			}
			state = {
				...state,
				selectionMap: newSelect,
				selection: Object.keys(newSelect),
				lastSelected: {
					uri: action.uri,
					changedAt: JSON.stringify(new Date())
				}
			};
			break;
		case actions.dashboard.setSelection.type:
			const selectionMap = {};
			action.uris.sort(sorting).forEach(uri => {
				selectionMap[uri] = true
			});
			let lastSelected = { ...state.lastSelected };
			action.uris.some(uri => {
				if (!state.selectionMap[uri]) {
					lastSelected = {
						uri,
						changedAt: JSON.stringify(new Date())
					}
					return true
				} else return false;
			});
			state = {
				...state,
				selectionMap,
				selection: Object.keys(selectionMap),
				multiple: action.uris.length > 1 ? true : state.multiple,
				lastSelected
			}
			break;
		case actions.dashboard.selectionClear.type:
			state = {
				...state,
				selectionMap: {},
				selection: []
			};
			break;
	}
	return state || defaultState;
}

// ---------------------------------------------------------

const interceptEpic = (action$, state$) => {
	return action$.pipe(
		ofType(appActionTypes.APP_ACTIONS_DISPATCH),
		filter(action => action.payload && action.payload.type == AppActionType.DEVICE_SELECTION_CHANGED),
		filter(action => state$.value.pages.dashboard.active),
		rxmap(action => actions.dashboard.selectionChanged({ uri: action.payload.uri }))
	);
}

const interceptSelectedEpic = (action$, state$) => {
	return action$.pipe(
		ofType(appActionTypes.APP_ACTIONS_DISPATCH),
		filter(action => action.payload && action.payload.type == AppActionType.DEVICE_SET_SELECTED),
		filter(action => state$.value.pages.dashboard.active),
		rxmap(action => actions.dashboard.setSelected({ uri: action.payload.uri }))
	);
}

const selectionAllEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.dashboard.setSelectionAll.type),
		rxmap(action => {
			const deviceList = state$.value.devices.list;
			const uris = deviceList ? deviceList.map(device => device.uri) : [];
			return actions.dashboard.setSelection({ uris });
		})
	);
}

const epic = combineEpics(interceptEpic, interceptSelectedEpic, selectionAllEpic);

export { actions, reducer, epic };
