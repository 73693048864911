import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useDrop } from 'react-dnd';
import { useI18n } from '../../../../../i18n';
import { cx } from '../../../../api';
import { fromProps, className } from '../../../../lib/className';
import { DragItemType } from '../../dnd/DragItemType';
import Loader from '../../../general/Loader';
import Form from '../../../general/form/Form';
import Modal from '../../../general/Modal';
import DefaultActionBar from '../../actionbar/DefaultActionBar';
import ActionAdd from '../../../share/actionbar/ActionAdd';
import PropertyElement from './PropertyElement';
import DevicePropertySelector from '../../../custom/deviceProperties/meta/DevicePropertySelector';
import DevicePropertyEditor from '../../../custom/deviceProperties/meta/DevicePropertyEditor';
import DeviceBundleEditor from '../../../custom/deviceProperties/meta/DeviceBundleEditor';
import './xElements.scss';

/**
 * @param {Object} props
 * @param {cx.ods.meta.Property} props.element
 */

function Element(props) {
	const [property, setProperty] = useState(null);

	useEffect(() => {
		if (props.properties.map != null) {
			setProperty(props.properties.map[props.element.elementId]);
		}
	}, [props.element, props.properties]);

	const onDrop = (type) => {
		type == DragItemType.ACTION_REMOVE ? props.onRemove(props.element) : props.onEdit(props.element);
	}

	const [dropState, dropRef] = useDrop({
		accept: [DragItemType.ACTION_REMOVE, DragItemType.ACTION_EDIT],
		drop: (item) => onDrop(item.type),
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		}),
	})

	let content = null;
	if (property != null) {
		content = <PropertyElement customRef={dropRef} element={property} className={className({ 'droppable': dropState.isOver && dropState.canDrop })} />
	} else {
		content = <Loader />;
	}

	return (content);
}

const BundleElement = connect(state => ({
	properties: state.meta.properties
}))(Element);

/**
 * @param {Object} props
 * @param {cx.ods.meta.PropertyBundle} props.bundle
 * @param {Array.<cx.ods.meta.Property>} props.value
 * @param {function} props.onChange
 */

function Elements(props) {
	const { f } = useI18n();
	const [adding, setAdding] = useState(false);
	const [editing, setEditing] = useState(false);
	const property = useRef(null);
	const value = props.value != null ? props.value : [];

	const onRemove = (element) => {
		const change = value.filter((item) => item.elementId != element.elementId);
		props.onChange(change.length > 0 ? change : null);
	}

	// -------------------------------------------

	const onAdd = () => {
		setAdding(true);
	}

	const onAddCancel = () => {
		setAdding(false);
	}

	const onAddApply = (properties) => {
		const elements = properties.map(property => {
			const element = new cx.ods.meta.BundleElement();
			element.elementId = property.propertyId;
			return element;
		});
		setAdding(false);
		const change = [...value].concat(elements);
		props.onChange(change.length > 0 ? change : null);
	}

	// -------------------------------------------

	const onEdit = (element) => {
		property.current = props.properties.map[element.elementId];
		setEditing(true);
	}

	const onEditCancel = () => {
		property.current = null;
		setEditing(false);
	}

	const onEditReady = () => {
		property.current = null;
		setEditing(false);
	}

	// -------------------------------------------

	let list = null, excludeIds = [props.bundle.propertyId];
	if (value && value.length > 0) {
		list = value.map(element =>
			<BundleElement
				key={element.elementId}
				element={element}
				onRemove={onRemove}
				onEdit={onEdit}
			/>
		);
		excludeIds = excludeIds.concat(value.map(property => property.elementId));
		if (props.bundle && props.bundle.elements) {
			const diff = props.bundle.elements.filter(property => {
				return value.includes(property);
			});
			excludeIds = excludeIds.concat(diff.map(property => property.elementId));
		}
	}

	return (
		<div className={className('x-elements', 'bundle-elements', fromProps(props))}>
			<div className="header">
				<div className="label">{f('members')}</div>
				<DefaultActionBar
					prependActions={<ActionAdd onClick={onAdd} />}
					hideEdit={list == null}
					hideRemove={list == null}
					disabled={props.disabled}
				/>
				{adding &&
					<Modal
						onClose={onAddCancel}
						title={f('select property')}
					>
						<DevicePropertySelector
							showBundles
							onApply={onAddApply}
							onCancel={onAddCancel}
							excludeIds={excludeIds}
						/>
					</Modal>
				}
				{editing && cx.o.typeOf(property.current, cx.ods.meta.ComposableProperty) &&
					<Modal
						onClose={onEditCancel}
						title={f('edit property')}
					>
						<DevicePropertyEditor
							onCancel={onEditCancel}
							onReady={onEditReady}
							property={property.current}
							nested
						/>
					</Modal>
				}
				{editing && cx.o.typeOf(property.current, cx.ods.meta.PropertyBundle) &&
					<Modal
						onClose={onEditCancel}
						title={f('edit template')}
					>
						<DeviceBundleEditor
							onCancel={onEditCancel}
							onReady={onEditReady}
							bundle={property.current}
							nested
						/>
					</Modal>
				}
			</div>
			<div className="elements">{list}</div>
		</div>
	);
}

const BundleElements = connect(state => ({
	properties: state.meta.properties
}))(Elements);

/**
 * @param {Object} props
 */

function BundleElementsControl(props) {
	const [elements, setElements] = useState(null);

	useEffect(() => {
		props.bundle.elements && setElements([...props.bundle.elements]);
	}, [props.bundle, props.bundle.elements]);

	return (
		<Form.Control
			controlType={BundleElements}
			controlName="elements"
			value={elements}
			onChange={setElements}
			bundle={props.bundle}
			{...props}
		/>
	);
}

export default BundleElementsControl;