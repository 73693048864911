import React from 'react';
import { connect } from 'react-redux';
import TagPicker from '../../general/form/TagPicker';
import { useI18n } from '../../../../i18n';
import Loader from '../../general/Loader';

/**
 * @callback MessageFieldPickerFilter
 * @param {MessageFilter} field
 */

/**
 * Control for selecting ods.registry.EventType.
 * @param {Object} props
 * @param {Array.<number||string>} props.value - propertyId
 * @param {Array.<number||string>} [props.initialValue] - propertyId
 * @param {function} props.onChange
 * @param {string} [props.label]
 * @param {string} [props.placeholder]
 * @param {boolean} [props.disabled]
 * @param {MessageFieldPickerFilter} [props.filter]
 */

function EventTypePicker(props) {
	const { f, fc } = useI18n();
	let content = null;

	const filterEventTypes = (eventType) => props.filter ? props.filter(eventType) : true;

	const formatEventTypes = ({ eventType, name }) => ({
		value: eventType,
		label: fc({ prefix: 'device-event', id: name })
	})

	if (props.eventTypeMap != null) {
		const items = Object.values(props.eventTypeMap)
			.filter(filterEventTypes)
			.map(formatEventTypes);

		const compare = (isGroup) => (a, b) => {
			if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
			if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
			return 0;
		};
		content = <TagPicker
			data={items}
			value={props.value}
			onChange={props.onChange}
			placeholder={f(props.placeholder || 'select event types')}
			label={props.label}
			sort={compare}
			disabled={props.disabled}
			defaultValue={props.initialValue}
			style={{ minHeight: '37px' }}
		/>;
	} else content = <Loader />;

	return (
		<div className="event-type-picker">
			{content}
		</div>
	);
}

export default connect(state => ({
	eventTypeMap: state.registry.eventTypes.typeMap
}))(EventTypePicker);
