import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../../i18n';
import { cx } from '../../../../api';
import { meta } from '../../../../redux/api/meta';
import Button from '../../../general/form/Button';
import PropertyTypePicker from '../../../share/properties/meta/PropertyTypePicker';
import PropertyForm from '../../../share/properties/meta/PropertyForm';
import { className, fromProps } from '../../../../lib/className';
import './deviceXEditor.scss';

/**
 * Displays property type picker and form based on picked type.
 * There is no possibility to change type when editing existing property (edit mode).
 * Dummy property is created in case of no property param (create mode).
 * @param {Object} props
 * @param {function} props.onCancel
 * @param {function} props.onReady
 * @param {cx.ods.meta.ComposableProperty} [props.property]
 * @param {boolean} [props.hideHeader=false]
 * @param {boolean} [props.nested=false] indicates whether property is nested into another
 */

function DevicePropertyEditor(props) {
	const { f } = useI18n();
	const [typeId, setTypeId] = useState(0);
	const property = useRef(null);
	const submitHook = useRef(null);
	const pendingProperty = useRef(null);
	const disabled = props.properties.pending;

	const creating = () => {
		return props.property == null;
	}

	const getType = () => {
		const type = props.datatypes.map[typeId];
		switch (type.mnemonics) {
			case 'integer': return cx.ods.meta.IntegerProperty;
			case 'decimal': return cx.ods.meta.DecimalProperty;
			case 'boolean': return cx.ods.meta.BooleanProperty;
			case 'datetime': return cx.ods.meta.DatetimeProperty;
			case 'date': return cx.ods.meta.DateProperty;
			case 'text': return cx.ods.meta.TextProperty;
			case 'vector': return cx.ods.meta.PropertyVector;
			case 'record': return cx.ods.meta.PropertyRecord;
			default: return null;
		}
	}

	useEffect(() => {
		if (props.datatypes.map != null && !creating()) {
			property.current = props.property;
			setTypeId(props.property.datatypeId);
		}
	}, [props.datatypes.map, props.property]);

	useEffect(() => {
		if (props.datatypes.map != null && creating() && typeId > 0) {
			const type = getType();
			property.current = new type();
			property.current.datatypeId = typeId;
			props.dispatch(meta.properties.actions.clear());
		}
	}, [typeId])

	// ----------------------------------------------------

	const onCancel = () => {
		props.onCancel();
	}

	const onSubmit = (property) => {
		pendingProperty.current = property;
		if (props.property != null) {
			props.dispatch(meta.properties.actions.update.request({ propertyId: props.property.propertyId, property }));
		} else {
			props.dispatch(meta.properties.actions.add.request({ property }));
		}
	}

	useEffect(() => {
		if (pendingProperty.current && !props.properties.pending) {
			// const result = pendingProperty.current.propertyId
			// 	? props.properties.map[pendingProperty.current.propertyId]
			// 	: props.properties.last
			// ;
			if (props.properties.error == null) {
				props.onReady(/* pendingProperty.current */);
				pendingProperty.current = null;
			}
		}
	}, [props.properties.pending]);

	const submit = () => {
		if (submitHook.current != null) submitHook.current();
	}

	let form = null;
	if (property.current != null) {
		form = <PropertyForm
			property={property.current}
			onSubmit={onSubmit}
			submitHook={submitHook}
			disabled={disabled}
			nested={props.nested}
		/>;
	}

	return (
		<div className={className('device-x-editor', 'device-property-editor', fromProps(props))}>
			{!props.hideHeader && <div className="header">{f((creating() ? 'create' : 'edit') + ' property')}</div>}
			<div className="content">
				<PropertyTypePicker
					value={typeId}
					onChange={(typeId) => setTypeId(typeId)}
					disabled={!creating()}
					label={f('type')}
				/>
				{form}
			</div>
			<div className="error">
				{props.properties.error}
			</div>
			<div className="footer">
				<Button
					onClick={onCancel}
					disabled={disabled}
				>
					{f('cancel')}
				</Button>
				<Button
					onClick={submit}
					disabled={typeId === 0 || disabled}
					loading={props.properties.pending}
				>
					{f('save')}
				</Button>
			</div>
		</div>
	);
}

export default connect(state => {
	return {
		datatypes: state.meta.datatypes,
		properties: state.meta.properties
	}
})(DevicePropertyEditor);
