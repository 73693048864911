import React from 'react';
import Form from '../../../general/form/Form';
import {
	CommonFields,
	IntegerFields,
	DecimalFields,
	BooleanFields,
	DatetimeFields,
	DateFields,
	TextFields,
	RecordFields,
	VectorFields,
	BundleFields
} from './propertyFields';
import { cx } from '../../../../api';
import { className, fromProps } from '../../../../lib/className';


/**
 * @param {Object} props
 * @param {cx.ods.meta.ComposableProperty} props.property
 * @param {function} props.onSubmit
 * @param {Object} props.submitHook react ref
 * @param {boolean} [props.nested=false] indicates whether property is nested into another
 * @param {boolean} [props.disabled]
 */

function PropertyForm(props) {
	const isBundle = cx.o.typeOf(props.property, cx.ods.meta.PropertyBundle);
	return (
		<Form
			className={className('property-form', fromProps(props))}
			onSubmit={props.onSubmit}
			submitHook={props.submitHook}
			objectType={props.property.constructor}
			disabled={props.disabled}
		>
			{props.property.propertyId &&
				<Form.Control
					controlType={Form.Control.Type.InputNumber}
					controlName="propertyId"
					value={props.property.propertyId}
					onChange={() => {}}
					hidden
				/>
			}
			<Form.Control
				controlType={Form.Control.Type.InputNumber}
				controlName="datatypeId"
				value={props.property.datatypeId}
				onChange={() => {}}
				hidden
			/>
			<CommonFields property={props.property} nested={props.nested} />
			{cx.o.typeOf(props.property, cx.ods.meta.IntegerProperty) && <IntegerFields property={props.property} />}
			{cx.o.typeOf(props.property, cx.ods.meta.DecimalProperty) && <DecimalFields property={props.property} />}
			{cx.o.typeOf(props.property, cx.ods.meta.BooleanProperty) && <BooleanFields property={props.property} />}
			{cx.o.typeOf(props.property, cx.ods.meta.DatetimeProperty) && <DatetimeFields property={props.property} />}
			{cx.o.typeOf(props.property, cx.ods.meta.DateProperty) && <DateFields property={props.property} />}
			{cx.o.typeOf(props.property, cx.ods.meta.TextProperty) && <TextFields property={props.property} />}
			{cx.o.typeOf(props.property, cx.ods.meta.PropertyRecord) && <RecordFields property={props.property} />}
			{cx.o.typeOf(props.property, cx.ods.meta.PropertyVector) && <VectorFields property={props.property} />}
			{isBundle && <BundleFields property={props.property} />}
			{props.children}
		</Form>
	)
}

export default PropertyForm;