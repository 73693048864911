import { actions } from './actions';
import { reduxSwitch } from '../../tools';

const defaultState = {
	list: null,
	isLoading: false,
	error: null,
};

const addReducer = (state, action) => {
	switch (action.type) {
		case actions.add.request.type:
			return { ...state, isLoading: true, error: null };
		case actions.add.fail.type:
			return { ...state, isLoading: false, error: action.errorMessage };
		default:
			return state;
	}
};

const loadReducer = (state, action) => {
	switch (action.type) {
		case actions.load.request.type:
			return { ...state, isLoading: true, error: null };
		case actions.load.success.type:
			return { ...state, isLoading: false, list: action.mqttClients };
		case actions.load.fail.type:
			return { ...state, isLoading: false, error: action.errorMessage };
		default:
			return state;
	}
};

const updateReducer = (state, action) => {
	switch (action.type) {
		case actions.update.request.type:
			return { ...state, isLoading: true, error: null };
		case actions.update.fail.type:
			return { ...state, isLoading: false, error: action.errorMessage };
		default:
			return state;
	}
};

const removeReducer = (state, action) => {
	switch (action.type) {
		case actions.remove.request.type:
			return { ...state, isLoading: true, error: null };
		case actions.remove.fail.type:
			return { ...state, isLoading: false, error: action.errorMessage };
		default:
			return state;
	}
};

function clearReducer(state, action) {
	switch (action.type) {
		case actions.clear.type:
			return { ...state, error: null };
		default:
			return state;
	}
}

const reducer = reduxSwitch([addReducer, loadReducer, updateReducer, removeReducer, clearReducer], defaultState);

export { reducer };
