import { fc, p } from "../../i18n";

const datetime = {
	SECOND: 1000,
	MINUTE: 60000, // 60 * 1000
	HOUR: 3600000, // 60 * 60 * 1000
	DAY: 86400000, // 24 * 60 * 60 * 1000,
	WEEK: 604800000, // 7 * 24 * 60 * 60 * 1000
	floor: (date) => {
		const result = new Date(date.getTime());
		result.setHours(0);
		result.setMinutes(0);
		result.setSeconds(0);
		result.setMilliseconds(0);
		return result;
	},
	ceil: (date) => {
		if (date.getHours() > 0 || date.getMinutes() > 0 || date.getSeconds() > 0 || date.getMilliseconds() > 0) {
			date = datetime.floor(new Date(date.getTime() + datetime.DAY));
		}
		return date;
	},
	today: (now) => {
		const today = now ? new Date(now) : new Date();
		today.setHours(0, 0, 0, 0);
		return today;
	},
	tomorrow: (now) => {
		let anchor = null;
		if (now) {
			anchor = new Date(now);
			anchor.setHours(0, 0, 0, 0);
		}
		return new Date((anchor ? anchor.getTime() : datetime.today().getTime()) + datetime.DAY);
	},
	isToday: (date, now) => {
		const copyDate = new Date(date);
		copyDate.setHours(0, 0, 0, 0);
		const copyNow = new Date(now);
		copyNow.setHours(0, 0, 0, 0);
		return copyDate.getTime() == copyNow.getTime();
	}
};

let formatter = {
	time: function (time, short) {
		let string = null;
		if (short) {
			const options = { hour: 'numeric', minute: 'numeric' };
			const lang = window.navigator.userLanguage || window.navigator.language;
			try {
				string = time.toLocaleTimeString(lang, options);
			} catch (e) {}
		}
		return string != null ? string : time.toLocaleTimeString();
	},
	date: (date) => {
		return date.toLocaleDateString();
	},
	format: function (datetime, short) {
		return this.date(datetime) + ' ' + this.time(datetime, short);
	},
	relative: function (datetime, now, short) {
		let formatted = { value: null, validUntil: null };
		if (datetime) {
			if (!now) now = new Date();
			formatted.value = this.time(datetime, short);
			const OFFSET = -now.getTimezoneOffset() * 60000;
			let time = datetime.getTime() + OFFSET, nowTime = now.getTime() + OFFSET;
			if (nowTime < time) {
				nowTime = time;
				time = now.getTime() + OFFSET;
			}
			const DAY_TIME = 24 * 60 * 60000;
			const date = Math.floor(time / DAY_TIME), today = Math.floor(nowTime / DAY_TIME);

			if (date == today) { // short
				formatted.validUntil = new Date((today + 1) * DAY_TIME - OFFSET);
			} else if (today - date == 1) {
				const HOUR_TIME = 60 * 60000;
				const hours = Math.floor(time / HOUR_TIME), nowHours = Math.floor(nowTime / HOUR_TIME);
				if (nowHours - hours <= 3) { // short
					formatted.validUntil = new Date((hours + 3) * HOUR_TIME - OFFSET);
				}
			}
			if (!formatted.validUntil) {
				formatted.value = this.date(datetime) + ' ' + formatted.value;
			}
		}
		return formatted;
	},
	duration: (left, right) => formatter.ticksDuration(right.getTime() - left.getTime()),
	ticksDuration: (ticks) => {
		let seconds = ticks / 1000;
		const days = Math.floor(seconds / 86400); seconds -= days * 86400 // 60*60*24
		const hours = Math.floor(seconds / 3600); seconds -= hours * 3600 // 60*60
		const minutes = Math.floor(seconds / 60); seconds -= minutes * 60
		seconds = Math.floor(seconds);
		let string = days > 0 ? (p('X days', days) + " ") : "";
		string += hours < 10 ? "0" + hours : hours; string += ":";
		string += minutes < 10 ? "0" + minutes : minutes; string += ":";
		string += seconds < 10 ? "0" + seconds : seconds;
		return string;
	},
	dayOfWeek: (datetime) => {
		if (!datetime) return;
		const dayOfWeek = [
			fc('sunday'),
			fc('monday'),
			fc('tuesday'),
			fc('wednesday'),
			fc('thursday'),
			fc('friday'),
			fc('saturday'),
		];
		const day = datetime.getDay();
		return dayOfWeek[day];
	}
};

export { formatter, datetime };
