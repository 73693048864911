import React, { FC, SVGProps } from 'react';

import { ISVGProps } from 'types/svg';

export type SVG = SVGProps<SVGSVGElement>;

export const SvgWrapper: FC<ISVGProps & SVG> = ({ width, height, details, ...props }) => (
  <svg
    width={width.toString()}
    height={height.toString()}
    viewBox={details?.viewBox}
    fill={details?.fill || 'currentColor'}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {details?.content}
  </svg>
);
