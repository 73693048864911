import { Point, LineString, Polygon, MultiPoint, MultiLineString, MultiPolygon } from 'ol/geom';
import LinearRing from 'ol/geom/LinearRing';
import WktReader from 'jsts/org/locationtech/jts/io/WKTReader';
import WktWriter from 'jsts/org/locationtech/jts/io/WKTWriter';
import Ol3Parser from 'jsts/org/locationtech/jts/io/OL3Parser';
import JstsMultiPolygon from 'jsts/org/locationtech/jts/geom/MultiPolygon';
import Feature from 'ol/Feature';
import { assert } from '../lib/assert';

let _wktReader = null;
const wktReader = () => {
	if (_wktReader == null) _wktReader = new WktReader();
	return _wktReader;
}

let _wktWriter = null;
const wktWriter = () => {
	if (_wktWriter == null) _wktWriter = new WktWriter();
	return _wktWriter;
}

let _olParser = null;
const olParser = () => {
	if (_olParser == null) {
		_olParser = new Ol3Parser();
		_olParser.inject(Point, LineString, LinearRing, Polygon, MultiPoint, MultiLineString, MultiPolygon);
	}
	return _olParser;
}

export { wktReader, wktWriter, olParser };

// ---------------------------------------------

export const readMultiPolygon = (wkt, transform = true) => {
	const features = [];
	if (wkt) {
		const multiPolygon = wktReader().read(wkt);
		assert(multiPolygon instanceof JstsMultiPolygon, "Wrong WKT type provided to parseWkt. Should be MultiPolygon");
		for (let at = 0; at < multiPolygon.getNumGeometries(); ++at) {
			const jstsGeometry = multiPolygon.getGeometryN(at);
			const geometry = olParser().write(jstsGeometry);
			if (transform) geometry.transform('EPSG:4326', 'EPSG:3857');
			const feature = new Feature({ geometry });
			features.push(feature);
		}
	}
	return features;
}

export const writeMultiPolygon = (features, transform = true) => {
	let wkt = null;
	if (features && features.length > 0) {
		let jstsGeometries = [];
		features.forEach(feature => {
			const geometry = feature.getGeometry();
			if (transform) geometry.transform('EPSG:3857', 'EPSG:4326');
			const coordinates = geometry.getCoordinates();
			coordinates.forEach(ring => ring.forEach(coord => {
				coord[0] = +coord[0].toFixed(7);
				coord[1] = +coord[1].toFixed(7);
			}));
			geometry.setCoordinates(coordinates);
			jstsGeometries.push(olParser().read(geometry));
		});
		const multiPolygon = new JstsMultiPolygon(jstsGeometries);
		wkt = wktWriter().write(multiPolygon);
	}
	return wkt;
}
