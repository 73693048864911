import React, { Dispatch, DispatchWithoutAction, FC } from 'react';
import { FiExternalLink as IconZoneGuards } from 'react-icons/fi';

import {
  ColumnedPageStyled,
  MainColumnStyled,
  SideColumnsStyled,
  SideColumnStyled,
} from 'ui/layout/columned-page.styled';
import { IDownsampledMessageHistoryProcessor } from 'types/detectors';
import { useProcessorView } from 'hooks/downsampled-message-history/useProcessorView';
import Intent from 'core/misc/ObjectActionIntent';
import { EntitiesActions } from 'components/molecules/entities-actions';
import { DeviceManager } from 'core/react/share/devices/DeviceManager';
import { ProcessorsList } from './processors-list';
import { ProcessorInfo } from './processor-info';
import { RemoveProcessorDialogue } from './remove-processor-dialogue';

interface IProcessorsViewProps {
  intent: Intent | null;
  selectedProcessor: IDownsampledMessageHistoryProcessor | null;
  onIntent: Dispatch<Intent>;
  onRemove: Dispatch<IDownsampledMessageHistoryProcessor>;
  onPopupClose: DispatchWithoutAction;
}

export const ProcessorsView: FC<IProcessorsViewProps> = (props) => {
  const { selectedProcessor, onIntent, ...rest } = props;

  const { actionsProps, listProps, isDisabled } = useProcessorView();

  return (
    <ColumnedPageStyled>
      <MainColumnStyled>
        <EntitiesActions icon={<IconZoneGuards size={20} />} {...actionsProps} />
        <ProcessorsList selectedId={selectedProcessor?.processorId || null} onIntent={onIntent} {...listProps} />
      </MainColumnStyled>
      <SideColumnsStyled>
        {!!selectedProcessor && (
          <>
            <SideColumnStyled>
              <DeviceManager uris={selectedProcessor?.uris || []} />
            </SideColumnStyled>
            <SideColumnStyled>{<ProcessorInfo processor={selectedProcessor} />}</SideColumnStyled>
          </>
        )}
        <RemoveProcessorDialogue isDisabled={isDisabled} {...rest} />
      </SideColumnsStyled>
    </ColumnedPageStyled>
  );
};
