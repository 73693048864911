import React, { useImperativeHandle } from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../../i18n';
import { actions } from '../../../../redux/api/reports';
import { cx } from '../../../../api';
import Form from '../../../general/form/Form';
import ObjectDeviceManager from '../../../share/devices/ObjectDeviceManager';
import './zoneVisits.scss';

const BATCH_SIZE = 10;

/**
 * @param {Object} props
 * @param {React.RefObject} props.customRef
 * @param {Object} props.timeRange
 * @param {boolean} [props.disabled]
 */

function ZoneVisits(props) {
	const { f } = useI18n();

	useImperativeHandle(props.customRef, () => ({
		submit: (values) => {
			const parameters = new cx.ods.reports.ZoneVisitReportParameters();
			parameters.timeRange = props.timeRange;
			parameters.retrospective = values.retrospective;
			parameters.window = new cx.ods.devices.DeviceWindow({ size: BATCH_SIZE });
			if (values.uriSetFilter && values.uriSetFilter.length > 0) {
				parameters.uriSetFilter = new cx.ods.devices.DeviceURISetFilter(values.uriSetFilter);
			}
			parameters.esg = values.esg;

			props.dispatch(actions.zoneVisits.request({ parameters, clear: true }));
		},
		clear: () => {
			props.dispatch(actions.zoneVisits.exportClear());
		}
	}));

	return (
		<div className="zone-visits parametrizator">
			<Form.ControlGroup key={50} disabled={props.disabled}>
				<ObjectDeviceManager
					editMode
					uris={props.state.parameters && props.state.parameters.uriSetFilter ? props.state.parameters.uriSetFilter.uriSet : []}
					disabled={props.disabled}
					controlName="uriSetFilter"
					title={f('devices restriction')}
				/>
				<Form.Control
					controlName="retrospective"
					controlType={Form.Control.Type.Checkbox}
					label={f('report later items first')}
					initialValue={props.state.parameters ? props.state.parameters.retrospective : false}
				/>
				<Form.Control
					controlName="esg"
					controlType={Form.Control.Type.Checkbox}
					label={f('ESG')}
					initialValue={props.state.parameters ? props.state.parameters.esg : false}
				/>

			</Form.ControlGroup>
		</div>
	);
}

export default connect(state => {
	return {
		state: state.reports.zoneVisits
	}
})(ZoneVisits);
