import { ofType, combineEpics } from 'redux-observable';
import { map as rxmap } from 'rxjs/operators';
import { ActionGeneratorBuilder, errorMap } from '../../actions';
import { actions as categoriesActions } from '../../api/categories';

const isRootCategory = category => category.mnemonics == 'grouping' || category.mnemonics == 'groupings';

const STORAGE_KEY = 'groupingId';

const defaultState = {
	root: null,
	map: null, // categoryId => [CategoryData]
	pending: false,
	error: null
};

const actions = new ActionGeneratorBuilder('groupings')
	.subtype('load', load => load.request().success('root').fail())
	.type('setRoot', { root: true })
	.type('addChildren', { categoryId: true, children: true })
	.build()
;

// ---------------------------------------------------------



function reducer(state = defaultState, action) {
	switch (action.type) {
		case actions.load.request.type:
			return {
				...state,
				pending: true,
				error: null
			};
		case categoriesActions.load.fail.type:
			return {
				...state,
				pending: false,
				error: action.errorMessage
			};
		case actions.setRoot.type:
			return {
				...state,
				root: action.root,
				map: {},
				pending: false
			};
		case actions.addChildren.type:
			const map = {...state.map};
			map[action.categoryId] = action.children;
			return {
				...state,
				map
			};
		default:
			return state;
	}
}

// ---------------------------------------------------------

const loadEpic = (action$) => {
	return action$.pipe(
		ofType(actions.load.request.type),
		rxmap(action => categoriesActions.load.request()),
		errorMap(actions.load.fail),
	)
}

const watchCategoriesLoadEpic = (action$, state$) => {
	return action$.pipe(
		ofType(categoriesActions.load.success.type),
		rxmap(({categories}) => actions.setRoot({ root: categories.find(isRootCategory) })),
		errorMap(actions.load.fail),
	)
}

const epic = combineEpics(loadEpic, watchCategoriesLoadEpic);

// ---------------------------------------------------------

let store = null;
const connect = (_store) => {
	store = _store;
}

const fetchChildren = (categoryId, force) => {
	const groupings = store.getState().categories.groupings.map[categoryId];
	if (groupings != null && !force) return;
	let children = [];
	store.getState().categories.general.list.forEach(category => {
		if (category.comprisingIds && category.comprisingIds.indexOf(categoryId) >= 0) children.push(category);
	});
	store.dispatch(actions.addChildren({ categoryId, children }));
}

// ---------------------------------------------------------

export { actions, reducer, connect, epic, fetchChildren, STORAGE_KEY };
