import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {useI18n} from '../../../../../i18n';

import {actions} from '../../../../redux/api/reports';
import {percentShare} from '../../../../redux/api/reports/drivingBehaviour';

import Loader from '../../../general/Loader';
import NoDataView from '../../../general/view/NoDataView';
import ScrollList from '../../../general/list/ScrollList';

import ReportHeader from '../renderer/ReportHeader';
import ReportSummary from '../renderer/ReportSummary';

import './renderer.scss';

export default withRouter(connect(state => ({
	state: state.reports.drivingBehaviour
	, deviceMap: state.devices.map
}))(props => {
	const {f} = useI18n();

	const reporting = props.state;
	const parameters = reporting.parameters;
	const devices = parameters.uris && parameters.uris.map(uri => props.deviceMap[uri]);
	const report = reporting.report;

	let content = null;
	if (reporting.error) content = <div className="center"><span className="error">{reporting.error}</span></div>;
	else if (report == null) content = <Loader size={Loader.Size.MD}/>;
	else if (report.length == 0) content = <NoDataView message={f('no data for such parameters')}/>
	else content = <ReportContent pending={reporting.pending} options={reporting.options} report={report}/>;

	const exportReport = () => {
		props.dispatch(actions.drivingBehaviour.export.request());
		props.history.goBack();
	};

	return <div className="report driving-behaviour">
		<ReportHeader 
			title={f('driving behaviour report')}
			canExport={0 < report?.length} onExport={exportReport}
		/>
		<ReportSummary 
			since={parameters.timeRange.since} until={parameters.timeRange.until}
			devices={devices} generatedAt={report?.timeAt}
		/>
		<div className='content'>{content}</div>
	</div>;
}));

const ReportContent = props => {
	const {options, pending, report: utilizations} = props;
	const optionColumns = [options.groupingId, options.includeAssetType].filter(Boolean).length;

	return <ScrollList pending={pending} className={`options-${optionColumns}`}>
		<ListHeaders/>
		{utilizations.map((utilization, at) => <ListItem key={utilization.uri} itemNo={at + 1} utilization={utilization}/>)}
	</ScrollList>;
};

const ListHeaders = connect(state => ({
	options: state.reports.drivingBehaviour.options	
	, categoryMap: state.categories.general.map
}))((props) => {
	const {f} = useI18n();
	const {options} = props;

	const grouping = options.groupingId ? props.categoryMap[options.groupingId] : null;

	return <div className='headers'>
		<div><span>{f('item No.')}</span></div>
		<div><span>{f('device')}</span></div>
		{options.groupingId && <div><span>{grouping?.name}</span></div>}
		{options.includeAssetType && <div><span>{f('asset type')}</span></div>}

		<div><span>{f('distance')}</span></div>
		<div className='group-header statistics' key="odometer">
			<span>{f("odometer")}</span>
		</div>

		<div><span>{f('utilization-phase.working')}</span></div>
		<div><span>{f('utilization-phase.parked')}</span></div>
		<div className='group-header idling'><span>{f('utilization-phase.idling')}</span></div>

		<div className='group-header speeding'><span>{f('device-event.speeding')}</span></div>

		<div><span>{f('device-event.harsh acceleration')}</span></div>
		<div><span>{f('device-event.harsh braking')}</span></div>
		<div><span>{f('device-event.harsh cornering')}</span></div>

		<div className='group-header violations'><span>{f('violations')}</span></div>

		<div className='group-header shares'><span>{f('shares')}</span></div>

		<div className='sub-header statistics' key="sub-odometer">
			<span>{f('first')}</span><span>{f('last')}</span>
		</div>

		<div className='sub-header'><span>{f('hours')}</span></div>
		<div className='sub-header'><span>{f('units.%')}</span></div>
		<div className='sub-header'><span>{f('quantity')}</span></div>

		<div className='sub-header'><span>{f('quantity')}</span></div>
		<div className='sub-header'><span>{f('per 100 km')}</span></div>
		<div className='sub-header'><span>{f('quantity')}</span></div>
		<div className='sub-header'><span>{f('per 100 km')}</span></div>

		<div className='sub-header'><span>{f('violations')}</span></div>
		<div className='sub-header'><span>{f('distance')}</span></div>
		<div className='sub-header'><span>{f('utilization-phase.working')}</span></div>
		<div className='sub-header'><span>{f('utilization-phase.idling')}</span></div>
	</div>;
});

const ListItem = connect(state => ({
	options: state.reports.drivingBehaviour.options
	, deviceMap: state.devices.map
	, categoryMap: state.categories.general.map
	, assetTypeRoot: state.categories.assetTypes.root
}))(props => {
	const {f} = useI18n();
	const {options, deviceMap: {[props.utilization.uri]: device}} = props;
	let deviceGroup = null, assetType = null;
	if (device.categoryIds && (options.groupingId || options.includeAssetType)) {
		const assetTypeRootId = props.assetTypeRoot?.categoryId;
		device.categoryIds.forEach(categoryId => {
			const category = props.categoryMap[categoryId], comprisingIds = category?.comprisingIds;
			if (comprisingIds) {
				if (options.groupingId && comprisingIds.includes(options.groupingId)) deviceGroup = category;
				if (options.includeAssetType && comprisingIds.includes(assetTypeRootId)) assetType = category;
			}
		});
	}

	const {phases, totals, shares} = props.utilization;

	const idling = phases.idling?.duration || 0;
	const idlingShare = 0 < idling ? percentShare(idling / totals.duration) : '';

	const firstOdometer = phases.total?.firstOdometer != null ? Math.round(phases.total?.firstOdometer / 1000) : null;
	const lastOdometer = phases.total?.lastOdometer != null ? Math.round(phases.total?.lastOdometer / 1000) : null;

	const specificSpeedings = 0 < totals.distance && totals.violationTypes?.speeding != null 
		? Math.round(totals.violationTypes.speeding * 100 * 100 / totals.distance) / 100 : null
	;
	const specificViolations = 0 < totals.distance ? Math.round(totals.violations * 100 * 100 / totals.distance) / 100 : null;

	return <div className='item'>
		<div>{props.itemNo}</div>
		<div>{device?.denomination()}</div>
		{options.groupingId && <div className='device-group'>{deviceGroup?.name}</div>}
		{options.includeAssetType && <div className='asset-type'><span>{assetType && f({ prefix: 'category', id: assetType.name })}</span></div>}

		<div>{totals.distance || null}</div>
		<div className='statistics' key="odometer">
			<span>{firstOdometer}</span>
			<span>{lastOdometer}</span>
		</div>

		<div>{phases.working?.duration}</div>
		<div>{phases.parked?.duration}</div>
		<div>{phases.idling?.duration}</div>
		<div>{idlingShare}</div>
		<div>{phases.idling?.quantity}</div>

		<div>{totals.violationTypes?.speeding}</div>
		<div>{specificSpeedings || null}</div>
	
		<div>{totals.violationTypes?.acceleration}</div>
		<div>{totals.violationTypes?.braking}</div>
		<div>{totals.violationTypes?.cornering}</div>

		<div>{totals.violations || null}</div>
		<div>{specificViolations || null}</div>

		<div>{shares.violations}</div>
		<div>{shares.distance}</div>
		<div>{shares.working}</div>
		<div>{shares.idling}</div>
	</div>;
});
