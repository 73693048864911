import React from 'react';
import tinycolor from 'tinycolor2';

/**
 * @param {Object} props
 * @param {Object} props.hsv
 * @param {function} props.onClick
 * @param {boolean} props.active
 */

function HsvSwatch(props) {
	const style = {
		background: props.hsv ? tinycolor(props.hsv).toHslString() : 'none',
		cursor: 'pointer',
		transform: props.active && 'scale(1.2)',
		borderRadius: props.active && '3.6px/2px'
	}

	const onClick = (event) => {
		props.onClick(props.hsv, event);
	}

	return (<div style={style} onClick={onClick}></div>);
}

/**
 * @param {Object} props
 * @param {Object} props.hsv
 * @param {string} props.axis 'h'|'s'|'v'
 * @param {number=5} props.quantity number of swatches to render
 * @param {function} props.onClick
 */

function HsvSwatches(props) {
	const { hsv, axis, quantity = 5, onClick } = props;
	const swatches = [];
	const ranges = {
		h: 360,
		s: 1,
		v: 1
	};
	const interval = ranges[axis] / quantity;
	let axisValue = interval / 2;
	for (let i = 0; i < quantity; ++i) {
		const active = Math.abs(hsv[axis] - axisValue).toFixed(4) < interval / 2;
		const swatchHsv = {
			...hsv,
			[axis]: axisValue,
			source: 'hsv'
		};
		swatches.push(<HsvSwatch key={i} hsv={swatchHsv} onClick={onClick} active={active} />);
		axisValue = axisValue + interval;
	}

	return (swatches);
}

export default HsvSwatches;