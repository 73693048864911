import React from 'react';
import { useI18n } from '../../../../i18n';
import { className } from '../../../lib/className';

/**
 * @param {Object} props
 * @param {cx.ods.processors.ZoneGuard} props.zoneGuard
 */

function ZoneGuardCard(props) {
	const { f } = useI18n();
	return (
		<div className="zone-guard card">
			<div className="name">
				<label>{f('name')}</label>
				<div>{props.zoneGuard.name}</div>
			</div>
			{(props.zoneGuard.onEntry || props.zoneGuard.onExit) &&
				<div className="group">
					<label>{f('options')}</label>
					{props.zoneGuard.onEntry && <span className="capitalize">{f('on entry')}</span>}
					{props.zoneGuard.onEntry && props.zoneGuard.onExit && ', '}
					{props.zoneGuard.onExit && <span className={className({ "capitalize": !props.zoneGuard.onEntry })}>{f('on leave')}</span>}
				</div>
			}
			{props.zoneGuard.esg &&
				<div>
					<label>ESG</label>
					<div>{props.zoneGuard.esg}</div>
				</div>
			}
			{props.zoneGuard.maximumSpeed != null &&
				<div className="group">
					<label>
						{f('speeding')}
						{': ' + props.zoneGuard.maximumSpeed + ' '}
						{f({ prefix: 'units', id: 'km/h' })}
						{props.zoneGuard.speedingDuration &&
							(', ' + props.zoneGuard.speedingDuration + ' ' + f({ prefix: 'units', id: 's' }))
						}
					</label>
				</div>
			}
			{props.zoneGuard.minimumSpeed != null &&
				<div className="group">
					<label>
						{f('stop detection')}
						{': ' + props.zoneGuard.minimumSpeed + ' '}
						{f({ prefix: 'units', id: 'km/h' })}
						{props.zoneGuard.stopDuration &&
							(', ' + props.zoneGuard.stopDuration + ' ' + f({ prefix: 'units', id: 's' }))
						}
					</label>
					{props.zoneGuard.onStop && <span className="capitalize">{f('on stop')}</span>}
					{props.zoneGuard.onStop && props.zoneGuard.onStopSkipped && ', '}
					{props.zoneGuard.onStopSkipped &&
						<span className={className({ "capitalize": !props.zoneGuard.onStop })}>{f('on stop skipped')}</span>
					}
				</div>
			}
			{props.zoneGuard.acceleration != null &&
				<div className="group">
					<label>
						{f('acceleration')}
						{': ' + props.zoneGuard.acceleration + ' '}
						{f({ prefix: 'units', id: 'km' }) + '/(' + f({ prefix: 'units', id: 'h' }) + '*' + f({ prefix: 'units', id: 's' }) + ')'}
					</label>
				</div>
			}
			{props.zoneGuard.deceleration != null &&
				<div className="group">
					<label>
						{f('deceleration')}
						{': ' + props.zoneGuard.deceleration + ' '}
						{f({ prefix: 'units', id: 'km' }) + '/(' + f({ prefix: 'units', id: 'h' }) + '*' + f({ prefix: 'units', id: 's' }) + ')'}
					</label>
				</div>
			}
		</div>
	);
}

export default ZoneGuardCard;
