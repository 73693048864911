import { Observable, Subscriber } from 'rxjs';
import OperationException from './app/OperationException';

const cx = window.cx;
const ods = cx.ods;
const api = ods.ws;

class OperationObservable extends Observable {

	constructor(operationType, operationArgs) {
		super();
		this.operationType = operationType;
		this.operationArgs = operationArgs;
		// As from observation - there is only one observer in redux-observable case.
		// But use array to make it general.
		this.subscriptions = [];
	}

	getSubscriptions() {
		return this.subscriptions;
	}

	subscribe(subscriber) {
		const subscription = new OperationSubscriber(this, subscriber, this.operationType, this.operationArgs);
		this.subscriptions.push(subscription);
		subscription.run();
		return subscription;
	}

	remove(subscription) {
		const at = this.subscriptions.indexOf(subscription);
		if (at >= 0) this.subscriptions.splice(at, 1);
	}
}

class OperationSubscriber extends Subscriber {

	constructor(observable, subscriber, operationType, operationArgs) {
		super(subscriber);
		this.observable = observable;
		this.operationType = operationType;
		this.operationArgs = operationArgs;
	}

	run() {
		this.operation = this.operationType.apply(this, this.operationArgs);
		this.operation.onready(operation => {
			if (operation.failed() || operation.notFound()) {
				this.destination.error(new OperationException(operation));
			} else {
				this.destination.next(operation);
				this.destination.complete();
			}
		});
	}

	cancel() {
		if (this.operation && this.operation.running) {
			this.operation.destroy();
			this.destination.complete();
		}
	}

	unsubscribe() {
		if (this.operation && this.operation.running) {
			this.operation.cancel();
		}
		this.operation = null;
		this.observable.remove(this);
		super.unsubscribe();
	}

}

function rx() {
	let operationType = arguments[0];
	let args = [];
	if (arguments.length > 1) {
		for (let at = 1; at < arguments.length; at++) {
			args.push(arguments[at]);
		}
	}
	return new OperationObservable(operationType, args);
}

const getOperationObservable = (observable) => {
	while (observable.source != null && !(observable instanceof OperationObservable)) {
		observable = observable.source;
	}
	return observable instanceof OperationObservable ? observable : null;
}

export { cx, ods, api, rx, getOperationObservable };
export { rs } from './rs';
