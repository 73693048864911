import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useI18n } from 'i18n';
import { IDownsampledMessageHistoryProcessor } from 'types/detectors';
import { AllRoutes } from 'constants/routes';
import { downsampledMessageHistorySelector } from 'core/redux/selectors/processors';

export const useProcessorView = () => {
  const { fc } = useI18n();

  const history = useHistory();

  const { list, pending: isDisabled } = useSelector(downsampledMessageHistorySelector);

  const [filter, setFilter] = useState<string>('');

  const processors = list || [];

  const getFilteredProcessors = () =>
    filter ? processors.filter((processor) => processor.name.toLowerCase().includes(filter.toLowerCase())) : processors;

  const getSortedProcessors = (processors: IDownsampledMessageHistoryProcessor[]) =>
    processors.sort((a, b) => (fc(a.name) > fc(b.name) ? 1 : -1));

  const processorsList = useMemo(() => getSortedProcessors(getFilteredProcessors()), [processors, filter]);

  const onAdd = () => {
    history.push(`${AllRoutes.DownsampledMessageHistory}/create`);
  };

  const onSelect = (processor: IDownsampledMessageHistoryProcessor) => {
    history.push(`${AllRoutes.DownsampledMessageHistory}/${processor.processorId}`);
  };

  const actionsProps = { isDisabled, title: 'downsampled message history processors', onAdd, isEditShown: true };
  const listProps = { filter, processors: processorsList, setFilter, onSelect };

  return { actionsProps, listProps, isDisabled };
};
