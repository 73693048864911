import React, { useRef, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { Collection } from 'ol';
import { extend, createEmpty as emptyExtent, isEmpty as isEmptyExtent, containsCoordinate } from 'ol/extent';
import { Draw, Modify, Snap, Interaction } from 'ol/interaction';
import * as condition from 'ol/events/condition';
import LayerGroup from 'ol/layer/Group';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { className, fromProps } from '../../../../../lib/className';
import GeometryType from 'ol/geom/GeometryType';
import { isRightMouseButton } from '../../../../../misc/misc';
import { Map } from '../../../../general/location/Map';
import MapControls from '../../../map/controls/MapControls';
import MapOptionLayers from '../../../map/controls/MapOptionLayers';
import MapSearch from '../../../map/controls/MapSearch';
import { readMultiPolygon } from '../../../../../misc/wkt';
import { setProps } from '../../../../../misc/ol';
import { makeStyle, ZIndex, StyleType } from '../../zoneStyle';
import MapOptions from '../../../map/controls/MapOptions';
import { SelectedFeatureContext, SelectedPointContext } from '../ZoneEditor';

const MAP_NAME = "zone-area-edit-map";

/**
 * @param {Object} props
 * @param {import("../../model/Zone")} props.zone
 * @param {number|string} props.areaId
 */

function ZoneAreaEditMap(props) {
	const map = useRef(null);
	const selectedFeatures = useRef(new Collection());

	const selectedFeatureContext = useContext(SelectedFeatureContext);
	const selectedFeatureContextRef = useRef(null);
	selectedFeatureContextRef.current = selectedFeatureContext;

	const selectedPointContext = useContext(SelectedPointContext);
	const selectedPointContextRef = useRef(null);
	selectedPointContextRef.current = selectedPointContext;

	// layers
	const backgroundLayer = useRef(null); // contains all features except of current zone
	const foregroundLayer = useRef(null); // contains features of current zone
	const group = useRef(null);

	// interactions
	const isSelectActive = useRef(true);
	const draw = useRef(null);
	const snap = useRef(null);
	const modify = useRef(null);

	const watch = useRef(null);
	const snapCoordinate = useRef(null);

	//
	const zones = props.zones.map;
	const zoneId = props.zone.id();
	const area = props.zone.area(selectedFeatureContext.value);

	const focus = (features) => {
		if (!features) return;
		let extent = new emptyExtent();
		features.forEach(feature => {
			extend(extent, feature.getGeometry().getExtent());
		});
		if (!isEmptyExtent(extent)) {
			map.current.getOwMap().fitExtent(extent);
		} else {
			map.current.getOwMap().focus();
		}
	}

	const focusExtent = (extent) => {
		map.current.getOwMap().fitExtent(extent);
	}

	const updateFeatures = (_feature) => {
		foregroundLayer.current.getSource().getFeatures().forEach(feature => {
			if (_feature && _feature == feature || selectedFeatures.current.item(0) == feature) {
				feature.setStyle(makeStyle(StyleType.AdvancedModifying, selectedPointContextRef.current.pointAt));
			} else {
				feature.setStyle(makeStyle(StyleType.Selected));
			}
		});
	}

	const updatePointStyle = () => {
		const selectedFeature = selectedFeatures.current.item(0);
		selectedFeature.setStyle(makeStyle(StyleType.AdvancedModifying, selectedPointContext.pointAt));
	}

	// interactions

	const resetInteractions = () => {
		isSelectActive.current = false;
		modify.current.setActive(false);
		draw.current.setActive(false);
		snap.current.setActive(false);
	}

	const setupModifyInteraction = () => {
		resetInteractions();
		modify.current.setActive(true);
		snap.current.setActive(true);
		isSelectActive.current = true;
	}

	const setupSelectInteraction = () => {
		resetInteractions();
		isSelectActive.current = true;
	}

	//

	useEffect(() => {
		map.current.updateSize();
	}, []);

	useEffect(() => {
		if (zones) {
			const bgFeatures = [];
			const fgFeatures = [];
			Object.values(zones).forEach(zone => {
				const selected = zone.zoneId == props.zone.id();
				const features = selected
					? props.zone.features()
					: setProps(readMultiPolygon(zone.geometry), {
						zoneId: zone.zoneId,
						name: zone.name,
						style: zone.style
					})
				;
				features.forEach(feature => {
					feature.setStyle(selected
						? makeStyle(StyleType.Selected)
						: makeStyle(StyleType.Default)
					);
				});
				if (!selected) {
					bgFeatures.push(...features);
				} else {
					fgFeatures.push(...features);
				}
			});
			if (props.zone.virtual()) {
				props.zone.features().forEach(feature => {
					feature.setStyle(feature.getId() == props.areaId
						? makeStyle(StyleType.Selected)
						: makeStyle(StyleType.Modifying)
					);
					fgFeatures.push(feature);
				});
			}
			backgroundLayer.current = new VectorLayer({
				source: new VectorSource({ features: bgFeatures })
			});
			foregroundLayer.current = new VectorLayer({
				source: new VectorSource({ features: fgFeatures }),
				zIndex: ZIndex.Selected
			});
			group.current = new LayerGroup({
				layers: new Collection([backgroundLayer.current, foregroundLayer.current])
			});
			map.current.getOlMap().addLayer(group.current);
			focus(foregroundLayer.current.getSource().getFeatures());
			// interactions
			draw.current = new Draw({
				source: foregroundLayer.current.getSource(),
				condition: (event) => {
					if (isRightMouseButton(event.originalEvent)) {
						draw.current.removeLastPoint();
						return false;
					}
					return condition.primaryAction(event);
				},
				type: GeometryType.POLYGON
			});
			modify.current = new Modify({
				features: selectedFeatures.current,
				condition: (event) => {
					return condition.primaryAction(event);
				},
				deleteCondition: function (event) {
					if (isRightMouseButton(event.originalEvent)) {
						const coords = selectedFeatures.current.item(0).getGeometry().getCoordinates();
						if (coords.find(coord => {
							if (coord.length < 5) return false; // last element equals first element
							const at = coord.findIndex(c => c[0] == event.coordinate[0] && c[1] == event.coordinate[1]);
							if (at >= 0) {
								coord.splice(at, 1);
								if (at == 0) {
									coord.pop();
									coord.push(coord[0]);
								}
								return true;
							}
							return false;
						})) {
							selectedFeatures.current.item(0).getGeometry().setCoordinates(coords);
						}
					}
					return false;
				},
			});
			watch.current = new Interaction({
				handleEvent: (event) => {
					snapCoordinate.current = event.coordinate;
					return true;
				}
			});
			// on event openlayers iterates all interactions from last to first
			// so snap should be the last one
			snap.current = new Snap({ features: new Collection(bgFeatures.concat(fgFeatures)) });
			map.current.getOlMap().addInteraction(draw.current);
			map.current.getOlMap().addInteraction(modify.current);
			map.current.getOlMap().addInteraction(watch.current);
			map.current.getOlMap().addInteraction(snap.current);
			setupSelectInteraction();
		}
		return () => {
			map.current.getOlMap().removeLayer(group.current);
			map.current.getOlMap().removeInteraction(modify.current);
			map.current.getOlMap().removeInteraction(draw.current);
			map.current.getOlMap().removeInteraction(snap.current);
			resetInteractions();
			group.current = null;
			foregroundLayer.current = null;
			backgroundLayer.current = null;
			draw.current = null;
			modify.current = null;
			snap.current = null;
		};
	}, [zones]);

	useEffect(() => {
		let addedAt = null;
		const onClick = (event) => {
			if (!isSelectActive.current) return;
			const features = map.current.getOlMap().getFeaturesAtPixel(event.pixel, {
				layerFilter: (layer) => layer == foregroundLayer.current
			});
			const hasFeatures = Array.isArray(features) && features.length > 0;
			const selectedFeature = selectedFeatures.current.item(0);
			if (hasFeatures) {
				const feature = features[0];
				if (feature.getId() == selectedFeature.getId()) { // selecting points
					const pointAt = addedAt != null ? addedAt : area.pointAt(snapCoordinate.current);
					addedAt = null;
					if (pointAt != null && pointAt != selectedPointContextRef.current.pointAt) {
						selectedPointContextRef.current.set(pointAt);
					}
				} else {
					selectedPointContextRef.current.clear();
					selectedFeatureContextRef.current.set(feature.getId(), true);
				}
			}
		}
		map.current.getOlMap().on('click', onClick);
		const handleAreaRemove = (area) => {
			foregroundLayer.current.getSource().removeFeature(area.feature());
		}
		props.zone.addObserver(props.zone.events.areaWillRemove, handleAreaRemove);
		const handeZoneChanged = () => {
			updateFeatures();
		}
		props.zone.addObserver(props.zone.events.changed, handeZoneChanged);
		const onPointAdded = (point, at) => {
			addedAt = at;
		}
		const onPointChanged = (old, point, at) => {
			const olMap = map.current.getOlMap();
			const mapExtent = olMap.getView().calculateExtent(olMap.getSize());
			if (!containsCoordinate(mapExtent, point)) {
				focus([area.feature()]);
			}
		}
		const observable = area.featureObservable();
		observable.addObserver(observable.events.pointAdded, onPointAdded);
		observable.addObserver(observable.events.pointChanged, onPointChanged);
		return () => {
			map.current.getOlMap().un('click', onClick);
			props.zone.removeObserver(props.zone.events.areaWillRemove, handleAreaRemove);
			props.zone.removeObserver(props.zone.events.changed, handeZoneChanged);
			observable.removeObserver(observable.events.pointAdded, onPointAdded);
			observable.removeObserver(observable.events.pointChanged, onPointChanged);
		}
	}, [zoneId]);

	useEffect(() => {
		if (selectedFeatureContext.value) {
			const area = props.zone.area(selectedFeatureContext.value)
			selectedFeatures.current.clear();
			selectedFeatures.current.push(area.feature());
			setupModifyInteraction();
		} else {
			selectedFeatures.current.clear();
		}
		updateFeatures();
	}, [selectedFeatureContext.value]);

	useEffect(() => {
		if (selectedFeatures.current.item(0) != null) {
			updatePointStyle();
		}
	}, [selectedPointContext.pointAt]);

	return (
		<div className={className('zone-area-edit-map', fromProps(props))}>
			<Map name={MAP_NAME} ref={map} baseLayer={Map.Layers.GOOGLE} />
			<MapControls>
				<MapOptions>
					<MapOptionLayers mapName={MAP_NAME} />
				</MapOptions>
				<MapSearch onReady={focusExtent} />
			</MapControls>
		</div>
	)
}

export default connect(state => {
	return {
		zones: state.zones
	}
})(ZoneAreaEditMap);