import { ofType, combineEpics } from 'redux-observable'
import { map as rxmap, filter } from 'rxjs/operators';
import { actions as sessionActions } from "../api/session"
import { localStorage as storage } from "../../app/storage";
import { getDistinctColor } from '../../lib/color';
import { ActionGeneratorBuilder } from '../actions';

//	{
// 		domain: {
//			map: { uid: { DEVICES_TRACEType: string, layout: {}, data?: {} } }
//			display: boolean,
//		}
//	}

const defaultState = {};

const defaultSubstate = {
	display: true,
	displayDirection: true,
	allTrace: true, // boolean
	selectedTraceUris: [], // [ uri ]
	traceUriMap: {}, // { uri: boolean }
	traceColorMap: {} // { uri: color }
};

const actions = new ActionGeneratorBuilder('deviceTrace')
	.type('setDisplayTrace', { domain: true, display: true })
	.type('setDisplayDirection', { domain: true, display: true })
	.type('setTraceUris', { domain: true, uris: true })
	.type('toggleTraceUri', { domain: true, uri: true })
	.type('setTraceColor', { domain: true, uri: true, color: false })
	.type('setAllTrace', { domain: true, all: false })
	.type('restoreState', { state: true })
	.build()
;

// ---------------------------------------------------------

const reducer = (state = defaultState, action) => {
	let substate = action.domain ? (state[action.domain] || defaultSubstate) : null;
	switch (action.type) {
		case actions.setDisplayTrace.type:
			return {
				...state,
				[action.domain]: {
					...substate,
					display: action.display
				}
			};
		case actions.setDisplayDirection.type:
			return {
				...state,
				[action.domain]: {
					...substate,
					displayDirection: action.display
				}
			};
		case actions.setTraceUris.type:
			const traceUriMap = {};
			const selectedTraceUris = [];
			action.uris.forEach(uri => {
				if (substate.traceUriMap[uri] != undefined) {
					traceUriMap[uri] = substate.traceUriMap[uri];
				} else {
					traceUriMap[uri] = substate.allTrace ? true : substate.traceUriMap[uri];
				}
				if (traceUriMap[uri]) selectedTraceUris.push(uri);
			});
			return {
				...state,
				[action.domain]: {
					...substate,
					selectedTraceUris,
					traceUriMap
				}
			};
		case actions.toggleTraceUri.type:
			substate.traceUriMap[action.uri] = !substate.traceUriMap[action.uri];
			const _selectedTraceUris = Object.keys(substate.traceUriMap).filter(uri => substate.traceUriMap[uri]);
			let _allTrace = _selectedTraceUris.length === Object.keys(substate.traceUriMap).length;
			return {
				...state,
				[action.domain]: {
					...substate,
					selectedTraceUris: _selectedTraceUris,
					allTrace: _allTrace
				}
			};
		case actions.setTraceColor.type:
			return {
				...state,
				[action.domain]: {
					...substate,
					traceColorMap: {
						...substate.traceColorMap,
						[action.uri]: (action.color || getDistinctColor(Object.values(substate.traceColorMap)))
					}
				}
			};
		case actions.setAllTrace.type:
			const selectedUris = Object.keys(substate.traceUriMap).filter(uri => {
				substate.traceUriMap[uri] = action.all ? true : false;
				return action.all;
			});
			return {
				...state,
				[action.domain]: {
					...substate,
					allTrace: action.all,
					selectedTraceUris: selectedUris
				}
			}
		case actions.restoreState.type:
			return action.state;
		default:
			return state;
	}
}

// ---------------------------------------------------------

const STORAGE_KEY = 'deviceTrace';

const restoreEpic = (action$, state$) => {
	return action$.pipe(
		ofType(sessionActions.events.started.type),
		rxmap(action => {
			const currentState = storage.get(STORAGE_KEY);
			if (currentState != null) {
				const domainMap = state$.value.pages;
				Object.keys(currentState).forEach(domain => {
					if (domainMap[domain] && domainMap[domain].selection) {
						const newTraceUriMap = {};
						domainMap[domain].selection.forEach(uri => {
							newTraceUriMap[uri] = currentState[domain].allTrace ? true : currentState[domain].traceUriMap[uri];
						});
						currentState[domain].traceUriMap = newTraceUriMap;
						currentState[domain].selectedTraceUris = Object.keys(newTraceUriMap).filter(uri => newTraceUriMap[uri]);
					}
				});
				return actions.restoreState({ state: currentState });
			}
		}),
		filter(action => action != null)
	)
}

const updateEpic = (action$, state$) => {
	return action$.pipe(
		ofType(
			actions.setDisplayTrace.type, actions.setTraceUris.type,
			actions.setTraceColor.type, actions.setAllTrace.type,
			actions.toggleTraceUri.type, actions.restoreState.type,
			actions.setDisplayDirection.type,
		),
		filter(() => {
			storage.set(STORAGE_KEY, state$.value.deviceTrace);
			return false;
		})
	)
}

const epic = combineEpics(restoreEpic, updateEpic);

// ---------------------------------------------------------

export { actions, reducer, epic };
