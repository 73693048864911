import React, { useState } from 'react';
import { Popover, Position } from '@blueprintjs/core';
import NavItem from './NavItem';

/**
 * @param {Object} props
 * @param {React.Component} props.icon
 * @param {React.Component} props.content
 * @param {string} [props.title]
 */

function PopupNavItem(props) {
	const [opened, setOpened] = useState(false);

	const toggle = () => {
		setOpened(!opened);
	}

	return (
		<Popover
			position={Position.BOTTOM_RIGHT}
			minimal={true}
			captureDismiss={true}
			onBlur={toggle}
		>
			<NavItem onClick={toggle} disabled={props.disabled} title={props.title}>
				{props.icon}
			</NavItem>
			{props.content}
		</Popover>
	);
}

export default PopupNavItem;