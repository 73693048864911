import sha1 from 'crypto-js/sha1';
import { rootLogger as logger } from '../lib/log';
import { assert } from '../lib/assert';

(function () {
	const REVISION = 5, REVISION_KEY = '_v';
	const storageRevision = (() => {
		const revision = Number(window.localStorage.getItem(REVISION_KEY));
		return 0 < revision ? revision : null;
	})();

	const backupData = {};

	const backup = (storage) => {
		Object.keys(storage).forEach(key => {
			if (key.length != 40) {
				backupData[key] = storage.getItem(key);
			}
		})
	}

	const restore = (storage) => {
		Object.keys(backupData).forEach(key => {
			storage.setItem(key, backupData[key]);
		});
	}

	const clean = (storage) => {
		Object.keys(storage).forEach(key => storage.removeItem(key));
	}

	const migrate = (storage) => {
		const obsoleteKeys = ['OMT', 'OMTLT'];
		for (const key of obsoleteKeys) {
			if (storage.getItem(key) != null) {
				storage.removeItem(key);
				delete backupData[key];
			}
		}
	}

	if (storageRevision != REVISION) {
		backup(window.localStorage);
		try {
			logger.info('Trying to migrate local storage structure to new version');
			migrate(window.localStorage);
			logger.info('Local storage structure successfully migrated');
		} catch (error) {
			logger.error('Local storage migration failed', error, 'cleaning...');
			clean(window.localStorage);
		} finally {
			restore(window.localStorage);
			window.localStorage.setItem(REVISION_KEY, REVISION);
		}
	}
})();

/**
 * @readonly
 * @enum {string}
 */
const StorageType = {
	Local: 'local',
	Session: 'session'
}

class Storage {

	/**
	 * @param {StorageType} type
	 */
	constructor(type) {
		this.storage = type == StorageType.Local ? window.localStorage : window.sessionStorage;
	}

	setPrefix(prefix) {
		this.prefix = sha1(prefix).toString();
		return this;
	}

	get(key) {
		assert(this.prefix != null, "Storage prefix should be initialized before calling get");
		const values = this.__read();
		return values[key];
	}

	set(key, value) {
		assert(this.prefix != null, "Storage prefix should be initialized before calling set");
		const values = this.__read();
		values[key] = value;
		this.__write(values);
	}

	assign(key, update) {
		assert(this.prefix != null, "Storage prefix should be initialized before calling assign");
		const values = this.__read();
		values[key] = Object.assign(values[key] || {}, update);
		this.__write(values);
	}

	remove(key) {
		assert(this.prefix != null, "Storage prefix should be initialized before calling remove");
		const values = this.__read();
		delete values[key];
		this.__write(values);
	}

	__read() {
		return this.__deserialize(this.storage.getItem(this.prefix)) || {};
	}

	__write(value) {
		this.storage.setItem(this.prefix, this.__serialize(value));
	}

	__serialize(value) {
		return JSON.stringify(value);
	}

	__deserialize(value) {
		if (value == null) return null;
		return value != '' ? JSON.parse(value) : null;
	}
}

export const localStorage = new Storage(StorageType.Local);
export const sessionStorage = new Storage(StorageType.Session);

export const commonLocalStorage = new Storage(StorageType.Local).setPrefix('common-storage');
export const commonSessionStorage = new Storage(StorageType.Session).setPrefix('common-storage');
