import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';

import { Colors } from 'ui/constants/colors';
import { fc } from 'i18n';
import { expiration } from 'assets/icons';
import { SvgWrapper } from 'components/atoms/svg-wrapper';
import { localeSelector } from 'core/redux/selectors/locale';

interface IDeviceExpirationIconProps {
  isExpired: boolean;
  expiresAt: string;
}

export const DeviceExpirationIcon: FC<IDeviceExpirationIconProps> = ({ isExpired, expiresAt }) => {
  const locale = useSelector(localeSelector);

  const expirationDate = moment(expiresAt).locale(locale).format('DD/MM/YYYY');
  const expirationText = isExpired ? fc('expired') : `${fc('expires on')} ${expirationDate}`;

  return (
    <div title={expirationText}>
      <SvgWrapper
        width={16}
        height={16}
        color={isExpired ? Colors.MaximumRed : Colors.SilverColor}
        details={expiration}
      />
    </div>
  );
};
