import React, { useEffect, useState } from "react";

import Form from "../../general/form/Form";
import { useI18n } from "../../../../i18n";
import GroupCheckbox from "../../general/form/GroupCheckbox";
import MultipleMessageFieldsPicker from "../../share/message/MultipleMessageFieldsPicker";
import EventTypePicker from "../../share/events/EventTypePicker";

import './messagePublisherDetailsForm.scss'

/**
 * @param {Object} props
 * @param {cx.ods.processors.MessagePublisher} props.messagePublisher
 */

export const MessagePublisherDetailsForm = ({ messagePublisher }) => {
	const { f } = useI18n();

	const [stateUpdates, setStateUpdates] = useState(false);
	const [events, setEvents] = useState(false);

	const filterPublishableEntities = (entity) => !!entity?.publishable;

	const validateForceUpdateTimeout = (value) => {
		if (value === 0 || !!value && (value < 0 || !Number.isInteger(value))) {
			return f('timeout value must be positive integer')
		}
	}

	useEffect(() => {
		setStateUpdates(!!messagePublisher?.states);
		setEvents(!!messagePublisher?.events);
	}, [messagePublisher])

	return (
		<>
			<Form.Control
				controlType={Form.Control.Type.Input}
				controlName="service"
				label={f('service')}
				placeholder={f('default')}
				initialValue={messagePublisher?.service || ''}
			/>
			<Form.Control
				controlType={GroupCheckbox}
				controlName="states"
				label={f('state updates')}
				value={stateUpdates}
				onChange={setStateUpdates}
			/>
			<Form.ControlGroup disabled={!stateUpdates}>
				<Form.Control
					className="destination-input"
					controlType={Form.Control.Type.Input}
					controlName="statesDestination"
					label={f('destination')}
					placeholder={f('default')}
					initialValue={messagePublisher?.statesDestination || ''}
				/>
			</Form.ControlGroup>
			<Form.Control
				controlType={GroupCheckbox}
				controlName="events"
				label={f('events')}
				value={events}
				onChange={setEvents}
			/>
			<Form.ControlGroup disabled={!events}>
				<Form.Control
					className="destination-input"
					controlType={Form.Control.Type.Input}
					controlName="eventsDestination"
					label={f('destination')}
					placeholder={f('default')}
					initialValue={messagePublisher?.eventsDestination || ''}
				/>
				<Form.Control
					controlType={EventTypePicker}
					controlName="eventTypes"
					label={f('event types')}
					placeholder={f('default')}
					initialValue={messagePublisher?.eventTypes || null}
					filter={filterPublishableEntities}
				/>
			</Form.ControlGroup>
			<Form.Control
				controlType={MultipleMessageFieldsPicker}
				controlName="fieldIds"
				label={f('attributes')}
				placeholder={f('default')}
				initialValue={messagePublisher?.fieldIds || null}
				filter={filterPublishableEntities}
			/>
			<Form.Control
				controlName="forceTimeout"
				label={`${f('force update timeout')}, ${f({ prefix: 'units', id: 's' })}`}
				controlType={Form.Control.Type.InputNumber}
				controlValidator={validateForceUpdateTimeout}
				initialValue={messagePublisher?.forceTimeout || ''}
				min={1}
			/>
		</>
	)
}
