import React from 'react';
import { InputPicker as RsInputPicker } from 'rsuite';
import { capitalize } from '../../../misc/misc';
import { className, fromProps } from '../../../lib/className';
import './xPicker.scss';
import './xSelectPicker.scss';

/**
 * For other parameters - see rsuite InputPicker component
 * @param {Object} props
 * @param {Array} props.data
 * @param {string | number | null} [props.value]
 * @param {boolean} [props.cleanable]
 * @param {boolean} [props.disabled]
 * @param {Function} [props.onChange]
 * @param {string} [props.label]
 * @param {string} [props.description]
 */

function InputPicker(props) {
	const { label, placeholder, description, ..._props } = props;
	return (
		<div className={className('x-picker', 'input-picker', fromProps(props))}>
			{props.label && <label>{props.label}</label>}
			<RsInputPicker
				block
				virtualized={false}
				placeholder={capitalize(placeholder)}
				renderMenu={(menu) => <div className={className('x-picker', 'input-picker menu', fromProps(props))}>{menu}</div>}
				{..._props}
			/>
			{description && <div className="description">{description}</div>}
		</div>
	)
}

export default InputPicker;
