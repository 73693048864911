import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { actions as processorActions } from '../../redux/api/processors';
import PagePanel from '../general/panel/PagePanel';
import { useI18n } from '../../../i18n';
import { AllRoutes } from 'constants/routes';
import Intent from '../../misc/ObjectActionIntent';
import DefaultActionBar from '../share/actionbar/DefaultActionBar';
import ActionBack from '../share/actionbar/ActionBack';
import ActionAdd from '../share/actionbar/ActionAdd';
import Button from '../general/form/Button';
import Form from '../general/form/Form';
import SidebarLayout from '../general/layout/SidebarLayout';
import ObjectActionDialogPopup from '../general/ObjectActionDialogPopup';
import RemoveDialog from '../general/RemoveDialog';
import Loader from '../general/Loader';
import { cx } from '../../api';
import DeviceStatusDetectorCard from '../custom/deviceStatusDetectors/DeviceStatusDetectorCard';
import DeviceStatusDetectorList from '../custom/deviceStatusDetectors/DeviceStatusDetectorList';
import DeviceStatusDetectorFields from '../custom/deviceStatusDetectors/DeviceStatusDetectorFields';
import DeviceStatusDetectorView from '../custom/deviceStatusDetectors/DeviceStatusDetectorView';
import { setPageTitle } from '../../misc/page';
import './deviceStatusDetectorsPage.scss';

const actions = processorActions.deviceStatusDetectors;

const PAGE_TITLE = 'device status detectors';

/**
 * @param {Object} props
 */

function DeviceStatusDetectorsPage(props) {
	const { f, fc } = useI18n();
	const history = useHistory();
	const form = useRef(null);
	const submitHook = useRef(null);
	const pending = useRef(false);
	const [intent, setIntent] = useState(null);
	const [detector, setDetector] = useState(null);
	const processorId = props.match.params.id;
	const modifying = props.match.params[0] == 'edit' || props.match.params[0] == 'create';
	const disabled = props.detectors.pending;

	useEffect(() => {
		setPageTitle(fc(PAGE_TITLE));
		if (props.detectors.list == null) {
			props.dispatch(actions.load.request());
		}
		return () => {
			props.detectors.error && props.dispatch(actions.clear());
		}
	}, []);

	useEffect(() => {
		props.detectors.error && props.dispatch(actions.clear());
	}, [intent]);

	useEffect(() => {
		form.current && form.current.purge();
	}, [processorId]);

	useEffect(() => {
		props.detectors.error && props.dispatch(actions.clear());
		onPopupClose();
	}, [props.match]);

	useEffect(() => {
		if (!props.detectors.pending && pending.current) {
			pending.current = false;
			if (props.detectors.error == null) {
				onBack();
			}
		}
	}, [props.detectors]);

	useEffect(() => {
		if (props.detectors.list != null && processorId) {
			const deviceStatusDetector = props.detectors.map[processorId];

			deviceStatusDetector ? setDetector(deviceStatusDetector) : history.replace(AllRoutes.DeviceStatusDetectors);
		} else if (props.detectors.list != null && !processorId) {
			setDetector(null);
		}
		if (
			intent
			&& props.detectors.map && !props.detectors.map[intent.object().processorId]
		) {
			onPopupClose();
		}
	}, [props]);

	const submit = () => {
		submitHook.current();
	}

	const onSubmit = (deviceStatusDetector) => {
		if (detector) {
			props.dispatch(actions.update.request({ processorId: detector.processorId, data: deviceStatusDetector }));
		} else {
			props.dispatch(actions.add.request({ data: deviceStatusDetector }));
		}
		pending.current = true;
	}

	const onPopupClose = () => {
		intent && setIntent(null);
	}

	const onAdd = () => {
		history.push(`${AllRoutes.DeviceStatusDetectors}/create`);
	}

	const onBack = () => {
		setDetector(null);
		history.goBack();
	}

	const onSelect = (detector) => {
		history.push(`${AllRoutes.DeviceStatusDetectors}/${detector.processorId}`);
	}

	const onIntent = (intent) => {
		if (intent.action() == Intent.Action.Remove) {
			setIntent(intent);
		} else if (intent.action() == Intent.Action.Edit) {
			history.push(`${AllRoutes.DeviceStatusDetectors}/${intent.object().processorId}/edit`);
		}
	}

	const onRemove = (detector) => {
		props.dispatch(actions.remove.request({ processorId: detector.processorId }));
	}

	const sidebarContent = (<>
		<DefaultActionBar
			prependActions={modifying
				? <ActionBack disabled={disabled} onClick={onBack} />
				: <ActionAdd onClick={onAdd} />
			}
			disabled={disabled}
		/>
		{modifying
			? <div className="content">
				<DeviceStatusDetectorFields detector={detector} />
				<div className="error">
					{props.detectors.error}
				</div>
				<Button onClick={submit} disabled={disabled}>
					{f('save')}
				</Button>
			</div>
			: <>
				<DeviceStatusDetectorList
					selectedId={detector ? detector.processorId : null}
					onSelect={onSelect}
					onIntent={onIntent}
				/>
				{detector && <DeviceStatusDetectorCard detector={detector} />}
			</>
		}
	</>);

	let content = null;
	if (props.detectors.list != null) {
		let excludeUris = null;
		props.detectors.list.forEach(_detector => {
			if (!(detector && _detector.processorId == detector.processorId)) {
				excludeUris = excludeUris ? excludeUris.concat(_detector.uris) : _detector.uris;
			}
		});

		content = (
			<Form
				onSubmit={onSubmit}
				submitHook={submitHook}
				objectType={cx.ods.processors.DeviceStatusDetector}
				disabled={disabled}
				customRef={form}
			>
				<SidebarLayout
					className="body"
					// icon={<IconZoneGuards size="20" />}
					title={modifying ? f('device status detector') : f('device status detectors')}
					sidebarContent={sidebarContent}
				>
					<DeviceStatusDetectorView
						excludeUris={excludeUris}
						detector={detector}
						editMode={modifying}
						disabled={disabled}
					/>
					{intent && intent.action() == Intent.Action.Remove && (
						<ObjectActionDialogPopup
							offset={intent.data().offset}
							onClose={onPopupClose}
							title={f('delete device status detector')}
							disabled={disabled}
							error={props.detectors.error}
						>
							<RemoveDialog
								object={intent.object()}
								title={f('device status detector')}
								text={f('are you sure you want to delete device status detector', { name: intent.object().name })}
								onSubmit={onRemove}
								onCancel={onPopupClose}
							/>
						</ObjectActionDialogPopup>
					)}
				</SidebarLayout>
			</Form>
		);
	} else if (props.detectors.pending) {
		content = <div className="vh-center"><Loader size={Loader.Size.LG} /></div>;
	}

	return (
		<div className="app-page device-status-detectors">
			<PagePanel>{content}</PagePanel>
		</div>
	);
}

DeviceStatusDetectorsPage.Url = AllRoutes.DeviceStatusDetectors;

export default connect(state => {
	return {
		detectors: state.processors.deviceStatusDetectors || {}
	}
})(DeviceStatusDetectorsPage);
