import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from "react-router-dom";
import { Overlay, Classes } from '@blueprintjs/core';
import Loader from './core/react/general/Loader';
import { ReduxKeyContext } from './core/misc/ReduxKeyContext';


/**
 * @param {Object} props
 * @param {React.Component} props.component
 * @param {React.Component} props.wrap
 * @param {string} [props.reduxKey]
 */

function ProtectedRoute(props) {
	const { component: Component, wrap, session, reduxKey, ...rest } = props;
	const Wrap = wrap ? wrap : ({children}) => children;
	return (
		<Route
			{...rest}
			render={(props) => {
				if (!session.initialized || !session.authorized) {
					return (<Redirect to={{ pathname: "/", state: { from: props.location } }}/>);
				} else {
					return (
						<ReduxKeyContext.Provider value={reduxKey}>
							<Wrap>
								<Component {...props} />
								{session.pending &&
									<Overlay className={Classes.OVERLAY_CONTAINER} isOpen={session.pending}>
										<div style={{
											display: "flex",
											width: "100vw",
											height: "100vh",
											alignItems: "center",
											justifyContent: "center"
										}}>
											<Loader size={Loader.Size.LG} />
										</div>
									</Overlay>
								}
							</Wrap>
						</ReduxKeyContext.Provider>
					);
				}
			}}
		 />
	);
}

export default connect(state => ({
	session: state.session
}))(ProtectedRoute);
