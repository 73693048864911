import React  from 'react';

import { useI18n } from '../../../../i18n';
import { MessagePublisherDetailsForm } from "./MessagePublisherDetailsForm";
import MessagePublisherDetailsInfo from "./MessagePublisherDetailsInfo";

import './messagePublisherDetails.scss';

/**
 * @param {Object} props
 * @param {cx.ods.processors.MessagePublisher} props.messagePublisher
 * @param {boolean} props.isEdit
 */

export const MessagePublisherDetails = (props) => {
	const { messagePublisher, isEdit } = props;

	const { f } = useI18n();

	return (
		<div className="message-publisher-details">
			<div className="header">
				<label>{f('details')}</label>
			</div>
			<div className="details-content">
				{isEdit ?
					<MessagePublisherDetailsForm messagePublisher={messagePublisher} /> :
					<MessagePublisherDetailsInfo messagePublisher={messagePublisher} />
				}
			</div>
		</div>
	);
}

