import React from 'react';
import ListItem from '../../general/list/ListItem';
import { fromProps } from '../../../lib/className';

/**
 * @param {cx.ods.zones.ZoneData} zone
 * @param {function} onClick
 * @param {React.RefObject} [props.customRef]
 */

function ZoneListItem(props) {
	return (
		<ListItem customRef={props.customRef} className={fromProps(props)} onClick={props.onClick}>
			{props.zone.name}
		</ListItem>
	);
}

export default ZoneListItem;