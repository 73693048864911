import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../i18n';
import { ods } from '../../../api';
import { actions } from '../../../redux/api/assets/actions';
import Form from '../../general/form/Form';
import Button from '../../general/form/Button';
import ButtonGroup from '../../general/form/ButtonGroup';
import EmployeeCategories from './EmployeeCategories';
import './employeeImportForm.scss';

/**
 * @param {Object} props
 * @param {Object} props.importedData { emploees: [cx.ods.assets.EmployeeInfo], errors: quantity }
 * @param {function} props.onCancel
 * @param {function} props.onComplete
 */

function EmployeeImportForm(props) {
	const { f } = useI18n();
	const submitHook = useRef(null);
	const pending = useRef(false);
	const disabled = props.employees.pending;

	useEffect(() => {
		if (!props.employees.pending && pending.current) {
			pending.current = false;
			if (props.employees.error == null) props.onComplete();
		}
	}, [props.employees]);

	const onSubmit = (upload) => {
		pending.current = true;
		upload.importees = props.importedData.employees;
		props.dispatch(actions.employees.upload.request({ upload }));
	}

	const submit = () => {
		submitHook.current();
	}

	return (
		<Form
			className="employee-import-form"
			onSubmit={onSubmit}
			submitHook={submitHook}
			objectType={ods.assets.EmployeeUpload}
			disabled={disabled}
		>
			<EmployeeCategories editMode />
			<Form.Control
				controlType={Form.Control.Type.Checkbox}
				controlName="inactivateOthers"
				initialValue={false}
				label={f('inactivate others')}
			/>
			<div className="loaded-info">
				{f('X records loaded', {quantity: props.importedData?.employees?.length || 0})}
				{Boolean(props.importedData.errors || props.importedData.errorMessage) && <div className="local-errors">
					{Boolean(props.importedData.errorMessage) && <span>{props.importedData.errorMessage}</span>}
					{Boolean(props.importedData.errors) && <span>{f('X rows failed to parse', {quantity: props.importedData.errors})}</span>}
				</div>}
			</div>
			<div className="error">
				{props.employees.error}
			</div>
			<ButtonGroup>
				<Button onClick={props.onCancel} disabled={disabled}>{f('cancel')}</Button>
				<Button onClick={submit} disabled={disabled || Boolean(props.importedData.errors)}>{f('import')}</Button>
			</ButtonGroup>
		</Form>
	);
}

export default connect(state => ({
	employees: state.assets.employees
}))(EmployeeImportForm)
