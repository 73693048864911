import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../../i18n';
import { cx } from '../../../../api';
import Form from '../../../general/form/Form';
import Button from '../../../general/form/Button';
import * as zoneCategories from '../../../../redux/app/categories/zone';

/**
 * @param {Object} props
 * @param {Object} props.category
 * @param {function} props.onSubmit
 */

function ZoneCategoryForm(props) {
	const { f } = useI18n();
	const [name, setName] = useState(null);
	const formSubmitHook = useRef(null);
	const category = useRef(null);
	const pending = useRef(false);

	const onSubmit = () => {
		category.current.name = name;
		if (props.category == null) {
			props.dispatch(zoneCategories.actions.add.request({ data: category.current }));
		} else {
			props.dispatch(zoneCategories.actions.update.request({ categoryId: props.category.categoryId, data: category.current }));
		}
		pending.current = true;
	}

	const submit = () => {
		formSubmitHook.current();
	}

	useEffect(() => {
		if (props.categories.map == null && !props.categories.pending) {
			props.dispatch(zoneCategories.actions.load.request());
		}
	}, []);

	useEffect(() => {
		setName(props.category ? props.category.name : f('no name'));
		props.categories.error != null && props.dispatch(zoneCategories.actions.clear());
	}, [props.category]);

	useEffect(() => {
		if (props.categories.map != null && category.current == null) {
			if (props.category == null) {
				category.current = new cx.ods.categories.CategoryData();
				category.current.name = f('no name');
				category.current.comprisingIds = [props.categories.root.categoryId];
			} else {
				category.current = cx.meta.clone(props.categories.map[props.category.categoryId]);
			}
			setName(category.current.name);
		}
		if (!props.categories.pending && pending.current) {
			pending.current = false;
			props.categories.error == null && props.onSubmit();
		}
	}, [props.categories]);

	return (
		<div className="zone-category-form">
			<Form
				onSubmit={onSubmit}
				submitHook={formSubmitHook}
				disabled={props.categories.pending}
			>
				<Form.Control
					controlName="name"
					controlType={Form.Control.Type.Input}
					controlValidator={(value) => !value && f('please type tag name')}
					label={f('name')}
					value={name}
					onChange={setName}
					autoFocus
				/>
				{props.children}
				<div className="error">
					{props.categories.error}
				</div>
				<Form.ControlGroup>
					<Button onClick={submit} disabled={props.categories.pending}>{f('save')}</Button>
				</Form.ControlGroup>
			</Form>
		</div>
	);
}

export default connect(
	state => ({
		categories: state.categories.zones
	})
)(ZoneCategoryForm);
