import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { className } from 'core/lib/className';
import { DevicesSideMenu } from './device-side-menu';
import { isSidebarCollapsedSelector } from 'core/redux/selectors/context';

import './device-sidebar.scss';

export const DeviceSidebar: FC = () => {
  const isCollapsed = useSelector(isSidebarCollapsedSelector);

  return (
    <div className={className('device-sidebar', { isCollapsed })}>
      <DevicesSideMenu />
    </div>
  );
};
