import React from 'react';
import { className, fromProps } from '../../../lib/className';
import './pagePanel.scss';

/**
 * @param {Object} props
 * @param {React.Component} [props.header]
 * @param {ReactElement | null} [props.children]
 */

function PagePanel(props) {
	return (
		<div className={className('page-panel', fromProps(props))}>
			<div className="wrap">
				{props.header && <div className="header">{props.header}</div>}
				<div className="content">{props.children}</div>
			</div>
		</div>
	);
}

export default PagePanel;
