import React from 'react';
import { useSelector } from 'react-redux';
import { getAssetType } from '../../../misc/assetTypes';
import { getAssetTypeIcon } from '../../../img';
import { useI18n } from '../../../../i18n';
import Loader from '../../general/Loader';


/**
 * @param {Object} props
 * @param {Array.<number>} [props.categoryIds]
 * @param {import("../../../img").IconSize} [props.size]
 */

function DeviceAssetTypeIcon(props) {
	const { fc } = useI18n();
	const assetTypeCategories = useSelector(state => state.categories.assetTypes);

	let type = null;
	let title = '';

	const assetType = getAssetType(props.categoryIds, assetTypeCategories.map);
	if (assetTypeCategories.pending) {
		return <Loader />;
	} else if (assetType) {
		type = assetType.getType();
		title = fc({ prefix: 'category', id: assetType.getName() });
	}

	return (<img src={getAssetTypeIcon(type, props.size)} alt="" title={title} />);
}

export default DeviceAssetTypeIcon;