import React, { FC } from 'react';

import { useI18n } from 'i18n';
import ListItem from 'core/react/general/list/ListItem';

import './mqttClientsTable.scss';

export const MqttClientsTableHeader: FC = () => {
  const { fc } = useI18n();

  return (
    <ListItem className="mqtt-clients-table--header">
      <div className="mqtt-clients-table--column-id">{fc('client id')}</div>
      <div className="mqtt-clients-table--column-identifier">{fc('identifier')}</div>
      <div className="mqtt-clients-table--column-description">{fc('description')}</div>
      <div className="mqtt-clients-table--column">{fc('created at')}</div>
    </ListItem>
  );
};
