import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import TagPicker from '../../general/form/TagPicker';
import { useI18n } from '../../../../i18n';
import Loader from '../../general/Loader';

/**
 * @callback MessageFieldPickerFilter
 * @param {MessageFilter} field
 */

/**
 * @param {Object} props
 * @param {Array.<number||string>} props.value
 * @param {Array.<number||string>} [props.initialValue]
 * @param {function} props.onChange
 * @param {string} [props.label]
 * @param {string} [props.placeholder]
 * @param {boolean} [props.disabled]
 * @param {MessageFieldPickerFilter} [props.filter]
 */

function MultipleMessageFieldsPicker(props) {
	const { label, initialValue, value, disabled, attributeTypes, messageFields, filter, onChange } = props;

	const { f, fc } = useI18n();

	const filterMessageFields = (field) => filter ? filter(field) : true;

	const formatMessageFields = (messageField) => ({
		value: messageField.fieldId,
		label: fc({ prefix: 'message-field' , id: messageField.description })
	})

	const sortMessageFieldsOptions = (a, b) => a.label.localeCompare(b.label);

	const messageFieldOptions = useMemo(() =>
		messageFields.typeMap && attributeTypes.codeMap ?
			Object.values(messageFields.typeMap)
				.filter(filterMessageFields)
				.map(formatMessageFields)
				.sort(sortMessageFieldsOptions)
			: [],
	[messageFields, attributeTypes]);

	return (
		<div className="event-type-picker">
			{messageFields && attributeTypes ?
				<TagPicker
					data={messageFieldOptions}
					value={value}
					onChange={onChange}
					placeholder={f(props.placeholder || 'select attributes')}
					label={label}
					disabled={disabled}
					defaultValue={initialValue}
					style={{ minHeight: '37px' }}
				/>
				: <Loader />
			}
		</div>
	);
}

export default connect(state => ({
	messageFields: state.registry.messageFields,
	attributeTypes: state.registry.attributeTypes
}))(MultipleMessageFieldsPicker);
