import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { FiExternalLink as IconZoneGuards } from 'react-icons/fi';
import { actions as processorActions } from '../../redux/api/processors';
import { AllRoutes } from 'constants/routes';
import Intent from '../../misc/ObjectActionIntent';
import { useI18n } from '../../../i18n';
import PagePanel from '../general/panel/PagePanel';
import Loader from '../general/Loader';
import Form from '../general/form/Form';
import SidebarLayout from '../general/layout/SidebarLayout';
import ObjectActionDialogPopup from '../general/ObjectActionDialogPopup';
import RemoveDialog from '../general/RemoveDialog';
import DefaultActionBar from '../share/actionbar/DefaultActionBar';
import ActionAdd from '../share/actionbar/ActionAdd';
import Button from '../general/form/Button';
import FuelProcessorList from '../custom/fuelLevelProcessors/FuelProcessorList';
import FuelProcessorCard from '../custom/fuelLevelProcessors/FuelProcessorCard';
import FuelProcessorFields from '../custom/fuelLevelProcessors/FuelProcessorFields';
import ActionBack from '../share/actionbar/ActionBack';
import FuelProcessorsView from '../custom/fuelLevelProcessors/FuelProcessorsView';
import { cx } from '../../api';
import { setPageTitle } from '../../misc/page';
import './fuelLevelProcessorsPage.scss';

const actions = processorActions.fuelLevel;

const PAGE_TITLE = 'fuel level processors';

/**
 * @param {Object} props
 */

function FuelLevelProcessorsPage(props) { // TODO
	const { f, fc } = useI18n();
	const history = useHistory();
	const form = useRef(null);
	const submitHook = useRef(null);
	const pending = useRef(false);
	const [intent, setIntent] = useState(null);
	const [processor, setProcessor] = useState(null);
	const processorId = props.match.params.id;
	const modifying = props.match.params[0] == 'edit' || props.match.params[0] == 'create';
	const disabled = props.processors.pending;

	useEffect(() => {
		setPageTitle(fc(PAGE_TITLE));
		if (props.processors.list == null) {
			props.dispatch(actions.load.request());
		}
		return () => {
			props.processors.error && props.dispatch(actions.clear());
		}
	}, []);

	useEffect(() => {
		props.processors.error && props.dispatch(actions.clear());
	}, [intent]);

	useEffect(() => {
		form.current && form.current.purge();
	}, [processorId]);

	useEffect(() => {
		props.processors.error && props.dispatch(actions.clear());
		onPopupClose();
	}, [props.match]);

	useEffect(() => {
		if (!props.processors.pending && pending.current) {
			pending.current = false;
			if (props.processors.error == null) {
				onBack();
			}
		}
	}, [props.processors]);

	useEffect(() => {
		if (props.processors.list != null && processorId) {
			const fuelLevelProcessor = props.processors.map[processorId];

			fuelLevelProcessor ? setProcessor(fuelLevelProcessor) : history.replace(AllRoutes.FuelLevelProcessors);
		} else if (props.processors.list != null && !processorId) {
			setProcessor(null);
		}
		if (
			intent
			&& props.processors.map && !props.processors.map[intent.object().processorId]
		) {
			onPopupClose();
		}
	}, [props]);

	const submit = () => {
		submitHook.current();
	}

	const onSubmit = (fuelProcessor) => {
		if (fuelProcessor.dropParameters) {
			Object.keys(fuelProcessor.dropParameters).forEach(key => fuelProcessor.dropParameters[key] = +fuelProcessor.dropParameters[key]);
			fuelProcessor.dropParameters = new cx.ods.processors.FuelLevelChangeParameters(fuelProcessor.dropParameters);
		}
		if (fuelProcessor.riseParameters) {
			Object.keys(fuelProcessor.riseParameters).forEach(key => fuelProcessor.riseParameters[key] = +fuelProcessor.riseParameters[key]);
			fuelProcessor.riseParameters = new cx.ods.processors.FuelLevelChangeParameters(fuelProcessor.riseParameters);
		}
		if (processor) {
			props.dispatch(actions.update.request({ processorId: processor.processorId, data: fuelProcessor }));
		} else {
			props.dispatch(actions.add.request({ data: fuelProcessor }));
		}
		pending.current = true;
	}

	const onPopupClose = () => {
		intent && setIntent(null);
	}

	const onAdd = () => {
		history.push(`${AllRoutes.FuelLevelProcessors}/create`);
	}

	const onBack = () => {
		setProcessor(null);
		history.goBack();
	}

	const onSelect = (processor) => {
		history.push(`${AllRoutes.FuelLevelProcessors}/${processor.processorId}`);
	}

	const onIntent = (intent) => {
		if (intent.action() == Intent.Action.Remove) {
			setIntent(intent);
		} else if (intent.action() == Intent.Action.Edit) {
			history.push(`${AllRoutes.FuelLevelProcessors}/${intent.object().processorId}/edit`);
		}
	}

	const onRemove = (fuelProcessor) => {
		props.dispatch(actions.remove.request({ processorId: fuelProcessor.processorId }));
	}

	const sidebarContent = (<>
		<DefaultActionBar
			prependActions={modifying
				? <ActionBack onClick={onBack} />
				: <ActionAdd onClick={onAdd} />
			}
			disabled={disabled}
		/>
		{modifying
			? <div className="content">
				<FuelProcessorFields processor={processor} />
				<div className="error">
					{props.processors.error}
				</div>
				<Button onClick={submit} disabled={disabled}>
					{f('save')}
				</Button>
			</div>
			: <>
				<FuelProcessorList
					selectedId={processor ? processor.processorId : null}
					onSelect={onSelect}
					onIntent={onIntent}
				/>
				{processor && <FuelProcessorCard processor={processor} />}
			</>
		}
	</>);

	let content = null;
	if (props.processors.list != null) {
		let excludeUris = null;
		props.processors.list.forEach(_processor => {
			if (!(processor && _processor.processorId == processor.processorId)) {
				excludeUris = excludeUris ? excludeUris.concat(_processor.uris) : _processor.uris;
			}
		});

		content = (
			<Form
				onSubmit={onSubmit}
				submitHook={submitHook}
				objectType={cx.ods.processors.FuelLevelProcessor}
				disabled={disabled}
				customRef={form}
			>
				<SidebarLayout
					className="body"
					icon={<IconZoneGuards size="20" />}
					title={modifying ? f('fuel level processor') : f('fuel level processors')}
					sidebarContent={sidebarContent}
				>
					<FuelProcessorsView
						excludeUris={excludeUris}
						fuelProcessor={processor}
						editMode={modifying}
						disabled={disabled}
					/>
					{intent && intent.action() == Intent.Action.Remove && (
						<ObjectActionDialogPopup
							offset={intent.data().offset}
							onClose={onPopupClose}
							title={f('delete fuel level processor')}
							disabled={disabled}
							error={props.processors.error}
						>
							<RemoveDialog
								object={intent.object()}
								title={f('fuel level processor')}
								text={f('are you sure you want to delete fuel level processor', { name: intent.object().name })}
								onSubmit={onRemove}
								onCancel={onPopupClose}
							/>
						</ObjectActionDialogPopup>
					)}
				</SidebarLayout>
			</Form>
		);
	} else if (props.processors.pending) {
		content = <div className="vh-center"><Loader size={Loader.Size.LG} /></div>;
	}

	return (
		<div className="app-page fuel-level-processors">
			<PagePanel>{content}</PagePanel>
		</div>
	);
}

FuelLevelProcessorsPage.Url = AllRoutes.FuelLevelProcessors;

export default connect(state => {
	return {
		processors: state.processors.fuelLevel
	}
})(FuelLevelProcessorsPage);
