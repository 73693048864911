import React from 'react';

import { className, fromProps } from '../../../lib/className';

/**
 * @param {Object} props
 * @param {string} props.id
 * @param {function} [props.onClick]
 * @param {ConnectDropTarget} [props.customRef]
 * @param {string} [props.id]
 * @param {React.ReactNode} props.children
 * @param {string} [props.className]
 */

function ListItem(props) {
	return (
		<div
			className={className('list-item', fromProps(props), { 'clickable': !!props.onClick })}
			id={props.id}
			onClick={props.onClick}
			ref={props.customRef}
		>
			{props.children}
		</div>
	);
}

export default ListItem;
