import React from 'react';
import { TagPicker as RsTagPicker } from 'rsuite';
import { useI18n } from '../../../../i18n';
import { capitalize } from '../../../misc/misc';
import { className, fromProps } from '../../../lib/className';
import './xPicker.scss';

/**
 * For other parameters - see rsuite TagPicker
 * @param {Object} props
 * @param {string} [props.label]
 */

function TagPicker(props) {
	const { f } = useI18n();
	const { label, placeholder, ..._props } = props;
	const _placeholder = placeholder || f('select');
	return (
		<div className={className('x-picker', 'tag-picker', fromProps(props))}>
			{label && <label>{label}</label>}
			<RsTagPicker
				block
				virtualized={false}
				placeholder={capitalize(_placeholder)}
				{..._props}
			/>
		</div>
	);
}

export default TagPicker;