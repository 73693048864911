import React, { FC } from 'react';

import { AllRoutes } from 'constants/routes';
import GeneralLayout from 'core/react/layout/GeneralLayout';
import PagePanel from 'core/react/general/panel/PagePanel';
import { ProcessorsPageStyled } from 'ui/pages/downsampled-message-history/processors-page.styled';
import { DownsampledMessageHistory } from 'components/organisms/downsampled-message-history';

const DownsampledMessageHistoryPage: FC = () => (
  <GeneralLayout>
    <ProcessorsPageStyled>
      <PagePanel>
        <DownsampledMessageHistory />
      </PagePanel>
    </ProcessorsPageStyled>
  </GeneralLayout>
);

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
DownsampledMessageHistoryPage.Url = AllRoutes.DownsampledMessageHistory;

export default DownsampledMessageHistoryPage;
