import { MutableRefObject, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { cx } from 'core/api';
import { IDownsampledMessageHistoryProcessor } from 'types/detectors';
import { actions as processorActions } from 'core/redux/api/processors';
import { downsampledMessageHistorySelector } from 'core/redux/selectors/processors';

interface IProcessorFormProps {
  processor: IDownsampledMessageHistoryProcessor | null;
  pending: MutableRefObject<boolean>;
}

export interface IProcessorForm {
  name: string;
  ignitionChange: boolean;
  odometerPrecision: string | number;
  speedPrecision: string | number;
  uris: string[];
}

export const FORM_DEFAULT_VALUES = {
  name: '',
  ignitionChange: false,
  odometerPrecision: '',
  speedPrecision: '',
  uris: [],
};

const actions = processorActions.downsampledMessageHistory;

export const useProcessorForm = (props: IProcessorFormProps) => {
  const { processor, pending } = props;

  const history = useHistory();

  const dispatch = useDispatch();
  const { error, pending: isDisabled } = useSelector(downsampledMessageHistorySelector);

  const formMethods = useForm<IProcessorForm>({
    defaultValues: FORM_DEFAULT_VALUES,
  });

  const { reset } = formMethods;

  const resetProcessor = (processor: IDownsampledMessageHistoryProcessor) => {
    const { name, ignitionChange, odometerPrecision, speedPrecision, uris } = processor;

    reset({ name, ignitionChange, odometerPrecision, speedPrecision, uris: uris || [] });
  };

  const onSubmit = formMethods.handleSubmit((formData: IProcessorForm) => {
    const data = new cx.ods.processors.DownsampledMessageHistoryProcessor(formData);

    processor
      ? dispatch(actions.update.request({ processorId: processor.processorId, data }))
      : dispatch(actions.add.request({ data }));

    pending.current = true;
  });

  const onBack = () => {
    history.goBack();
  };

  useEffect(() => {
    !!processor && resetProcessor(processor);
  }, [processor]);

  useEffect(() => {
    return () => {
      reset(FORM_DEFAULT_VALUES);
    };
  }, []);

  const actionsProps = { isDisabled, title: 'downsampled message history processor', onBack };

  return { isDisabled, actionsProps, formMethods, error, onSubmit };
};
