import { ActionGeneratorBuilder } from '../../actions';

export const actions = new ActionGeneratorBuilder('zoneGuard')
	.subtype('add', add => add.request('data').success('data').fail().cancel())
	.subtype('load', load => load.request().success('zoneGuards').fail().cancel())
	.subtype('update', update => update.request({ processorId: true, data: true }).success('data').fail().cancel())
	.subtype('remove', remove => remove.request('processorId').success().fail().cancel())
	.type('clear')
	.build()
;
