import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { fromEPSG4326 } from 'ol/proj/epsg3857';
import { boundingExtent } from 'ol/extent';
import MapMarker from '../../../general/location/MapMarker';
import { Map, getOwMap } from '../../../general/location/Map';
import { LineString, MultiPoint } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import LayerGroup from 'ol/layer/Group';
import { Feature } from 'ol';
import { Style, Stroke, Circle, Fill } from 'ol/style';
import css from '../../../../defaults.scss';
import { className } from '../../../../lib/className';
import { getAssetType } from '../../../../misc/assetTypes';
import { messageProxy } from '../../../../api/message';
import { actions as zonesActions } from '../../../../redux/api/zones';
import useLocalStorage from '../../../../misc/useLocalStorage';
import { setProps } from '../../../../misc/ol';
import { readMultiPolygon } from '../../../../misc/wkt';
import { makeStyle, StyleType } from '../../zones/zoneStyle';
import MapControls from '../../map/controls/MapControls';
import MapOptions from '../../map/controls/MapOptions';
import MapOptionLayers from '../../map/controls/MapOptionLayers';
import MapOptionZones from '../../map/controls/MapOptionZones';
import MapOptionGoogleSatMode from '../../map/controls/MapOptionGoogleSatMode';

const processLocations = (locations) => {
	const locationsByMarkers = { ...locations };
	const lines = [];

	const indexes = Object.keys(locations);
	let line = [];
	indexes.forEach(index => {
		if (line.length == 0) {
			line.push(index);
		} else if (line[line.length - 1] == index - 1) {
			line.push(index);
		} else {
			if (line.length < 2) {
				line = [index];
			} else {
				const _locations = [];
				line.forEach(i => {
					const position = line.indexOf(i);
					if (line.includes(i) && position != 0 && position != line.length -1) {
						delete locationsByMarkers[i];
					}
					_locations.push(locations[i]);
				});
				lines.push(_locations);
				line = [index];
			}
		}
	});
	if (line.length > 1) {
		const _locations = [];
		line.forEach(i => {
			const position = line.indexOf(i);
			if (line.includes(i) && position != 0 && position != line.length -1) {
				delete locationsByMarkers[i];
			}
			_locations.push(locations[i]);
		});
		lines.push(_locations);
	}
	return [lines, locationsByMarkers];
}

const MAP_NAME = 'check-location';

/**
 * locations = { id: location }.
 * "location" is an object that contains two required properties: "longitude" and "latitude".
 * If the object IDs are in ascending order, a line will be drawn until the order is interrupted.
 * @param {Object} props
 * @param {Object} props.locations
 * @param {function} [props.onClick]
 * @param {string} [props.title]
 * @param {DeviceDetailsProxy} [props.device]
 */

function CheckLocationMap(props) {
	const map = useRef(null);
	const layerGroup = useRef(null);
	const zoneLayerGroup = useRef(null);
	const [displayZones, setDisplayZones] = useLocalStorage(MAP_NAME + '_display_zones');
	const assetTypeCategoryMap = useSelector(state => state.categories.assetTypes.map);
	const [lines, locationsByMarkers] = processLocations(props.locations);

	useEffect(() => {
		if (lines && lines.length > 0) {
			const layers = [];
			lines.forEach(line => {
				const coordinates = [];
				line.forEach(location => {
					coordinates.push(fromEPSG4326([location.longitude, location.latitude]));
				});
				const geometry = new LineString(coordinates);
				const feature = new Feature({
					geometry,
					name: 'trace',
				});
				layers.push(
					new VectorLayer({
						source: new VectorSource({ features: [feature] }),
						style: [
							new Style({
								stroke: new Stroke({
									color: css.traceStrokeColor1,
									width: css.traceStrokeWidth1
								})
							})
							, new Style({
								stroke: new Stroke({
									color: css.traceStrokeColor2,
									width: css.traceStrokeWidth2
								})
							}),
							new Style({
								image: new Circle({
									radius: css.tracePointRadius,
									fill: new Fill({ color: css.tracePointFillColor }),
									stroke: new Stroke({
										color: css.traceStrokeColor1,
										width: css.tracePointStrokeWidth
									})
								}),
								geometry: function () {
									return new MultiPoint(coordinates);
								}
							})
						]
					})
				);
			});
			layerGroup.current = new LayerGroup({ layers });
			map.current.getOlMap().addLayer(layerGroup.current);
		}
		const locationValues = Object.values(props.locations);
		const coordinates = [];
		if (locationValues && locationValues.length > 0) {
			locationValues.forEach(location => {
				if (location) {
					coordinates.push(fromEPSG4326([location.longitude, location.latitude]));
				}
			});
		}
		if (coordinates.length == 1) {
			const location = locationValues[0];
			const center = fromEPSG4326([location.longitude, location.latitude]);
			getOwMap(MAP_NAME).focus(center, 16);
		}
		if (coordinates.length > 1) {
			getOwMap(MAP_NAME).fitExtent(boundingExtent(coordinates));
		}
		return () => {
			if (map.current) {
				map.current.getOlMap().removeLayer(layerGroup.current);
				layerGroup.current = null;
			}
		};
	}, [props.locations]);

	useEffect(() => {
		if (props.zones.map != null) {
			const zones = Object.values(props.zones.map);
			const layers = {};
			zones.forEach(zone => {
				const layer = new VectorLayer({
					source: new VectorSource({
						features: setProps(readMultiPolygon(zone.geometry), {
							zoneId: zone.zoneId,
							name: zone.name,
							style: zone.style
						})
					}),
					style: makeStyle(StyleType.Default),
				});
				layer.setProperties({ zoneId: zone.zoneId });
				layers[zone.zoneId] = layer;
			});
			zoneLayerGroup.current = new LayerGroup({ layers: Object.values(layers) });
			map.current.getOlMap().addLayer(zoneLayerGroup.current);
			zoneLayerGroup.current.setVisible(displayZones);
		}
	}, [props.zones]);

	useEffect(() => {
		if (props.zones.map == null) {
			props.dispatch(zonesActions.load.request());
		}
		if (zoneLayerGroup.current != null) {
			zoneLayerGroup.current.setVisible(displayZones);
		}
	}, [displayZones]);

	let markers = null;
	if (locationsByMarkers) {
		const assetType = props.device && getAssetType(props.device.categoryIds, assetTypeCategoryMap);
		markers = [];
		Object.keys(locationsByMarkers).forEach(index => {
			const message = messageProxy(locationsByMarkers[index]);
			if (message && message.hasLonLat()) {
				const onClick = () => {
					if (props.onClick) props.onClick(locationsByMarkers[index]);
				}
				markers.push(
					<MapMarker
						key={index}
						className={className(assetType && assetType.getType())}
						map={map.current}
						onClick={onClick}
						title={props.title ? props.title : 'No. ' + index}
						coordinates={message.lonLat()}
					/>
				);
			};
		});
	}

	return (
		<Map
			className="fleet"
			name={MAP_NAME}
			ref={map}
			baseLayer={Map.Layers.GOOGLE}
			controls={
				<MapControls>
					<MapOptions>
						<MapOptionLayers mapName={MAP_NAME} />
						<MapOptionGoogleSatMode mapName={MAP_NAME} />
						<MapOptionZones value={displayZones} onChange={setDisplayZones} />
					</MapOptions>
					{props.controls}
				</MapControls>
			}
		>
			{markers}
		</Map>
	);
}

export default connect(state => ({
	zones: state.zones
}))(CheckLocationMap);