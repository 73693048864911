import React from 'react';
import MapControls from '../../map/controls/MapControls';
import MapOptions from '../../map/controls/MapOptions';
import MapOptionLayers from '../../map/controls/MapOptionLayers';
import MapOptionZones from '../../map/controls/MapOptionZones';
import MapOptionWidgets from '../../map/controls/MapOptionWidgets';
import MapSearch from '../../map/controls/MapSearch';
import TimeMachineWidgetSelector from '../widgets/TimeMachineWidgetSelector';
import MapWidgetsControl from '../../widgets/MapWidgetsControl';
import DashboardMapButton from '../../dashboard/map/DashboardMapButton';
import MapOptionDisplayTrace from '../../map/controls/MapOptionDisplayTrace';
import MapOptionGoogleSatMode from '../../map/controls/MapOptionGoogleSatMode';
import MapOptionGoogleTraffic from '../../map/controls/MapOptionGoogleTraffic';
import MapOptionDisplayTraceDirection from '../../map/controls/MapOptionDisplayTraceDirection';
import MapOptionDisplayEvents from '../../map/controls/MapOptionDisplayEvents';
import { DefaultViewMapOption } from 'components/molecules/map/default-view-map-option';

/**
 * @param {Object} props
 * @param {function} props.focusExtent
 * @param {boolean} props.displayZones
 * @param {function} props.setDisplayZones
 * @param {function} props.saveMapView
 */

function TimeMachineMapControls(props) {

	const toggleDisplayZones = () => {
		props.setDisplayZones(!props.displayZones);
	}

	return (
		<MapControls>
			<MapOptions>
				<MapOptionLayers mapName={props.mapName} />
				<MapOptionGoogleSatMode mapName={props.mapName} />
				<MapOptionGoogleTraffic mapName={props.mapName} />
				<MapOptionZones value={props.displayZones} onChange={toggleDisplayZones} />
				<MapOptionWidgets />
				<MapOptionDisplayEvents />
				<MapOptionDisplayTrace />
				<MapOptionDisplayTraceDirection />
				<DefaultViewMapOption saveMapView={props.saveMapView}/>
			</MapOptions>
			<MapSearch onReady={props.focusExtent} />
			<MapWidgetsControl>
				<TimeMachineWidgetSelector />
			</MapWidgetsControl>
			<DashboardMapButton />
		</MapControls>
	)
}

export default TimeMachineMapControls;
