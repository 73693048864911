import { getSize as extentSize } from 'ol/extent';
import GeometryType from 'ol/geom/GeometryType';

export const setProps = (objects, props) => {
	objects.forEach(object => object.setProperties(props));
	return objects;
}

export const isExtentFinite = (extent) => {
	const size = extentSize(extent);
	return Math.abs(size[0]) != Infinity && Math.abs(size[1]) != Infinity;
}

export const isLineString = (geometry) => {
	return geometry.getType() == GeometryType.LINE_STRING;
}

export const isPolygon = (geometry) => {
	return geometry.getType() == GeometryType.POLYGON;
}

/**
 * @param {Object} geometry
 */
export const plainCoordinates = (geometry) => {
	if (isLineString(geometry)) {
		return geometry.getCoordinates();
	} else { // polygon
		return geometry.getCoordinates()[0]; // first ring
	}
}