import React, { useState, useEffect, useMemo } from 'react';
import { useI18n } from '../../../../../i18n';
import Form from '../../../general/form/Form';
import PropertyUnitsPicker from './PropertyUnitsPicker';
import DatePicker from '../../../general/form/DatePicker';
import DateTimePicker from '../../../general/form/DateTimePicker';
import RecordElementsControl from './RecordElementsControl';
import VectorElementControl from './VectorElementControl';
import TagPicker from '../../../general/form/TagPicker';
import NumberPicker from '../../../general/form/NumberPicker';
import BundleElementsControl from './BundleElementsControl';

// ------------------- auxiliary components -------------------

/**
 * @param {Object} props
 * @param {cx.ods.meta.ComposableProperty} props.property
 * @param {boolean} [props.integer]
 * @param {Object} [props.config]
 * {
 *		minimum: {
 *			 name:,
 *			 label:,
 *			 validator?:
 *		},
 *		maximum: --//--
 *	}
 */

function NumberRangeFields(props) {
	const { f } = useI18n();
	const [minimum, setMinimum] = useState(null);
	const [maximum, setMaximum] = useState(null);

	const minimumName = props.config ? props.config.minimum.name : 'minimum';
	const maximumName = props.config ? props.config.maximum.name : 'maximum';

	useEffect(() => {
		setMinimum(props.property && props.property[minimumName] != null ? props.property[minimumName] : null);
		setMaximum(props.property && props.property[maximumName] != null ? props.property[maximumName] : null);
	}, [props.property]);

	return (
		<Form.ControlGroup>
			<Form.Control
				controlType={Form.Control.Type.InputNumber}
				controlName={minimumName}
				controlValidator={(value, values, number) => {
					if (number != null) {
						if (props.integer && !/^-?(\d+)$/.test(value)) return f('please enter an integer value');
						else if (values.maximum != null && number > values.maximum) return f('please enter a value not greater then maximum');
						else if (props.config && props.config.minimum.validator) return props.config.minimum.validator(number);
					}
				}}
				label={props.config ? props.config.minimum.label : f('minimum')}
				step={props.integer ? 1 : 0.1}
				value={minimum}
				onChange={setMinimum}
			/>
			<Form.Control
				controlType={Form.Control.Type.InputNumber}
				controlName={maximumName}
				controlValidator={(value, values, number) => {
					if (number != null) {
						if (props.integer && !/^-?(\d+)$/.test(value)) return f('please enter an integer value');
						else if (values.minimum != null && number < values.minimum) return f('please enter a value not less than minimum');
						else if (props.config && props.config.minimum.validator) return props.config.maximum.validator(number);
					}
				}}
				label={props.config ? props.config.maximum.label : f('maximum')}
				step={props.integer ? 1 : 0.1}
				value={maximum}
				onChange={setMaximum}
			/>
		</Form.ControlGroup>
	);
}

/**
 * @param {Object} props
 * @param {cx.ods.meta.ComposableProperty} props.property
 * @param {boolean} [props.onlyDate]
 */

function DatetimeRangeFields(props) {
	const { f } = useI18n();
	const [minimum, setMinimum] = useState(null);
	const [maximum, setMaximum] = useState(null);

	useEffect(() => {
		setMinimum(props.property && props.property.minimum != null ? props.property.minimum : null);
		setMaximum(props.property && props.property.maximum != null ? props.property.maximum : null);
	}, [props.property]);

	return (
		<Form.ControlGroup>
			<Form.Control
				controlType={props.onlyDate ? DatePicker : DateTimePicker}
				controlName="minimum"
				label={f('minimum')}
				value={minimum}
				onChange={setMinimum}
				disabledDate={(date) => maximum && date.getTime() > maximum.getTime()}
			/>
			<Form.Control
				controlType={props.onlyDate ? DatePicker : DateTimePicker}
				controlName="maximum"
				label={f('maximum')}
				value={maximum}
				onChange={setMaximum}
				disabledDate={(date) => minimum && date.getTime() < minimum.getTime()}
			/>
		</Form.ControlGroup>
	);
}

// --------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {cx.ods.meta.Property} props.property
 * @param {boolean} [props.nested=false]
 */

export function CommonFields(props) {
	const { f } = useI18n();
	const [name, setName] = useState('');
	const [label, setLabel] = useState('');
	const [description, setDescription] = useState('');
	const [required, setRequired] = useState(false);

	useEffect(() => {
		setName(props.property && props.property.name ? props.property.name : '');
		setLabel(props.property && props.property.label ? props.property.label : '');
		setDescription(props.property && props.property.description ? props.property.description : '');
		setRequired(props.property && props.property.required !== undefined ? props.property.required : false);
	}, [props.property]);

	return (
		<Form.ControlGroup>
			<Form.Control
				controlType={Form.Control.Type.Input}
				controlName="name"
				label={f('name')}
				value={name}
				onChange={setName}
			/>
			<Form.Control
				controlType={Form.Control.Type.Input}
				controlName="label"
				label={f('label')}
				value={label}
				onChange={setLabel}
			/>
			<Form.Control
				controlType={Form.Control.Type.Input}
				controlName="description"
				label={f('description')}
				value={description}
				onChange={setDescription}
			/>
			{props.nested &&
				<Form.Control
					controlType={Form.Control.Type.Checkbox}
					controlName="required"
					label={f('required')}
					value={required}
					onChange={setRequired}
				/>
			}
		</Form.ControlGroup>
	);
}

/**
 * @param {Object} props
 * @param {cx.ods.meta.ComposableProperty} props.property
 */

export function IntegerFields(props) {
	const { f } = useI18n();
	const [unitId, setUnitId] = useState(null);
	const [enumeration, setEnumeration] = useState(null);

	useEffect(() => {
		setUnitId(props.property && props.property.unitId ? props.property.unitId : null);
		setEnumeration(props.property && props.property.enumeration ? props.property.enumeration : null);
	}, [props.property]);

	return (
		<Form.ControlGroup>
			<Form.Control
				controlType={PropertyUnitsPicker}
				controlName="unitId"
				label={f('units')}
				placeholder={f('no selected units')}
				value={unitId}
				onChange={setUnitId}
			/>
			<NumberRangeFields property={props.property} integer />
			<Form.Control
				controlType={NumberPicker}
				controlName="enumeration"
				controlValidator={(value, values, numbers) => {
					if (value != null) {
						if (values.minimum != null || values.maximum !== null) {
							return "Please specify either (\"Minimum\", \"Maxiumum\") or \"Allowed Values\"";
						} else if (numbers.some(number => isNaN(number) || !Number.isInteger(number))) {
							return f('please enter only integer numbers');
						}
					}
				}}
				label={f('allowed values')}
				value={enumeration}
				onChange={setEnumeration}
				creatable
			/>
		</Form.ControlGroup>
	);
}

/**
 * @param {Object} props
 * @param {cx.ods.meta.ComposableProperty} props.property
 */

export function DecimalFields(props) {
	const { f } = useI18n();
	const [unitId, setUnitId] = useState(null);
	const [enumeration, setEnumeration] = useState(null);

	useEffect(() => {
		setUnitId(props.property && props.property.unitId ? props.property.unitId : null);
		setEnumeration(props.property && props.property.enumeration ? props.property.enumeration : null);
	}, [props.property]);

	return (
		<Form.ControlGroup>
			<Form.Control
				controlType={PropertyUnitsPicker}
				controlName="unitId"
				label={f('units')}
				value={unitId}
				onChange={setUnitId}
			/>
			<NumberRangeFields property={props.property} />
			<Form.Control
				controlType={NumberPicker}
				controlName="enumeration"
				controlValidator={(value, values, numbers) => {
					if (value != null) {
						if (values.minimum != null || values.maximum !== null) {
							return f("please specify either (\"minimum\", \"maxiumum\") or \"allowed values\"");
						} else if (numbers.some(number => isNaN(number))) {
							return f("please enter only numbers");
						}
					}
				}}
				label={f('allowed values')}
				value={enumeration}
				onChange={setEnumeration}
				creatable
			/>
		</Form.ControlGroup>
	);
}

/**
 * @param {Object} props
 * @param {cx.ods.meta.ComposableProperty} props.property
 */

export function BooleanFields(props) {
	const { f, fc } = useI18n();
	const [enumeration, setEnumeration] = useState(null);

	useEffect(() => {
		setEnumeration(props.property && props.property.enumeration ? props.property.enumeration : null);
	}, [props.property]);

	return (
		<Form.ControlGroup>
			<Form.Control
				controlType={TagPicker}
				controlName="enumeration"
				label={f('allowed values')}
				value={enumeration}
				onChange={setEnumeration}
				data={[
					{ label: fc('true'), value: true },
					{ label: fc('false'), value: false }
				]}
			/>
		</Form.ControlGroup>
	);
}

/**
 * @param {Object} props
 * @param {cx.ods.meta.ComposableProperty} props.property
 */

export function DatetimeFields(props) {
	return <DatetimeRangeFields property={props.property} />;
}

/**
 * @param {Object} props
 * @param {cx.ods.meta.ComposableProperty} props.property
 */

export function DateFields(props) {
	return <DatetimeRangeFields property={props.property} onlyDate />;
}

/**
 * @param {Object} props
 * @param {cx.ods.meta.ComposableProperty} props.property
 */

export function TextFields(props) {
	const { f } = useI18n();
	const [enumeration, setEnumeration] = useState(null);

	const enumerationOptions = useMemo(
		() => (props.property?.enumeration ? props.property.enumeration.map((item) => ({ label: item, value: item })) : []),
		[props.property],
	);

	useEffect(() => {
		setEnumeration(props.property && props.property.enumeration ? props.property.enumeration : null);
	}, [props.property]);

	return (
		<Form.ControlGroup>
			<NumberRangeFields
				property={props.property}
				integer
				config={{
					minimum: {
						name: "minimumLength",
						label: f('minimum length'),
						validator: (value) => value <= 0 && f('please enter a positive value')
					},
					maximum: {
						name: "maximumLength",
						label: f('maximum length'),
						validator: (value) => value <= 0 && f('please enter a positive value')
					}
				}}
			/>
			<Form.Control
				controlType={TagPicker}
				controlName="enumeration"
				controlValidator={(value, values) => {
					if (value != null) {
						if (values.minimumLength != null && value.some(string => string.length < values.minimumLength)) {
							return f("please enter values not shorter than \"minimum length\"");
						} else if (values.maximumLength != null && value.some(string => string.length > values.maximumLength)) {
							return f("please enter values not longer than \"maximum length\"");
						}
					}
				}}
				label={f('allowed values')}
				data={enumerationOptions}
				value={enumeration}
				onChange={setEnumeration}
				creatable
			/>
		</Form.ControlGroup>
	);
}

/**
 * @param {Object} props
 * @param {cx.ods.meta.ComposableProperty} props.property
 */

export function VectorFields(props) {
	const { f } = useI18n();
	return (<>
		<NumberRangeFields
			property={props.property}
			integer
			config={{
				minimum: {
					name: "minimumArity",
					label: f('minimum arity'),
					validator: (value) => value < 1 && f('please enter a number not less than', { value: 1})
				},
				maximum: {
					name: "maximumArity",
					label: f('maximum arity'),
					validator: (value) => value < 1 && f('please enter a number not less than', { value: 1})
				},
			}}
		/>
		<VectorElementControl vector={props.property} />
	</>);
}

/**
 * @param {Object} props
 * @param {cx.ods.meta.PropertyRecord} props.property
 */

export function RecordFields(props) {
	const { f } = useI18n();
	return (<>
		<NumberRangeFields
			property={props.property}
			integer
			config={{
				minimum: {
					name: "minimumArity",
					label: f('minimum arity'),
					validator: (value) => value < 1 && f('please enter a number not less than', { value: 1})
				},
				maximum: {
					name: "maximumArity",
					label: f('maximum arity'),
					validator: (value) => value < 1 && f('please enter a number not less than', { value: 1})
				},
			}}
		/>
		<RecordElementsControl record={props.property} />
	</>);
}

/**
 * @param {Object} props
 * @param {cx.ods.meta.PropertyBundle} props.property
 */

export function BundleFields(props) {
	const { f } = useI18n();
	return (<>
		<NumberRangeFields
			property={props.property}
			integer
			config={{
				minimum: {
					name: "minimumArity",
					label: f('minimum arity'),
					validator: (value) => value < 1 && f('please enter a number not less than', { value: 1})
				},
				maximum: {
					name: "maximumArity",
					label: f('maximum arity'),
					validator: (value) => value < 1 && f('please enter a number not less than', { value: 1})
				},
			}}
		/>
		<BundleElementsControl bundle={props.property} />
	</>);
}
