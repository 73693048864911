import React, { useEffect } from 'react';
import { useI18n } from '../../../../../i18n';
import { className, fromProps } from '../../../../lib/className';
import { connect } from 'react-redux';
import { meta } from '../../../../redux/api/meta';
import './propertyElement.scss';

/**
 * @param {Object} props
 * @param {cx.ods.meta.ComposableProperty} props.element
 * @param {function} [props.onClick]
 */

function PropertyElement(props) {
	const { f } = useI18n();

	useEffect(() => {
		if (props.datatypes.map == null && !props.datatypes.pending) {
			props.dispatch(meta.datatypes.actions.datatypes.request());
		}
	}, []);


	return (
		<div
			ref={props.customRef}
			className={className('property-element', fromProps(props), { 'clickable': !!props.onClick })}
			onClick={props.onClick}
		>
			<div className="name">{props.element.label || props.element.name}</div>
			<div className="data-type">
				{props.datatypes.map
					&& props.datatypes.map[props.element.datatypeId]
					&& f(props.datatypes.map[props.element.datatypeId].name)
				}
			</div>
		</div>
	);
}

export default connect(state => {
	return {
		datatypes: state.meta.datatypes
	}
})(PropertyElement);