import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Menu, MenuItem } from "@blueprintjs/core";
import { useI18n } from '../../../../../i18n';
import { cx } from '../../../../api';
import { className, fromProps } from '../../../../lib/className';
import { actions as contextActions } from '../../../../redux/app/context';
import Popup from '../../../general/Popup';
import ActionBar from '../../../general/actionbar/ActionBar';
import icon from '../../../../img/icons/plus-bound.png';
import '../../../general/actionbar/actionBar.scss';
import './deviceActionAdd.scss';

const UID = "device-action-add";

/**
 * @param {Object} props
 * @param {number} props.groupingId
 */

function DeviceActionAdd(props) {
	const { f, fc } = useI18n();
	const [isOpen, setIsOpen] = useState(false);
	let menu = null;

	const clickMenuItem = (id, name) => {
		props.dispatch(contextActions.actionSet({
			actionType: 'edit',
			name: 'category',
			data: {
				comprisingId: id,
				offsetTop: cx.dom.at.client(document.getElementById(UID)).top,
				title: name,
				standalone: true
			}
		}));
	}

	if (props.groupings.root && props.groupingId) {
		const rootCategoryId = props.groupings.root.categoryId;
		menu = (
			<Menu>
				<MenuItem onClick={() => clickMenuItem(rootCategoryId, "scheme")} text={fc('create scheme')} />
				<MenuItem onClick={() => clickMenuItem(props.groupingId, "group")} text={fc('create group')} />
			</Menu>
		);
	}
	return (
		<ActionBar.Action
			id={UID}
			className={className('device-add', fromProps(props))}
			title={f('create')}
		>
			<Popup
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				content={menu}
				disabled={!props.groupings.root || !props.groupingId}
				className="device-action-add"
			>
				<img src={icon} alt="" />
			</Popup>
		</ActionBar.Action>
	);
}

export default connect(
	state => ({
		groupings: state.categories.groupings
	})
)(DeviceActionAdd);
