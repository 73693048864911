import tinycolor from 'tinycolor2';

const getRandom = (min, max) => {
	return Math.random() * (max - min) + min;
}

const getRandomInt = (min, max) => {
	min = Math.ceil(min); max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const getRandomColor = () => {
	return tinycolor.random().toHexString();
}

export const getViewableColor = (hue) => {
	return tinycolor("hsv(" + (hue || getRandom(20, 340)) + "," + getRandomInt(50, 100) + "%," + getRandomInt(50, 100) + "%)").toHexString();
}

export const getDistinctColor = (colors) => {
	let result = null;
	if (colors && colors.length > 0) {
		const hues = colors.map(color => tinycolor(color).toHsv().h);
		hues.sort((left, right) => left - right);
		hues.unshift(0); hues.push(360);
		let maxInterval = hues[1] - hues[0], maxAt = 0;
		for (let at = 1; at < hues.length; ++at) {
			const hue = hues[at], nextHue = hues[at+1];
			if (nextHue && nextHue - hue > maxInterval) {
				maxInterval = nextHue - hue;
				maxAt = at;
			}
		}
		result = getViewableColor(hues[maxAt] + maxInterval / 2);
	} else {
		result = getViewableColor();
	}

	return result;
}