import React from 'react';
import { connect } from 'react-redux';
import moment from "moment";

import Loader from '../../../general/Loader';
import Address from '../../../general/location/Address';
import DeviceStatusIcon from '../../../share/icons/DeviceStatusIcon';
import GpsFixIcon from '../../../share/icons/GpsFixIcon';
import IgnitionIcon from '../../../share/icons/IgnitionIcon';
import PinIcon from '../../../../img/icons/pin-full.png';
import DeviceAssetTypeIcon from '../../../share/devices/DeviceAssetTypeIcon';
import { Link } from 'react-router-dom';
import { FiSliders } from 'react-icons/fi';
import { DeviceDetailsProxy } from '../../../../api/device'; // eslint-disable-line
import { rootLogger as logger } from '../../../../lib/log';
import { DeviceExpirationIcon } from 'core/react/share/icons/device-expiration-icon';

/**
 * @param {Object} props
 * @param {DeviceDetailsProxy} props.device
 * @param {function} props.togglePin
 * @param {boolean} [props.selected]
 * @param {string} [props.reduxKey]
 * @param {boolean} [props.pinned]
 */

function DeviceListItemContent(props) {
	const { device, togglePin, pinned, deviceStates } = props;
	const displayDetails = props.reduxKey != 'timeMachine' || props.selected;

	const location = () => {
		if (deviceStates.map != null) {
			const deviceState = deviceStates.map[device.uri];
			const message = deviceState ? deviceState.message : null;
			return (<Address message={message} showIcon />);
		} else if (deviceStates.pending) {
			return (<Loader />);
		}
	}

	const icons = () => {
		let _icons = [];
		if (deviceStates.map != null) {
			const deviceState = deviceStates.map[device.uri];
			const message = deviceState ? deviceState.message : null;
			const isExpired = moment(device.expiresAt).isSameOrBefore(moment());
			const isExpiresSoon = moment(device.expiresAt).isSameOrBefore(moment().add(1, 'M'));

			!!device?.expiresAt &&
			  (isExpired || isExpiresSoon) &&
			  _icons.push(<DeviceExpirationIcon key="expiration" isExpired={isExpired} expiresAt={device.expiresAt} />);
			_icons.push(<DeviceStatusIcon key="status" uri={device.uri} reduxKey={props.reduxKey} />);
			_icons.push(<IgnitionIcon key="ignition" message={message} />);
			_icons.push(<GpsFixIcon key="gnss" message={message} />);
		}
		return _icons;
	}

	return (
		<div className="device-list-item-content">
			<div className="wrap">
				{pinned &&
					<div className="pin-block" onClick={togglePin}>
						<img className="pin-icon" alt="" src={PinIcon} />
					</div>
				}
				<div className="icon">
					<DeviceAssetTypeIcon categoryIds={device.categoryIds} />
				</div>
				<div className="content">
					<div>{device.denomination()}</div>
					{displayDetails && <>
						<div>{location()}</div>
						<div className="icons">{icons()}</div>
					</>}
				</div>
			</div>
			{props.selected &&
				<Link className="action" to={'/device/' + props.device.uri + '/control-panel'}><FiSliders /></Link>
			}
		</div>
	);
}

export default connect(
	(state, props) => {
		let deviceStates = null;
		if (props.reduxKey && props.reduxKey == 'timeMachine') {
			deviceStates = state.timeMachine.state;
		} else {
			if (!props.reduxKey) logger.warning('DeviceListItemContent: reduxKey is not defined');
			deviceStates = state.deviceStates;
		}
		return { deviceStates };
	}
)(DeviceListItemContent);
