import { ofType, combineEpics } from 'redux-observable'
import { map as rxmap, filter } from 'rxjs/operators';
import { actions as sessionActions } from "../api/session"
import { localStorage as storage } from "../../app/storage";
import { ActionGeneratorBuilder } from '../actions';

const defaultState = {};

const defaultSubstate = {
	display: true,
	filter: null, // TODO
	allEvents: true, // boolean
	selectedEventsUris: [], // [ uri ]
	eventsUriMap: {} // { uri: boolean }
};

const actions = new ActionGeneratorBuilder('appDeviceEvents')
	.type('setDisplayEvents', { domain: true, display: true })
	.type('setEventsUris', { domain: true, uris: true })
	.type('setEventsFilter', { domain: true, filter: true })
	.type('toggleEventsUri', { domain: true, uri: true })
	.type('setAllEvents', { domain: true, all: false })
	.type('restoreState', { state: true })
	.build()
;

// ---------------------------------------------------------

const reducer = (state = defaultState, action) => {
	let substate = action.domain ? (state[action.domain] || defaultSubstate) : null;
	switch (action.type) {
		case actions.setDisplayEvents.type:
			return {
				...state,
				[action.domain]: {
					...substate,
					display: action.display
				}
			};
		case actions.setEventsUris.type:
			const eventsUriMap = {};
			const selectedEventsUris = [];
			action.uris.forEach(uri => {
				if (substate.eventsUriMap[uri] != undefined) {
					eventsUriMap[uri] = substate.eventsUriMap[uri];
				} else {
					eventsUriMap[uri] = substate.allEvents ? true : substate.eventsUriMap[uri];
				}
				if (eventsUriMap[uri]) selectedEventsUris.push(uri);
			});
			return {
				...state,
				[action.domain]: {
					...substate,
					eventsUriMap,
					selectedEventsUris
				}
			};
		case actions.setEventsFilter.type:
			return {
				...state,
				[action.domain]: {
					...substate,
					filter: action.filter
				}
			};
		case actions.toggleEventsUri.type:
			substate.eventsUriMap[action.uri] = !substate.eventsUriMap[action.uri];
			const _selectedEventsUris = Object.keys(substate.eventsUriMap).filter(uri => substate.eventsUriMap[uri]);
			let _allEvents = _selectedEventsUris.length === Object.keys(substate.eventsUriMap).length;
			return {
				...state,
				[action.domain]: {
					...substate,
					selectedEventsUris: _selectedEventsUris,
					allEvents: _allEvents
				}
			};
		case actions.setAllEvents.type:
			const selectedUris = Object.keys(substate.eventsUriMap).filter(uri => {
				substate.eventsUriMap[uri] = action.all ? true : false;
				return action.all;
			});
			return {
				...state,
				[action.domain]: {
					...substate,
					allEvents: action.all,
					selectedEventsUris: selectedUris
				}
			}
		case actions.restoreState.type:
			return action.state;
		default:
			return state;
	}
}

// ---------------------------------------------------------

const STORAGE_KEY = 'appDeviceEvents';

const restoreEpic = (action$, state$) => {
	return action$.pipe(
		ofType(sessionActions.events.started.type),
		rxmap(action => {
			const currentState = storage.get(STORAGE_KEY);
			if (currentState != null) {
				const domainMap = state$.value.pages;
				Object.keys(currentState).forEach(domain => {
					if (domainMap[domain] && domainMap[domain].selection) {
						const newEventsUriMap = {};
						domainMap[domain].selection.forEach(uri => {
							newEventsUriMap[uri] = currentState[domain].allEvents ? true : currentState[domain].eventsUriMap[uri];
						});
						currentState[domain].eventsUriMap = newEventsUriMap;
						currentState[domain].selectedEventsUris = Object.keys(newEventsUriMap).filter(uri => newEventsUriMap[uri]);
					}
				});
				return actions.restoreState({ state: currentState });
			}
		}),
		filter(action => action != null)
	)
}

const updateEpic = (action$, state$) => {
	return action$.pipe(
		ofType(
			actions.setDisplayEvents.type, actions.setEventsUris.type,
			actions.setEventsFilter.type, actions.toggleEventsUri.type,
			actions.setAllEvents.type, actions.restoreState.type
		),
		filter(() => {
			storage.set(STORAGE_KEY, state$.value.appDeviceEvents);
			return false;
		})
	)
}

const epic = combineEpics(restoreEpic, updateEpic);

// ---------------------------------------------------------

export { actions, reducer, epic };
