import React from 'react';
import { useI18n } from '../../../../i18n';
import { FiBox } from 'react-icons/fi';
import './noDataView.scss';

/**
 * @param {Object} props
 * @param {React.Component} [props.icon]
 * @param {string} [props.message] default is "no data"
 */

function NoDataView(props) {
	const { f } = useI18n();
	const message = props.message ? props.message : 'no data';
	const Icon = props.icon ? props.icon : FiBox;

	return (
		<div className="no-data-view">
			<div><Icon /></div>
			<div><span className="capitalize">{f(message)}</span></div>
		</div>
	)
}

export default NoDataView;