import React, { useState, useEffect, useRef } from 'react';
import { useI18n } from '../../../../i18n';
import EditableLevelFields from './EditableLevelFields';
import CreateLevelFields from './CreateLevelFields';
import { className } from '../../../lib/className';
import { cx } from '../../../api';
import { useDrop } from 'react-dnd';
import { DragItemType } from '../../share/dnd/DragItemType';
import VoltageLevelsGraphView from './VoltageLevelsGraphView';
import './fuelLevelProcessorField.scss';

/**
 * @param {Object} props
 * @param {cx.ods.processors.VoltageLevel} props.voltageLevel
 * @param {number} props.index
 * @param {function} [props.onItemClick]
 * @param {function} [props.onEdit]
 * @param {function} [props.onRemove]
 * @param {boolean} [props.selected]
 * @param {boolean} [props.editable]
 * @param {boolean} [props.removable]
 */

function LevelItem(props) {

	const canDrop = (type) => {
		if (props.editable) {
			if (!type || type === DragItemType.ACTION_REMOVE && !props.removable) {
				return false;
			} else return true;
		} else return false;
	}

	const handleDrop = (item) => {
		if (!canDrop(item.type)) return;
		switch (item.type) {
			case DragItemType.ACTION_EDIT:
				props.onEdit(props.voltageLevel, props.index);
				break;
			case DragItemType.ACTION_REMOVE:
				props.onRemove(props.voltageLevel, props.index, true);
				break;
			default:
				return null;
		}
	}

	const [dropState, drop] = useDrop({
		accept: [DragItemType.ACTION_REMOVE, DragItemType.ACTION_EDIT],
		drop: item => handleDrop(item),
		collect: monitor => ({
			isOver: monitor.isOver(),
			itemType: monitor.getItemType()
		})
	});

	return (
		<div
			ref={drop}
			className={className("table-item", {
				'clickable': !!props.onItemClick && props.editable,
				'selected': props.selected,
				'droppable': dropState.isOver && canDrop(dropState.itemType)
			})}
			onClick={props.onItemClick}
		>
			<div>{props.voltageLevel.voltage}</div>
			<div>{props.voltageLevel.level}</div>
		</div>
	);
}

/**
 * @param {Object} props
 * @param {Array.<cx.ods.processors.VoltageLevel>} props.value
 * @param {function} props.onChange
 * @param {boolean} [props.editMode]
 * @param {boolean} [props.disabled]
 * @param {Array.<string>} [props.uris]
 */

function FuelLevelProcessorField(props) {
	const skipClearing = useRef(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [selectedLevel, setSelectedLevel] = useState();
	const [selectedLevelIndex, setSelectedLevelIndex] = useState(null);
	const { f, fc, pc } = useI18n();

	const clearAll = () => {
		if (!skipClearing.current) {
			clearLevel();
			clearLevelIndex();
			clearError();
		}
		if (skipClearing.current) skipClearing.current = false;
	}

	const clearError = () => {
		if (errorMessage) setErrorMessage(null);
	}

	const clearLevel = () => {
		if (selectedLevel) setSelectedLevel(null);
	}

	const clearLevelIndex = () => {
		if (selectedLevelIndex != null) setSelectedLevelIndex(null);
	}

	const isNegative = (value) => +value < 0;

	const processVoltageLevel = (value, voltageLevel, index) => {
		const copyValue = [ ...value ];
		const compareSort = (a, b) => {
			if (+a.voltage > +b.voltage) return 1;
			if (+a.voltage < +b.voltage) return -1;
			return 0;
		}
		if (index != null) copyValue[index] = voltageLevel;
		else copyValue.push(voltageLevel);

		copyValue.sort(compareSort);
		return copyValue.filter((level, i) => !(copyValue[i + 1] && level.voltage === copyValue[i + 1].voltage));
	}

	const onAdd = () => {
		setSelectedLevel({});
		clearLevelIndex();
	}

	const onEdit = (voltageLevel, index) => {
		if (index !== selectedLevelIndex) setSelectedLevelIndex(index);
		setSelectedLevel({ ...(voltageLevel || props.value[index]) });
	}

	const onSave = (voltageLevel, index) => {
		if (Object.values(voltageLevel).some(value => isNegative(value))) {
			setErrorMessage(fc('please enter a positive value'));
		} else if (+voltageLevel.level > 100) {
			setErrorMessage(pc('please enter a value not greater than', 100));
		} else {
			const newLevel = new cx.ods.processors.VoltageLevel(voltageLevel);
			const result = processVoltageLevel(props.value, newLevel, index);
			if (result.length < 2) {
				setErrorMessage(fc('please specify at least two entries with different voltages'));
			} else {
				props.onChange(result);
			}
		}
	}

	const onRemove = (voltageLevel, index, fromAction) => {
		if (fromAction && index !== selectedLevelIndex) {
			if (index < selectedLevelIndex) setSelectedLevelIndex(selectedLevelIndex - 1);
			skipClearing.current = true;
		}
		const copyValue = [ ...props.value ];
		copyValue.splice(index, 1);
		props.onChange(copyValue);
	}

	const onCreate = (levels) => {
		let result = props.value;
		levels.forEach(level => result = processVoltageLevel(result, level));
		props.onChange(result);
	}

	const onCancel = () => {
		clearError();
		setSelectedLevel(null);
	}

	useEffect(() => {
		clearAll();
	}, [props.value, props.editMode]);

	let content = props.value.map((voltageLevel, index) => {
		const onClick = () => {
			if (props.editMode && !selectedLevel) {
				setSelectedLevelIndex(selectedLevelIndex !== index ? index : null);
			}
		}
		return (
			<LevelItem
				key={voltageLevel.voltage + voltageLevel.level}
				index={index}
				voltageLevel={voltageLevel}
				onEdit={onEdit}
				onRemove={onRemove}
				selected={index === selectedLevelIndex}
				onItemClick={!selectedLevel ? onClick : null}
				editable={props.editMode}
				removable={props.value.length > 2}
			/>
		);
	});

	return (
		<div className="fuel-level-processor-field">
			<div className="header">
				<label>{f('voltage levels')}</label>
			</div>
			<div className="content">
				{props.value && props.value.length > 0
					? <>
						<VoltageLevelsGraphView levels={props.value} />
						<div className="table">
							<div className={"table-item"}>
								<div><label>{fc('voltage')}</label>, {fc({ prefix: 'units', id: 'V' })}</div>
								<div><label>{fc('level')}</label>, {fc({ prefix: 'units', id: '%' })}</div>
							</div>
							{content}

						</div>
						{props.editMode
							&& <EditableLevelFields
								onAdd={onAdd}
								onEdit={onEdit}
								onSave={onSave}
								onCancel={onCancel}
								onRemove={onRemove}
								uris={props.uris}
								removable={props.value.length > 2}
								voltageLevel={selectedLevel}
								index={selectedLevelIndex}
							/>
						}
					</>
					: <CreateLevelFields onCreate={onCreate} uris={props.uris} />
				}
				{errorMessage && <div className="error">{errorMessage}</div>}
			</div>
		</div>
	);
}

export default FuelLevelProcessorField;