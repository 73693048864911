import React from 'react';
import ObjectDeviceManager from '../../share/devices/ObjectDeviceManager';
import ObjectZoneManager from '../../share/zones/ObjectZoneManager';
import './zoneGuardView.scss';

/**
 * @param {Object} props
 * @param {Object} [props.zoneGuard]
 * @param {boolean} [props.editMode]
 * @param {boolean} [props.disabled]
 */

function ZoneGuardView(props) {
	let content = null;

	if (props.zoneGuard || props.editMode) {
		content = (
			<div className="zone-guard body">
				<ObjectDeviceManager
					uris={props.zoneGuard ? props.zoneGuard.uris : null}
					disabled={props.disabled}
					editMode={props.editMode}
				/>
				<ObjectZoneManager
					zoneIds={props.zoneGuard ? props.zoneGuard.zoneIds : null}
					disabled={props.disabled}
					editMode={props.editMode}
				/>
			</div>
		);
	}

	return (content);
}

export default ZoneGuardView;