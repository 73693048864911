import React from 'react';
import { useI18n } from '../../../../i18n';
import './deviceStatusDetectorCards.scss';

/**
 * @param {Object} props
 * @param {cx.ods.processors.DeviceStatusDetector} props.detector
 */

function DeviceStatusDetectorCard({ detector }) {
	const { f } = useI18n();

	return (
		<div className="device-status-detector card">
			<div className="name">
				<label>{f('name')}</label>
				<div>{detector.name}</div>
			</div>
			{detector.onlineTimeout && (
				<div className="name">
					<label className="inline">{`${f('online timeout')}: `}</label>
					{`${detector.onlineTimeout} ${f({ prefix: 'units', id: 's' })}`}
				</div>
			)}
			{detector.downTimeout && (
				<div className="name">
					<label className="inline">{`${f('down timeout')}: `}</label>
					{`${detector.downTimeout} ${f({ prefix: 'units', id: 'min' })}`}
				</div>
			)}
		</div>
	);
}

export default DeviceStatusDetectorCard;
