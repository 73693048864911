import React, { useContext, useState, useEffect, useRef } from 'react';
import { className, fromProps } from '../../../../../lib/className';
import { toEPSG4326 } from 'ol/proj/epsg3857';
import { useI18n } from '../../../../../../i18n';
import { isCtrlPressed, isLeftButton, isRightButton } from '../../../../../misc/misc';
import LonLatForm from './LonLatForm';
import { SelectedPointContext } from '../ZoneEditor';
import Button from '../../../../general/form/Button';
import ButtonGroup from '../../../../general/form/ButtonGroup';
import { FiArrowLeft, FiArrowRight, FiPlus } from 'react-icons/fi';
import iconTrash from '../../../../../img/icons/trash.png';

/**
 * @param {Object} props
 * @param {import("../../model/ZoneArea")} props.area
 */

function ZoneAreaEditor(props) {
	const { f } = useI18n();
	const [serial, setSerial] = useState(0);
	const selectedPointContext = useContext(SelectedPointContext);
	const point = props.area.point(selectedPointContext.pointAt);
	const lonLat = point != null ? toEPSG4326(point) : null;
	const lonInputRef = useRef(null);

	useEffect(() => {
		if (props.area.pointCount() > 0 && selectedPointContext.pointAt == null) {
			selectedPointContext.set(0);
		}
	}, []);

	const onApply = (lonLat, source) => {
		props.area.modifyPoint(selectedPointContext.pointAt, lonLat);
		if (source != 'button') {
			let at = selectedPointContext.pointAt+1;
			if (at >= props.area.pointCount()-1) at = 0;
			selectedPointContext.set(at);
		}
	}

	const onRemove = () => {
		props.area.removePoint(selectedPointContext.pointAt);
		let at = selectedPointContext.pointAt;
		const count = props.area.pointCount();
		if (count > 0) {
			if (at > 0) at -= 1;
		} else {
			at = null;
		}
		selectedPointContext.set(at);
	}

	const onPrev = () => {
		let at = selectedPointContext.pointAt-1;
		if (at < 0) at = props.area.pointCount()-2;
		selectedPointContext.set(at);
	}

	const onNext = () => {
		let at = selectedPointContext.pointAt+1;
		if (at > props.area.pointCount()-2) at = 0;
		selectedPointContext.set(at);
	}

	const onInsertBefore = () => {
		const point = props.area.point(selectedPointContext.pointAt);
		let prevAt = selectedPointContext.pointAt-1;
		if (prevAt < 0) prevAt = props.area.pointCount()-2;
		const prevPoint = props.area.point(prevAt);
		const x = (point[0] + prevPoint[0]) / 2;
		const y = (point[1] + prevPoint[1]) / 2;
		props.area.insertPoint(selectedPointContext.pointAt, toEPSG4326([x, y]));
	}

	const onInsertAfter = () => {
		const point = props.area.point(selectedPointContext.pointAt);
		let nextAt = selectedPointContext.pointAt+1;
		if (nextAt > props.area.pointCount()-2) nextAt = 0;
		const nextPoint = props.area.point(nextAt);
		const x = (point[0] + nextPoint[0]) / 2;
		const y = (point[1] + nextPoint[1]) / 2;
		props.area.insertPoint(selectedPointContext.pointAt+1, toEPSG4326([x, y]));
		selectedPointContext.set(selectedPointContext.pointAt+1);
	}

	useEffect(() => {
		if (selectedPointContext.pointAt != null) {
			lonInputRef.current.focus();
		}
		const onKeydown = (event) => {
			if (isCtrlPressed(event)) {
				if (isLeftButton(event)) {
					onPrev();
				} else if (isRightButton(event)) {
					onNext();
				}
			}
		}
		document.body.addEventListener('keydown', onKeydown);
		return () => {
			document.body.removeEventListener('keydown', onKeydown);
		}
	}, [selectedPointContext.pointAt]);

	useEffect(() => {
		const onChanged = (old, point, at) => {
			if (at != selectedPointContext.pointAt) {
				selectedPointContext.set(at);
			} else {
				setSerial(serial+1);
			}
		}
		const observable = props.area.featureObservable();
		observable.addObserver(observable.events.pointChanged, onChanged);
		return () => {
			observable.removeObserver(observable.events.pointChanged, onChanged);
		}
	}, [props.area, serial, selectedPointContext.pointAt]);

	// summary

	const pointsSummary = (
		<div>
			<span className="caption">{f('points count')}: </span>
			<span>{props.area.pointCount() - (props.area.building() ? 0 : 1)}</span>
		</div>
	);

	let areaSummary = null;
	if (!props.area.building()) {
		let area = props.area.square();
		let units = 'm';
		if (area > 10000) {
			area = Math.round(area / 1000000 * 100) / 100;
			units = 'km';
		}
		areaSummary = (
			<div>
				<span className="caption">{f('square area')}: </span>
				<span>{area} {units}<sup>2</sup></span>
			</div>
		);
	}

	return (
		<div className={className('zone-area-editor', fromProps(props))}>
			<div className="summary">
				{pointsSummary}
				{areaSummary}
			</div>
			{selectedPointContext.pointAt !== null && <>
				<div>
					<span className="caption">{f('selected point')}: </span>
					<span>#{selectedPointContext.pointAt+1}</span>
				</div>
				<ButtonGroup className="toolbar">
					<Button
						onClick={onPrev}
						disabled={selectedPointContext.pointAt === null}
						title={f('select previous point')}
					>
						<FiArrowLeft />
					</Button>
					<Button
						onClick={onInsertBefore}
						title={f('insert new point before')}
					>
						<FiPlus />
					</Button>
					<Button
						onClick={onRemove}
						disabled={!props.area.canRemovePoints() || selectedPointContext.pointAt == null}
						title={f('remove current point')}
					>
						<img src={iconTrash} alt="" />
					</Button>
					<Button
						onClick={onInsertAfter}
						title={f('insert new point after')}
					>
						<FiPlus />
					</Button>
					<Button
						onClick={onNext}
						disabled={selectedPointContext.pointAt === null}
						title={f('select next point')}
					>
						<FiArrowRight />
					</Button>
				</ButtonGroup>
				<LonLatForm
					lonLat={lonLat}
					onApply={onApply}
					disabled={selectedPointContext.pointAt == null}
					precision={7}
					lonRef={lonInputRef}
				/>
			</>}
		</div>
	)
}

export default ZoneAreaEditor;