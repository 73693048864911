import { Address, ComponentType } from "./address";
import { AbstractResolver, ResolverThrottledError } from "./resolver";

const google = window.google;

export class GoogleResolver extends AbstractResolver {

	constructor() {
		super();
		this.geocoder = new google.maps.Geocoder();
	}

	name() {
		return 'google';
	}

	submit(entry) {
		return new Promise((resolve, reject) => {
			this.geocoder.geocode({
				location: new google.maps.LatLng(entry.latitude, entry.longitude)
			}, (results, status) => {
				if (status === 'OK') resolve(0 < results.length ? this.constructAddress(results) : null);
				else if (status === 'ZERO_RESULTS') resolve(null);
				else if (status === 'OVER_QUERY_LIMIT') reject(ResolverThrottledError.getInstance());
				else reject(new Error(status));
			});
		});
	}

	static componentTypeMap = {
		'country': ComponentType.country
		, 'administrative_area_level_1': ComponentType.subdivision1
		, 'administrative_area_level_2': ComponentType.subdivision2
		, 'administrative_area_level_3': ComponentType.subdivision3
		, 'locality': ComponentType.locality
		, 'neighborhood': ComponentType.neighborhood
		, 'route': ComponentType.street, 'street_number': ComponentType.streetNumber
		, 'street_address': ComponentType.streetAddress
		, 'intersection': ComponentType.reference, 'premise': ComponentType.reference
		, 'establishment': ComponentType.reference, 'landmark': ComponentType.reference
		, 'point_of_interest': ComponentType.reference, 'parking': ComponentType.reference
		, 'bus_station': ComponentType.reference, 'train_station': ComponentType.reference, 'transit_station': ComponentType.reference
		, 'postal_code': ComponentType.postalCode
	};

	constructAddress(results) {
		const result = results.find(({types}) => !types.find(type => type == "plus_code"));
		if (!result) return null;
		let components = null;
		if (result.address_components) {
			const entries = result.address_components
				.map(({types: [type], long_name: name}) => {
					const componentType = GoogleResolver.componentTypeMap[type];
					return componentType ? [componentType, name] : null;
				})
				.filter(Boolean)
			;
			if (0 < entries.length) components = Object.fromEntries(entries);
		}
		return result.formatted_address || components ? new Address(result.formatted_address, components) : null;
	}
}
