import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../general/Loader';
import { useI18n } from '../../../i18n';
import { cx } from '../../api';
import { actions } from '../../redux/api/assets/actions';
import { AllRoutes } from 'constants/routes';
import PagePanel from '../general/panel/PagePanel';
import SidebarLayout from '../general/layout/SidebarLayout';
import Form from '../general/form/Form';
import Button from '../general/form/Button';
import ButtonGroup from '../general/form/ButtonGroup';
import DefaultActionBar from '../share/actionbar/DefaultActionBar';
import ActionAdd from '../share/actionbar/ActionAdd';
import { FiExternalLink as IconZoneGuards } from 'react-icons/fi';
import { setPageTitle } from '../../misc/page';
import ActionBack from '../share/actionbar/ActionBack';
import EmployeeFields from '../custom/employeeManager/EmployeeFields';
import EmployeeView from '../custom/employeeManager/EmployeeView';
import EmployeeCategoryList from '../custom/employeeManager/EmployeeCategoryList';
import DevicePopupActionDispatcher from '../custom/sidebar/DevicePopupActionDispatcher';
import EmployeeImportForm from '../custom/employeeManager/EmployeeImportForm';
import EmployeeList from '../custom/employeeManager/EmployeeList';
import './employeeManagerPage.scss';

const PAGE_TITLE = 'employee manager';

/**
 * @param {Object} props
 */

function EmployeeManagerPage(props) {
	const { f, fc } = useI18n();
	const history = useHistory();
	const form = useRef(null);
	const submitHook = useRef(null);
	const pending = useRef(false);
	const addNext = useRef(false); // to make default submit
	const [employee, setEmployee] = useState();
	const [countOfAdd, setCountOfAdd] = useState(0);
	const [showAll, setShowAll] = useState(false);
	const [importedData, setImportedData] = useState(); // { emploees: [cx.ods.assets.EmployeeInfo], errors: quantity }
	const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
	const selectedId = props.match.params.id;
	const importing = props.match.params[0] == 'import';
	const modifying = props.match.params[0] == 'edit' || props.match.params[0] == 'create' || importing;
	const disabled = props.employees.pending;

	const goToInitialPage = () => {
		history.replace(AllRoutes.EmployeeManager);
	}

	useEffect(() => {
		setPageTitle(fc(PAGE_TITLE));
		if (props.employees.list == null) props.dispatch(actions.employees.load.request());
		return () => {
			if (props.employees.error) props.dispatch(actions.employees.clear());
		}
	}, []);

	useEffect(() => {
		if (!importing && importedData) history.push(`${AllRoutes.EmployeeManager}/import`);
		else if (importing && !importedData) goToInitialPage();
	}, [importedData]);

	useEffect(() => {
		if (form.current) form.current.purge();
	}, [selectedId]);

	useEffect(() => {
		if (props.employees.error) props.dispatch(actions.employees.clear());
	}, [props.match]);

	useEffect(() => {
		if (!props.employees.pending && pending.current) {
			pending.current = false;
			if (props.employees.error == null) {
				if (addNext.current) {
					setCountOfAdd(value => value + 1);
				} else history.goBack();
			}
		}
	}, [props.employees]);

	useEffect(() => {
		if (props.employees.list != null) {
			if (selectedId) {
				const selectedEmployee = props.employees.map[selectedId];
				selectedEmployee ? setEmployee(selectedEmployee) : (!importedData && goToInitialPage());
			} else setEmployee(null);
		}
	}, [props.employees.list, selectedId]);

	const submit = (next) => {
		addNext.current = next; // to make default submit
		submitHook.current();
	}

	const onActivate = () => {
		props.dispatch(actions.employees.inactivate.request({ assetId: employee.assetId, activate: Boolean(employee.inactivatedAt) }));
	}

	const onSubmit = (newEmployee) => {
		pending.current = true;
		if (employee) props.dispatch(actions.employees.update.request({ assetId: employee.assetId, employee: newEmployee }));
		else props.dispatch(actions.employees.add.request({ employee: newEmployee }));
	}

	const onAdd = () => {
		history.push(`${AllRoutes.EmployeeManager}/create`);
	}

	const onBack = () => {
		setEmployee(null);
		history.goBack();
	}

	const onSelect = (_employee) => {
		history.push(`${AllRoutes.EmployeeManager}${_employee?.assetId ? `/${_employee.assetId}` : ''}`);
	}

	const onEditEmployee = (employee) => {
		history.push(`${AllRoutes.EmployeeManager}/${employee.assetId}/edit`);
	}

	const sidebarContent = (<>
		<DefaultActionBar
			prependActions={modifying ? <ActionBack onClick={onBack} /> : <ActionAdd onClick={onAdd} />}
			hideEdit={modifying}
			hideRemove={modifying}
			disabled={disabled}
		/>
		{modifying
			? (importing
				? <EmployeeImportForm importedData={importedData} onCancel={goToInitialPage} onComplete={goToInitialPage} />
				: <Form
					className="content"
					onSubmit={onSubmit}
					submitHook={submitHook}
					objectType={cx.ods.assets.EmployeeInfo}
					disabled={disabled || !modifying}
					customRef={form}
				>
					<EmployeeFields employee={employee} optionalDependency={countOfAdd} />
					<div className="error">
						{props.employees.error}
					</div>
					<ButtonGroup>
						<Button onClick={() => submit(false)} disabled={disabled}>
							{f('save')}
						</Button>
						{employee
							? <Button onClick={onActivate} disabled={disabled}>{employee.inactivatedAt ? f('activate') : f('inactivate')}</Button>
							: <Button onClick={() => submit(true)} disabled={disabled}>{f('save and add next')}</Button>
						}
					</ButtonGroup>
				</Form>
			)
			: <EmployeeCategoryList
				selectedIds={selectedCategoryIds}
				onChange={setSelectedCategoryIds}
			/>
		}
	</>);

	let content = null;
	if (props.employees.list != null) {
		content = (
			<SidebarLayout
				className="body"
				icon={<IconZoneGuards size="20" />}
				title={importing ? f('employee import') : (modifying ? f('employee'): f('employee manager'))}
				sidebarContent={sidebarContent}
			>
				{importing
					? <EmployeeList employees={importedData.employees} onImport={setImportedData} />
					: (!modifying && <EmployeeView
						byCategories={selectedCategoryIds}
						selectedEmployee={employee}
						onSelectEmployee={onSelect}
						onEdit={onEditEmployee}
						onImport={setImportedData}
						showAll={showAll}
						setShowAll={setShowAll}
						editMode={modifying}
						disabled={disabled}
					/>)
				}
			</SidebarLayout>
		);
	} else if (props.employees.pending) {
		content = <div className="vh-center"><Loader size={Loader.Size.LG} /></div>;
	}

	return (
		<div className="app-page employee-manager">
			<DevicePopupActionDispatcher />
			<PagePanel>{content}</PagePanel>
		</div>
	);
}

EmployeeManagerPage.Url = AllRoutes.EmployeeManager;

export default connect(state => ({
	employees: state.assets.employees
}))(EmployeeManagerPage);
