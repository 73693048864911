import React, { useImperativeHandle, useState } from 'react';

import { connect } from 'react-redux';

import { useI18n } from '../../../../../i18n';
import { cx } from '../../../../api';

import { actions } from '../../../../redux/api/reports';

import GroupingPicker from '../../../share/categories/GroupingPicker';
import ObjectDeviceManager from '../../../share/devices/ObjectDeviceManager';

import Form from '../../../general/form/Form';
import GroupCheckbox from '../../../general/form/GroupCheckbox';
import Radio from '../../../general/form/Radio';
import RadioGroup from '../../../general/form/RadioGroup';

import './parametrizator.scss';

export default connect(state => ({
	state: state.reports.drivingBehaviour
}))(props => {
	const {f} = useI18n();
	const {disabled, state: {parameters, options}} = props;
	const [groupingId, setGroupingId] = useState(options?.groupingId || null);
	const [includeAssetType, setIncludeAssetType] = useState(Boolean(options?.includeAssetType));
	const [applyFilter, setApplyFilter] = useState(Boolean(Boolean(options?.filter)));
	const [includeMatching, setIncludeMatching] = useState(Boolean(options?.includeMatching));
	const [minimumIdling, setMinimumIdling] = useState(options?.filter?.minimumIdling || null);
	const [minimumViolations, setMinimumViolations] = useState(options?.filter?.minimumViolations || null);

	useImperativeHandle(props.customRef, () => ({
		submit: values => {
			const parameters = new cx.ods.reports.UtilizationReportParameters();
			parameters.timeRange = new cx.ods.TimeRange({since: props.timeRange.since, until: props.timeRange.until});
			if (0 < values.uris?.length) parameters.uris = values.uris;
			const options = {
				groupingId, includeAssetType
			};
			if (applyFilter) {
				options.includeMatching = includeMatching;
				options.filter = {};
				if (minimumIdling != null && minimumIdling !== '') options.filter.minimumIdling = Number(minimumIdling);
				if (minimumViolations != null && minimumViolations !== '') options.filter.minimumViolations = Number(minimumViolations);
			}
			props.dispatch(actions.drivingBehaviour.generate.request({parameters, options}));
		}
		, clear: () => {
			props.dispatch(actions.drivingBehaviour.export.clear());
 		}
	}));

	return <div className="device-behaviour-parameters">
		<ObjectDeviceManager
			controlName="uris"
			title={f('devices restriction')}
			disabled={disabled}
			emptyMessage={f('all devices')}
			editMode
			uris={parameters?.uris}
		/>

		<GroupingPicker
			label={f('include device grouping')} cleanable={true}
			disabled={disabled}
			categoryId={groupingId} onChange={setGroupingId}
		/>
		<Form.Control
			controlType={Form.Control.Type.Checkbox}
			label={f('include asset type')}
			disabled={disabled}
			value={includeAssetType} onChange={setIncludeAssetType}
		/>

		<Form.Control
			controlType={GroupCheckbox}
			label={f('apply filter')}
			disabled={disabled}
			value={applyFilter} onChange={setApplyFilter}
		/>
		<RadioGroup value={includeMatching} onChange={setIncludeMatching} inline>
			<Radio value={false} disabled={disabled || !applyFilter}>{f('exclude matching')}</Radio>
			<Radio value={true}  disabled={disabled || !applyFilter}>{f('include matching')}</Radio>
		</RadioGroup>

		<Form.Control
			controlType={Form.Control.Type.InputNumber}
			label={f('minimum idling') + ', ' + f({ prefix: 'units', id: '%' })}
			min={0} max={100}
			disabled={disabled || !applyFilter}
			value={minimumIdling} onChange={setMinimumIdling}
		/>

		<Form.Control
			controlType={Form.Control.Type.InputNumber}
			label={f('minimum violations') + ', ' + f('per 100 km')}
			min={0}
			disabled={disabled || !applyFilter}
			value={minimumViolations} onChange={setMinimumViolations}
		/>
	</div>;
});
