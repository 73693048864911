import React from 'react';
import { Checkbox as RsCheckbox } from 'rsuite';
import { className, fromProps } from '../../../lib/className';
import './checkbox.scss';

/**
 * For other parameters - see rsuite Checkbox component
 * @param {Object} props
 * @param {boolean} props.value
 * @param {boolean} [props.inline]
 * @param {string} [props.label]
 * @param {string} [props.title]
 * @param {boolean | undefined} [props.disabled]
 * @param {string} [props.className]
 * @param {boolean} [props.description]
 * @param {React.Ref} [props.customRef]
 * @param {function} [props.onChange]
 */

function Checkbox(props) {
	const { inline, value, onChange, customRef, description, ..._props } = props;

	const _onChange = (value, checked) => {
		if (props.onChange) props.onChange(checked);
	}

	return (
		<div ref={customRef} className={className('checkbox', fromProps(props), { inline })}>
			<RsCheckbox
				checked={value}
				onChange={_onChange}
				{..._props}
			>
				{props.label}
			</RsCheckbox>
			{description && <span className="description capitalize">{description}</span>}
		</div>
	)
}

export default Checkbox;
