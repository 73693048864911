import React, { DispatchWithoutAction, FC } from 'react';

import { useI18n } from 'i18n';
import {
  EntitiesActionsButtonsStyled,
  EntitiesActionsIconStyled,
  EntitiesActionsStyled,
  EntitiesActionsTitleStyled,
} from 'ui/molecules/entities-actions.styled';
import ActionAdd from 'core/react/share/actionbar/ActionAdd';
import ActionEdit from 'core/react/share/actionbar/ActionEdit';
import ActionRemove from 'core/react/share/actionbar/ActionRemove';
import ActionBack from 'core/react/share/actionbar/ActionBack';

interface IEntitiesActionsProps {
  isDisabled: boolean;
  icon: JSX.Element;
  title: string;
  isEditShown?: boolean;
  onBack?: DispatchWithoutAction;
  onAdd?: DispatchWithoutAction;
}

export const EntitiesActions: FC<IEntitiesActionsProps> = (props) => {
  const { isDisabled, icon, title, isEditShown, onBack, onAdd } = props;

  const { fc } = useI18n();

  return (
    <EntitiesActionsStyled>
      <EntitiesActionsIconStyled>{icon}</EntitiesActionsIconStyled>
      <EntitiesActionsTitleStyled>{fc(title)}</EntitiesActionsTitleStyled>
      <EntitiesActionsButtonsStyled>
        {!!onBack && <ActionBack disabled={isDisabled} onClick={onBack} />}
        {!!onAdd && <ActionAdd disabled={isDisabled} onClick={onAdd} />}
        {!!isEditShown && <ActionEdit disabled={isDisabled} />}
        <ActionRemove disabled={isDisabled} />
      </EntitiesActionsButtonsStyled>
    </EntitiesActionsStyled>
  );
};
