import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';

import { reducer as employeesReducer } from './employees/reducer';
import { epic as employeesEpic } from './employees/epic';

import { reducer as commuteReducer } from './commute/reducer';
import { epic as commuteEpic } from './commute/epic';

export const reducer = combineReducers({
	employees: employeesReducer,
	commute: commuteReducer
});


export const epic = combineEpics(
	employeesEpic,
	commuteEpic
);

export { actions } from './actions';
