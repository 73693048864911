import React, { useState, useEffect, useRef } from 'react';
import { useI18n } from '../../../../i18n';
import CategorySelector from './CategorySelector';
import CategoryForm from './CategoryForm';
import DefaultActionBar from '../actionbar/DefaultActionBar';
import ActionAdd from '../actionbar/ActionAdd';
import CategoryRemover from './CategoryRemover';

/**
 * @param {Object} props
 * @param {function} props.onReady
 * @param {function} props.onCancel
 * @param {Array.<number>} [props.selectedIds]
 * @param {Array.<number>} [props.comprisingIds]
 * @param {boolean} [props.isTag]
 */

function CategorySwitch(props) {
	const { f } = useI18n();
	const [adding, setAdding] = useState(false);
	const [removing, setRemoving] = useState(false);
	const pendingCategory = useRef(null);

	const { onCancel, onReady, ..._props } = props;

	const onAdd = () => {
		setAdding(true);
	}

	const onSelectApply = (values) => {
		onReady(values);
	}

	const onEditCancel = () => {
		pendingCategory.current = null;
		setAdding(false);
	}

	const onEditReady = () => {
		pendingCategory.current = null;
		setAdding(false);
	}

	const onRemoveCancel = () => {
		pendingCategory.current = null;
		setRemoving(false);
	}

	const onRemoveReady = () => {
		pendingCategory.current = null;
		setRemoving(false);
	}

	const onIntent = (intent) => {
		pendingCategory.current = intent.category;
		if (intent.type == 'edit') {
			setAdding(true);
		} else {
			setRemoving(true);
		}
	}

	useEffect(() => {
		if (props.setModalTitle) {
			props.setModalTitle(f((adding ? 'create' : 'select') + ' ' + (props.isTag ? 'tag' : 'category')));
		}
		if (props.setModalHeader) {
			if (!adding) {
				props.setModalHeader(
					<DefaultActionBar
						// hideEdit={}
						// hideRemove
						appendActions={<ActionAdd title={f('create ' + (props.isTag ? 'tag' : 'category'))} onClick={onAdd}/>}
						className="align-right"
					/>
				);
			} else {
				props.setModalHeader(null);
			}
		}
	}, [adding]);

	return (
		<div className="category-switch">
			{!props.setModalHeader && !adding &&
				<DefaultActionBar
					// hideEdit
					// hideRemove
					appendActions={<ActionAdd title={f('create property')} onClick={onAdd}/>}
				/>
			}
			{!adding && !removing &&
				<CategorySelector
					onCancel={onCancel}
					onApply={onSelectApply}
					categoryIds={props.selectedIds}
					hideHeader={!!props.setModalTitle}
					onIntent={onIntent}
					{..._props}
				/>
			}
			{adding && !removing &&
				<CategoryForm
					categoryId={pendingCategory.current && pendingCategory.current.categoryId}
					onCancel={onEditCancel}
					onReady={onEditReady}
					comprisingIds={props.comprisingIds}
					hideHeader={!!props.setModalTitle}
					standalone
					{..._props}
				/>
			}
			{removing &&
				<CategoryRemover
					categoryId={pendingCategory.current.categoryId}
					onCancel={onRemoveCancel}
					onReady={onRemoveReady}
					title={props.isTag ? 'tag' : 'category'}
					standalone
				/>
			}
		</div>
	);
}

export default CategorySwitch;
