import React, { useState, useEffect } from 'react';
import { useI18n } from '../../../../../i18n';
import DevicePropertySelector from './DevicePropertySelector';
import DevicePropertyEditor from './DevicePropertyEditor';
import DefaultActionBar from '../../../share/actionbar/DefaultActionBar';
import ActionAdd from '../../../share/actionbar/ActionAdd';
import { className, fromProps } from '../../../../lib/className';
import './devicePropertySwitch.scss';

/**
 * @param {Object} props
 * @param {function} props.onCancel
 * @param {function} props.onReady
 * @param {boolean} [props.showBundles]
 */

function DevicePropertySwitch(props) {
	const { f } = useI18n();
	const [adding, setAdding] = useState(false);

	const { onCancel, onReady, ..._props } = props;

	const onAdd = () => {
		setAdding(true);
	}

	const onSelectApply = (properties) => {
		onReady(properties);
	}

	const onEditCancel = () => {
		setAdding(false);
	}

	const onEditReady = (property) => {
		setAdding(false);
		// TODO
	}

	useEffect(() => {
		if (props.setModalTitle) {
			props.setModalTitle(f((adding ? 'create' : 'select') + ' property'));
		}
		if (props.setModalHeader) {
			if (!adding) {
				props.setModalHeader(
					<DefaultActionBar
						hideEdit
						hideRemove
						prependActions={<ActionAdd title={f('create property')} onClick={onAdd}/>}
						className="align-right"
					/>
				);
			} else {
				props.setModalHeader(null);
			}
		}
	}, [adding]);

	return (
		<div className={className('property-switch', 'device', fromProps(props))}>
			{!props.setModalHeader && !adding &&
				<DefaultActionBar
					hideEdit
					hideRemove
					prependActions={<ActionAdd title={f('create property')} onClick={onAdd}/>}
				/>
			}
			<div>
				{!adding &&
					<DevicePropertySelector
						onCancel={onCancel}
						onApply={onSelectApply}
						hideHeader={!!props.setModalTitle}
						showBundles={props.showBundles}
						{..._props}
					/>
				}
				{adding &&
					<DevicePropertyEditor
						onCancel={onEditCancel}
						onReady={onEditReady}
						hideHeader={!!props.setModalTitle}
						{..._props}
					/>
				}
			</div>
		</div>
	);
}

export default DevicePropertySwitch;