import React, { useEffect, useState, useContext } from 'react';
import { resolve } from '../../../misc/location/resolve';
import locationIcon from '../../../img/icons/location.png';
import Loader from '../Loader';
import { ResizeContext } from '../../../misc/ResizeContext';
import { className } from '../../../lib/className';
import './address.scss';

/**
 * @param {Object} props
 * @param {cx.ods.devices.MessageDetails|Object} prpos.message - | {lon:, lat:}
 * @param {boolean} [props.full]
 * @param {boolean} [props.showIcon]
 */

function Address(props) {
	const [address, setAddress] = useState(null);
	const [resolving, setResolving] = useState(false);
	const resizeContext = useContext(ResizeContext);

	const location = props.message && props.message.latitude && props.message.longitude
		? { lon: props.message.longitude, lat: props.message.latitude } : props.location
	;

	useEffect(() => {
		if (address && resizeContext?.resize) resizeContext.resize();
	}, [address]);

	function handleReady(entry) {
		setResolving(false);
		setAddress(entry.getAddress());
	}

	function handleBusy() {
		setResolving(true);
	}

	useEffect(() => {
		setAddress(null);
		if (location == null) return;
		const entry = resolve(location.lon, location.lat, handleReady, handleBusy, location);
		return () => entry.removeObservers(location);
	}, [props.message, props.location]);

	const addressString = address?.format();
	const locationString = location ? "(" + location.lon.toFixed(6) + "; " + location.lat.toFixed(6) + ")" : "n/a";

	return (
		<span className={className('location', { 'resolved': !!address })}>
			{props.showIcon &&
				<span className="location-icon">
					<img src={locationIcon} alt="" />
				</span>
			}
			{addressString &&
				<span>{addressString}</span>
			}
			{(!addressString || props.full) &&
				<span className="coordinates">{locationString}</span>
			}
			{resolving &&
				<Loader className="loader" size={Loader.Size.XS} />
			}
		</span>
	);
}

export default Address;
