import React, { useState, useEffect } from 'react';
import { useI18n } from '../../../../i18n';
import { className, fromProps } from '../../../lib/className';
import Modal from '../../general/Modal';
import Form from '../../general/form/Form';
import ZoneSet from './ZoneSet';
import ZoneSelectMap from '../../custom/zones/viewer/ZoneSelectMap'; // TODO
import ActionAdd from '../actionbar/ActionAdd';
import ZoneSelector from './ZoneSelector';
import './objectZoneManager.scss';

const MAP_NAME = 'objectZoneManagerMap';
/**
 * @param {Object} props
 * @param {Array.<number>} [props.zoneIds]
 * @param {boolean} [props.editMode]
 * @param {boolean} [props.disabled]
 * @param {string} [props.controlName]
 */

function ObjectZoneManager(props) {
	const { f } = useI18n();
	const [zoneIds, setZoneIds] = useState([]);
	const [selectedIds, setSelectedIds] = useState([]);
	const [lastSelectedId, setLastSelectedId] = useState(null);
	const [adding, setAdding] = useState(false);

	useEffect(() => {
		setZoneIds(props.zoneIds ? props.zoneIds : []);
	}, [props.zoneIds]);

	const onChange = (ids) => {
		setZoneIds(ids);
		setAdding(false);
	}

	const onSelect = (zoneId) => {
		setSelectedIds([zoneId]);
		setLastSelectedId(zoneId);
	}

	const onChangeSelectOnMap = (zoneIds) => {
		const zoneId = zoneIds.length > 0 ? zoneIds[0] : null;
		setSelectedIds(zoneIds);
		setLastSelectedId(zoneId)
	}

	const closeModal = () => {
		setAdding(false);
	}

	return (
		<div className={className('object-zone-manager', fromProps(props))}>
			<div className="zones">
				<div className="header">
					<span className="capitalize">{f('zones')}</span>
					{props.editMode &&
						<ActionAdd disabled={props.disabled} onClick={() => setAdding(true)} />
					}
				</div>
				<Form.Control
					controlType={ZoneSet}
					controlName={props.controlName || 'zoneIds'}
					value={zoneIds}
					onChange={setZoneIds}
					selectedIds={selectedIds}
					lastSelectedId={lastSelectedId}
					editMode={props.editMode}
					onClick={onSelect}
					emptyMessage={f('no items yet')}
				/>
			</div>
			<ZoneSelectMap
				mapName={MAP_NAME}
				zoneIds={zoneIds}
				selectedIds={selectedIds}
				onChange={onChangeSelectOnMap}
				singleSelection
				noDrop
			/>
			{adding &&
				<Modal
					className="object-zone-manager"
					title={f('select zones')}
					onClose={closeModal}
					fullHeight
					fullWidth
				>
					<ZoneSelector
						zoneIds={zoneIds}
						onSubmit={onChange}
						onCancel={closeModal}
					/>
				</Modal>
			}
		</div>
	);
}

export default ObjectZoneManager;