import React, { useState, useEffect } from 'react';
import ObjectDeviceManager from '../../share/devices/ObjectDeviceManager';
import FuelLevelProcessorField from './FuelLevelProcessorField';
import Form from '../../general/form/Form';
import './fuelProcessorsView.scss';

/**
 * @param {Object} props
 * @param {cx.ods.processors.FuelLevelProcessor} [props.fuelProcessor]
 * @param {Array.<string>} [props.excludeUris]
 * @param {boolean} [props.editMode]
 * @param {boolean} [props.disabled]
 */

function FuelProcessorsView(props) {
	const [deviceUris, setUris] = useState(props.fuelProcessor && props.fuelProcessor.uris ? props.fuelProcessor.uris : []);
	const [voltageLevels, setVoltageLevels] = useState([]);

	useEffect(() => {
		setUris(props.fuelProcessor ? props.fuelProcessor.uris : []);
		setVoltageLevels(props.fuelProcessor && props.fuelProcessor.uris ? props.fuelProcessor.voltageLevels : []);
	}, [props.fuelProcessor]);

	let content = null;

	if (props.fuelProcessor || props.editMode) {
		content = (
			<div className="fuel-processor body">
				<ObjectDeviceManager
					excludeUris={props.excludeUris}
					uris={deviceUris}
					setUris={setUris}
					disabled={props.disabled}
					editMode={props.editMode}
				/>
				<Form.Control
					controlType={FuelLevelProcessorField}
					controlName={'voltageLevels'}
					value={voltageLevels}
					onChange={setVoltageLevels}
					uris={deviceUris}
					editMode={props.editMode}
					disabled={props.disabled}
				/>
			</div>
		);
	}
	return (content);
}

export default FuelProcessorsView;
