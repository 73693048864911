import React from 'react';
import { useI18n } from '../../../../i18n';
import Form from '../../general/form/Form';

/**
 * @param {Object} props
 * @param {function} props.onCancel
 * @param {function} porps.onSubmit
 * @param {cx.ods.processors.DevicePresenceDetector} [props.detector]
 */

function PresenceDetectorFields(props) {
	const { f } = useI18n();
	return (
		<div className="presence-detector-fields">
			<Form.ControlGroup>
				<Form.Control
					controlType={Form.Control.Type.Input}
					controlValidator={(value) => value == null && f('please enter a value')}
					autoFocus
					label={f('name')}
					controlName="name"
					initialValue={props.detector && props.detector.name}
				/>
				<Form.Control
					controlType={Form.Control.Type.Checkbox}
					controlName="onPresent"
					label={f('on present')}
					initialValue={props.detector && props.detector.onPresent}
				/>
				<Form.Control
					controlType={Form.Control.Type.Checkbox}
					controlName="onAbsent"
					label={f('on absent')}
					initialValue={props.detector && props.detector.onAbsent}
				/>
			</Form.ControlGroup>
		</div>
	);
}

export default PresenceDetectorFields;