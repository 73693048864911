import { ActionGeneratorBuilder } from '../../actions';

const defaultState = {
	currentCategoriesIds: null,
	hiddenCategoriesIds: null,
	isDeviceFocusDisabled: false,
};

const actions = new ActionGeneratorBuilder('categoriesVisibility')
	.type('setCategoriesVisibility', { currentCategoriesIds: true, hiddenCategoriesIds: true, isDeviceFocusDisabled: true })
	.type('setAllCategoriesVisible')
	.type('setAllCategoriesHidden')
	.type('setDeviceFocusDisabled', { isDeviceFocusDisabled: true })
	.build();

function reducer(state = defaultState, action) {
	switch (action.type) {
		case actions.setCategoriesVisibility.type:
			return {
				...state,
				currentCategoriesIds: action.currentCategoriesIds || state.currentCategoriesIds,
				hiddenCategoriesIds: action.hiddenCategoriesIds || state.hiddenCategoriesIds,
				isDeviceFocusDisabled: action.isDeviceFocusDisabled,
			};
		case actions.setAllCategoriesVisible.type:
			return { ...state, hiddenCategoriesIds: [], isDeviceFocusDisabled: true };
		case actions.setAllCategoriesHidden.type:
			return { ...state, hiddenCategoriesIds: [...state.currentCategoriesIds, 0] };
		case actions.setDeviceFocusDisabled.type:
			return { ...state, isDeviceFocusDisabled: action.isDeviceFocusDisabled };
		default:
			return state;
	}
}

export { actions, reducer };
