import React, { useEffect, useState } from 'react';
import { useI18n } from '../../../../../i18n';
import { connect } from 'react-redux';
import { meta } from '../../../../redux/api/meta';
import DevicePropertyTile from '../DevicePropertyTile';
import Button from '../../../general/form/Button';
import Input from '../../../general/form/Input';
import { isBundle } from '../../../share/properties/meta/filter';
import { filter as metafilter } from '../../../share/properties/meta/filter';
import { className, fromProps } from '../../../../lib/className';
import { sortByName } from '../../../../lib/sorting';
import './devicePropertySelector.scss';

/**
 * Does not show bundles by default, use showBundles parameter.
 * @param {Object} props
 * @param {function} props.onApply
 * @param {function} props.onCancel
 * @param {boolean} [props.hideProperties]
 * @param {boolean} [props.showBundles]
 * @param {Array.<cx.ods.meta.ComposableProperty>} [props.extraProperties]
 * @param {Array.<number>} [props.excludeIds]
 * @param {boolean} [props.singleSelection]
 * @param {boolean} [props.hideHeader]
 */

function DevicePropertySelector(props) {
	const { f } = useI18n();
	const [criterion, setCriterion] = useState('');
	const [selected, setSelected] = useState([]);

	useEffect(() => {
		if (props.properties.list == null && !props.properties.pending) {
			props.dispatch(meta.properties.actions.load.request());
		}
	}, []);

	const onSelect = (property) => {
		if (props.singleSelection) {
			setSelected([property.propertyId]);
		} else {
			const filtered = selected.filter(id => id != property.propertyId);
			if (selected.length != filtered.length) {
				setSelected(filtered);
			} else {
				setSelected(selected.concat(property.propertyId));
			}
		}
	}

	const onApply = () => {
		const map = {...props.properties.map};
		props.extraProperties && props.extraProperties.forEach(property => {
			map[property.propertyId] = property;
		});
		props.onApply(selected.map(id => map[id]));

	}

	const filter = (property) => {
		let passed = props.showBundles ? true : !isBundle(property);
		if (passed) passed = !(props.hideProperties && !isBundle(property));
		if (passed) passed = metafilter(property, criterion);
		if (passed && props.excludeIds != null) passed = !props.excludeIds.includes(property.propertyId);
		return passed;
	}

	let items = null;
	if (props.properties.list != null) {
		let source = [...props.properties.list];
		if (props.extraProperties != null) source = source.concat(props.extraProperties);
		const filtered = source.filter(filter);
		items = sortByName(filtered).map(property =>
			<DevicePropertyTile
				key={property.propertyId}
				property={property}
				onClick={onSelect}
				className={className({ 'selected': selected.includes(property.propertyId) })}
			/>
		);
	}

	return (
		<div className={className('property-selector', 'device', fromProps(props))}>
			{!props.hideHeader && <div className="header">{f('select property')}</div>}
			<div className="filter">
				<label>{f('filter')}</label>
				<Input value={criterion} onChange={(value) => setCriterion(value)}></Input>
			</div>
			<div className="content">{items}</div>
			<div className="footer">
				<Button onClick={props.onCancel}>{f('cancel')}</Button>
				<Button onClick={onApply} disabled={selected.length == 0}>{f('apply')}</Button>
			</div>
		</div>
	);
}

export default connect(state => {
	return {
		properties: state.meta.properties,
		datatypes: state.meta.datatypes
	}
})(DevicePropertySelector);